








import { Component, Prop, Vue } from 'vue-property-decorator';
import AuthServices from '@/services/auth-services';
import CommonUtils from '@/utils/common-utils';
import { cmnSession } from '@/common/planbook-storage';

@Component
export default class OAuth2Callback extends Vue {
  @Prop({ required: true })
  code!: string;

  @Prop({ required: true })
  state!: string;

  mounted() {
    AuthServices.exchangeCodeForTokens(this.code).then(() => {
      const redirect = cmnSession.get('savedRedirectUri');
      this.$store.commit('settings/setInitialSignIn', true);
      if (CommonUtils.hasText(redirect)) {
        this.$router.push(redirect).then(() => {
          cmnSession.set('savedRedirectUri', '');
        });
      } else {
        this.$router.push('/plans');
      }
    }).catch(() => {
      this.$router.push('/error');
    });
  }
}
