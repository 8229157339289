



















































































































































import { PlanbookStorage } from '@/common/planbook-storage';
import Teachers from '@/store/modules/teachers';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import RosteredItemIndicator from '@/components/integration/RosteredItemIndicator.vue';

const settings = namespace('settings');
const plans = namespace('plans');
const teachers = namespace('teachers');
const schoolstatus = namespace('schoolstatus');
const index = namespace('index');
const integration = namespace('integration');

@Component({
  components: {
    RosteredItemIndicator
  }
})
export default class Index extends Vue {
  localSearch = '';
  localIsLoading = false;
  localIsTeacherListFullWidth = false;
  showAllTeachersOption = false;
  allTeachersSelected = false;
  localStatuses: Array<any> = [];

  @Prop({ type: Boolean, default: false })
  isTeacherListFullWidth!: boolean;

  @Prop({ type: String, default: 'left' })
  dockPosition!: string;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getSchoolStatuses')
  allSchoolStatus!: Array<any>;

  @settings.Getter('getSchools')
  schools!: any;

  @settings.Getter('getPrimarySchool')
  primarySchool!: any;

  @teachers.Getter('getTeachersOnly')
  adminItems!: any[];

  @plans.Getter
  getMonday!: string;

  @teachers.Action
  changeTeacher!: (params: any) => Promise<any>;

  @schoolstatus.Action
  weekSubmissions!: (params: any) => Promise<any>;

  @teachers.Action
  loadTeachers!: () => Promise<any>;

  @index.Getter
  hasBanner!: boolean;

  @integration.Getter
  isSchoolRostered!: (param: any) => boolean;

  @integration.Getter
  isTeacherRostered!: (param: any) => boolean;

  $refs!: {
    teacherListFilterMenu: any
  }

  get haveMultipleSchools() {
    return this.schools.length > 1;
  }

  get filterSelections() {
    return this.schoolItems;
  }

  get schoolItems() {
    const items = [{ text: this.$t('allSchoolsLabel'), value: 0, isItemRostered: false }];
    this.schools.forEach((s: any) => {
      items.push({ text: s.schoolName, value: +s.schoolId, isItemRostered: this.isSchoolRostered(s) });
    });

    return items;
  }

  get dockPositionItems() {
    console.log(this.localDockPosition);
    return [
      {
        value: 'right',
        text: 'Dock Right'
      },
      {
        value: 'left',
        text: 'Dock Left'
      }
    ].filter(item => item.value !== this.localDockPosition);
  }

  get getAdminItems() {
    const that = this;
    const prepend = (this.showAllTeachersOption) ? [{ photoUrl: 'https://cdn.planbook.com/images/planbook-icon.svg', displayName: this.$t('allTeachersLabel'), teacherId: 0 }] : [];
    const adminItems = prepend.concat(this.adminItems);
    return adminItems.map((t:any) => {
      t.isItemRostered = that.isTeacherRostered(t);
      return t;
    });
  }

  get tableHeight() {
    return this.browserHeight - this.tableHeightOffset;
  }

  get tableHeightOffset() {
    const bannerElement:any = document.getElementsByClassName('banner');
    if (this.localIsTeacherListFullWidth && (this.$currentUser.isDistrictAdmin || this.haveMultipleSchools)) {
      return 170;
    } else if (this.hasBanner || CommonUtils.isNotEmpty(bannerElement)) {
      let totalHeight = 0;
      for (const el of bannerElement) {
        totalHeight += el.clientHeight;
      }
      return (this.localIsTeacherListFullWidth && (this.$currentUser.isDistrictAdmin || this.haveMultipleSchools)) ? totalHeight + 170 : totalHeight + 120;
    } else {
      return 120;
    }
  }

  get itemsPerPage(): number {
    return -1;
  }

  get localHeadersAvatarsOnly() {
    return [
      {
        width: 40,
        text: '',
        value: 'photoUrl',
        sortable: false,
        filterable: false,
        groupable: false
      }
    ];
  }

  get localHeaders() {
    return [
      {
        width: 40,
        text: '',
        value: 'photoUrl',
        sortable: false,
        filterable: false,
        groupable: false
      },
      {
        text: '',
        value: 'name',
        align: 'start',
        sortable: false
      }
    ];
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get currentTeacherId(): number {
    return +this.$store.state.settings.currentTeacherId;
  }

  set currentTeacherId(value: number) {
    this.$store.commit('settings/setCurrentTeacherId', value);
  }

  get currentYearId(): number {
    return +this.$store.state.settings.currentYearId;
  }

  set currentYearId(value: number) {
    this.$store.commit('settings/setCurrentYearId', value);
  }

  get localDockPosition() {
    return this.dockPosition;
  }

  set localDockPosition(value: string) {
    this.$emit('update:dockPosition', value);
  }

  get currentSchoolId() {
    if (this.$store.state.teachers.currentSchoolId === null) {
      return this.primarySchool.schoolId;
    }

    return this.$store.state.teachers.currentSchoolId;
  }

  set currentSchoolId(val:any) {
    this.$store.commit('teachers/setCurrentSchoolId', val);
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  loadStatusBars(p?: any) {
    const yearId = (p) ? p.yearId : this.currentYearId;
    const monday = this.getMonday || DateTimeUtils.getMonday(DateTimeUtils.toDate(DateTimeUtils.currentDateAddDays(0)));
    this.weekSubmissions({ yearId: yearId, teacherId: this.currentTeacherId, monday: monday, allTeachers: true }).then((d: any) => {
      this.localStatuses = d.yearlist;
    })
  }

  reloadTeacherStatusBar() {
    this.weekSubmissions({ yearId: this.currentYearId, teacherId: this.currentTeacherId, monday: this.getMonday, allTeachers: false }).then((d: any) => {
      const index = this.localStatuses.findIndex((s:any) => { return s.teacherId === this.currentTeacherId; });
      this.localStatuses[index] = d.yearlist[0];
    });
  }

  hasNotification(item: any) {
    return (item.newCommentT === 'Y');
  }

  getItemClass(item: any): string {
    if (this.allTeachersSelected) {
      if (item.teacherId === 0) {
        if (item.photoUrl && item.photoUrl !== '') {
          return 'data-table-selected-with-photourl';
        } else {
          return 'data-table-selected';
        }
      } else {
        if (item.photoUrl && item.photoUrl !== '') {
          return '';
        } else {
          return 'data-table-not-selected';
        }
      }
    } else if (item.teacherId === this.currentTeacherId) {
      if (item.photoUrl && item.photoUrl !== '') {
        return 'data-table-selected-with-photourl';
      } else {
        return 'data-table-selected';
      }
    } else {
      if (item.photoUrl && item.photoUrl !== '') {
        return '';
      } else {
        return 'data-table-not-selected';
      }
    }
  }

  getStatusColor(teacher: any) {
    const schoolStatusId = this.getAppliedStatus(teacher);
    if (schoolStatusId === 0) {
      return this.$vuetify.theme.currentTheme.info;
    }
    const showingStatus = this.allSchoolStatus.find((s: any) => s.schoolStatusId === schoolStatusId);
    return (showingStatus || { color: this.$vuetify.theme.currentTheme.info }).color;
  }

  getAppliedStatus(teacher: any) {
    if (this.localStatuses && this.localStatuses.length > 0) {
      const teacherYears = this.localStatuses.filter((s: any) => s.teacherId === teacher.teacherId);
      if (teacherYears.length === 0) {
        return 0; // no submission
      } else {
        if (teacherYears[0].statuses.find((s: any) => s === 2)) {
          return 2; // reviewed
        } else if (teacherYears[0].statuses.find((s: any) => s === 1)) {
          return 1; // submitted
        } else {
          return 0; // no submission
        }
      }
    }
    return 0;
  }

  getDisplayName(teacher: any) {
    return Teachers.getDisplayName(teacher);
  }

  getFullName(teacher: any) {
    let fullName = '';
    fullName += CommonUtils.getOrDefaultTo(teacher.firstName, '');
    if (CommonUtils.hasText(fullName)) {
      fullName += ' ' + CommonUtils.getOrDefaultTo(teacher.lastName, '');
    } else {
      fullName += CommonUtils.getOrDefaultTo(teacher.lastName, '');
    }

    return fullName;
  }

  getInitials(teacher: any) {
    try {
      let initials = '';
      if (CommonUtils.hasText(teacher.firstName)) {
        initials += teacher.firstName[0];
      }

      if (CommonUtils.hasText(teacher.lastName)) {
        initials += teacher.lastName[0];
      }

      if (
        CommonUtils.hasNoText(initials) &&
        CommonUtils.hasText(teacher.displayName)
      ) {
        initials += teacher.displayName[0];
      }

      if (CommonUtils.hasNoText(initials)) {
        initials += teacher.emailAddress[0];
      }
      return initials.toUpperCase();
    } catch (e) {
      console.log(e);
    }
  }

  teacherChanged(item: any) {
    const param = { yearId: +item.currentYearId, teacherId: item.teacherId };
    this.allTeachersSelected = (item.teacherId === 0);
    if (this.allTeachersSelected) {
      this.$eventBus.$emit('schoolYearChanged', { yearId: 0, teacherId: 0 });
    } else {
      this.changeTeacher(param).then(() => {
        if (this.localPage === 'messages' && this.hasNotification(item)) {
          item.newCommentT = 'N';
        }
        this.$eventBus.$emit('schoolYearChanged', { yearId: this.currentYearId, teacherId: this.currentTeacherId });
      })
    }
  }

  customFilter(value: string, search: string, item: any) {
    return search !== null &&
          item !== null &&
          (this.getDisplayName(item).toLowerCase().includes(search.toLowerCase()) ||
          this.getFullName(item).toLowerCase().includes(search.toLowerCase()) ||
          item.emailAddress.toLowerCase().includes(search.toLowerCase()));
  }

  teacherListWidthChanged() {
    this.localIsTeacherListFullWidth = !this.localIsTeacherListFullWidth
    this.$eventBus.$emit('teacherListWidthChanged', this.localIsTeacherListFullWidth);
  }

  created() {
    if (this.localPage === 'messages') {
      this.showAllTeachersOption = true;
    }
    this.localIsTeacherListFullWidth = this.isTeacherListFullWidth;
    this.loadStatusBars();
    this.$eventBus.$on('schoolYearChanged', this.loadStatusBars)
    this.$eventBus.$on('schoolStatusUpdate', () => {
      this.reloadTeacherStatusBar();
    });
    this.$eventBus.$on('changeWeek', () => {
      this.loadStatusBars();
    })
    this.$eventBus.$on('showAllTeachersOption', () => {
      this.showAllTeachersOption = true;
    });
    this.$eventBus.$on('hideAllTeachersOption', () => {
      this.showAllTeachersOption = false;
      this.allTeachersSelected = false;
    });
  }

  @Watch('currentSchoolId')
  onCurrentSchoolIdChanged() {
    CommonUtils.showLoading();
    this.$store.commit('teachers/setHaveTeachers', false, { root: true });
    this.loadTeachers().then(() => {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          const teacherId = this.$store.state.settings.currentTeacherId;
          const yearId = this.$store.state.settings.currentYearId;
          PlanbookStorage.set('currentTeacherId', +teacherId);
          PlanbookStorage.set('currentYearId', 'T' + teacherId, +yearId);
          this.$store.commit('reporting/reset', {}, { root: true });
          this.$store.commit('reporting/init', {}, { root: true });
          this.teacherChanged({ teacherId, yearId });
        });
      })
      return Promise.resolve();
    }).finally(CommonUtils.hideLoading);
  }

  destroyed() {
    this.$eventBus.$off('schoolYearChanged', this.loadStatusBars);
    this.$eventBus.$off('schoolStatusUpdate');
    this.$eventBus.$off('changeWeek');
    this.$eventBus.$off('showAllTeachersOption');
    this.$eventBus.$off('hideAllTeachersOption');
  }
}
