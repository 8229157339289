

















































































import LessonEditorForm from '@/components/lessons/LessonEditorForm.vue';
import LessonActions from '@/components/lessons/LessonActions.vue';
import LessonEditorHeader from '@/components/lessons/LessonEditorHeader.vue';
import BaseModalLessonNav from '../core/BaseModalLessonNav.vue';
import LessonServices from '@/services/lesson-services';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { SnotifyToast } from 'vue-snotify';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import { FormError } from '@/errors';
import moment from 'moment';

const plans = namespace('plans');
const lessons = namespace('lessons');
const settings = namespace('settings');
const lessonlists = namespace('lessonlists');

@Component({
  components: {
    LessonEditorForm,
    LessonActions,
    LessonEditorHeader,
    BaseModalLessonNav,
    Confirm
  }
})
export default class LessonEditor extends Vue {
  @Prop({
    required: true,
    type: Object,
    default: () => {
      return { loadData: true, data: { date: '' } };
    }
  })
  input!: any;

  @Prop({ type: Boolean, default: true })
  isModal!: boolean;

  @Prop({ type: Boolean })
  value!: boolean;

  autoSaveId = 0;

  @plans.State
  viewType!: string;

  @lessons.State
  saving!: boolean;

  @lessons.State
  updatedFields!: Set<string>;

  @lessons.State
  classId!: number;

  @lessons.State
  date!: string;

  @lessons.State
  lesson!: any;

  @lessons.State
  extraLesson!: number;

  @settings.Getter('getAutoSaveInterval')
  autoSaveInterval!: number;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @lessonlists.Getter('getSelectedLessonBankClassId')
  selectedLessonBankClassId!: number;

  @lessons.Getter
  headerTitle!: string;

  @lessons.Action
  undoSave!: (params: any) => Promise<any>;

  @lessons.Action('saveLesson')
  saveLessonByNavigation!: (params: any) => Promise<any>;

  @lessons.Getter
  cloneState!: () => any;

  @lessons.State
  sections!: Array<any>;

  @plans.Action
  prevLessonsEvents!: (param?: any) => Promise<any>;

  @plans.Action
  nextLessonsEvents!: (param?: any) => Promise<any>;

  @plans.Action
  gotoLessonsEvents!: (param: Date) => Promise<any>;

  @settings.Getter
  getCurrentYear!: any;

  $refs!: {
    form: LessonEditorForm;
    confirm: Confirm;
  };

  get localIsReadonly() {
    return this.input.readonly || false;
  }

  get stackMode():boolean {
    if (this.input.lessonListsView) {
      return this.$store.state.lessons.listStackMode;
    } else {
      return this.$store.state.lessons.stackMode;
    }
  }

  set stackMode(val:boolean) {
    if (this.input.lessonListsView) {
      this.$store.commit('lessons/setIsListStackMode', val);
    } else {
      this.$store.commit('lessons/setIsStackMode', val);
    }
  }

  get currentSubjectId() {
    return +this.$store.state.settings.currentSubjectId;
  }

  get isCalledByLessonBanks() {
    if (this.localPage === 'lesson-banks') {
      return true;
    } else {
      return false;
    }
  }

  get editLink(): string {
    return this.$store.state.lessons.editLink;
  }

  get hasLessonNav() {
    return (CommonUtils.getPage(this.$route) === 'plans' || this.input.lessonListsView) && this.isModal && this.input.data.date && !this.editLink;
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get willIncludeClasswork() {
    const excludes:any[] = LessonServices.excludedLessonActions(this.input.data, this.userMode, this.localPage);
    return !excludes.includes('addClasswork');
  }

  get enabledSections() {
    const enabledSections = this.sections.filter(s => s.enabled)
    if (this.localPage === 'plans' || this.willIncludeClasswork) {
      enabledSections.push({
        section: 'classwork',
        label: this.$t('classworkLabel'),
        enabled: true
      })
    }

    return enabledSections
  }

  get localActiveTab() {
    return this.enabledSections.findIndex(s => s.active);
  }

  isDirty() {
    if (this.isCalledByLessonBanks) {
      return !this.saving && this.updatedFields && this.updatedFields.size > 0;
    } else {
      return (
        !this.saving &&
        this.classId > 0 &&
        CommonUtils.hasText(this.date) &&
        this.updatedFields &&
        this.updatedFields.size > 0
      );
    }
  }

  get autoSaveIntervalMillis() {
    return this.autoSaveInterval * 1000;
  }

  get localValue() {
    return this.value;
  }

  set localValue(val: boolean) {
    this.$emit('input', val);
  }

  get currentYearFirstDay() {
    return moment(this.getCurrentYear.firstDay, 'MM/DD/YYYY');
  }

  get currentYearLastDay() {
    return moment(this.getCurrentYear.lastDay, 'MM/DD/YYYY');
  }

  get fullScreen() {
    return this.$store.state.index.fullScreen;
  }

  set fullScreen(val: boolean) {
    this.$store.commit('index/setFullScreen', val, { root: true });
  }

  typeChange() {
    this.input.loadData = false
  }

  async findPreviousLesson() {
    const currentLesson = this.input.data;
    const currentLessonId = currentLesson.lessonId || this.lesson.lessonId;
    const dayIndex = this.dayObjects.findIndex((d: any) => d.date === currentLesson.date);
    const associatedDayObj = this.dayObjects[dayIndex];
    const lessonIndex = associatedDayObj.objects.findIndex((l: any) => l.lessonId === currentLessonId && l.extraLesson === currentLesson.extraLesson && l.classId === currentLesson.classId && l.startTime === currentLesson.startTime)

    const prevLesson = this.searchLessonsBackward(currentLesson.classId, dayIndex, lessonIndex);
    if (prevLesson !== null) {
      return prevLesson;
    } else {
      let prevWeekLesson = null;
      do {
        prevWeekLesson = await this.prevLessonsEvents({ skipNoSchoolDays: true }).then(() => {
          const prevLesson = this.searchLessonsBackward(currentLesson.classId, this.dayObjects.length - 1, this.dayObjects[this.dayObjects.length - 1].objects.length);
          return prevLesson;
        });
      } while (prevWeekLesson === null && moment(this.dayObjects[this.dayObjects.length - 1].date, 'MM/DD/YYYY').isAfter(this.currentYearFirstDay));
      return prevWeekLesson;
    }
  }

  async findNextLesson() {
    const currentLesson = this.input.data;
    const currentLessonId = currentLesson.lessonId || this.lesson.lessonId;
    const dayIndex = this.dayObjects.findIndex((d: any) => d.date === currentLesson.date);
    const lessonIndex = this.dayObjects[dayIndex].objects.findIndex((l: any) => l.lessonId === currentLessonId && l.extraLesson === currentLesson.extraLesson && l.classId === currentLesson.classId && l.startTime === currentLesson.startTime)

    const nextLesson = this.searchLessonsForward(currentLesson.classId, dayIndex, lessonIndex);
    if (nextLesson !== null) {
      return nextLesson;
    } else {
      let nextWeekLesson = null;
      do {
        nextWeekLesson = await this.nextLessonsEvents({ skipNoSchoolDays: true }).then(() => {
          const nextLesson = this.searchLessonsForward(currentLesson.classId, 0, 0);
          return nextLesson;
        });
      } while (nextWeekLesson === null && moment(this.dayObjects[0].date, 'MM/DD/YYYY').isBefore(this.currentYearLastDay));
      return nextWeekLesson;
    }
  }

  get dayObjects() {
    const viewType = this.$store.state.plans.viewType;
    if (viewType === 'C') {
      return this.$store.state.plans.currentCycleData.days;
    } else if (viewType === 'M') {
      return this.$store.state.plans.currentMonthData.days;
    } else {
      return this.$store.state.plans.currentData.days;
    }
  }

  searchLessonsBackward(classId: number, dayIndex: number, lessonIndex: number) {
    for (let i = lessonIndex - 1; i >= 0; i--) {
      const examineLesson = this.dayObjects[dayIndex].objects[i];
      if (examineLesson.classId === classId) {
        return examineLesson;
      }
    }
    for (let d = dayIndex - 1; d >= 0; d--) {
      for (let i = this.dayObjects[d].objects.length - 1; i >= 0; i--) {
        const examineLesson = this.dayObjects[d].objects[i];
        if (examineLesson.classId === classId) {
          return examineLesson;
        }
      }
    }
    return null;
  }

  searchLessonsForward(classId: number, dayIndex: number, lessonIndex: number) {
    for (let i = lessonIndex + 1; i < this.dayObjects[dayIndex].objects.length; i++) {
      const examineLesson = this.dayObjects[dayIndex].objects[i];
      if (examineLesson.classId === classId) {
        return examineLesson;
      }
    }
    for (let d = dayIndex + 1; d < this.dayObjects.length; d++) {
      for (let i = 0; i < this.dayObjects[d].objects.length; i++) {
        const examineLesson = this.dayObjects[d].objects[i];
        if (examineLesson.classId === classId) {
          return examineLesson;
        }
      }
    }
    return null;
  }

  async navigateLeft() {
    if (this.input.lessonListsView) {
      this.previousLesson();
      return;
    }
    if (await this.$refs.form.validateForm()) {
      const tabIdx = this.localActiveTab;
      if (this.$store.state.lessons.updatedFields.size > 0) {
        const title = this.headerTitle;
        const cloneState = this.cloneState();
        this.saveLessonByNavigation({ isCalledByLessonNavigation: true, state: cloneState }).then(() => {
          if (this.showSnackbarNotifications) {
            this.showNotification(title, cloneState);
          }
        })
      }
      this.findPreviousLesson().then((lesson) => {
        this.goToLesson(lesson, tabIdx);
      })
    }
  }

  async navigateRight() {
    if (this.input.lessonListsView) {
      this.nextLesson();
      return;
    }
    if (await this.$refs.form.validateForm()) {
      const tabIdx = this.localActiveTab;
      if (this.$store.state.lessons.updatedFields.size > 0) {
        const title = this.headerTitle;
        const cloneState = this.cloneState();
        this.saveLessonByNavigation({ isCalledByLessonNavigation: true, state: cloneState }).then(() => {
          if (this.showSnackbarNotifications) {
            this.showNotification(title, cloneState);
          }
        })
      }
      this.findNextLesson().then((lesson) => {
        this.goToLesson(lesson, tabIdx);
      })
    }
  }

  goToLesson(lesson: any, tabIdx: number) {
    if (lesson) {
      if (this.$store.state.plans.viewType === 'D') {
        this.gotoLessonsEvents(moment(lesson.date).toDate())
      }
      this.$eventBus.$emit('openSubPage', {
        type: 'lesson',
        modal: true,
        input: {
          loadData: true,
          data: lesson,
          tab: tabIdx
        }
      });
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          const e = document.querySelector('.lesson-nav-container input') as HTMLElement;
          e.focus();
        });
      })
    } else {
      this.$eventBus.$emit('closeSubPage');
    }
  }

  doApply() {
    CommonUtils.showLoading();
    if (this.isCalledByLessonBanks) {
      return this.$refs.form
        .saveLesson({
          isCalledByLessonBank: true
        })
        .then(() => {
          if (this.showSnackbarNotifications) {
            this.showNotification();
          }

          this.localValue = false;
          if (this.selectedLessonBankClassId !== 0) {
            this.$eventBus.$emit('resetLessonBankData', { autoScrollBottom: this.input.action === 'A' });
          }
          return Promise.resolve(false);
        }).catch((error) => {
          if (!(error instanceof FormError)) {
            return Promise.reject(error);
          } else {
            CommonUtils.hideLoading();
            return Promise.resolve(true);
          }
        });
    } else {
      return this.$refs.form
        .saveLesson()
        .then((resp:any) => {
          const data = resp.data || {};
          if (this.showSnackbarNotifications) {
            this.showNotification();
          }
          this.localValue = false;
          this.$eventBus.$emit('lessonHasBeenSave', data);
          if (this.input.lessonListsView) {
            this.localValue = true;
            return Promise.resolve(true);
          }
        })
        .finally(() => {
          CommonUtils.hideLoading();
          return Promise.resolve(false);
        }).catch((error) => {
          if (!(error instanceof FormError)) {
            return Promise.reject(error);
          } else {
            CommonUtils.hideLoading();
            return Promise.resolve(true);
          }
        });
    }
  }

  showNotification(headerTitle?: any, cloneState?: any) {
    const clone = cloneState || this.cloneState();
    this.$snotify.confirm((headerTitle || this.headerTitle), this.$t('lessonUpdatedMsg') as string, {
      timeout: 5000,
      showProgressBar: false,
      buttons: [
        {
          text: this.$t('undoLabel') as string,
          action: (toast: SnotifyToast) => {
            this.$snotify.remove(toast.id, true);
            CommonUtils.showLoading();
            this.undoSave(clone).then((resp:any) => {
              this.$snotify.success(this.$t('statusMsg27') as string);
              if (this.isCalledByLessonBanks) {
                this.$eventBus.$emit('resetLessonBankData', { autoScrollBottom: false });
              } else {
                const data = resp.data || {};
                setTimeout(() => {
                  this.$eventBus.$emit('lessonHasBeenSave', data);
                }, 1000);
              }
              return Promise.resolve();
            }).finally(CommonUtils.hideLoading);
          },
          bold: true
        },
        {
          text: this.$t('closeLabel') as string,
          action: (toast: SnotifyToast) => {
            this.$snotify.remove(toast.id);
          }
        }
      ]
    });
  }

  getLessonInput() {
    const excludes = LessonServices.excludedLessonActions(this.input.data, this.userMode, this.localPage);
    excludes.push('edit');
    return {
      exclude: excludes,
      loadData: false,
      data: this.input.data
    }
  }

  removeAutoSave() {
    clearInterval(this.autoSaveId);
    this.autoSaveId = 0;
  }

  createAutoSave() {
    if (this.autoSaveInterval > 0 && this.autoSaveId === 0) {
      this.autoSaveId = setInterval(async () => {
        if (this.isDirty()) {
          console.log('Autosaving - lesson');
          await this.autoSave();
        } else {
          console.log('Autosaving - not dirty');
        }
      }, this.autoSaveIntervalMillis);
    }
  }

  async autoSave() {
    return this.$refs.form.save(this.isCalledByLessonBanks ? { isCalledByLessonBank: true } : undefined).then(resp => {
      const data = resp.data;
      if (this.extraLesson === 999) {
        const rawDayObject = data.day.objects.find((o: any) => o.classId === data.subjectId && o.extraLesson === data.extraLesson);
        this.$nextTick(() => {
          this.$eventBus.$emit('openSubPage', {
            type: 'lesson',
            modal: this.isModal,
            input: {
              loadData: true,
              data: rawDayObject
            }
          });
        });
      } else if (this.isCalledByLessonBanks && this.input.action === 'A') {
        this.$eventBus.$emit('resetLessonBankData', { autoScrollBottom: true, autoSave: true });
      } else if (this.isCalledByLessonBanks && this.input.action !== 'A') {
        this.$eventBus.$emit('lessonHasBeenSave');
      } else if (this.viewType === 'L' && this.localPage === 'plans') {
        this.$eventBus.$emit('lessonHasBeenSave', { data: data, autoSave: true });
      } else if (this.localPage === 'search' || this.localPage === 'reporting') {
        this.$eventBus.$emit('lessonHasBeenSave', { autoSave: true });
      }
    })
  }

  async doOnClose() {
    if (this.isDirty()) {
      return this.$refs.confirm
        .confirm({
          title: this.$t('lessonLabel'),
          text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
          option1ButtonAlternativeText: this.$t('saveChangesLabel'),
          cancelButtonText: this.$t('discardChangesLabel')
        })
        .then(result => {
          if (result === 0) {
            this.$eventBus.$emit('lessonEditorClosed');
            this.fullScreen = false;
            return Promise.resolve(false);
          } else {
            this.doApply();
            return Promise.resolve(true);
          }
        });
    } else {
      this.fullScreen = false;
      this.$eventBus.$emit('lessonEditorClosed');
      return Promise.resolve(false);
    }
  }

  closeEditor() {
    this.$eventBus.$emit('lessonEditorClosed');
  }

  @Watch('localValue')
  onLocalValueChange(val: boolean) {
    if (val) {
      this.createAutoSave();
    } else {
      this.removeAutoSave();
    }
  }

  destroyed() {
    this.removeAutoSave();
  }

  switchToStackMode() {
    this.stackMode = true;
  }

  switchToTabMode() {
    this.stackMode = false;
  }

  isUntitledLesson(item:any) {
    return this.hasLessonId(item) && CommonUtils.hasNoText(item.lessonTitle);
  }

  isEmptyLesson(item:any) {
    return !this.hasLessonId(item);
  }

  hasLessonId(item:any) {
    if (+item.lessonId > 0) {
      return true;
    } else {
      return false;
    }
  }

  async previousLesson() {
    if (this.input.lessonListsView) {
      if (this.isDirty()) {
        await this.doApply();
      }
      this.$nextTick(() => {
        this.$eventBus.$emit('previousLesson', { item: this.input.itemData || this.input.data, isEditMode: true });
      });
    }
  }

  async nextLesson() {
    if (this.input.lessonListsView) {
      if (this.isDirty()) {
        await this.doApply();
      }
      this.$nextTick(() => {
        this.$eventBus.$emit('nextLesson', { item: this.input.itemData || this.input.data, isEditMode: true });
      });
    }
  }
}
