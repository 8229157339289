var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-sheet",
    {
      ref: "schoolYearsRef",
      staticClass: "school-years",
      attrs: { color: "#ffffff" }
    },
    [
      _vm.$vuetify.breakpoint.smAndDown
        ? _c(
            "v-sheet",
            { staticClass: "mx-auto", attrs: { elevation: "0" } },
            [
              _vm.$currentUser.isDistrictAdmin
                ? _c(
                    "v-slide-group",
                    { staticClass: "py-2", attrs: { "center-active": "" } },
                    [
                      _c(
                        "v-slide-item",
                        { key: 0 },
                        [
                          _c("v-autocomplete", {
                            staticClass: "ml-2 pill-mobile-filters",
                            staticStyle: {
                              "max-width": "200px",
                              "font-size": "12px"
                            },
                            attrs: {
                              rounded: "",
                              dense: "",
                              flat: "",
                              solo: "",
                              outlined: "",
                              "hide-details": "auto",
                              items: _vm.schoolItems
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function(data) {
                                    return [
                                      _c("span", [
                                        _vm._v(_vm._s(data.item.text))
                                      ]),
                                      _c("rostered-item-indicator", {
                                        key:
                                          data.item.value +
                                          data.item.isItemRostered,
                                        attrs: {
                                          itemType: "school",
                                          item: data.item
                                        }
                                      })
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              901267436
                            ),
                            model: {
                              value: _vm.currentSchoolId,
                              callback: function($$v) {
                                _vm.currentSchoolId = $$v
                              },
                              expression: "currentSchoolId"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-0",
                  class: {
                    "mt-5": !_vm.$vuetify.breakpoint.smAndDown,
                    "mt-0": !_vm.$vuetify.breakpoint.smAndDown
                  },
                  attrs: { flat: "" }
                },
                [
                  _c("v-data-table", {
                    ref: "table",
                    attrs: {
                      "show-select": _vm.editMode,
                      loading: _vm.listLoading,
                      "loading-text": _vm.$t("listLoadingMsg"),
                      headers: _vm.tableHeaders,
                      items: _vm.filteredSchoolYears,
                      "selectable-key": "selectable",
                      "item-key": "yearId",
                      height: _vm.tableHeight,
                      search: _vm.searchText,
                      "fixed-header": "",
                      "hide-default-footer": "",
                      "disable-pagination": "",
                      "mobile-breakpoint": _vm.mobileBreakpoint
                    },
                    on: { "click:row": _vm.edit },
                    scopedSlots: _vm._u([
                      {
                        key: "body",
                        fn: function(ref) {
                          var items = ref.items
                          var isSelected = ref.isSelected
                          var select = ref.select
                          return [
                            _c(
                              "tbody",
                              {
                                ref: "listContainer",
                                staticClass: "list-container"
                              },
                              _vm._l(items, function(item, index) {
                                return _c(
                                  "tr",
                                  {
                                    key: index,
                                    class: _vm.mobileTableClass,
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { tabindex: "0", role: "row" },
                                    on: {
                                      click: function($event) {
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.edit(item)
                                      },
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        $event.preventDefault()
                                        $event.stopPropagation()
                                        return _vm.edit(item)
                                      }
                                    }
                                  },
                                  [
                                    _vm.editMode
                                      ? _c(
                                          "td",
                                          {
                                            class: _vm.mobileRowClass,
                                            on: {
                                              keyup: function($event) {
                                                if (
                                                  !$event.type.indexOf("key") &&
                                                  _vm._k(
                                                    $event.keyCode,
                                                    "enter",
                                                    13,
                                                    $event.key,
                                                    "Enter"
                                                  )
                                                ) {
                                                  return null
                                                }
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                select(item, !isSelected(item))
                                              }
                                            }
                                          },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass
                                                })
                                              : _vm._e(),
                                            _c("v-checkbox", {
                                              class: _vm.mobileCellClass,
                                              staticStyle: {
                                                margin: "0px",
                                                padding: "0px"
                                              },
                                              attrs: {
                                                disabled: !item.selectable,
                                                "input-value": isSelected(item),
                                                color: !_vm.$vuetify.theme.dark
                                                  ? "#7a7a7a"
                                                  : "#ffffff",
                                                "hide-details": "",
                                                "aria-label": item.yearName
                                              },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  select(
                                                    item,
                                                    !isSelected(item)
                                                  )
                                                }
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.$currentUser.isTeacher
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c(
                                                  "div",
                                                  {
                                                    class: _vm.mobileHeaderClass
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.headers[0].text
                                                      )
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "pb-btn",
                                              {
                                                staticClass: "mx-3",
                                                class: _vm.mobileCellClass,
                                                attrs: {
                                                  label: _vm.$t(
                                                    "switchToThisSchoolYearLabel"
                                                  ),
                                                  small: "",
                                                  fab: "",
                                                  elevation: "0"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    $event.stopPropagation()
                                                    return _vm.changePlans({
                                                      yearId: item.yearId
                                                    })
                                                  }
                                                }
                                              },
                                              [
                                                _vm.isCurrentSchoolYear(item)
                                                  ? _c(
                                                      "span",
                                                      [
                                                        _c("v-icon", {
                                                          staticClass:
                                                            "fa-solid fa-star",
                                                          attrs: {
                                                            color: "orange"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  : _c(
                                                      "span",
                                                      [
                                                        _c("v-icon", {
                                                          staticClass:
                                                            "fa-regular fa-star"
                                                        })
                                                      ],
                                                      1
                                                    )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[1].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [_vm._v(_vm._s(item.firstDayDisplay))]
                                      )
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[2].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [_vm._v(_vm._s(item.lastDayDisplay))]
                                      )
                                    ]),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileHeaderClass },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.headers[3].text)
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        { class: _vm.mobileCellClass },
                                        [_vm._v(_vm._s(item.typeLabel))]
                                      )
                                    ]),
                                    _c(
                                      "td",
                                      {
                                        class: _vm.mobileRowClass,
                                        style: {
                                          "min-width": _vm.tableWidths.longText
                                        }
                                      },
                                      [
                                        _vm.isMobileMode
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileHeaderClass },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.headers[4].text)
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        item.editYearName
                                          ? _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _c("v-text-field", {
                                                  attrs: {
                                                    autofocus: "",
                                                    dense: "",
                                                    "hide-details": "",
                                                    "append-icon":
                                                      "fa fa-check",
                                                    value: item.yearName,
                                                    disabled: _vm.listLoading
                                                  },
                                                  on: {
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      return _vm.saveYearName(
                                                        item
                                                      )
                                                    },
                                                    input: function($event) {
                                                      return _vm.updateYearName(
                                                        item,
                                                        $event
                                                      )
                                                    },
                                                    "click:append": function(
                                                      $event
                                                    ) {
                                                      return _vm.saveYearName(
                                                        item
                                                      )
                                                    },
                                                    blur: _vm.disableEditable
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                class: _vm.mobileCellClass,
                                                on: {
                                                  click: function($event) {
                                                    $event.stopPropagation()
                                                    $event.preventDefault()
                                                    return _vm.toggleYearNameEditable(
                                                      item
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(_vm._s(item.yearName))
                                                ]),
                                                item.editable
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ml-2 hover-edit-icon",
                                                        staticStyle: {
                                                          cursor: "pointer"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              "x-small": ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "fal fa-pencil"
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            )
                                      ]
                                    ),
                                    _vm.haveRosteredItems
                                      ? _c(
                                          "td",
                                          { class: _vm.mobileRowClass },
                                          [
                                            _vm.isMobileMode
                                              ? _c("div", {
                                                  class: _vm.mobileHeaderClass
                                                })
                                              : _vm._e(),
                                            _c(
                                              "div",
                                              { class: _vm.mobileCellClass },
                                              [
                                                _c("rostered-item-indicator", {
                                                  key:
                                                    (_vm.$currentUser.isTeacher
                                                      ? item.parentYearId
                                                      : item.yearId) +
                                                    item.isItemRostered,
                                                  attrs: {
                                                    itemType: "schoolyear",
                                                    item: item
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c("td", { class: _vm.mobileRowClass }, [
                                      _vm.isMobileMode
                                        ? _c("div", {
                                            class: _vm.mobileHeaderClass
                                          })
                                        : _vm._e(),
                                      item.editable
                                        ? _c(
                                            "div",
                                            { class: _vm.mobileCellClass },
                                            [
                                              _c(
                                                "v-btn",
                                                {
                                                  staticClass:
                                                    "mx-3 hover-button-primary",
                                                  attrs: {
                                                    text: "",
                                                    small: "",
                                                    color:
                                                      _vm.contentBreakpoint ===
                                                      "xs"
                                                        ? "primary"
                                                        : "transparent"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      $event.preventDefault()
                                                      return _vm.edit(item)
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("editLabel")
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                              _vm.$currentUser.isTeacher ||
                                              item.numUsers <= 0
                                                ? _c(
                                                    "v-btn",
                                                    {
                                                      staticClass:
                                                        "mx-3 hover-button-error",
                                                      attrs: {
                                                        text: "",
                                                        small: "",
                                                        color:
                                                          _vm.contentBreakpoint ===
                                                          "xs"
                                                            ? "error"
                                                            : "transparent"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          $event.stopPropagation()
                                                          $event.preventDefault()
                                                          return _vm.deleteSchoolYear(
                                                            item
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "deleteLabel"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ])
                                  ]
                                )
                              }),
                              0
                            )
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.selectedRows,
                      callback: function($$v) {
                        _vm.selectedRows = $$v
                      },
                      expression: "selectedRows"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("pb-confirm", { ref: "confirm" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }