



























































import { FormError } from '@/errors';
import { ActionsModal } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../core/Confirm.vue';
import DisplayFormatOptions from '../core/DisplayFormatOptions.vue';
import EventNotification from './EventNotification.vue';
import moment from 'moment';

const events = namespace('events');
const settings = namespace('settings');

@Component({
  components: {
    DisplayFormatOptions,
    EventNotification
  }
})
export default class EventActions extends Vue implements ActionsModal {
    @Prop({
      required: true,
      type: Object,
      default: () => {
        return { loadData: true, data: {}, mode: 'P' };
      }
    })
    input!: any;

    @Prop({ required: false, type: String, default: 'absolute' })
    type!: string;

    actions = [
      {
        id: 'edit',
        icon: 'fal fa-pencil',
        label: 'editLabel'
      },
      {
        id: 'view',
        icon: 'fal fa-eye',
        label: 'viewLabel'
      },
      {
        id: 'format',
        icon: 'fal fa-font',
        label: 'formatLabel'
      },
      {
        id: 'notification',
        icon: 'fal fa-bell',
        label: 'addNotificationLabel'
      },
      {
        id: 'delete',
        icon: 'fal fa-trash-alt',
        label: 'deleteLabel'
      },
      {
        id: 'sticker',
        icon: 'fal fa-image',
        label: this.addOrEditStickerLabel
      }
    ];

    timeOptionsTime = [
      { title: this.$t('atEventLabel'), display: '0', minutesPrior: 0 },
      { title: this.$t('xMinutesBeforeLabel', { x: 5 }), display: 'M', minutesPrior: 5 },
      { title: this.$t('xMinutesBeforeLabel', { x: 10 }), display: 'M', minutesPrior: 10 },
      { title: this.$t('xMinutesBeforeLabel', { x: 15 }), display: 'M', minutesPrior: 15 },
      { title: this.$t('xMinutesBeforeLabel', { x: 30 }), display: 'M', minutesPrior: 30 },
      { title: this.$t('1HourBeforeLabel'), display: 'H', minutesPrior: 60 },
      { title: this.$t('xHoursBeforeLabel', { x: 2 }), display: 'H', minutesPrior: 120 },
      { title: this.$t('1DayBeforeLabel'), display: 'D', minutesPrior: 1440 },
      { title: this.$t('xDaysBeforeLabel', { x: 2 }), display: 'D', minutesPrior: 1440 }
    ]

    timeOptionsDay = [
      { title: this.$t('1DayBeforeLabel').toString() + this.$t('notificationAtTime', { time: '9:00 AM' }), sendTime: '9:00 AM', display: 'D', minutesPrior: 1440 },
      { title: this.$t('xDaysBeforeLabel', { x: 2 }).toString() + this.$t('notificationAtTime', { time: '9:00 AM' }), sendTime: '9:00 AM', display: 'D', minutesPrior: 1440 },
      { title: this.$t('1WeekBeforeLabel').toString() + this.$t('notificationAtTime', { time: '9:00 AM' }), sendTime: '9:00 AM', display: 'W', minutesPrior: 10080 },
      { title: this.$t('xWeeksBeforeLabel', { x: 2 }).toString() + this.$t('notificationAtTime', { time: '9:00 AM' }), sendTime: '9:00 AM', display: 'W', minutesPrior: 20160 }
    ]

    showMenu = false;
    notificationsExpanded = false;
    x = 0;
    y = 0;

    $refs!: {
        confirm: Confirm,
        format: DisplayFormatOptions,
        notification: EventNotification
    }

    @events.State
    eventId!: number;

    @events.State
    customEventId!: number;

    @events.State
    noSchool!: boolean;

    @events.State
    noCycle!: boolean;

    @events.State
    origEvent!: any;

    @events.State
    eventCurrentDate!: string;

    @events.State
    eventTitle!: string;

    @events.State
    eventStartTime!: string;

    @settings.Getter('getUserInfo')
    userInfo!: any;

    @settings.Getter('getShowSnackbarNotifications')
    showSnackbarNotifications!: boolean;

    @settings.State
    userMode!: string;

    @settings.Getter
    getCurrentYear!: any;

    @events.Action
    init!: (params?: any) => Promise<any>;

    @events.Action
    delete!: (params?: any) => Promise<any>;

    @events.Action('save')
    doSave!: (params?: any) => Promise<any>;

    @events.Action('updateNotifications')
    saveNotifications!: (params?: any) => Promise<any>;

    get schoolId(): number {
      return +this.$store.state.events.schoolId;
    }

    get isEditable() {
      if (this.input.mode !== 'E' && ((this.input.data.schoolId && this.input.data.schoolId > 0 && !this.$currentUser.isAdmin) || (this.input.data.districtId && this.input.data.districtId > 0 && !this.$currentUser.isDistrictAdmin))) {
        return false;
      }
      return true;
    }

    get addOrEditStickerLabel():string {
      if (+this.stickerId > 0) {
        return 'editStickerLabel';
      } else {
        return 'addStickerLabel';
      }
    }

    get updateCurrentEvent() {
      return this.$store.state.events.updateCurrentEvent;
    }

    set updateCurrentEvent(val: boolean) {
      this.$store.commit('events/setUpdateCurrentEvent', val);
    }

    get eventDate(): string {
      return this.$store.state.events.eventDate;
    }

    get endDate(): string {
      return this.$store.state.events.endDate;
    }

    get shiftLessons() {
      return this.$store.state.events.updateCurrentEvent;
    }

    set shiftLessons(val: boolean) {
      this.$store.commit('events/setShiftLessons', val);
    }

    get isFieldRestricted() {
      if (this.eventId > 0) {
        const eventDate = this.$store.state.events.origEvent.eventDate;
        const noSchool = this.$store.state.events.origEvent.noSchool;
        const noCycle = this.$store.state.events.origEvent.noCycle;
        if (CommonUtils.hasValue(eventDate) && CommonUtils.hasValue(noSchool) && CommonUtils.hasValue(noCycle) && !this.isSchoolYearNew) {
          if (DateTimeUtils.daysDiff(eventDate, DateTimeUtils.currentDateAddDays(0)) > 365) {
            return true;
          } else if ((noSchool || noCycle) && DateTimeUtils.daysDiff(eventDate, DateTimeUtils.currentDateAddDays(0)) > 30) {
            return true;
          }
        }
      }
      return false;
    }

    get isSchoolYearNew() {
      if (this.$currentUser.isTeacher && DateTimeUtils.daysDiff(this.getCurrentYear.created, DateTimeUtils.formatDate(moment().toDate(), 'MM/DD/YYYY')) <= 7) {
        return true;
      }
      return false;
    }

    get filteredActions() {
      return this.actions.filter(a => {
        if (a.id === 'view' && (['E', 'V'].includes(this.input.mode) || this.$currentUser.isAdmin || (this.input.data.schoolId === 0 && this.input.data.districtId === 0))) {
          return false;
        } else if ((this.input.mode === 'V' || !this.isEditable) && !['notification', 'view'].includes(a.id)) {
          return false;
        } else if (a.id === 'edit' && this.input.mode === 'E') {
          return false;
        } else if (a.id === 'delete' && (!this.eventId || this.eventId === 0 || this.isFieldRestricted)) {
          if (+this.stickerId > 0 && (!this.eventId || this.eventId === 0)) {
            return true;
          }
          return false;
        } else if ((a.id === 'format' || a.id === 'sticker') && this.input.mode === 'P') {
          return false;
        }
        if (a.id === 'sticker') {
          a.label = this.addOrEditStickerLabel;
        }
        return true;
      });
    }

    get dayEvent() {
      return (this.eventStartTime === null || CommonUtils.hasNoText(this.eventStartTime));
    }

    get timeOptions() {
      if (this.dayEvent) {
        return this.timeOptionsDay;
      } else {
        return this.timeOptionsTime;
      }
    }

    addNotification(item: any) {
      this.appendNotification(item);
      this.showMenu = false;
    }

    appendNotification(item: any) {
      if (this.input.mode === 'P') {
        this.saveNotifications({
          eventId: this.input.data.eventId,
          customEventId: this.input.data.customEventId,
          allEvents: false,
          mode: 'A',
          notificationUnit: item.display,
          notificaitonMinutesPrior: item.minutesPrior,
          notificationTime: item.sendTime || ''
        })
      } else {
        this.$store.commit('events/appendNotification', item);
      }
    }

    get isCycleSchedule() {
      return !isNaN(+this.userInfo.schoolYearSettings.classCycle);
    }

    get eventFormat() {
      return {
        titleFont: this.titleFont,
        titleFontSize: this.titleFontSize,
        titleColor: this.titleColor,
        titleFillColor: this.titleFillColor,
        titleBold: this.titleBold,
        titleItalic: this.titleItalic,
        titleUnderline: this.titleUnderline,
        bodyFont: this.bodyFont,
        bodyFontSize: this.bodyFontSize,
        bodyColor: this.bodyColor,
        bodyFillColor: this.bodyFillColor,
        bodyBold: this.bodyBold,
        bodyItalic: this.bodyItalic,
        bodyUnderline: this.bodyUnderline,
        stickerBackgroundStyle: this.stickerBackgroundStyle,
        stickerBackgroundColor: this.stickerBackgroundColor,
        stickerSize: this.stickerSize
      }
    }

    set eventFormat(val: any) {
      this.titleFont = val.titleFont;
      this.titleFontSize = val.titleFontSize;
      this.titleColor = val.titleColor;
      this.titleFillColor = val.titleFillColor;
      this.titleBold = val.titleBold;
      this.titleItalic = val.titleItalic;
      this.titleUnderline = val.titleUnderline;
      this.bodyFont = val.bodyFont;
      this.bodyFontSize = val.bodyFontSize;
      this.bodyColor = val.bodyColor;
      this.bodyFillColor = val.bodyFillColor;
      this.bodyBold = val.bodyBold;
      this.bodyItalic = val.bodyItalic;
      this.bodyUnderline = val.bodyUnderline;
      this.stickerBackgroundStyle = val.stickerBackgroundStyle;
      this.stickerBackgroundColor = val.stickerBackgroundColor;
      this.stickerSize = val.stickerSize;
      this.addUpdatedField(val.updatedField);
    }

    get titleFont(): string {
      return this.$store.state.events.titleFont;
    }

    set titleFont(val: string) {
      this.$store.commit('events/setTitleFont', val);
    }

    get titleFontSize(): string {
      return this.$store.state.events.titleFontSize;
    }

    set titleFontSize(val: string) {
      this.$store.commit('events/setTitleFontSize', val);
    }

    get titleColor(): string {
      return this.$store.state.events.titleColor;
    }

    set titleColor(val: string) {
      this.$store.commit('events/setTitleColor', val);
    }

    get titleFillColor(): string {
      return this.$store.state.events.titleFillColor;
    }

    set titleFillColor(val: string) {
      this.$store.commit('events/setTitleFillColor', val);
    }

    get titleBold(): string {
      return this.$store.state.events.titleBold;
    }

    set titleBold(val: string) {
      this.$store.commit('events/setTitleBold', val);
    }

    get titleItalic(): string {
      return this.$store.state.events.titleItalic;
    }

    set titleItalic(val: string) {
      this.$store.commit('events/setTitleItalic', val);
    }

    get titleUnderline(): string {
      return this.$store.state.events.titleUnderline;
    }

    set titleUnderline(val: string) {
      this.$store.commit('events/setTitleUnderline', val);
    }

    get bodyFont(): string {
      return this.$store.state.events.bodyFont;
    }

    set bodyFont(val: string) {
      this.$store.commit('events/setBodyFont', val);
    }

    get bodyFontSize(): string {
      return this.$store.state.events.bodyFontSize;
    }

    set bodyFontSize(val: string) {
      this.$store.commit('events/setBodyFontSize', val);
    }

    get bodyColor(): string {
      return this.$store.state.events.bodyColor;
    }

    set bodyColor(val: string) {
      this.$store.commit('events/setBodyColor', val);
    }

    get bodyFillColor(): string {
      return this.$store.state.events.bodyFillColor;
    }

    set bodyFillColor(val: string) {
      this.$store.commit('events/setBodyFillColor', val);
    }

    get bodyBold(): string {
      return this.$store.state.events.bodyBold;
    }

    set bodyBold(val: string) {
      this.$store.commit('events/setBodyBold', val);
    }

    get bodyItalic(): string {
      return this.$store.state.events.bodyItalic;
    }

    set bodyItalic(val: string) {
      this.$store.commit('events/setBodyItalic', val);
    }

    get bodyUnderline(): string {
      return this.$store.state.events.bodyUnderline;
    }

    set bodyUnderline(val: string) {
      this.$store.commit('events/setBodyUnderline', val);
    }

    get updatedFields(): Set<any> {
      return this.$store.state.events.updatedFields;
    }

    set updatedFields(val: Set<any>) {
      this.$store.commit('events/setUpdatedFields', val);
    }

    get stickerBackgroundStyle(): string {
      return this.$store.state.events.stickerBackgroundStyle;
    }

    set stickerBackgroundStyle(val: string) {
      this.$store.commit('events/setEventStickerBackgroundStyle', val);
    }

    get stickerBackgroundColor(): string {
      return this.$store.state.events.stickerBackgroundColor;
    }

    set stickerBackgroundColor(val: string) {
      this.$store.commit('events/setEventStickerBackgroundColor', val);
    }

    get stickerSize(): string {
      return this.$store.state.events.stickerSize;
    }

    set stickerSize(val: string) {
      this.$store.commit('events/setEventStickerSize', val);
    }

    get initialized(): boolean {
      return this.$store.state.events.editorInitialized;
    }

    get formattedDate() {
      if (CommonUtils.hasText(this.eventCurrentDate)) {
        return `${DateTimeUtils.formatToDisplay(this.eventCurrentDate)}`;
      }
    }

    get actionHeader() {
      return this.formattedDate + ' - ' + (this.eventTitle || 'Event');
    }

    get stickerId(): string {
      return this.$store.state.events.stickerId;
    }

    addUpdatedField(field: string) {
      if (this.initialized) {
        this.updatedFields.add(field);
      }
    }

    async confirmShiftLessons(message: string) {
      const that = this;
      if (that.isCycleSchedule) {
        that.shiftLessons = true;
        return Promise.resolve();
      } else {
        return this.$refs.confirm.confirm({
          title: this.$t('noSchoolEventLabel'),
          text: message,
          option1ButtonAlternativeText: this.$t('shiftLessonsLabel'),
          option2ButtonAlternativeText: this.$t('doNotShiftLessonsLabel')
        }).then((result) => {
          if (result === 1) {
            that.shiftLessons = true;
            return Promise.resolve();
          } else if (result === 2) {
            that.shiftLessons = false;
            return Promise.resolve();
          } else {
            return Promise.reject(new FormError());
          }
        });
      }
    }

    doEditEvent() {
      this.showMenu = false;
      this.$eventBus.$emit('openSubPage', {
        type: 'event',
        modal: true,
        input: {
          loadData: false,
          data: this.input.data
        }
      });
      return Promise.resolve();
    }

    async doDeleteEvent() {
      const that = this;
      that.showMenu = false;
      if ((!this.eventId || this.eventId === 0) && +this.stickerId > 0) {
        return Promise.resolve().then(() => {
          this.$refs.confirm.confirm({
            title: this.$t('deleteHolidayLabel'),
            text: this.$t('wouldYouLikeToDeleteHolidayMsg'),
            option1ButtonAlternativeText: this.$t('deleteLabel')
          })
            .then((result) => {
              if (result === 1) {
                CommonUtils.showLoading();
                that.addUpdatedField('noEventDays');
                that.$store.commit('events/setNoEventDays', [that.eventCurrentDate]);
                return that.doSave();
              } else {
                return Promise.reject(new FormError());
              }
            })
            .then(() => {
              if (this.showSnackbarNotifications) {
                this.$snotify.success(this.$t('statusMsg75') as string);
              }

              return Promise.resolve();
            })
            .then(() => {
              this.$eventBus.$emit('refreshEvents');
              this.closeEditor();
              return Promise.resolve();
            })
            .catch(error => {
              if (!(error instanceof FormError)) {
                Promise.reject(error);
              }
            });
        });
      } else {
        return Promise.resolve().then(() => {
          const withCustomEvent = (that.customEventId > 0 || CommonUtils.isNotEmpty(that.origEvent.extraDays) || CommonUtils.isNotEmpty(that.origEvent.noEventDays));
          if (withCustomEvent || that.eventDate !== that.endDate) {
            return this.$refs.confirm.confirm({
              title: this.$t('deleteEventLabel'),
              text: this.$t('wouldYouLikeToDeleteEventMsg', { text: DateTimeUtils.formatToShow(that.eventCurrentDate) }),
              option1ButtonAlternativeText: this.$t('allEventsLabel'),
              option2ButtonAlternativeText: this.$t('onlyThisEventLabel')
            }).then((result) => {
              if (result === 1) {
                return Promise.resolve();
              } else if (result === 2) {
                that.updateCurrentEvent = true;
                return Promise.resolve();
              } else {
                return Promise.reject(new FormError());
              }
            })
          } else {
            Promise.resolve();
          }
        }).then(() => {
          if (that.userMode === 'T') {
            if (that.noSchool) {
              return that.confirmShiftLessons(this.$t('shiftLessonsMsg4') as string);
            } else if (that.noCycle) {
              that.shiftLessons = true;
              return Promise.resolve();
            }
          } else {
            return Promise.resolve();
          }
        }).then(async () => {
          return that.delete().then(resp => {
            if (this.showSnackbarNotifications) {
              this.$snotify.success(this.$t('statusMsg16') as string);
            }

            return Promise.resolve(resp);
          });
        }).then(() => {
          this.$eventBus.$emit('refreshEvents');
          this.closeEditor();
          return Promise.resolve();
        }).catch(error => {
          if (!(error instanceof FormError)) {
            Promise.reject(error);
          }
        });
      }
    }

    openCustomNotificaiton() {
      return this.$refs.notification.show();
    }

    doAction(action: any, event: MouseEvent) {
      Promise.resolve().then(() => {
        if (action.id === 'edit' || action.id === 'view') {
          return this.doEditEvent();
        } else if (action.id === 'delete') {
          CommonUtils.showLoading();
          return this.doDeleteEvent();
        } else if (action.id === 'notification') {
          this.openCustomNotificaiton();
        } else if (action.id === 'format') {
          return this.$refs.format.show(event);
        } else if (action.id === 'sticker') {
          return this.doAddSticker();
        }
        return Promise.resolve();
      }).finally(() => {
        this.showMenu = false;
        CommonUtils.hideLoading();
      });
    }

    closeEditor() {
      this.$eventBus.$emit('closeSubPage');
    }

    doAddSticker() {
      this.$eventBus.$emit('addEventSticker');
    }

    public show(e: MouseEvent) {
      e.preventDefault()
      this.showMenu = false;
      this.x = e.clientX;
      this.y = e.clientY;
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 10)).then(() => {
          this.showMenu = true;
        });
      })
    }

    doInit() {
      this.notificationsExpanded = false;
      this.init(this.input);
    }

    @Watch('input')
    @Watch('showMenu')
    onInputChange() {
      if (this.showMenu) {
        this.doInit();
      }
    }

    created() {
      this.doInit();
    }
}
