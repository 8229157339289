
















































































































import { UserSession } from '@/common/user-session';
import { exportTypes, printViews, remindersEventsprintViews } from '@/constants';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import MultiSelectField from '../core/MultiSelectField.vue';

const prints = namespace('prints');
const students = namespace('students');
const classes = namespace('classes');
const settings = namespace('settings');

@Component({
  components: {
    MultiSelectField
  }
})
export default class PrintOptions extends Vue {
  localValue = false;
  printParams: any = null;
  localResolve: any;
  localStartDate = '';
  localEndDate = '';
  changePrintViews = false;

  @prints.Getter('getPrintItems')
  printItems!: Array<any>;

  @students.Getter('getNotesStudentsItems')
  studentItems!: Array<any>;

  @classes.Getter('getClassItems')
  classItems!: Array<any>;

  @prints.Getter('getPrintToDoItems')
  todoItems!: Array<any>;

  @classes.Getter('getLessonBankItems')
  lessonBankItems!: Array<any>;

  @settings.Getter('getSharedClassesList')
  sharedClassesList!: any[];

  @prints.Action
  initDayPrint!: (value?: any) => Promise<any>;

  @prints.Action
  initPrint!: (value?: any) => Promise<any>;

  @prints.Action('print')
  doPrint!: (value?: any) => Promise<any>;

  @students.Action
  loadNotesStudents!: (params?: any) => Promise<any>;

  @settings.Getter('getUserMode')
  userMode!: string;

  $refs!: {
    form: Vue & { validate: () => boolean, resetValidation: () => void }
    printRange: Vue & { validate: () => boolean, resetValidation: () => void }
  }

  get notesClassItems() {
    let items = [{
      text: this.$t('allClassesLabel'),
      value: 0
    }];
    items = items.concat(this.classItems)
    return items;
  }

  get lessonBankAndSharedItems() {
    const items = this.lessonBankItems;
    return items.concat(this.sharedClassesList.filter(c => c.yearId === 0).map(c => {
      return {
        value: c.classId,
        text: c.className,
        yearId: c.yearId
      }
    }));
  }

  get exportTypes() {
    return exportTypes.filter(e => {
      if (e.value === 'calendar' && !this.$currentUser.isTeacher) {
        return false;
      }
      return true;
    });
  }

  get printViews() {
    let returnedVal: any = null;
    this.changePrintViews ? returnedVal = remindersEventsprintViews : returnedVal = printViews;
    return returnedVal.filter((v: any) => {
      if (this.userMode === 'A') {
        return ['Week', 'Day', 'Class', 'ClassMonth', 'Event', 'EventMonth', 'LessonBank', 'Comments'].includes(v.value);
      } else if (this.userMode === 'S') {
        return ['Week', 'Day', 'Comments'].includes(v.value);
      } else if (CommonUtils.isEmpty(this.classItems) && (v.value === 'Class' || v.value === 'ClassMonth')) {
        return false;
      }
      return true;
    });
  }

  async doRequestPrint() {
    if ((!this.$refs.printRange && this.$refs.form.validate()) || (this.$refs.form.validate() && this.$refs.printRange.validate())) {
      CommonUtils.showLoading();
      new Promise(resolve => {
        if (['drive', 'calendar'].includes(this.exportType) && CommonUtils.hasNoText(UserSession.getGoogleAuthToken())) {
          this.$google.connect({
            scope: [
              `https://www.googleapis.com/auth/${this.exportType}`
            ],
            response_type: 'access_token',
            onAuth: async (authResult: any) => {
              UserSession.setGoogleAuthToken(authResult.access_token);
              resolve({});
            }
          });
        } else {
          resolve({});
        }
      }).then(async () => {
        return this.doPrint().finally(() => {
          this.localValue = false;
          this.localResolve();
          CommonUtils.hideLoading();
        });
      })
    }
  }

  get printRangeRules() {
    return [() => Math.abs(DateTimeUtils.daysDiff(this.startDate, this.endDate)) > 365 ? this.$t('printErrorMsg15') as string : ''];
  }

  print(params?: any) {
    this.localValue = true;
    this.printParams = params || null;
    return new Promise(resolve => {
      this.localResolve = resolve;
    });
  }

  @Watch('localValue')
  onLocalValueChange() {
    if (this.localValue) {
      this.$nextTick(() => {
        this.doInitPrint();
        if (this.printView === 'ClassMonth' && (!this.classId || this.classId === 0)) {
          this.classId = this.classItems[0].value;
        }
      })
    } else {
      this.printParams = null;
    }
  }

  async doInitPrint() {
    const type = this.printParams?.type || 'default';
    if (type === 'day') {
      return this.initDayPrint(this.printParams);
    } else {
      return this.initPrint(this.printParams);
    }
  }

  @Watch('printView')
  onPrintViewChange() {
    if (!this.$currentUser.isStudent) {
      this.loadNotesStudents();
    }
  }

  get exportType() {
    return this.$store.state.prints.exportType;
  }

  set exportType(val) {
    this.$store.commit('prints/setExportType', val);
  }

  get printView() {
    return this.$store.state.prints.printView;
  }

  set printView(val) {
    this.$store.commit('prints/setPrintView', val);
    if (val === 'ClassMonth' && (!this.classId || this.classId === 0)) {
      this.classId = this.classItems[0].value;
    }
  }

  get selectedPrintItems() {
    return this.$store.state.prints.selectedPrintItems || [];
  }

  set selectedPrintItems(val) {
    this.$store.commit('prints/setSelectedPrintItems', val);
  }

  get selectedClassItems() {
    return this.$store.state.prints.selectedClassItems || [];
  }

  set selectedClassItems(val) {
    this.$store.commit('prints/setSelectedClassItems', val);
  }

  get selectedToDoItems() {
    return this.$store.state.prints.selectedToDoItems || [];
  }

  set selectedToDoItems(val) {
    this.$store.commit('prints/setSelectedToDoItems', val);
  }

  get lessonBankId() {
    return this.$store.state.prints.lessonBankId;
  }

  set lessonBankId(val) {
    this.$store.commit('prints/setLessonBankId', val);
  }

  get startDate() {
    return this.$store.state.prints.startDate;
  }

  set startDate(val) {
    this.$store.commit('prints/setStartDate', val);
  }

  get endDate() {
    return this.$store.state.prints.endDate;
  }

  set endDate(val) {
    this.$store.commit('prints/setEndDate', val);
  }

  get todoStartDate() {
    return this.$store.state.prints.todoStartDate;
  }

  set todoStartDate(val) {
    this.$store.commit('prints/setTodoStartDate', val);
  }

  get todoEndDate() {
    return this.$store.state.prints.todoEndDate;
  }

  set todoEndDate(val) {
    this.$store.commit('prints/setTodoEndDate', val);
  }

  get notesStartDate() {
    return this.$store.state.prints.notesStartDate;
  }

  set notesStartDate(val) {
    this.$store.commit('prints/setNotesStartDate', val);
  }

  get notesEndDate() {
    return this.$store.state.prints.notesEndDate;
  }

  set notesEndDate(val) {
    this.$store.commit('prints/setNotesEndDate', val);
  }

  get notesClassId() {
    return this.$store.state.prints.notesClassId;
  }

  set notesClassId(val) {
    this.$store.commit('prints/setNotesClassId', val);
  }

  get notesStudentId() {
    return this.$store.state.prints.notesStudentId;
  }

  set notesStudentId(val) {
    this.$store.commit('prints/setNotesStudentId', val);
  }

  get commentsStartDate() {
    return this.$store.state.prints.commentsStartDate;
  }

  set commentsStartDate(val) {
    this.$store.commit('prints/setCommentsStartDate', val);
  }

  get commentEndDate() {
    return this.$store.state.prints.commentsEndDate;
  }

  set commentEndDate(val) {
    this.$store.commit('prints/setCommentsEndDate', val);
  }

  get startTime() {
    return this.$store.state.prints.startTime;
  }

  set startTime(val) {
    this.$store.commit('prints/setStartTime', val);
  }

  get endTime() {
    return this.$store.state.prints.endTime;
  }

  set endTime(val) {
    this.$store.commit('prints/setEndTime', val);
  }

  get orientation() {
    return this.$store.state.prints.orientation;
  }

  set orientation(val) {
    this.$store.commit('prints/setOrientation', val);
  }

  get weekPages() {
    return this.$store.state.prints.weekPages === 2;
  }

  set weekPages(val) {
    this.$store.commit('prints/setWeekPages', val ? 2 : 1);
  }

  get equalSize() {
    return this.$store.state.prints.equalSize;
  }

  set equalSize(val) {
    this.$store.commit('prints/setEqualSize', val);
  }

  get avoidPageBreak() {
    return this.$store.state.prints.avoidPageBreak;
  }

  set avoidPageBreak(val) {
    this.$store.commit('prints/setAvoidPageBreak', val);
  }

  get grayScale() {
    return this.$store.state.prints.grayScale;
  }

  set grayScale(val) {
    this.$store.commit('prints/setGrayScale', val);
  }

  get oneLessonPerPage() {
    return this.$store.state.prints.oneLessonPerPage;
  }

  set oneLessonPerPage(val) {
    this.$store.commit('prints/setOneLessonPerPage', val);
  }

  get twoColumns() {
    return this.$store.state.prints.twoColumns;
  }

  set twoColumns(val) {
    this.$store.commit('prints/setTwoColumns', val);
  }

  get initialized() {
    return this.$store.state.prints.initialized;
  }

  set initialized(val) {
    this.$store.commit('prints/setInitialized', val);
  }

  get classId() {
    return this.$store.state.prints.classId;
  }

  set classId(val) {
    this.$store.commit('prints/setClassId', val);
  }

  get extraLesson() {
    return this.$store.state.prints.extraLesson;
  }

  set extraLesson(val) {
    this.$store.commit('prints/setExtraLesson', val);
  }

  get skipClasses() {
    return this.$store.state.prints.skipClasses;
  }

  set skipClasses(val) {
    this.$store.commit('prints/setSkipClasses', val);
  }

  get encrypted() {
    return this.$store.state.prints.encrypted;
  }

  set encrypted(val) {
    this.$store.commit('prints/setEncrypted', val);
  }

  changePrintViewsValue() {
    this.changePrintViews = true
    this.$store.commit('prints/setPrintView', 'Event');
  }

  defaultPrintViews() {
    this.changePrintViews = false
  }

  mounted() {
    try {
      this.$refs.form.resetValidation();
      this.$refs.printRange.resetValidation();
    } catch (e) {
    }
  }
}
