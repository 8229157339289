var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("base-toolbar", {
    attrs: { searchable: "", search: _vm.searchText },
    on: {
      "update:search": function($event) {
        _vm.searchText = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "mobile-search-filters",
        fn: function() {
          return [
            _c("search-filter", {
              ref: "searchFilter",
              attrs: {
                filterCount: _vm.filterCount,
                isFiltered: _vm.isFiltered,
                clearFilter: _vm.clearFilter,
                mobileMode: true
              },
              on: { focusMenu: _vm.focusMenu },
              scopedSlots: _vm._u([
                {
                  key: "filters-menu",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex align-center" },
                            [
                              _c("h3", { staticClass: "ml-2" }, [
                                _vm._v("Type")
                              ]),
                              _c(
                                "pb-chip",
                                {
                                  ref: "firstMenuItem",
                                  staticClass: "ml-2",
                                  staticStyle: { opacity: "1" },
                                  attrs: {
                                    filter: "",
                                    outlined: "",
                                    "active-class":
                                      "accent3--text font-weight-bold",
                                    disabled: _vm.selectedEventType === "all",
                                    "input-value":
                                      _vm.selectedEventType === "all"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectEventType("all")
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("allLabel")))
                                  ])
                                ]
                              ),
                              _c(
                                "pb-chip",
                                {
                                  staticClass: "ml-2",
                                  staticStyle: { opacity: "1" },
                                  attrs: {
                                    filter: "",
                                    outlined: "",
                                    "active-class":
                                      "accent3--text font-weight-bold",
                                    disabled: _vm.selectedEventType === "none",
                                    "input-value":
                                      _vm.selectedEventType === "none"
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.selectEventType("none")
                                    }
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(_vm._s(_vm.$t("noneLabel")))
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-chip-group",
                            {
                              staticClass: "ml-2",
                              attrs: {
                                "active-class":
                                  "accent3--text font-weight-bold",
                                column: "",
                                multiple: ""
                              },
                              model: {
                                value: _vm.filterSelectionsShowEventTypes,
                                callback: function($$v) {
                                  _vm.filterSelectionsShowEventTypes = $$v
                                },
                                expression: "filterSelectionsShowEventTypes"
                              }
                            },
                            _vm._l(_vm.localEventTypeItems, function(item) {
                              return _c(
                                "pb-chip",
                                {
                                  key: item.value,
                                  attrs: {
                                    filter: "",
                                    outlined: "",
                                    value: item.value
                                  }
                                },
                                [_c("span", [_vm._v(_vm._s(item.text))])]
                              )
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            })
          ]
        },
        proxy: true
      },
      {
        key: "toolbar-left",
        fn: function() {
          return [
            _c(
              "v-col",
              {
                staticClass: "px-0",
                style: { marginRight: _vm.searchWidthGrow + "px" },
                attrs: { md: "2", sm: "3" }
              },
              [
                _c("search-filter", {
                  ref: "searchFilter",
                  attrs: {
                    filterCount: _vm.filterCount,
                    isFiltered: _vm.isFiltered,
                    clearFilter: _vm.clearFilter,
                    searchText: _vm.searchText,
                    searchWidthGrow: _vm.searchWidthGrow,
                    mobileMode: false
                  },
                  on: {
                    "update:searchText": function($event) {
                      _vm.searchText = $event
                    },
                    "update:search-text": function($event) {
                      _vm.searchText = $event
                    },
                    focusMenu: _vm.focusMenu
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "filters-menu",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex align-center" },
                                [
                                  _c("h3", { staticClass: "ml-2" }, [
                                    _vm._v("Type")
                                  ]),
                                  _c(
                                    "pb-chip",
                                    {
                                      ref: "firstMenuItem",
                                      staticClass: "ml-2",
                                      staticStyle: { opacity: "1" },
                                      attrs: {
                                        filter: "",
                                        outlined: "",
                                        "active-class":
                                          "accent3--text font-weight-bold",
                                        disabled:
                                          _vm.selectedEventType === "all",
                                        "input-value":
                                          _vm.selectedEventType === "all"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectEventType("all")
                                        }
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("allLabel")))
                                      ])
                                    ]
                                  ),
                                  _c(
                                    "pb-chip",
                                    {
                                      staticClass: "ml-2",
                                      staticStyle: { opacity: "1" },
                                      attrs: {
                                        filter: "",
                                        outlined: "",
                                        "active-class":
                                          "accent3--text font-weight-bold",
                                        disabled:
                                          _vm.selectedEventType === "none",
                                        "input-value":
                                          _vm.selectedEventType === "none"
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.selectEventType("none")
                                        }
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(_vm._s(_vm.$t("noneLabel")))
                                      ])
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-chip-group",
                                {
                                  staticClass: "ml-2",
                                  attrs: {
                                    "active-class":
                                      "accent3--text font-weight-bold",
                                    column: "",
                                    multiple: ""
                                  },
                                  model: {
                                    value: _vm.filterSelectionsShowEventTypes,
                                    callback: function($$v) {
                                      _vm.filterSelectionsShowEventTypes = $$v
                                    },
                                    expression: "filterSelectionsShowEventTypes"
                                  }
                                },
                                _vm._l(_vm.localEventTypeItems, function(item) {
                                  return _c(
                                    "pb-chip",
                                    {
                                      key: item.value,
                                      attrs: {
                                        filter: "",
                                        outlined: "",
                                        value: item.value
                                      }
                                    },
                                    [_c("span", [_vm._v(_vm._s(item.text))])]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ],
              1
            ),
            _vm.$currentUser.isDistrictAdmin && !_vm.isMenuOverlapping()
              ? _c("v-autocomplete", {
                  staticClass: "ml-2",
                  staticStyle: { "max-width": "190px", "min-width": "190px" },
                  attrs: {
                    label: _vm.$t("schoolLabel"),
                    "display:inline-flex": "",
                    "hide-details": "auto",
                    items: _vm.schoolItems,
                    outlined: "",
                    dense: ""
                  },
                  model: {
                    value: _vm.currentSchoolId,
                    callback: function($$v) {
                      _vm.currentSchoolId = $$v
                    },
                    expression: "currentSchoolId"
                  }
                })
              : _vm._e(),
            !_vm.editMode
              ? _c(
                  "pb-btn",
                  {
                    staticClass: "ml-2 my-1",
                    attrs: {
                      color: "primary",
                      label: _vm.$t("todayLabel"),
                      text: "",
                      outlined: "",
                      id: "eventsTodayButton"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.scrollToToday(true)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("fal fa-calendar-star")])],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "toolbar-right",
        fn: function() {
          return [
            !_vm.editMode
              ? _c(
                  "pb-btn",
                  {
                    staticClass: "ml-2 my-1",
                    attrs: {
                      color: "primary",
                      label: _vm.$t("addLabel") + " " + _vm.$t("eventLabel"),
                      text: "",
                      outlined: ""
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.editEvent(null)
                      }
                    }
                  },
                  [_c("v-icon", [_vm._v("fal fa-plus")])],
                  1
                )
              : [
                  _vm.hasSelectedRows
                    ? _c(
                        "pb-btn",
                        {
                          staticClass: "ml-2 my-1",
                          attrs: {
                            color: "error",
                            label: _vm.$t("deleteLabel"),
                            text: "",
                            outlined: ""
                          },
                          on: {
                            click: function($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.deleteEvents.apply(null, arguments)
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v("fal fa-trash-alt")])],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "pb-btn",
                    {
                      staticClass: "ml-2 my-1",
                      attrs: {
                        color: "primary",
                        label: _vm.$t("doneLabel"),
                        text: "",
                        outlined: ""
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.toggleEditMode.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("v-icon", { staticClass: "done-icon" }, [
                        _vm._v("fal fa-pen-slash")
                      ])
                    ],
                    1
                  )
                ],
            !_vm.editMode
              ? _c(
                  "v-menu",
                  {
                    attrs: { bottom: "", "offset-y": "", "close-on-click": "" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function(ref) {
                            var onMenu = ref.on
                            return [
                              _c(
                                "pb-btn",
                                _vm._g(
                                  {
                                    staticClass: "ml-2 my-1",
                                    attrs: {
                                      color: "primary",
                                      label: _vm.$t("actionsLabel"),
                                      text: "",
                                      outlined: ""
                                    }
                                  },
                                  Object.assign({}, onMenu)
                                ),
                                [
                                  _c("v-icon", [
                                    _vm._v("fal fa-ellipsis-stroke-vertical")
                                  ])
                                ],
                                1
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      648777904
                    )
                  },
                  [
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item",
                          { on: { click: _vm.toggleEditMode } },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "justify-start",
                                staticStyle: { width: "100%" },
                                attrs: { text: "", color: "primary" }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-pen-to-square")
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("manageLabel")))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.showImportEvents = true
                              }
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "justify-start",
                                staticStyle: { width: "100%" },
                                attrs: { text: "", color: "primary" }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-upload")
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("importEventsLabel")))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-list-item",
                          {
                            on: {
                              click: function($event) {
                                _vm.showExportEvents = true
                              }
                            }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                staticClass: "justify-start",
                                staticStyle: { width: "100%" },
                                attrs: { text: "", color: "primary" }
                              },
                              [
                                _c("v-icon", { attrs: { small: "" } }, [
                                  _vm._v("fal fa-download")
                                ]),
                                _c("span", { staticClass: "ml-1" }, [
                                  _vm._v(_vm._s(_vm.$t("exportEventsLabel")))
                                ])
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "pb-btn",
              {
                staticClass: "ml-2 my-1 d-inline-block",
                attrs: {
                  id: "print",
                  color: "primary",
                  label: _vm.$t("sendToLabel"),
                  text: "",
                  outlined: ""
                },
                on: {
                  click: function($event) {
                    return _vm.showPrintOptions(_vm.$refs.printOptions, "Event")
                  }
                }
              },
              [_c("v-icon", [_vm._v("fal fa-arrow-up-from-square")])],
              1
            ),
            _c("print-options", { ref: "printOptions" })
          ]
        },
        proxy: true
      },
      {
        key: "mobile-toolbar-right",
        fn: function() {
          return [
            _c(
              "pb-btn",
              {
                attrs: {
                  color: "primary",
                  label: _vm.$t("todayLabel"),
                  text: "",
                  small: ""
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.scrollToToday(true)
                  }
                }
              },
              [_c("v-icon", [_vm._v("fal fa-calendar-star")])],
              1
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }