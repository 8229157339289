import { BASE_URL } from '@/axios';
import { PlanbookStorage } from '@/common/planbook-storage';
import { UserSession } from '@/common/user-session';
import { fonts, lessonSectionsMapping, lessonSectionsPlaceHolders, otherViewItems, sections, selectLinkedViewItems, selectViewItems, studentViewItems, viewItems } from '@/constants';
import AppError, { FormError, SessionExpiredError, createErrOptions } from '@/errors';
import eventBus from '@/events';
import vuetify from '@/plugins/vuetify';
import LessonServices from '@/services/lesson-services';
import PaymentServices from '@/services/payment-services';
import SchoolYearServices from '@/services/school-year-services';
import SettingsServices from '@/services/settings-services';
import UserServices from '@/services/user-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import i18n, { languages, getLocaleFromURL } from '@/plugins/i18n';
import moment from 'moment-timezone';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '@/store';
import CurrentUser from '@/common/current-user';
import TeacherServices from '@/services/teacher-services';
import AuthServices from '@/services/auth-services';

@Module({ namespaced: true })
class Settings extends VuexModule {
  pickerColor = '#ffffff';
  userId = 0;
  userMode = 'T';
  settings: any = {};
  updatedSettings: any = {};
  originalSettings: any = {};
  studentId = 0;
  studentKey = '';
  studentFirstName = '';
  studentLastName = '';
  studentEmailAddress = '';
  studentPhotoUrl = '';
  studentViewStandardsId = '';
  studentViewStandardsDesc = '';
  studentViewAttachments = '';
  teacherEmail = '';
  teacherId = 0;
  studentTeachers: Array<any> = [];
  studentTeacherId = 0;
  currentTeacherId = 0;
  currentYearId = 0;
  currentSubjectId = 0;
  currentStatuses: Array<any> = [];
  adminSchoolYears: Array<any> = [];
  groupAccountSchoolYears: Array<any> = [];
  schoolYearEditorInitialized = false;
  loading = true;
  shouldLogin = false;
  haveSettings = false;
  showDetails = true;
  browserDimensions: any = {};
  confirming = false;
  confirmTitle = 'Please Confirm';
  confirmSubtitle = '';
  confirmText = '';
  confirmOption1ButtonAlternativeText = 'Continue';
  confirmOption1ButtonCallback: any = null;
  confirmOption2ButtonAlternativeText = '';
  confirmOption2ButtonCallback: any = null;
  customFontsKey = 'initial';
  newUser = false;
  schoolYearListLoading = false;
  schoolYearSearchText = '';
  schoolYearSelectedRows: Array<any> = [];
  schoolYearEditMode = false;
  schoolYearCurrentSchoolId = 0;
  firstLoad = true;
  additionalInfoMsgs: Array<any> = [];
  additionalErrorMsgs: Array<any> = [];
  showLessonStatus: any = {};
  initialSignIn = false;

  get getBrowserWidth() {
    return this.browserDimensions.width;
  }

  get getStickerStyling() {
    return this.getDisplaySettings.stickerStyling;
  }

  get getStickeSettings() {
    return this.getDisplaySettings.stickerSettings;
  }

  get getNotesTodosStyling() {
    return this.getDisplaySettings.notesTodosStyling;
  }

  get getGlobalFontSize() {
    return this.getDisplaySettings.global.globalFontSize || '10';
  }

  get getGlobalFontFamily() {
    return CommonUtils.getFontFamily(this.getDisplaySettings.global.globalFontType || 'raleway');
  }

  get getBrowserHeight() {
    return this.browserDimensions.height;
  }

  get getLocationHost(): string {
    if (location.host === 'localhost:8090') {
      return 'http://localhost:8080';
    } else {
      return 'https://planbook.com';
    }
  }

  get getLocationHash(): string {
    return location.hash;
  }

  get getUserId(): number {
    return this.userId;
  }

  get getSettingsByUserId(): (userId?: number) => any {
    return (userId?: number): any => {
      if (!userId) {
        userId = this.userId;
      }
      return this.settings[userId] || Settings.createSettings();
    }
  }

  get getSettings() {
    return this.getSettingsByUserId();
  }

  get getUserInfo(): any {
    return this.getSettings;
  }

  get hasNewAdminComment(): boolean {
    return CommonUtils.isTrue(this.getInfoSettings.newCommentA);
  }

  get hasNewTeacherComment(): boolean {
    return CommonUtils.isTrue(this.getInfoSettings.newCommentT);
  }

  get getAutoSaveInterval(): number {
    return +this.getLessonRetrieval.autosaveInterval;
  }

  get getUserType() {
    try {
      return this.getSettings.accountSettings.userType;
    } catch (e) {
      return 'T';
    }
  }

  get dualUser() {
    return this.getUserType === 'B' || (this.allowViewTeachers && this.getUserType === 'T' && this.userMode !== 'S');
  }

  get getDisplayViewItems() {
    const items = [];
    if (this.userMode === 'S') {
      if (this.studentViewStandardsId === 'Y') items.push('studentViewStandardsId');
      if (this.studentViewStandardsDesc === 'Y') items.push('studentViewStandardsDesc');
      if (this.studentViewAttachments === 'Y') items.push('studentViewAttachments');
    } else {
      const userInfo = this.getSettings;
      if (userInfo) {
        const displayItems = userInfo.viewSettings.displayItems;
        for (let i = 0; i < displayItems.length; i++) {
          if (displayItems.charAt(i) === 'Y') {
            items.push(viewItems[i]);
          }
        }
      }
    }
    return items;
  }

  get getViewType(): string {
    const displayView = this.getSettings.viewSettings.displayView;
    if (displayView === 'day' || vuetify.framework.breakpoint.mobile) {
      return 'D';
    } else if (displayView === 'month') {
      return 'M';
    } else {
      return 'W';
    }
  }

  get getHideClassIds(): Array<any> {
    return (this.getSettings.viewSettings.hideClassIds || []).map((c: any) => { return +c });
  }

  get getStandardSettings(): any {
    return this.getSettings.standardSettings || {};
  }

  get getActiveFrameworks(): Array<any> {
    return this.getStandardSettings.showStateIds || [];
  }

  get getDisplaySettings(): any {
    return this.getSettings.displaySettings || {};
  }

  get getPrintLayout() {
    return this.getDisplaySettings.printLayout || [];
  }

  get getDateStyling() {
    return this.getDisplaySettings.dateStyling || {};
  }

  get getDateFormat() {
    return +this.getDateStyling.dateFormat;
  }

  get getTimeFormat() {
    return CommonUtils.get(+this.getOtherSettings.timeFormat, 0);
  }

  get getClassworkStyling() {
    return this.getDisplaySettings.classworkStyling || {};
  }

  get showCount() {
    return CommonUtils.isTrue(this.getDateStyling.showCount);
  }

  get getLessonHeadings() {
    return this.getDisplaySettings.lessonHeadings || {};
  }

  get getClassHeadOrder() {
    return this.getLessonHeadings.classHeadOrder;
  }

  get getLessonStatusSettings() {
    return this.getDisplaySettings.lessonStatus || {};
  }

  get getEventStyling() {
    return this.getDisplaySettings.eventStyling || {};
  }

  get getLessonOrientationSettings(): any {
    return this.getDisplaySettings.lessonOrientation || { adjustToFit: 'N', equalSize: 'N', boxSize: 100, viewOrientation: 0 };
  }

  get getLessonRetrieval(): any {
    return this.getDisplaySettings.lessonRetrieval || {};
  }

  get getAdminLayout(): any {
    const userInfo = this.getSettings;
    if (userInfo) {
      const displaySettings = userInfo.displaySettings;
      const adminLayouts = (displaySettings.lessonSections || {}).lessonLayouts;
      let defaultDistrictLayout = null;
      let firstDistrictLayout = null;
      let defaultAdminLayout = null;
      let firstAdminLayout = null;
      if (CommonUtils.isNotEmpty(adminLayouts)) {
        for (const i in adminLayouts) {
          const adminLayout = adminLayouts[i];
          if (!firstAdminLayout) {
            firstAdminLayout = adminLayout;
          }

          if (!firstDistrictLayout && adminLayout.districtId > 0) {
            firstDistrictLayout = adminLayout;
          }

          if (!defaultDistrictLayout && adminLayout.districtId > 0 && adminLayout.defaultLayout === 'Y') {
            defaultDistrictLayout = adminLayout;
          }

          if (!defaultAdminLayout && adminLayout.districtId === 0 && adminLayout.defaultLayout === 'Y') {
            defaultAdminLayout = adminLayout;
          }
        }
      }
      return defaultAdminLayout || defaultDistrictLayout || firstAdminLayout || firstDistrictLayout;
    }
    return {};
  }

  get getAdminLessonSections(): any {
    const layout = this.getAdminLayout;
    if (layout) {
      return {
        lesson: {
          lessonEnabled: layout.tab1Enabled,
          lessonLabel: layout.tab1Label,
          lessonDisplayOrder: layout.tab1DisplayOrder
        },
        tab2: {
          tab2Enabled: layout.tab2Enabled,
          tab2Label: layout.tab2Label,
          tab2DisplayOrder: layout.tab2DisplayOrder
        },
        tab3: {
          tab3Enabled: layout.tab3Enabled,
          tab3Label: layout.tab3Label,
          tab3DisplayOrder: layout.tab3DisplayOrder
        },
        tab4: {
          tab4Enabled: layout.tab4Enabled,
          tab4Label: layout.tab4Label,
          tab4DisplayOrder: layout.tab4DisplayOrder
        },
        tab5: {
          tab5Enabled: layout.tab5Enabled,
          tab5Label: layout.tab5Label,
          tab5DisplayOrder: layout.tab5DisplayOrder
        },
        tab6: {
          tab6Enabled: layout.tab6Enabled,
          tab6Label: layout.tab6Label,
          tab6DisplayOrder: layout.tab6DisplayOrder
        },
        myStandards: {
          myStandardsEnabled: layout.tab7Enabled,
          myStandardsLabel: layout.tab7Label,
          myStandardsDisplayOrder: layout.tab7DisplayOrder
        },
        sharedStandards: {
          sharedStandardsEnabled: layout.tab8Enabled,
          sharedStandardsLabel: layout.tab8Label,
          sharedStandardsDisplayOrder: layout.tab8DisplayOrder
        },
        strategies: {
          strategiesEnabled: layout.strategiesEnabled,
          strategiesLabel: layout.strategiesLabel,
          strategiesDisplayOrder: layout.strategiesDisplayOrder
        },
        schoolStandards: {
          schoolStandardsEnabled: layout.tab9Enabled,
          schoolStandardsLabel: layout.tab9Label,
          schoolStandardsDisplayOrder: layout.tab9DisplayOrder
        }
      };
    }
    return {};
  }

  get getLessonSections(): any {
    const userInfo = this.getSettings;
    if (userInfo) {
      if (this.userMode === 'A') {
        if (store.state.currentPage === 'lesson-banks' || store.state.currentPage === 'templates') {
          const adminSections = this.getAdminLessonSections;
          const lessonSections = ld.cloneDeep(this.getDisplaySettings.lessonSections);
          for (const key in adminSections) {
            for (const prop in adminSections[key]) {
              lessonSections[key][prop] = adminSections[key][prop];
            }
          }
          return lessonSections;
        }
        return this.getSettingsByUserId(this.currentTeacherId).displaySettings.lessonSections;
      } else {
        return this.getDisplaySettings.lessonSections;
      }
    }
    return {};
  }

  get getLessonSection(): (section: string) => any {
    return (section): any => {
      return ld.get(this.getLessonSections, section);
    };
  }

  get getLessonSectionLabel(): (s: string) => string {
    return (s: string) => {
      const section = this.getLessonSection(s);
      if (section) {
        return section[s + 'Label'];
      }
      return '';
    };
  }

  get isLessonSectionAndEnabled() {
    return (s: string, checkLayoutsUsed?: boolean): boolean => {
      const section = this.getLessonSection(s);
      if (section) {
        let isEnabled = section[s + 'Enabled'] === 'Y';
        if (!isEnabled && checkLayoutsUsed) {
          isEnabled = this.isEnabledOnAnyLayoutsUsed(s);
        }
        return isEnabled;
      }
      return true;
    };
  }

  get isLessonSectionEnabled() {
    return (s: string, checkLayoutsUsed?: boolean): boolean => {
      const section = this.getLessonSection(s);
      if (section) {
        let isEnabled = section[s + 'Enabled'] === 'Y';
        if (!isEnabled && checkLayoutsUsed) {
          isEnabled = this.isEnabledOnAnyLayoutsUsed(s);
        }
        return isEnabled;
      }
      return false;
    };
  }

  get isEnabledOnAnyLayoutsUsed() {
    return (s: string): boolean => {
      const section = lessonSectionsMapping[s];
      if (CommonUtils.hasText(section) && this.layoutsUsed) {
        for (const layoutId in this.layoutsUsed) {
          const layout = this.layoutsUsed[layoutId];
          if (CommonUtils.isTrue(layout[`${section}Enabled`])) {
            return true;
          }
        }
      }
      return false;
    };
  }

  get getOtherLessonLayoutLabels() {
    return (s: string): Array<any> => {
      const items: Set<string> = new Set();
      const section = lessonSectionsMapping[s];
      const currentLabel = this.getLessonSectionLabel(s);
      if (CommonUtils.hasText(section) && this.layoutsUsed) {
        for (const layoutId in this.layoutsUsed) {
          const layout = this.layoutsUsed[layoutId];
          const label = layout[`${section}Label`];
          if (CommonUtils.hasText(label) && label !== currentLabel) {
            items.add(label);
          }
        }
      }
      return [...items];
    };
  }

  get layoutsUsed(): any {
    const layoutIds = this.context.rootGetters['classes/classLessonLayoutIds'];
    const items: any = {};
    this.getLessonLayouts.forEach(layout => {
      if (layoutIds.includes(layout.lessonLayoutId)) {
        items[layout.lessonLayoutId] = layout;
      }
    })
    return items;
  }

  get getLessonSectionViewItems() {
    const groupedItems = [];
    if (this.userMode === 'S') {
      groupedItems.push(studentViewItems);
    } else {
      const items = [];
      for (const i in selectViewItems) {
        const item: any = selectViewItems[i];
        const label = this.getLessonSectionLabel(item.value);
        if (item.value === 'sectionTags' && !this.displaySectionTags) {
          continue;
        }
        if (CommonUtils.hasText(label)) {
          if (this.isLessonSectionEnabled(item.value, true)) {
            item.text = label;
            const otherTexts = this.getOtherLessonLayoutLabels(item.value);
            if (CommonUtils.isNotEmpty(otherTexts)) {
              (item as any).tooltip = otherTexts.join(', ');
            }
            items.push(item);
          }
        } else {
          items.push(item);
        }
      }
      groupedItems.push(items);
      const linkedItems = [];
      for (const i in selectLinkedViewItems) {
        const item = selectLinkedViewItems[i];
        const label = this.getLessonSectionLabel(item.value);
        if (CommonUtils.hasText(label)) {
          if (this.isLessonSectionEnabled(item.value)) {
            item.text = label;
            linkedItems.push(item);
          }
        } else {
          linkedItems.push(item);
        }
      }
      groupedItems.push(linkedItems);
      const otherItems = [];
      for (const i in otherViewItems) {
        const item: any = otherViewItems[i];
        const label = this.getLessonSectionLabel(item.value);
        if (CommonUtils.hasText(label)) {
          if (this.isLessonSectionEnabled(item.value, true)) {
            item.text = label;
            const otherTexts = this.getOtherLessonLayoutLabels(item.value);
            if (CommonUtils.isNotEmpty(otherTexts)) {
              (item as any).tooltip = otherTexts.join(', ');
            }
            otherItems.push(item);
          }
        } else {
          otherItems.push(item);
        }
      }
      groupedItems.push(otherItems);
    }
    return groupedItems;
  }

  get displaySectionTags() {
    return this.getOtherSettings.displaySesctionTags;
  }

  get getAccountSettings() {
    return this.getSettings.accountSettings || {};
  }

  get paidThrougDate() {
    return this.getAccountSettings.paidThroughDate || '01/01/0001';
  }

  get isSubscriptionExpiring() {
    return (this.paidThroughDateDiff <= 7 && this.paidThroughDateDiff > 0);
  }

  get paidThroughDateDiff() {
    return moment(this.paidThrougDate, 'MM/DD/YYYY').diff(new Date(), 'days');
  }

  get getOtherSettings() {
    return this.getSettings.otherSettings || {};
  }

  get getLessonSectionsGrouping() {
    return this.getOtherSettings.lessonSectionsGrouping || {
      defaultGroupingLessonSectionOrder: 2,
      defaultGroupingLinkedLessonSectionOrder: 3,
      defaultGroupingLinkedUnitSectionOrder: 4,
      defaultGroupingUnitSectionOrder: 1,
      groupedGroupingLessonOrder: 2,
      groupedGroupingLinkedLessonOrder: 3,
      groupedGroupingLinkedUnitOrder: 4,
      groupedGroupingUnitOrder: 1,
      lessonSectionsGroupingValue: 1
    }
  }

  get isReduceBrightnessOfPastDays() {
    return this.getOtherSettings.reduceBrightnessOfPastDays;
  }

  get getShowSnackbarNotifications() {
    return this.getOtherSettings.showSnackbarNotifications;
  }

  get darkMode() {
    return this.getOtherSettings.darkMode;
  }

  get autoColorScheme() {
    return CommonUtils.get(this.getOtherSettings.autoColorScheme, true);
  }

  get highContrastMode() {
    return CommonUtils.get(this.getOtherSettings.highContrastMode, false) && !this.getOtherSettings.darkMode;
  }

  get defaultEditorMode() {
    return this.getOtherSettings.defaultEditorMode || 'split';
  }

  get getLessonStyling() {
    return this.getDisplaySettings.lessonStyling || {};
  }

  get getLessonTitleStyling() {
    return this.getLessonStyling.lessonTitle;
  }

  get getAttachmentsStyling() {
    return this.getLessonStyling.attachments;
  }

  get getAssessmentsStyling() {
    return this.getLessonStyling.assessments;
  }

  get getAssignmentsStyling() {
    return this.getLessonStyling.assignments;
  }

  get getPromo() {
    return this.getOtherSettings.promo || {};
  }

  get getStatuses() {
    if (this.userMode === 'A') {
      return this.currentStatuses || [];
    } else {
      return this.getAccountSettings.statuses || [];
    }
  }

  get getSchoolStatuses() {
    if (this.getAccountSettings.schoolStatuses && this.userMode === 'T') {
      return this.getAccountSettings.schoolStatuses.filter((s: any) => !s.overrideDelete);
    }
    return this.getAccountSettings.schoolStatuses || [];
  }

  get getStatus() {
    return (statusId: number | string) => {
      const statuses = this.getStatuses;
      for (const i in statuses) {
        const status = statuses[i];
        if (+status.lessonStatusId === +statusId) {
          return status;
        }
      }
    };
  }

  get getSchoolStatus() {
    return (statusId: number | string) => {
      const schoolStatuses = this.getSchoolStatuses;
      for (const i in schoolStatuses) {
        const status = schoolStatuses[i];
        if (+status.schoolStatusId === +statusId) {
          return status;
        }
      }
    };
  }

  get getViewSchoolStatusItems() {
    const items = [{ text: i18n.t('noStatusLabel'), value: 0 }];
    const statuses = this.getSchoolStatuses;
    for (const i in statuses) {
      const status = statuses[i];
      items.push({
        text: status.statusText,
        value: status.schoolStatusId
      });
    }
    return items;
  }

  get getSelectedSchoolStatusItems() {
    const userInfo = this.getSettings;
    const items = [];
    if (userInfo) {
      const profile = userInfo.viewSettings.statusProfileSettings || {};
      const statusSettings = profile.schoolStatusSettings || [];
      const statusSettingsMap: any = {};
      for (const i in statusSettings) {
        const ss = statusSettings[i];
        if (this.userMode === 'A') {
          statusSettingsMap[ss.schoolStatusId] = ss;
        } else {
          statusSettingsMap[ss.schoolStatusId] = ss;
        }
      }
      const statuses = this.getSchoolStatuses;
      const viewNoSchoolStatus = profile.viewNoSchoolStatus;
      if (viewNoSchoolStatus) {
        items.push(0);
      }
      for (const i in statuses) {
        const status = statuses[i];
        const ss = statusSettingsMap[status.schoolStatusId];
        if (ss) {
          if (ss.view) {
            items.push(status.schoolStatusId);
          }
        } else {
          items.push(status.schoolStatusId);
        }
      }
    }
    return items;
  }

  get getViewStatusItems() {
    const items = [{ text: i18n.t('noStatusLabel'), value: 0 }];
    const statuses = this.getStatuses;
    for (const i in statuses) {
      const status = statuses[i];
      items.push({
        text: status.statusText,
        value: status.lessonStatusId
      });
    }
    return items;
  }

  get getSelectedViewStatusItems() {
    const userInfo = this.getSettings;
    const items = [];
    if (userInfo) {
      const profile = userInfo.viewSettings.statusProfileSettings || {};
      const statusSettings = profile.statusSettings || [];
      const statusSettingsMap: any = {};
      for (const i in statusSettings) {
        const ss = statusSettings[i];
        if (this.userMode === 'A') {
          if (ss.teacherId === this.currentTeacherId) {
            statusSettingsMap[ss.lessonStatusId] = ss;
          }
        } else {
          statusSettingsMap[ss.lessonStatusId] = ss;
        }
      }
      const statuses = this.getStatuses;
      const viewNoStatus = profile.viewNoStatus;
      if (viewNoStatus) {
        items.push(0);
      }
      for (const i in statuses) {
        const status = statuses[i];
        const ss = statusSettingsMap[status.lessonStatusId];
        if (ss) {
          if (ss.view) {
            items.push(status.lessonStatusId);
          }
        } else {
          items.push(status.lessonStatusId);
        }
      }
    }
    return items;
  }

  get getAccessToken() {
    return this.getAccountSettings.accessToken;
  }

  get getInfoSettings() {
    return this.getSettings.infoSettings || {};
  }

  get infoMsg() {
    return this.getInfoSettings.infoMsg;
  }

  get alertMsg() {
    return this.getInfoSettings.alertMsg;
  }

  get infoMessages() {
    return (this.getInfoSettings.infoMessages || []).concat(this.additionalInfoMsgs || []);
  }

  get errorMessages() {
    return (this.getInfoSettings.errorMessages || []).concat(this.additionalErrorMsgs || []);
  }

  get getAllFrameworks(): Array<any> {
    let allFrameworks: any[] = [];
    if (this.settings) {
      const userInfo = this.getSettings;
      if (userInfo) {
        const standardSettings = userInfo.standardSettings;
        const schoolSettings = userInfo.schoolSettings;

        if (+schoolSettings.schoolId > 0 && CommonUtils.isTrue(standardSettings.hasStandards)) {
          allFrameworks.push({
            name: 'School - ' + schoolSettings.schoolName,
            id: '' + schoolSettings.schoolId,
            school: true
          });
        }
        if (+schoolSettings.districtId > 0 && CommonUtils.isTrue(standardSettings.hasDistrictStandards)) {
          allFrameworks.push({
            name: 'District - ' + schoolSettings.districtName,
            id: '' + schoolSettings.districtId,
            district: true
          });
        }
        allFrameworks = allFrameworks.concat(standardSettings.codes || []);
      }
    }
    return allFrameworks;
  }

  get getActiveFrameworkItems() {
    const activeFrameworks = this.getActiveFrameworks;
    return this.getAllFrameworks.filter(
      framework =>
        framework.school || framework.district || activeFrameworks.includes(framework.id) || this.userMode === 'A'
    ).map(framework => {
      return {
        value: framework.id,
        text: framework.name
      };
    })
  }

  get getUserMode() {
    return this.userMode;
  }

  get getEmailAddress() {
    return this.getAccountSettings.emailAddress || '';
  }

  get getSubPlans() {
    return this.getAccountSettings.subPlans || '';
  }

  get getStudentNotes() {
    return this.getAccountSettings.studentNotes || '';
  }

  get isPaymentNeeded() {
    return CommonUtils.isTrue(this.getAccountSettings.paymentNeeded);
  }

  get isSchoolSuspended() {
    return CommonUtils.isTrue(this.getSchoolSettings.schoolSuspendAccount);
  }

  get getDisplayName() {
    let displayName = '';
    const accountSettings = this.getAccountSettings;
    if (CommonUtils.hasText(accountSettings.displayName)) {
      displayName = accountSettings.displayName;
    } else {
      if (CommonUtils.hasText(accountSettings.firstName)) {
        displayName += accountSettings.firstName + ' ';
      }

      if (CommonUtils.hasText(accountSettings.lastName)) {
        displayName += accountSettings.lastName;
      }
    }
    return displayName;
  }

  get getNameOrder() {
    return this.getUserInfo.otherSettings.nameOrder;
  }

  get getIsNameCommaSeparated() {
    return this.getUserInfo.otherSettings.isNameCommaSeparated;
  }

  get getShowCount() {
    return this.getDisplaySettings.dateStyling.showCount || 'N';
  }

  get getCustomFonts(): Array<any> {
    return this.getOtherSettings.customFonts || [];
  }

  get getCurrentTeacherId() {
    if (this.getUserMode === 'T') {
      return +this.userId;
    } else {
      return +this.currentTeacherId
    }
  }

  get getCustomFontsCSSUrl() {
    let mode = 't';
    let id = CurrentUser.id;
    if (CurrentUser.isDistrictAdmin) {
      mode = 'da';
    } else if (CurrentUser.isAdmin) {
      mode = 'a';
    } else if (CurrentUser.isStudent) {
      id = this.currentTeacherId;
    }
    return `${BASE_URL}/services/common/css/${mode}/${id}/custom-fonts.css?apiKey=2e6c6353-3891-4e8a-b579-f6ef074dea34&fontKey=${this.customFontsKey}`;
  }

  get getFontItems() {
    return fonts.concat(this.getCustomFonts.map(customFont => {
      return {
        value: CommonUtils.getFontFamilyKey(customFont),
        text: customFont.fontFamily
      }
    }));
  }

  get getSchoolYearSettings() {
    return this.getSettings.schoolYearSettings || {};
  }

  get getClassCycle() {
    return this.getSchoolYearSettings.classCycle || 'oneWeek';
  }

  get isCycleSchedule() {
    return !isNaN(+this.getClassCycle);
  }

  get isNewYearNeeded() {
    return CommonUtils.isTrue(this.getSchoolYearSettings.yearNeeded) && this.userMode === 'T';
  }

  get getCycleDayNames() {
    return this.getSchoolYearSettings.cycleDayNames || [];
  }

  get getSharingOptionsSettings() {
    return this.getSettings.sharingOptionsSettings || {};
  }

  get getSchoolYears(): Array<any> {
    return this.getSchoolYearSettings.years || [];
  }

  get getYearName() {
    return (yearId: number | string): string => {
      const schoolYears = this.getSchoolYears;
      for (const x in schoolYears) {
        if (+yearId === +schoolYears[x].yearId) {
          return schoolYears[x].yearName;
        }
      }
      return '';
    }
  }

  get getSchoolYearItems() {
    return this.getSchoolYears.map((s: any) => {
      return { text: s.yearName, value: s.yearId, firstDay: s.firstDay, lastDay: s.lastDay, parentId: s.parentYearId || 0, parentSchoolId: s.parentYearSchoolId, parentDistrictId: s.parentYearDistrictId };
    })
  }

  get getYearNames() {
    return this.getSchoolYears.map(s => s.yearName);
  }

  get getCurrentSchoolYearId() {
    return +this.getSchoolYearSettings.currentYearId || this.currentYearId;
  }

  get getCurrentYear() {
    const currentYearId = this.getCurrentSchoolYearId;
    const schoolYears = this.getSchoolYears;
    for (const x in schoolYears) {
      if (currentYearId === schoolYears[x].yearId) {
        return schoolYears[x];
      }
    }
    return { yearId: 0, yearName: '', parentYearId: 0, parentYearName: '' };
  }

  get getFirstDay() {
    return this.getCurrentYear.firstDay || '';
  }

  get getLastDay() {
    return this.getCurrentYear.lastDay || '';
  }

  get getSchoolYearNames() {
    const item = [];
    const years = this.getSchoolYears;
    for (const i in years) {
      const year = years[i];
      item.push(year.yearName);
    }
    return item;
  }

  get getSharedClasses() {
    return this.getSharingOptionsSettings?.sharedClasses;
  }

  get getSharedClassesList() {
    if (this.getSharedClasses) {
      return Object.values(this.getSharedClasses);
    }
    return [];
  }

  get getStudentTeacherItems() {
    const items = [{ text: 'All Teachers', value: 0 }];
    if (CommonUtils.isNotEmpty(this.studentTeachers)) {
      for (const i in this.studentTeachers.filter((value, index, self) => self.findIndex(v => v.teacherId === value.teacherId) === index)) {
        const studentTeacher = this.studentTeachers[i];
        const teacherId = studentTeacher.userId;
        let displayName = '';
        if (CommonUtils.hasText(studentTeacher.displayName)) {
          displayName = studentTeacher.displayName;
        } else {
          if (CommonUtils.hasText(studentTeacher.firstName)) {
            displayName += studentTeacher.firstName + ' ';
          }
          if (CommonUtils.hasText(studentTeacher.lastName)) {
            displayName += studentTeacher.lastName;
          }
        }
        items.push({ text: displayName, value: teacherId });
      }
    }
    return items;
  }

  get hasSchoolInvites() {
    return CommonUtils.isNotEmpty(this.schoolInvites);
  }

  get schoolInvites(): Array<any> {
    return this.getSchoolSettings.schoolInvites || [];
  }

  get userSchoolsToManage() {
    return this.schoolInvites.concat(this.getSchools).sort((a, b) => {
      if (CommonUtils.isTrue(a.primary)) {
        return -1;
      } else if (CommonUtils.isTrue(b.primary)) {
        return 1;
      } else {
        try {
          return a.schoolName.localeCompare(b.schoolName);
        } catch (e) {
          return 0;
        }
      }
    });
  }

  get getSchoolSettings() {
    return this.getSettings.schoolSettings || {};
  }

  get getPrimarySchool() {
    return this.getSchoolSettings.primarySchool || {};
  }

  get allowViewTeachers() {
    const pss = this.getPrimarySchool.settings;
    return pss ? pss.allowViewTeachers : false;
  }

  get getSchools(): Array<any> {
    return this.getSchoolSettings.schools || [];
  }

  get getSchoolIds() {
    return this.getSchools.map(s => s.schoolId);
  }

  get getSchoolId() {
    return this.getSchoolSettings.schoolId || 0;
  }

  get getDistrictId() {
    return this.getSchoolSettings.districtId || 0;
  }

  get getSchoolStartTime() {
    return this.getUserInfo.schoolStartTime;
  }

  get getSchoolEndTime() {
    return this.getUserInfo.schoolEndTime;
  }

  get getConfirmTitle() {
    return this.confirmTitle;
  }

  get getConfirmSubtitle() {
    return this.confirmSubtitle;
  }

  get getConfirmText() {
    return this.confirmText;
  }

  get getConfirmOption1ButtonAlternativeText() {
    return this.confirmOption1ButtonAlternativeText;
  }

  get getConfirmOption1ButtonCallback(): any {
    return this.confirmOption1ButtonCallback;
  }

  get getConfirmOption2ButtonAlternativeText() {
    return this.confirmOption2ButtonAlternativeText;
  }

  get getConfirmOption2ButtonCallback(): any {
    return this.confirmOption2ButtonCallback;
  }

  get getLessonLayouts(): Array<any> {
    return this.getDisplaySettings.lessonSections?.lessonLayouts || [];
  }

  get getShowLessonStatus(): any {
    return this.showLessonStatus || {};
  }

  get lessonLayoutItems() {
    const items: Array<any> = [];
    this.getLessonLayouts.filter(l => {
      return CurrentUser.isTeacher || (CurrentUser.isAdmin && (l.schoolId > 0 || l.districtId > 0 || l.lessonLayoutId <= 0))
    }).forEach(l => {
      if (l.lessonLayoutId <= 0) {
        items.unshift({
          value: 0,
          text: CurrentUser.isTeacher ? l.name : i18n.t('defaultLabel')
        });
      } else {
        items.push({
          value: l.lessonLayoutId,
          text: l.name
        });
      }
    });
    return items;
  }

  get getCurrentLessonLayout() {
    if (this.userMode === 'T') {
      return sections.filter(s => s !== 'attachments').map((s: string) => {
        const section = this.getLessonSections[s];
        return {
          section: s,
          color: section[s + 'Color'],
          displayOrder: section[s + 'DisplayOrder'],
          enabled: section[s + 'Enabled'],
          font: section[s + 'Font'],
          hide: section[s + 'Hide'],
          placeholder: lessonSectionsPlaceHolders.find((p: any) => p.value === s),
          label: section[s + 'Label'],
          labelBold: section[s + 'LabelBold'],
          labelFillColor: section[s + 'LabelFillColor'],
          labelItalic: section[s + 'LabelItalic'],
          labelUnderline: section[s + 'LabelUnderline'],
          size: section[s + 'Size'],
          textBold: section[s + 'TextBold'],
          textColor: section[s + 'TextColor'],
          textFillColor: section[s + 'TextFillColor'],
          textFont: section[s + 'TextFont'],
          textItalic: section[s + 'TextItalic'],
          textSize: section[s + 'TextSize'],
          textUnderline: section[s + 'TextUnderline']
        }
      });
    }
    return {};
  }

  get getCurrentLessonLayoutId() {
    if (this.userMode === 'T') {
      return this.getLessonSections.currentLessonLayoutId;
    }
    return 0;
  }

  get getSchoolYearListLoading() {
    return this.schoolYearListLoading;
  }

  get getPushNotificationsEnabled() {
    return this.userMode !== 'S' && this.getUserInfo.otherSettings.allowPush;
  }

  get getGlobalLanguage() {
    return this.getDisplaySettings.global.globalLanguage;
  }

  get getGlobalLanguageItems() {
    const localLanguages:any[] = [];
    for (const key in languages) {
      if (Object.prototype.hasOwnProperty.call(languages, key)) {
        const element = languages[key];
        localLanguages.push({
          text: element.label,
          value: key
        });
      }
    }
    return localLanguages;
  }

  get getStudentTeacherId() {
    return this.studentTeacherId;
  }

  @Mutation
  storeSettings(settingsData: any) {
    const userInfo = Settings.createSettings();
    userInfo.userId = settingsData.userId;
    this.currentYearId = settingsData.currentYearId;
    this.currentTeacherId = settingsData.userId;
    const user = UserSession.getCurrentUser();
    if (user?.type === 'S' && CommonUtils.hasText(user?.key) && user?.currentSchoolYearId) {
      this.currentYearId = this.currentYearId || user?.currentSchoolYearId;
    }
    // Standard Settings
    userInfo.standardSettings.codes = settingsData.codes;
    userInfo.standardSettings.currentState = settingsData.currentState;
    userInfo.standardSettings.currentSubject = settingsData.currentSubject;
    userInfo.standardSettings.hasStandards = settingsData.hasStandards;
    userInfo.standardSettings.hasDistrictStandards = settingsData.hasDistrictStandards;
    userInfo.standardSettings.currentGrade = settingsData.currentGrade;
    userInfo.standardSettings.showStateIds = settingsData.showStateIds;

    // Info Settings
    userInfo.infoSettings.infoMsg = settingsData.infoMsg;
    userInfo.infoSettings.alertMsg = settingsData.alertMsg;
    userInfo.infoSettings.infoMessages = settingsData.infoMessages;
    userInfo.infoSettings.errorMessages = settingsData.errorMessages;
    userInfo.infoSettings.infoCount = settingsData.infoCount;
    userInfo.infoSettings.mobileAlertMsg = settingsData.mobileAlertMsg;
    userInfo.infoSettings.newCommentA = settingsData.newCommentA;
    userInfo.infoSettings.newCommentT = settingsData.newCommentT;

    // Sharing Options Settings
    userInfo.sharingOptionsSettings.sharedList = settingsData.sharedList || [];
    userInfo.sharingOptionsSettings.studentPassword = settingsData.studentPassword;
    userInfo.sharingOptionsSettings.sharedUserKey = settingsData.sharedUserKey;
    userInfo.sharingOptionsSettings.viewAssessments = settingsData.viewAssessments;
    userInfo.sharingOptionsSettings.viewAssignments = settingsData.viewAssignments;
    userInfo.sharingOptionsSettings.viewEvents = settingsData.viewEvents;
    userInfo.sharingOptionsSettings.viewGoogleEvents = settingsData.viewGoogleEvents;
    userInfo.sharingOptionsSettings.viewGrades = settingsData.viewGrades;
    userInfo.sharingOptionsSettings.viewHomework = settingsData.viewHomework;
    userInfo.sharingOptionsSettings.viewLength = settingsData.viewLength;
    userInfo.sharingOptionsSettings.viewLessons = settingsData.viewLessons;
    userInfo.sharingOptionsSettings.viewMyList = settingsData.viewMyList;
    userInfo.sharingOptionsSettings.viewNotes = settingsData.viewNotes;
    userInfo.sharingOptionsSettings.viewSchoolList = settingsData.viewSchoolList;
    userInfo.sharingOptionsSettings.viewStandards = settingsData.viewStandards;
    userInfo.sharingOptionsSettings.viewStrategies = settingsData.viewStrategies;
    userInfo.sharingOptionsSettings.viewTab4 = settingsData.viewTab4;
    userInfo.sharingOptionsSettings.viewTab5 = settingsData.viewTab5;
    userInfo.sharingOptionsSettings.viewTab6 = settingsData.viewTab6;
    userInfo.sharingOptionsSettings.sharedClasses = {};
    CommonUtils.forEach(settingsData.sharedList, shared => {
      if (CommonUtils.isNotEmpty(shared.years)) {
        CommonUtils.forEach(shared.years, year => {
          CommonUtils.forEach(year.classes, c => {
            if (shared.fromPermissions) {
              const sharedClass = userInfo.sharingOptionsSettings.sharedClasses[c.classId] = c;
              sharedClass.hasFullPermissions = LessonServices.hasFullPermissions(c.permissionSettings);
              sharedClass.teacherId = shared.teacherSharedId;
              sharedClass.email = shared.sharedUserEmail;
              sharedClass.schoolId = shared.sharedSchoolId;
              sharedClass.schoolName = shared.sharedSchoolName;
              sharedClass.districtId = shared.sharedDistrictId;
              sharedClass.fromPermissions = true;
            }
          });
        });
      }
    });

    // Account Settings
    userInfo.accountSettings.photoUrl = settingsData.photoUrl;
    userInfo.accountSettings.paymentNeeded = settingsData.paymentNeeded;
    userInfo.accountSettings.userId = settingsData.userId;
    userInfo.accountSettings.userType = settingsData.userType;
    userInfo.accountSettings.emailAddress = settingsData.emailAddress;
    userInfo.accountSettings.firstName = settingsData.firstName;
    userInfo.accountSettings.lastName = settingsData.lastName;
    userInfo.accountSettings.adminPayments = settingsData.adminPayments;
    userInfo.accountSettings.displayName = settingsData.displayName;
    userInfo.accountSettings.extend1 = settingsData.extend1;
    userInfo.accountSettings.extend2 = settingsData.extend2;
    userInfo.accountSettings.extend3 = settingsData.extend3;
    userInfo.accountSettings.paidThroughDate = settingsData.paidThroughDate;
    userInfo.accountSettings.payments = settingsData.payments;
    userInfo.accountSettings.registeredDate = settingsData.registeredDate;
    userInfo.accountSettings.sendEmail = settingsData.sendEmail;
    userInfo.accountSettings.showBanner = settingsData.showBanner;

    userInfo.accountSettings.accessToken = settingsData.accessToken;
    userInfo.accountSettings.hasToken = settingsData.hasToken;
    userInfo.accountSettings.PaymentGatewayMode = settingsData.PaymentGatewayMode;

    userInfo.accountSettings.strategyGroup = settingsData.strategyGroup;
    userInfo.accountSettings.studentNotes = settingsData.studentNotes;
    userInfo.accountSettings.teacherNotes = settingsData.teacherNotes;
    userInfo.accountSettings.subPlans = settingsData.subPlans;
    userInfo.accountSettings.viewNotesText = settingsData.viewNotesText;

    userInfo.accountSettings.inviteId = settingsData.inviteId;
    userInfo.accountSettings.lastLogon = settingsData.lastLogon;

    userInfo.accountSettings.currentGradingPeriodId = settingsData.currentGradingPeriodId;
    userInfo.accountSettings.gradingPeriods = settingsData.gradingPeriods;
    userInfo.accountSettings.letterGrades = settingsData.letterGrades;
    userInfo.accountSettings.scoreCodes = settingsData.scoreCodes;
    userInfo.accountSettings.scoreWeights = settingsData.scoreWeights;
    userInfo.accountSettings.standardWeights = settingsData.standardWeights;

    userInfo.accountSettings.statuses = settingsData.statuses;
    userInfo.accountSettings.schoolStatuses = settingsData.schoolStatuses;

    userInfo.accountSettings.haveAdminLetterGrades = settingsData.haveAdminLetterGrades;
    userInfo.accountSettings.haveAdminGradingPeriods = settingsData.haveAdminGradingPeriods;
    userInfo.accountSettings.currentStrategyGroup = 0;
    userInfo.accountSettings.enableCalendars = [];
    if (CommonUtils.isNotEmpty(settingsData.googleEmailCal)) {
      for (const i in settingsData.googleEmailCal) {
        userInfo.accountSettings.enableCalendars.push(settingsData.googleEmailCal[i].calendar);
      }
    }

    // School Settings
    userInfo.schoolSettings.schoolId = settingsData.schoolId;
    userInfo.schoolSettings.districtId = settingsData.districtId;
    userInfo.schoolSettings.districtName = settingsData.districtName;
    userInfo.schoolSettings.freeCount = settingsData.freeCount;
    userInfo.schoolSettings.primarySchool = settingsData.primarySchool;
    userInfo.schoolSettings.promoCode = settingsData.promoCode;
    userInfo.schoolSettings.schoolDistrict = settingsData.schoolDistrict;
    userInfo.schoolSettings.schoolInvites = settingsData.schoolInvites;
    userInfo.schoolSettings.schoolLabel = settingsData.schoolLabel;
    userInfo.schoolSettings.schoolName = settingsData.schoolName;
    userInfo.schoolSettings.schoolPastDue = settingsData.schoolPastDue;
    userInfo.schoolSettings.schoolSuspendAccount = settingsData.schoolSuspendAccount;
    userInfo.schoolSettings.schools = settingsData.schools;
    userInfo.schoolSettings.allowTeacherStudents = settingsData.allowTeacherStudents;

    // School Year Settings
    userInfo.schoolYearSettings.classCycle = settingsData.classCycle;
    userInfo.schoolYearSettings.cycleDayNames = settingsData.cycleDays;
    userInfo.schoolYearSettings.convertStatus = settingsData.convertStatus;
    userInfo.schoolYearSettings.currentYearId = settingsData.currentYearId;
    userInfo.schoolYearSettings.yearNeeded = settingsData.yearNeeded;
    userInfo.schoolYearSettings.years = settingsData.years;

    // Other Settings
    settingsData.otherSettings = settingsData.otherSettings || {};
    userInfo.otherSettings = settingsData.otherSettings;
    userInfo.otherSettings.sharingProfiles = settingsData.otherSettings.sharingProfiles || [];

    // View Settings
    userInfo.viewSettings.displayItems = settingsData.displayItems;
    userInfo.viewSettings.viewItems = {};
    const di = settingsData.displayItems;
    if (di) {
      for (let i = 0; i < di.length; i++) {
        userInfo.viewSettings.viewItems[viewItems[i]] = di.charAt(i);
      }
    }

    userInfo.viewSettings.displayListClass = settingsData.displayListClass;
    userInfo.viewSettings.displayView = settingsData.displayView;
    userInfo.viewSettings.hideClassIds = settingsData.hideClassIds;
    userInfo.viewSettings.statusProfileSettings = {
      statusSettings: settingsData.otherSettings.statusSettings,
      viewNoStatus: CommonUtils.isEmpty(settingsData.statuses) || settingsData.otherSettings.viewNoStatus,
      shareNoStatus: CommonUtils.isEmpty(settingsData.statuses) || settingsData.otherSettings.shareNoStatus,
      schoolStatusSettings: settingsData.otherSettings.schoolStatusSettings,
      viewNoSchoolStatus: CommonUtils.isEmpty(settingsData.schoolStatuses) || settingsData.otherSettings.viewNoSchoolStatus
    };

    // Display Settings
    userInfo.displaySettings.global.globalFontSize = settingsData.otherSettings.globalFontSize;
    userInfo.displaySettings.global.globalFontType = settingsData.otherSettings.globalFontType;
    userInfo.displaySettings.global.globalLanguage = settingsData.otherSettings.globalLanguage || 'en';

    userInfo.displaySettings.lessonSections.lessonLayouts = settingsData.lessonLayouts;
    userInfo.displaySettings.lessonSections.currentLessonLayoutId = settingsData.currentLessonLayoutId;
    userInfo.displaySettings.lessonSections.lesson.lessonType = 'section-text';
    userInfo.displaySettings.lessonSections.lesson.lessonColor = settingsData.lessonColor;
    userInfo.displaySettings.lessonSections.lesson.lessonDisplayOrder = settingsData.lessonDisplayOrder;
    userInfo.displaySettings.lessonSections.lesson.lessonDisplaySettingsId = settingsData.lessonDisplaySettingsId;
    userInfo.displaySettings.lessonSections.lesson.lessonEnabled = settingsData.lessonEnabled;
    userInfo.displaySettings.lessonSections.lesson.lessonFont = settingsData.lessonFont;
    userInfo.displaySettings.lessonSections.lesson.lessonHide = settingsData.lessonHide;
    userInfo.displaySettings.lessonSections.lesson.lessonLabel = settingsData.lessonLabel;
    userInfo.displaySettings.lessonSections.lesson.lessonLabelBold = settingsData.lessonLabelBold;
    userInfo.displaySettings.lessonSections.lesson.lessonLabelFillColor = settingsData.lessonLabelFillColor;
    userInfo.displaySettings.lessonSections.lesson.lessonLabelItalic = settingsData.lessonLabelItalic;
    userInfo.displaySettings.lessonSections.lesson.lessonLabelUnderline = settingsData.lessonLabelUnderline;
    userInfo.displaySettings.lessonSections.lesson.lessonSize = settingsData.lessonSize;
    userInfo.displaySettings.lessonSections.lesson.lessonTextBold = settingsData.lessonTextBold;
    userInfo.displaySettings.lessonSections.lesson.lessonTextColor = settingsData.lessonTextColor;
    userInfo.displaySettings.lessonSections.lesson.lessonTextFillColor = settingsData.lessonTextFillColor;
    userInfo.displaySettings.lessonSections.lesson.lessonTextFont = settingsData.lessonTextFont;
    userInfo.displaySettings.lessonSections.lesson.lessonTextItalic = settingsData.lessonTextItalic;
    userInfo.displaySettings.lessonSections.lesson.lessonTextSize = settingsData.lessonSize;
    userInfo.displaySettings.lessonSections.lesson.lessonTextUnderline = settingsData.lessonTextUnderline;

    userInfo.displaySettings.lessonSections.tab2.tab2Type = 'section-text';
    userInfo.displaySettings.lessonSections.tab2.homeWorkDisplaySettingsId = userInfo.homeWorkDisplaySettingsId;
    userInfo.displaySettings.lessonSections.tab2.tab2Color = settingsData.tab2Color;
    userInfo.displaySettings.lessonSections.tab2.tab2DisplaySettingsId = settingsData.homeWorkDisplaySettingsId;
    userInfo.displaySettings.lessonSections.tab2.tab2DisplayOrder = settingsData.tab2DisplayOrder;
    userInfo.displaySettings.lessonSections.tab2.tab2Enabled = settingsData.tab2Enabled;
    userInfo.displaySettings.lessonSections.tab2.tab2Font = settingsData.tab2Font;
    userInfo.displaySettings.lessonSections.tab2.tab2Hide = settingsData.tab2Hide;
    userInfo.displaySettings.lessonSections.tab2.tab2Label = settingsData.tab2Label;
    userInfo.displaySettings.lessonSections.tab2.tab2LabelBold = settingsData.tab2LabelBold;
    userInfo.displaySettings.lessonSections.tab2.tab2LabelFillColor = settingsData.tab2LabelFillColor;
    userInfo.displaySettings.lessonSections.tab2.tab2LabelItalic = settingsData.tab2LabelItalic;
    userInfo.displaySettings.lessonSections.tab2.tab2LabelUnderline = settingsData.tab2LabelUnderline;
    userInfo.displaySettings.lessonSections.tab2.tab2Size = settingsData.tab2Size;
    userInfo.displaySettings.lessonSections.tab2.tab2TextBold = settingsData.tab2TextBold;
    userInfo.displaySettings.lessonSections.tab2.tab2TextColor = settingsData.tab2TextColor;
    userInfo.displaySettings.lessonSections.tab2.tab2TextFillColor = settingsData.tab2TextFillColor;
    userInfo.displaySettings.lessonSections.tab2.tab2TextFont = settingsData.tab2TextFont;
    userInfo.displaySettings.lessonSections.tab2.tab2TextItalic = settingsData.tab2TextItalic;
    userInfo.displaySettings.lessonSections.tab2.tab2TextSize = settingsData.tab2TextSize;
    userInfo.displaySettings.lessonSections.tab2.tab2TextUnderline = settingsData.tab2TextUnderline;

    userInfo.displaySettings.lessonSections.tab3.tab3Type = 'section-text';
    userInfo.displaySettings.lessonSections.tab3.tab3Color = settingsData.tab3Color;
    userInfo.displaySettings.lessonSections.tab3.tab3DisplayOrder = settingsData.tab3DisplayOrder;
    userInfo.displaySettings.lessonSections.tab3.tab3DisplaySettingsId = settingsData.tab3DisplaySettingsId;
    userInfo.displaySettings.lessonSections.tab3.tab3Enabled = settingsData.tab3Enabled;
    userInfo.displaySettings.lessonSections.tab3.tab3Font = settingsData.tab3Font;
    userInfo.displaySettings.lessonSections.tab3.tab3Hide = settingsData.tab3Hide;
    userInfo.displaySettings.lessonSections.tab3.tab3Label = settingsData.tab3Label;
    userInfo.displaySettings.lessonSections.tab3.tab3LabelBold = settingsData.tab3LabelBold;
    userInfo.displaySettings.lessonSections.tab3.tab3LabelFillColor = settingsData.tab3LabelFillColor;
    userInfo.displaySettings.lessonSections.tab3.tab3LabelItalic = settingsData.tab3LabelItalic;
    userInfo.displaySettings.lessonSections.tab3.tab3LabelUnderline = settingsData.tab3LabelUnderline;
    userInfo.displaySettings.lessonSections.tab3.tab3Size = settingsData.tab3Size;
    userInfo.displaySettings.lessonSections.tab3.tab3TextBold = settingsData.tab3TextBold;
    userInfo.displaySettings.lessonSections.tab3.tab3TextColor = settingsData.tab3TextColor;
    userInfo.displaySettings.lessonSections.tab3.tab3TextFillColor = settingsData.tab3TextFillColor;
    userInfo.displaySettings.lessonSections.tab3.tab3TextFont = settingsData.tab3TextFont;
    userInfo.displaySettings.lessonSections.tab3.tab3TextItalic = settingsData.tab3TextItalic;
    userInfo.displaySettings.lessonSections.tab3.tab3TextSize = settingsData.tab3TextSize;
    userInfo.displaySettings.lessonSections.tab3.tab3TextUnderline = settingsData.tab3TextUnderline;

    userInfo.displaySettings.lessonSections.tab4.tab4Type = 'section-text';
    userInfo.displaySettings.lessonSections.tab4.tab4Color = settingsData.tab4Color;
    userInfo.displaySettings.lessonSections.tab4.tab4DisplayOrder = settingsData.tab4DisplayOrder;
    userInfo.displaySettings.lessonSections.tab4.tab4DisplaySettingsId = settingsData.tab4DisplaySettingsId;
    userInfo.displaySettings.lessonSections.tab4.tab4Enabled = settingsData.tab4Enabled;
    userInfo.displaySettings.lessonSections.tab4.tab4Font = settingsData.tab4Font;
    userInfo.displaySettings.lessonSections.tab4.tab4Hide = settingsData.tab4Hide;
    userInfo.displaySettings.lessonSections.tab4.tab4Label = settingsData.tab4Label;
    userInfo.displaySettings.lessonSections.tab4.tab4LabelBold = settingsData.tab4LabelBold;
    userInfo.displaySettings.lessonSections.tab4.tab4LabelFillColor = settingsData.tab4LabelFillColor;
    userInfo.displaySettings.lessonSections.tab4.tab4LabelItalic = settingsData.tab4LabelItalic;
    userInfo.displaySettings.lessonSections.tab4.tab4LabelUnderline = settingsData.tab4LabelUnderline;
    userInfo.displaySettings.lessonSections.tab4.tab4Size = settingsData.tab4Size;
    userInfo.displaySettings.lessonSections.tab4.tab4TextBold = settingsData.tab4TextBold;
    userInfo.displaySettings.lessonSections.tab4.tab4TextColor = settingsData.tab4TextColor;
    userInfo.displaySettings.lessonSections.tab4.tab4TextFillColor = settingsData.tab4TextFillColor;
    userInfo.displaySettings.lessonSections.tab4.tab4TextFont = settingsData.tab4TextFont;
    userInfo.displaySettings.lessonSections.tab4.tab4TextItalic = settingsData.tab4TextItalic;
    userInfo.displaySettings.lessonSections.tab4.tab4TextSize = settingsData.tab4TextSize;
    userInfo.displaySettings.lessonSections.tab4.tab4TextUnderline = settingsData.tab4TextUnderline;

    userInfo.displaySettings.lessonSections.tab5.tab5Type = 'section-text';
    userInfo.displaySettings.lessonSections.tab5.tab5Color = settingsData.tab5Color;
    userInfo.displaySettings.lessonSections.tab5.tab5DisplayOrder = settingsData.tab5DisplayOrder;
    userInfo.displaySettings.lessonSections.tab5.tab5DisplaySettingsId = settingsData.tab5DisplaySettingsId;
    userInfo.displaySettings.lessonSections.tab5.tab5Enabled = settingsData.tab5Enabled;
    userInfo.displaySettings.lessonSections.tab5.tab5Font = settingsData.tab5Font;
    userInfo.displaySettings.lessonSections.tab5.tab5Hide = settingsData.tab5Hide;
    userInfo.displaySettings.lessonSections.tab5.tab5Label = settingsData.tab5Label;
    userInfo.displaySettings.lessonSections.tab5.tab5LabelBold = settingsData.tab5LabelBold;
    userInfo.displaySettings.lessonSections.tab5.tab5LabelFillColor = settingsData.tab5LabelFillColor;
    userInfo.displaySettings.lessonSections.tab5.tab5LabelItalic = settingsData.tab5LabelItalic;
    userInfo.displaySettings.lessonSections.tab5.tab5LabelUnderline = settingsData.tab5LabelUnderline;
    userInfo.displaySettings.lessonSections.tab5.tab5Size = settingsData.tab5Size;
    userInfo.displaySettings.lessonSections.tab5.tab5TextBold = settingsData.tab5TextBold;
    userInfo.displaySettings.lessonSections.tab5.tab5TextColor = settingsData.tab5TextColor;
    userInfo.displaySettings.lessonSections.tab5.tab5TextFillColor = settingsData.tab5TextFillColor;
    userInfo.displaySettings.lessonSections.tab5.tab5TextFont = settingsData.tab5TextFont;
    userInfo.displaySettings.lessonSections.tab5.tab5TextItalic = settingsData.tab5TextItalic;
    userInfo.displaySettings.lessonSections.tab5.tab5TextSize = settingsData.tab5TextSize;
    userInfo.displaySettings.lessonSections.tab5.tab5TextUnderline = settingsData.tab5TextUnderline;

    userInfo.displaySettings.lessonSections.tab6.tab6Type = 'section-text';
    userInfo.displaySettings.lessonSections.tab6.tab6Color = settingsData.tab6Color;
    userInfo.displaySettings.lessonSections.tab6.tab6DisplayOrder = settingsData.tab6DisplayOrder;
    userInfo.displaySettings.lessonSections.tab6.tab6DisplaySettingsId = settingsData.tab6DisplaySettingsId;
    userInfo.displaySettings.lessonSections.tab6.tab6Enabled = settingsData.tab6Enabled;
    userInfo.displaySettings.lessonSections.tab6.tab6Font = settingsData.tab6Font;
    userInfo.displaySettings.lessonSections.tab6.tab6Hide = settingsData.tab6Hide;
    userInfo.displaySettings.lessonSections.tab6.tab6Label = settingsData.tab6Label;
    userInfo.displaySettings.lessonSections.tab6.tab6LabelBold = settingsData.tab6LabelBold;
    userInfo.displaySettings.lessonSections.tab6.tab6LabelFillColor = settingsData.tab6LabelFillColor;
    userInfo.displaySettings.lessonSections.tab6.tab6LabelItalic = settingsData.tab6LabelItalic;
    userInfo.displaySettings.lessonSections.tab6.tab6LabelUnderline = settingsData.tab6LabelUnderline;
    userInfo.displaySettings.lessonSections.tab6.tab6Size = settingsData.tab6Size;
    userInfo.displaySettings.lessonSections.tab6.tab6TextBold = settingsData.tab6TextBold;
    userInfo.displaySettings.lessonSections.tab6.tab6TextColor = settingsData.tab6TextColor;
    userInfo.displaySettings.lessonSections.tab6.tab6TextFillColor = settingsData.tab6TextFillColor;
    userInfo.displaySettings.lessonSections.tab6.tab6TextFont = settingsData.tab6TextFont;
    userInfo.displaySettings.lessonSections.tab6.tab6TextItalic = settingsData.tab6TextItalic;
    userInfo.displaySettings.lessonSections.tab6.tab6TextSize = settingsData.tab6TextSize;
    userInfo.displaySettings.lessonSections.tab6.tab6TextUnderline = settingsData.tab6TextUnderline;

    userInfo.displaySettings.lessonSections.myStandards.myStandardsType = 'section-standards';
    userInfo.displaySettings.lessonSections.myStandards.myStandardsColor = settingsData.myStandardsColor;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsDisplayOrder = settingsData.myStandardsDisplayOrder;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsDisplaySettingsId = settingsData.myStandardsDisplaySettingsId;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsEnabled = settingsData.myStandardsEnabled;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsFont = settingsData.myStandardsFont;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsHide = settingsData.myStandardsHide;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsLabel = settingsData.myStandardsLabel;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsLabelBold = settingsData.myStandardsLabelBold;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsLabelFillColor = settingsData.myStandardsLabelFillColor;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsLabelItalic = settingsData.myStandardsLabelItalic;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsLabelUnderline = settingsData.myStandardsLabelUnderline;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsSize = settingsData.myStandardsSize;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsTextBold = settingsData.myStandardsTextBold;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsTextColor = settingsData.myStandardsTextColor;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsTextFillColor = settingsData.myStandardsTextFillColor;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsTextFont = settingsData.myStandardsTextFont;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsTextItalic = settingsData.myStandardsTextItalic;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsTextSize = settingsData.myStandardsSize;
    userInfo.displaySettings.lessonSections.myStandards.myStandardsTextUnderline = settingsData.myStandardsTextUnderline;

    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsType = 'section-standards';
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsColor = settingsData.sharedStandardsColor;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsDisplayOrder = settingsData.sharedStandardsDisplayOrder;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsDisplaySettingsId = settingsData.sharedStandardsDisplaySettingsId;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsEnabled = settingsData.sharedStandardsEnabled;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsFont = settingsData.sharedStandardsFont;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsHide = settingsData.sharedStandardsHide;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabel = settingsData.sharedStandardsLabel;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabelBold = settingsData.sharedStandardsLabelBold;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabelFillColor = settingsData.sharedStandardsLabelFillColor;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabelItalic = settingsData.sharedStandardsLabelItalic;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabelUnderline = settingsData.sharedStandardsLabelUnderline;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsSize = settingsData.sharedStandardsSize;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextBold = settingsData.sharedStandardsTextBold;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextColor = settingsData.sharedStandardsTextColor;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextFillColor = settingsData.sharedStandardsTextFillColor;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextFont = settingsData.sharedStandardsTextFont;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextItalic = settingsData.sharedStandardsTextItalic;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextSize = settingsData.sharedStandardsTextSize;
    userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextUnderline = settingsData.sharedStandardsTextUnderline;

    userInfo.displaySettings.lessonSections.strategies.strategiesType = 'section-standards';
    userInfo.displaySettings.lessonSections.strategies.strategiesColor = settingsData.strategiesColor;
    userInfo.displaySettings.lessonSections.strategies.strategiesDisplayOrder = settingsData.strategiesDisplayOrder;
    userInfo.displaySettings.lessonSections.strategies.strategiesDisplaySettingsId = settingsData.strategiesDisplaySettingsId;
    userInfo.displaySettings.lessonSections.strategies.strategiesEnabled = settingsData.strategiesEnabled;
    userInfo.displaySettings.lessonSections.strategies.strategiesFont = settingsData.strategiesFont;
    userInfo.displaySettings.lessonSections.strategies.strategiesHide = settingsData.strategiesHide;
    userInfo.displaySettings.lessonSections.strategies.strategiesLabel = settingsData.strategiesLabel;
    userInfo.displaySettings.lessonSections.strategies.strategiesLabelBold = settingsData.strategiesLabelBold;
    userInfo.displaySettings.lessonSections.strategies.strategiesLabelFillColor = settingsData.strategiesLabelFillColor;
    userInfo.displaySettings.lessonSections.strategies.strategiesLabelItalic = settingsData.strategiesLabelItalic;
    userInfo.displaySettings.lessonSections.strategies.strategiesLabelUnderline = settingsData.strategiesLabelUnderline;
    userInfo.displaySettings.lessonSections.strategies.strategiesSize = settingsData.strategiesSize;
    userInfo.displaySettings.lessonSections.strategies.strategiesTextBold = settingsData.strategiesTextBold;
    userInfo.displaySettings.lessonSections.strategies.strategiesTextColor = settingsData.strategiesTextColor;
    userInfo.displaySettings.lessonSections.strategies.strategiesTextFillColor = settingsData.strategiesTextFillColor;
    userInfo.displaySettings.lessonSections.strategies.strategiesTextFont = settingsData.strategiesTextFont;
    userInfo.displaySettings.lessonSections.strategies.strategiesTextItalic = settingsData.strategiesTextItalic;
    userInfo.displaySettings.lessonSections.strategies.strategiesTextSize = settingsData.strategiesSize;
    userInfo.displaySettings.lessonSections.strategies.strategiesTextUnderline = settingsData.strategiesTextUnderline;

    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsType = 'section-standards';
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsColor = settingsData.schoolStandardsColor;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsDisplayOrder = settingsData.schoolStandardsDisplayOrder;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsDisplaySettingsId = settingsData.schoolStandardsDisplaySettingsId;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsEnabled = settingsData.schoolStandardsEnabled;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsFont = settingsData.schoolStandardsFont;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsHide = settingsData.schoolStandardsHide;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabel = settingsData.schoolStandardsLabel;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabelBold = settingsData.schoolStandardsLabelBold;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabelFillColor = settingsData.schoolStandardsLabelFillColor;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabelItalic = settingsData.schoolStandardsLabelItalic;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabelUnderline = settingsData.schoolStandardsLabelUnderline;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsSize = settingsData.schoolStandardsSize;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextBold = settingsData.schoolStandardsTextBold;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextColor = settingsData.schoolStandardsTextColor;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextFillColor = settingsData.schoolStandardsTextFillColor;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextFont = settingsData.schoolStandardsTextFont;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextItalic = settingsData.schoolStandardsTextItalic;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextSize = settingsData.schoolStandardsTextSize;
    userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextUnderline = settingsData.schoolStandardsTextUnderline;

    userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleType = 'section-title';
    userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleColor = settingsData.lessonTitleColor;
    userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleDisplaySettingsId = settingsData.lessonTitleDisplaySettingsId;
    userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleFont = settingsData.lessonTitleFont;
    userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleLabelBold = settingsData.lessonTitleLabelBold;
    userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleLabelFillColor = settingsData.lessonTitleLabelFillColor;
    userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleLabelItalic = settingsData.lessonTitleLabelItalic;
    userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleLabelUnderline = settingsData.lessonTitleLabelUnderline;
    userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleSize = settingsData.lessonTitleSize;

    userInfo.displaySettings.lessonStyling.assessments.assessmentsType = 'section-actions';
    userInfo.displaySettings.lessonStyling.assessments.assessmentsColor = settingsData.assessmentsColor;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsDisplaySettingsId = settingsData.assessmentsDisplaySettingsId;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsFont = settingsData.assessmentsFont;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsLabel = 'Assessments';
    userInfo.displaySettings.lessonStyling.assessments.assessmentsHide = settingsData.assessmentsHide;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsLabelBold = settingsData.assessmentsLabelBold;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsLabelFillColor = settingsData.assessmentsLabelFillColor;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsLabelItalic = settingsData.assessmentsLabelItalic;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsLabelUnderline = settingsData.assessmentsLabelUnderline;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsSize = settingsData.assessmentsSize;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsTextBold = settingsData.assessmentsTextBold;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsTextColor = settingsData.assessmentsTextColor;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsTextFillColor = settingsData.assessmentsTextFillColor;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsTextFont = settingsData.assessmentsTextFont;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsTextItalic = settingsData.assessmentsTextItalic;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsTextSize = settingsData.assessmentsSize;
    userInfo.displaySettings.lessonStyling.assessments.assessmentsTextUnderline = settingsData.assessmentsTextUnderline;

    userInfo.displaySettings.lessonStyling.assignments.assignmentsType = 'section-actions';
    userInfo.displaySettings.lessonStyling.assignments.assignmentsColor = settingsData.assignmentsColor;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsDisplaySettingsId = settingsData.assignmentsDisplaySettingsId;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsFont = settingsData.assignmentsFont;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsHide = settingsData.assignmentsHide;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsLabel = 'Assignments';
    userInfo.displaySettings.lessonStyling.assignments.assignmentsLabelBold = settingsData.assignmentsLabelBold;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsLabelFillColor = settingsData.assignmentsLabelFillColor;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsLabelItalic = settingsData.assignmentsLabelItalic;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsLabelUnderline = settingsData.assignmentsLabelUnderline;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsSize = settingsData.assignmentsSize;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsTextBold = settingsData.assignmentsTextBold;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsTextColor = settingsData.assignmentsTextColor;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsTextFillColor = settingsData.assignmentsTextFillColor;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsTextFont = settingsData.assignmentsTextFont;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsTextItalic = settingsData.assignmentsTextItalic;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsTextSize = settingsData.assignmentsTextSize;
    userInfo.displaySettings.lessonStyling.assignments.assignmentsTextUnderline = settingsData.assignmentsTextUnderline;

    userInfo.displaySettings.lessonStyling.attachments.attachmentsType = 'section-attachments';
    userInfo.displaySettings.lessonStyling.attachments.attachmentsColor = settingsData.attachmentsColor;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsDisplaySettingsId = settingsData.attachmentsDisplaySettingsId;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsFont = settingsData.attachmentsFont;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsHide = settingsData.attachmentsHide;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsLabel = 'Attachments';
    userInfo.displaySettings.lessonStyling.attachments.attachmentsLabelBold = settingsData.attachmentsLabelBold;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsLabelFillColor = settingsData.attachmentsLabelFillColor;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsLabelItalic = settingsData.attachmentsLabelItalic;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsLabelUnderline = settingsData.attachmentsLabelUnderline;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsSize = settingsData.attachmentsSize;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsTextBold = settingsData.attachmentsTextBold;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsTextColor = settingsData.attachmentsTextColor;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsTextFillColor = settingsData.attachmentsTextFillColor;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsTextFont = settingsData.attachmentsTextFont;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsTextItalic = settingsData.attachmentsTextItalic;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsTextSize = settingsData.attachmentsTextSize;
    userInfo.displaySettings.lessonStyling.attachments.attachmentsTextUnderline = settingsData.attachmentsTextUnderline;

    userInfo.displaySettings.lessonRetrieval.fetchWeekSize = settingsData.otherSettings.fetchWeekSize;
    userInfo.displaySettings.lessonRetrieval.autosaveInterval = settingsData.autosaveInterval;

    userInfo.displaySettings.lessonOrientation.viewOrientation = settingsData.viewOrientation;
    userInfo.displaySettings.lessonOrientation.adjustToFit = settingsData.adjustToFit;
    userInfo.displaySettings.lessonOrientation.boxSize = settingsData.boxSize;
    userInfo.displaySettings.lessonOrientation.equalSize = settingsData.equalSize;

    userInfo.displaySettings.lessonHeadings.classHeadCentered = settingsData.classHeadCentered;
    userInfo.displaySettings.lessonHeadings.classHeadOrder = settingsData.classHeadOrder;

    userInfo.displaySettings.lessonStatus.displayStatusText = settingsData.otherSettings.displayStatusText;
    userInfo.displaySettings.lessonStatus.statusTextFontSize = settingsData.otherSettings.statusTextFontSize;

    userInfo.displaySettings.lessonToolTips.displayToolTipStandardDetails = settingsData.otherSettings.displayToolTipStandardDetails;
    userInfo.displaySettings.eventStyling.eventEnabled = settingsData.eventEnabled;
    userInfo.displaySettings.eventStyling.eventLabel = settingsData.eventLabel;
    userInfo.displaySettings.eventStyling.eventLabelBold = settingsData.eventLabelBold;
    userInfo.displaySettings.eventStyling.eventLabelColor = settingsData.eventLabelColor;
    userInfo.displaySettings.eventStyling.eventLabelDisplaySettingsId = settingsData.eventLabelDisplaySettingsId;
    userInfo.displaySettings.eventStyling.eventLabelFillColor = settingsData.eventLabelFillColor;
    userInfo.displaySettings.eventStyling.eventLabelFont = settingsData.eventLabelFont;
    userInfo.displaySettings.eventStyling.eventLabelItalic = settingsData.eventLabelItalic;
    userInfo.displaySettings.eventStyling.eventLabelSize = settingsData.eventLabelSize;
    userInfo.displaySettings.eventStyling.eventLabelUnderline = settingsData.eventLabelUnderline;
    userInfo.displaySettings.eventStyling.eventTextBold = settingsData.eventTextBold;
    userInfo.displaySettings.eventStyling.eventTextColor = settingsData.eventTextColor;
    userInfo.displaySettings.eventStyling.eventTextDisplaySettingsId = settingsData.eventTextDisplaySettingsId;
    userInfo.displaySettings.eventStyling.eventTextFillColor = settingsData.eventTextFillColor;
    userInfo.displaySettings.eventStyling.eventTextFont = settingsData.eventTextFont;
    userInfo.displaySettings.eventStyling.eventTextItalic = settingsData.eventTextItalic;
    userInfo.displaySettings.eventStyling.eventTextSize = settingsData.eventTextSize;
    userInfo.displaySettings.eventStyling.eventTextUnderline = settingsData.eventTextUnderline;
    userInfo.displaySettings.dateStyling.dateDelimiter = settingsData.dateDelimiter || '/';
    userInfo.displaySettings.dateStyling.dateFormat = settingsData.dateFormat;
    userInfo.displaySettings.dateStyling.currentDateBackgroundColor = settingsData.otherSettings.currentDateBackgroundColor;
    userInfo.displaySettings.dateStyling.currentDateTextColor = settingsData.otherSettings.currentDateTextColor;
    userInfo.displaySettings.dateStyling.showCount = settingsData.showCount;

    userInfo.displaySettings.dateStyling.sunShow = settingsData.sunShow;
    userInfo.displaySettings.dateStyling.monShow = settingsData.monShow;
    userInfo.displaySettings.dateStyling.tueShow = settingsData.tueShow;
    userInfo.displaySettings.dateStyling.wedShow = settingsData.wedShow;
    userInfo.displaySettings.dateStyling.thuShow = settingsData.thuShow;
    userInfo.displaySettings.dateStyling.friShow = settingsData.friShow;
    userInfo.displaySettings.dateStyling.satShow = settingsData.satShow;

    userInfo.displaySettings.classworkStyling.currentAssessmentColor = settingsData.otherSettings.currentAssessmentColor;
    userInfo.displaySettings.classworkStyling.currentAssignmentColor = settingsData.otherSettings.currentAssignmentColor;

    userInfo.displaySettings.stickerStyling.eventStickerLabelFont = settingsData.otherSettings.eventStickerLabelFont || '2';
    userInfo.displaySettings.stickerStyling.eventStickerLabelSize = settingsData.otherSettings.eventStickerLabelSize || '10';
    userInfo.displaySettings.stickerStyling.eventStickerLabelColor = settingsData.otherSettings.eventStickerLabelColor || '#666666';
    userInfo.displaySettings.stickerStyling.eventStickerLabelFillColor = settingsData.otherSettings.eventStickerLabelFillColor || '#ffffff';
    userInfo.displaySettings.stickerStyling.eventStickerLabelBold = settingsData.otherSettings.eventStickerLabelBold || 'N';
    userInfo.displaySettings.stickerStyling.eventStickerLabelItalic = settingsData.otherSettings.eventStickerLabelItalic || 'N';
    userInfo.displaySettings.stickerStyling.eventStickerLabelUnderline = settingsData.otherSettings.eventStickerLabelUnderline || 'N';
    userInfo.displaySettings.stickerStyling.eventStickerTextFont = settingsData.otherSettings.eventStickerTextFont || '2';
    userInfo.displaySettings.stickerStyling.eventStickerTextSize = settingsData.otherSettings.eventStickerTextSize || '10';
    userInfo.displaySettings.stickerStyling.eventStickerTextColor = settingsData.otherSettings.eventStickerTextColor || '#666666';
    userInfo.displaySettings.stickerStyling.eventStickerTextFillColor = settingsData.otherSettings.eventStickerTextFillColor || '#ffffff';
    userInfo.displaySettings.stickerStyling.eventStickerTextBold = settingsData.otherSettings.eventStickerTextBold || 'N';
    userInfo.displaySettings.stickerStyling.eventStickerTextItalic = settingsData.otherSettings.eventStickerTextItalic || 'N';
    userInfo.displaySettings.stickerStyling.eventStickerTextUnderline = settingsData.otherSettings.eventStickerTextUnderline || 'N';
    userInfo.displaySettings.stickerStyling.stickerBackgroundColor = settingsData.otherSettings.stickerBackgroundColor;
    userInfo.displaySettings.stickerStyling.stickerBackgroundStyle = settingsData.otherSettings.stickerBackgroundStyle;
    if (settingsData.otherSettings.stickerSize === '150') settingsData.otherSettings.stickerSize = '40';
    userInfo.displaySettings.stickerStyling.stickerSize = settingsData.otherSettings.stickerSize;

    userInfo.displaySettings.stickerSettings.showAllHolidays = settingsData.otherSettings.showAllHolidays;
    userInfo.displaySettings.stickerSettings.showAllStudentBirthdays = settingsData.otherSettings.showAllStudentBirthdays;

    userInfo.displaySettings.notesTodosStyling.notesIconColor = settingsData.otherSettings.notesIconColor;
    userInfo.displaySettings.notesTodosStyling.toDosIconColor = settingsData.otherSettings.toDosIconColor;
    userInfo.displaySettings.notesTodosStyling.lowPriorityColor = settingsData.otherSettings.lowPriorityColor;
    userInfo.displaySettings.notesTodosStyling.mediumPriorityColor = settingsData.otherSettings.mediumPriorityColor;
    userInfo.displaySettings.notesTodosStyling.highPriorityColor = settingsData.otherSettings.highPriorityColor;

    userInfo.displaySettings.customFonts = settingsData.otherSettings.customFonts;

    userInfo.otherSettings.nameOrder = settingsData.otherSettings.nameOrder;
    userInfo.otherSettings.isNameCommaSeparated = settingsData.otherSettings.isNameCommaSeparated;

    userInfo.otherSettings.sunShowSchooYears = settingsData.otherSettings.sunShowSchoolYears;
    userInfo.otherSettings.monShowSchooYears = settingsData.otherSettings.monShowSchoolYears;
    userInfo.otherSettings.tueShowSchooYears = settingsData.otherSettings.tueShowSchoolYears;
    userInfo.otherSettings.wedShowSchooYears = settingsData.otherSettings.wedShowSchoolYears;
    userInfo.otherSettings.thuShowSchooYears = settingsData.otherSettings.thuShowSchoolYears;
    userInfo.otherSettings.friShowSchooYears = settingsData.otherSettings.friShowSchoolYears;
    userInfo.otherSettings.satShowSchooYears = settingsData.otherSettings.satShowSchoolYears;

    userInfo.displaySettings.leftDrawerMenuType = settingsData.otherSettings.leftDrawerMenuType || 0;

    userInfo.displaySettings.printLayout = CommonUtils.isNotEmpty(userInfo.otherSettings.printLayout) ? ld.cloneDeep(userInfo.otherSettings.printLayout) : [
      { selection: 'https://s3.amazonaws.com/planbook-assets/images/planbook-icon.png', showCustomText: false },
      { selection: 'SY', showCustomText: false },
      { selection: 'DR', showCustomText: false },
      { selection: 'TN', showCustomText: false },
      { selection: 'NA', showCustomText: false }
    ];

    const settings = ld.cloneDeep(this.settings);
    settings[settingsData.userId] = userInfo;
    this.settings = settings;
  }

  @Mutation
  setSchoolYearEditMode(schoolYearEditMode: boolean) {
    this.schoolYearEditMode = schoolYearEditMode;
  }

  @Mutation
  setSchoolYearSelectedRows(schoolYearSelectedRows: Array<any>) {
    this.schoolYearSelectedRows = schoolYearSelectedRows;
  }

  @Mutation
  setSchoolYearSearchText(schoolYearSearchText: string) {
    this.schoolYearSearchText = schoolYearSearchText;
  }

  @Mutation
  setNewUser(newUser: boolean) {
    this.newUser = newUser;
  }

  @Mutation
  setAllowCreateLessonBanks(allowCreateLessonBanks: boolean) {
    const clone = ld.cloneDeep(this.settings);
    const schoolSettings = clone[this.userId].schoolSettings;
    const schoolId = schoolSettings.schoolId;
    const schools: Array<any> = schoolSettings.schools || [];
    schools.forEach(school => {
      if (school.schoolId === schoolId) {
        school.allowCreateLessonBanks = allowCreateLessonBanks;
      }
    });
    ld.set(clone, this.userId + '.schoolSettings.primarySchool.allowCreateLessonBanks', allowCreateLessonBanks);
    this.settings = clone;
  }

  @Mutation
  setAllowViewLessonBanks(allowViewLessonBanks: boolean) {
    const clone = ld.cloneDeep(this.settings);
    const schoolSettings = clone[this.userId].schoolSettings;
    const schoolId = schoolSettings.schoolId;
    const schools: Array<any> = schoolSettings.schools || [];
    schools.forEach(school => {
      if (school.schoolId === schoolId) {
        school.allowViewLessonBanks = allowViewLessonBanks;
      }
    });
    ld.set(clone, this.userId + '.schoolSettings.primarySchool.allowViewLessonBanks', allowViewLessonBanks);
    this.settings = clone;
  }

  @Mutation
  setSchoolYearEditorInitialized(schoolYearEditorInitialized: boolean) {
    this.schoolYearEditorInitialized = schoolYearEditorInitialized;
  }

  @Mutation
  setAccessToken(accessToken: string) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.accessToken = accessToken;
      userInfo.accountSettings.hasToken = 'Y';
      this.settings = clone;
    }
  }

  @Mutation
  setPaymentNeeded(paymentNeeded: any) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.paymentNeeded = paymentNeeded;
      this.settings = clone;
    }
  }

  @Mutation
  clearAccessToken() {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.accessToken = '';
      userInfo.accountSettings.hasToken = 'N';
      this.settings = clone;
    }
  }

  @Mutation
  setStandardSettings(params: any) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      if (params) {
        const userInfo = clone[this.userId];
        userInfo.standardSettings.currentState = params.state;
        userInfo.standardSettings.currentSubject = params.subject;
        userInfo.standardSettings.currentGrade = params.grade;
        this.settings = clone;
      }
    }
  }

  @Mutation
  setShowStateIds(showStateIds: string) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.standardSettings.showStateIds = showStateIds;
      this.settings = clone;
    }
  }

  @Mutation
  setPaidThroughDate(paidThroughDate: string) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.paidThroughDate = paidThroughDate;
      userInfo.accountSettings.paymentNeeded = false;
      this.settings = clone;
    }
  }

  @Mutation
  setPayments(payments: Array<any>) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.payments = payments;
      this.settings = clone;
    }
  }

  @Mutation
  setSchoolYears(schoolYears: Array<any>) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.schoolYearSettings.years = schoolYears;
      this.settings = clone;
    }
  }

  @Mutation
  setAdminPayments(payments: Array<any>) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.adminPayments = payments;
      this.settings = clone;
    }
  }

  @Mutation
  setSubPlans(subPlans: string) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.subPlans = subPlans;
      this.settings = clone;
    }
  }

  @Mutation
  setShowBanner(showBanner: string) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.showBanner = showBanner;
      this.settings = clone;
    }
  }

  @Mutation
  setStudentNotes(studentNotes: string) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.studentNotes = studentNotes;
      this.settings = clone;
    }
  }

  @Mutation
  setAdminSchoolYears(adminSchoolYears: any) {
    this.adminSchoolYears = adminSchoolYears;
  }

  @Mutation
  setGroupAccountSchoolYears(groupAccountSchoolYears: any) {
    this.groupAccountSchoolYears = groupAccountSchoolYears;
  }

  @Mutation
  setUserId(userId: any) {
    this.userId = userId;
  }

  @Mutation
  setStudentTeachers(studentTeachers: any) {
    this.studentTeachers = studentTeachers;
  }

  @Mutation
  setStudentTeacherId(studentTeacherId: number) {
    this.studentTeacherId = studentTeacherId;
  }

  @Mutation
  setStudentId(studentId: number) {
    this.studentId = studentId;
  }

  @Mutation
  setStudentKey(studentKey: string) {
    this.studentKey = studentKey;
  }

  @Mutation
  setTeacherEmail(teacherEmail: string) {
    this.teacherEmail = teacherEmail;
  }

  @Mutation
  setStudentFirstName(studentFirstName: string) {
    this.studentFirstName = studentFirstName;
  }

  @Mutation
  setStudentLastName(studentLastName: string) {
    this.studentLastName = studentLastName;
  }

  @Mutation
  setStudentEmailAddress(studentEmailAddress: string) {
    this.studentEmailAddress = studentEmailAddress;
  }

  @Mutation
  setStudentPhotoUrl(studentPhotoUrl: string) {
    this.studentPhotoUrl = studentPhotoUrl;
  }

  @Mutation
  setTeacherId(teacherId: number) {
    this.teacherId = teacherId;
  }

  @Mutation
  setStudentViewStandardsId(studentViewStandardsId: string) {
    this.studentViewStandardsId = studentViewStandardsId;
  }

  @Mutation
  setStudentViewStandardsDesc(studentViewStandardsDesc: string) {
    this.studentViewStandardsDesc = studentViewStandardsDesc;
  }

  @Mutation
  setStudentViewAttachments(studentViewAttachments: string) {
    this.studentViewAttachments = studentViewAttachments;
  }

  @Mutation
  setCurrentTeacherId(currentTeacherId: number) {
    this.currentTeacherId = currentTeacherId;
  }

  @Mutation
  setCurrentYearId(currentYearId: number) {
    this.currentYearId = currentYearId;
  }

  @Mutation
  setCurrentSubjectId(currentSubjectId: number) {
    this.currentSubjectId = currentSubjectId;
  }

  @Mutation
  setUserMode(um: string) {
    this.userMode = um;
  }

  @Mutation
  setSharedList(data: any) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.sharingOptionsSettings.sharedList = data.sharedList;
      this.settings = clone;
    }
  }

  @Mutation
  reset() {
    this.userId = 0;
    this.settings = {};
  }

  @Mutation
  setPickerColor(pickerColor: string) {
    this.pickerColor = pickerColor;
  }

  @Mutation
  setStatuses(statuses: any) {
    const clone = ld.cloneDeep(this.settings);
    ld.set(clone, this.userId + '.accountSettings.statuses', statuses);
    this.settings = clone;
  }

  @Mutation
  setSchoolStatuses(statuses: any) {
    const clone = ld.cloneDeep(this.settings);
    ld.set(clone, this.userId + '.accountSettings.schoolStatuses', statuses);
    this.settings = clone;
  }

  @Mutation
  setAdjustToFit(adjustToFit: any) {
    const clone = ld.cloneDeep(this.settings);
    ld.set(clone, this.userId + '.displaySettings.lessonOrientation.adjustToFit', adjustToFit);
    this.settings = clone;
  }

  @Mutation
  setEqualSize(equalSize: any) {
    const clone = ld.cloneDeep(this.settings);
    ld.set(clone, this.userId + '.displaySettings.lessonOrientation.equalSize', equalSize);
    this.settings = clone;
  }

  @Mutation
  setBoxSize(boxSize: any) {
    const clone = ld.cloneDeep(this.settings);
    ld.set(clone, this.userId + '.displaySettings.lessonOrientation.boxSize', boxSize);
    this.settings = clone;
  }

  @Mutation
  setViewOrientation(viewOrientation: any) {
    const clone = ld.cloneDeep(this.settings);
    ld.set(clone, this.userId + '.displaySettings.lessonOrientation.viewOrientation', viewOrientation);
    this.settings = clone;
  }

  @Mutation
  setPlansTextScale(plansTextScale: any) {
    const clone = ld.cloneDeep(this.settings);
    ld.set(clone, this.userId + '.otherSettings.plansTextScale', plansTextScale);
    this.settings = clone;
  }

  @Mutation
  setNewCommentA(newCommentA: string) {
    const clone = ld.cloneDeep(this.settings);
    ld.set(clone, this.userId + '.infoSettings.newCommentA', newCommentA);
    this.settings = clone;
  }

  @Mutation
  setNewCommentT(newCommentT: string) {
    const clone = ld.cloneDeep(this.settings);
    ld.set(clone, this.userId + '.infoSettings.newCommentT', newCommentT);
    this.settings = clone;
  }

  @Mutation
  setDisplayViewItems(selectedItems: any) {
    if (this.userMode === 'S') {
      this.studentViewStandardsId = CommonUtils.booleanToString(selectedItems.includes('studentViewStandardsId'));
      this.studentViewStandardsDesc = CommonUtils.booleanToString(selectedItems.includes('studentViewStandardsDesc'));
      this.studentViewAttachments = CommonUtils.booleanToString(selectedItems.includes('studentViewAttachments'));
    } else {
      const clonedSettings = ld.cloneDeep(this.settings);
      const userInfo = clonedSettings[this.userId];
      let displayItems = '';
      for (const i in viewItems) {
        const key = viewItems[i]
        if (selectedItems.includes(key)) {
          displayItems += 'Y';
        } else {
          displayItems += 'N';
        }
      }
      userInfo.viewSettings.displayItems = displayItems;
      userInfo.viewSettings.viewItems = {};
      for (let i = 0; i < displayItems.length; i++) {
        userInfo.viewSettings.viewItems[viewItems[i]] = displayItems.charAt(i);
      }
      this.settings = clonedSettings;
    }
  }

  @Mutation
  setDisplayView(displayView: any) {
    const clonedSettings = ld.cloneDeep(this.settings);
    ld.set(clonedSettings, this.userId + '.viewSettings.displayView', displayView);
    this.settings = clonedSettings;
  }

  @Mutation
  setAdditionalInfoMsgs(additionalInfoMsgs: Array<any>) {
    this.additionalInfoMsgs = additionalInfoMsgs;
  }

  @Mutation
  addAdditionalInfoMsg(infoMsg: any) {
    if (this.additionalInfoMsgs && CommonUtils.hasText(infoMsg)) {
      this.additionalInfoMsgs = this.additionalInfoMsgs.concat([infoMsg])
    } else if (!this.additionalInfoMsgs && CommonUtils.hasText(infoMsg)) {
      this.additionalInfoMsgs = [infoMsg];
    }
  }

  @Mutation
  setAdditionalErrorMsgs(additionalErrorMsgs: Array<any>) {
    this.additionalErrorMsgs = additionalErrorMsgs;
  }

  @Mutation
  addAdditionalErrorMsg(errorMsg: any) {
    if (this.additionalErrorMsgs && CommonUtils.hasText(errorMsg)) {
      this.additionalErrorMsgs = this.additionalErrorMsgs.concat([errorMsg])
    } else if (!this.additionalErrorMsgs && CommonUtils.hasText(errorMsg)) {
      this.additionalErrorMsgs = [errorMsg];
    }
  }

  @Mutation
  setHideClassIds(hideClassIds: any) {
    const clonedSettings = ld.cloneDeep(this.settings);
    ld.set(clonedSettings, this.userId + '.viewSettings.hideClassIds', hideClassIds);
    this.settings = clonedSettings;
  }

  @Mutation
  addStatusSetting(status: any) {
    const clonedSettings = ld.cloneDeep(this.settings);
    const userInfo = clonedSettings[this.userId];
    const statusSettings = userInfo.viewSettings.statusProfileSettings.statusSettings;
    if (statusSettings) {
      let haveSettings = false;
      for (const i in statusSettings) {
        const ss = statusSettings[i];
        if (ss.lessonStatusId === status.lessonStatusId) {
          haveSettings = true;
          break;
        }
      }

      if (!haveSettings) {
        statusSettings.unshift({
          teacherId: this.userId,
          lessonStatusId: status.lessonStatusId,
          share: true,
          view: true
        });
      }
    }
    this.settings = clonedSettings;
  }

  @Mutation
  deleteStatusSetting(lessonStatusId: number | string) {
    const clonedSettings = ld.cloneDeep(this.settings);
    const userInfo = clonedSettings[this.userId];
    const statusSettings = userInfo.viewSettings.statusProfileSettings.statusSettings;
    if (statusSettings) {
      for (const i in statusSettings) {
        const ss = statusSettings[i];
        if (+ss.lessonStatusId === +lessonStatusId) {
          statusSettings.splice(i, 1);
          break;
        }
      }
    }
    this.settings = clonedSettings;
  }

  @Mutation
  setSelectedViewStatusItems(selectedViewStatusItems: Array<any>) {
    const selected = selectedViewStatusItems || [];
    const clonedSettings = ld.cloneDeep(this.settings);
    const userInfo = clonedSettings[this.userId];
    const profile = userInfo.viewSettings.statusProfileSettings;
    const statusSettings = profile.statusSettings;
    const statusSettingsMap = {};
    for (const i in statusSettings) {
      const ss = statusSettings[i];
      ld.set(statusSettingsMap, ss.teacherId + '-' + ss.lessonStatusId, ss);
    }

    let statuses;
    if (this.userMode === 'A') {
      statuses = this.currentStatuses || [];
    } else {
      statuses = userInfo.accountSettings.statuses || [];
    }

    profile.viewNoStatus = selected.includes(0);

    for (const i in statuses) {
      const s = statuses[i];
      const ss = ld.get(statusSettingsMap, s.teacherId + '-' + s.lessonStatusId);
      if (ss) {
        ss.view = selected.includes(s.lessonStatusId);
      } else {
        statusSettings.push({
          view: selected.includes(s.lessonStatusId),
          share: false,
          teacherId: this.currentTeacherId,
          lessonStatusId: s.lessonStatusId
        });
      }
    }
    this.settings = clonedSettings;
  }

  @Mutation
  setSelectedViewSchoolStatusItems(selectedViewStatusItems: Array<any>) {
    const selected = selectedViewStatusItems || [];
    const clonedSettings = ld.cloneDeep(this.settings);
    const userInfo = clonedSettings[this.userId];
    const profile = userInfo.viewSettings.statusProfileSettings;
    const statusSettings = profile.schoolStatusSettings;
    const statusSettingsMap = {};
    for (const i in statusSettings) {
      const ss = statusSettings[i];
      ld.set(statusSettingsMap, ss.schoolId + '-' + ss.schoolStatusId, ss);
    }

    let statuses;
    if (userInfo.accountSettings.schoolStatuses && this.userMode === 'T') {
      statuses = userInfo.accountSettings.schoolStatuses.filter((s: any) => !s.overrideDelete);
    } else {
      statuses = userInfo.accountSettings.schoolStatuses || [];
    }

    profile.viewNoSchoolStatus = selected.includes(0);
    const schoolId = userInfo.schoolSettings.schoolId
    for (const i in statuses) {
      const s = statuses[i];
      const ss = ld.get(statusSettingsMap, s.schoolId + '-' + s.schoolStatusId);
      if (ss) {
        ss.view = selected.includes(s.schoolStatusId);
      } else {
        statusSettings.push({
          view: selected.includes(s.schoolStatusId),
          schoolId: schoolId,
          schoolStatusId: s.schoolStatusId
        });
      }
    }
    this.settings = clonedSettings;
  }

  @Mutation
  setCurrentStatuses(currentStatuses: any) {
    this.currentStatuses = currentStatuses;
  }

  @Mutation
  setUpdatedSettings(updatedSettings: any) {
    this.updatedSettings = updatedSettings;
  }

  @Mutation
  setOriginalSettings(settings: any) {
    this.originalSettings = settings;
  }

  @Mutation
  setHaveSettings(haveSettings?: boolean) {
    this.haveSettings = haveSettings === undefined ? true : haveSettings;
  }

  @Mutation
  showLoading() {
    this.loading = true;
  }

  @Mutation
  hideLoading() {
    this.loading = false;
  }

  @Mutation
  showConfirm(payload: any) {
    this.context.commit('setConfirmTitle', payload.title);
    this.context.commit('setConfirmSubtitle', payload.subtitle);
    this.context.commit('setConfirmText', payload.text);
    this.context.commit('setConfirmOption1ButtonAlternativeText', payload.option1ButtonAlternativeText);
    this.context.commit('setConfirmOption1ButtonCallback', payload.option1ButtonCallback);
    this.context.commit('setConfirmOption2ButtonAlternativeText', payload.option2ButtonAlternativeText);
    this.context.commit('setConfirmOption2ButtonCallback', payload.option2Button2Callback);
    this.confirming = true;
  }

  @Mutation
  hideConfirm() {
    this.confirming = false;
    this.context.commit('setConfirmTitle', 'Please Confirm');
    this.context.commit('setConfirmSubtitle', '');
    this.context.commit('setConfirmText', '');
    this.context.commit('setConfirmOption1ButtonAlternativeText', 'Continue');
    this.context.commit('setConfirmOption1ButtonCallback', null);
    this.context.commit('setConfirmOption2ButtonAlternativeText', '');
    this.context.commit('setConfirmOption2ButtonCallback', null);
  }

  @Mutation
  setShouldLogin(shouldLogin: boolean) {
    this.shouldLogin = shouldLogin;
  }

  @Mutation
  setShowDetails(showDetails: boolean) {
    this.showDetails = showDetails;
  }

  @Mutation
  resetBrowserDimensions() {
    this.browserDimensions = CommonUtils.getBrowserDimensions();
  }

  @Mutation
  setConfirmTitle(v: string) {
    this.confirmTitle = v;
  }

  @Mutation
  setConfirmSubtitle(v: string) {
    this.confirmSubtitle = v;
  }

  @Mutation
  setConfirmText(v: string) {
    this.confirmText = v;
  }

  @Mutation
  setConfirmOption1ButtonAlternativeText(v: string) {
    this.confirmOption2ButtonAlternativeText = v;
  }

  @Mutation
  setConfirmOption1ButtonCallback(v: any) {
    this.confirmOption2ButtonCallback = v;
  }

  @Mutation
  setConfirmOption2ButtonAlternativeText(v: string) {
    this.confirmOption2ButtonAlternativeText = v;
  }

  @Mutation
  setSchoolYearCurrentSchoolId(v: number) {
    this.schoolYearCurrentSchoolId = v;
  }

  @Mutation
  setConfirmOption2ButtonCallback(v: any) {
    this.confirmOption2ButtonCallback = v;
  }

  @Mutation
  setCustomFontsKey(customFontsKey: string) {
    this.customFontsKey = customFontsKey;
  }

  @Mutation
  setShowLessonStatus(showLessonStatus: any) {
    this.showLessonStatus = showLessonStatus;
  }

  @Mutation
  deleteSharingProfile(index: number) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      if (index < userInfo.otherSettings.sharingProfiles.length) {
        userInfo.otherSettings.sharingProfiles.splice(index, 1);
        this.settings = clone;
      }
    }
  }

  @Mutation
  updateSharingProfile(params: any) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      if (params.index > userInfo.otherSettings.sharingProfiles.length - 1) {
        userInfo.otherSettings.sharingProfiles.push(params.profile);
      } else {
        userInfo.otherSettings.sharingProfiles[params.index] = params.profile;
      }
      this.settings = clone;
    }
  }

  @Mutation
  setSharingProfiles(params: any) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.otherSettings.sharingProfiles = params;
      this.settings = clone;
    }
  }

  @Mutation
  setSharedUserKey(key: string) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.sharingOptionsSettings.sharedUserKey = key;
      this.settings = clone;
    }
  }

  @Mutation
  setFirstLoad(firstLoad: boolean) {
    this.firstLoad = firstLoad;
  }

  @Mutation
  setInitialSignIn(initialSignIn: boolean) {
    this.initialSignIn = initialSignIn;
  }

  @Mutation
  updateScoreWeights(weights: any) {
    if (this.settings && this.settings[this.userId]) {
      const clone = ld.cloneDeep(this.settings);
      const userInfo = clone[this.userId];
      userInfo.accountSettings.scoreWeights = weights.scores;
      userInfo.accountSettings.standardWeights = weights.standards;
      this.settings = clone;
    }
  }

  @Action({ rawError: true })
  async initUserMode(settingsData: any) {
    const userType = this.settings[settingsData.userId].accountSettings.userType;
    if (userType === 'B') {
      const um = PlanbookStorage.get('userMode');
      if (um) {
        this.context.commit('setUserMode', um);
      } else {
        this.context.commit('setUserMode', 'T');
        PlanbookStorage.set('userMode', 'T');
      }
    } else if (userType === 'D') {
      this.context.commit('setUserMode', 'A');
    } else {
      this.context.commit('setUserMode', userType);
    }
  }

  @Action({ rawError: true })
  async storeSettingsList(settingsList: any[]) {
    if (CommonUtils.isNotEmpty(settingsList)) {
      for (const i in settingsList) {
        this.context.commit('storeSettings', settingsList[i]);
      }
    }
    return Promise.resolve();
  }

  @Action({ rawError: true })
  async reloadSettings(params?: any) {
    this.context.commit('setHaveSettings', false);
    return await this.context.dispatch('loadSettings', params).then(() => {
      this.context.commit('setUpdatedSettings', {});
    });
  }

  @Mutation
  setPlansFontStyling(params: any) {
    const clone = ld.cloneDeep(this.settings);
    const font = params.font || '';
    const size = params.size || '10pt';
    if (params.lessonLayouts) {
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.lessonTitle.lessonTitleFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.lesson.lessonFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab2.tab2Font', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab3.tab3Font', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab4.tab4Font', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab5.tab5Font', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab6.tab6Font', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.sharedStandards.sharedStandardsFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.myStandards.myStandardsFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.strategies.strategiesFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.schoolStandards.schoolStandardsFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.assignments.assignmentsFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.assessments.assessmentsFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.attachments.attachmentsFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.lesson.lessonTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab2.tab2TextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab3.tab3TextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab4.tab4TextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab5.tab5TextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab6.tab6TextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.sharedStandards.sharedStandardsTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.myStandards.myStandardsTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.strategies.strategiesTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.schoolStandards.schoolStandardsTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.assignments.assignmentsTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.assessments.assessmentsTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.attachments.attachmentsTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.lessonTitle.lessonTitleSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.lesson.lessonSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab2.tab2Size', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab3.tab3Size', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab4.tab4Size', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab5.tab5Size', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab6.tab6Size', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.sharedStandards.sharedStandardsSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.myStandards.myStandardsSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.strategies.strategiesSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.schoolStandards.schoolStandardsSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.assignments.assignmentsSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.assessments.assessmentsSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.attachments.attachmentsSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.lesson.lessonTextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab2.tab2TextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab3.tab3TextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab4.tab4TextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab5.tab5TextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.tab6.tab6TextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.sharedStandards.sharedStandardsTextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.myStandards.myStandardsTextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.strategies.strategiesTextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonSections.schoolStandards.schoolStandardsTextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.assignments.assignmentsTextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.assessments.assessmentsTextSize', size);
      ld.set(clone, this.userId + '.displaySettings.lessonStyling.attachments.attachmentsTextSize', size);
    }

    if (params.events) {
      ld.set(clone, this.userId + '.displaySettings.eventStyling.eventLabelFont', font);
      ld.set(clone, this.userId + '.displaySettings.eventStyling.eventLabelSize', size);
      ld.set(clone, this.userId + '.displaySettings.eventStyling.eventTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.eventStyling.eventTextSize', size);
    }

    if (params.stickers) {
      ld.set(clone, this.userId + '.displaySettings.stickerStyling.eventStickerLabelFont', font);
      ld.set(clone, this.userId + '.displaySettings.stickerStyling.eventStickerLabelSize', size);
      ld.set(clone, this.userId + '.displaySettings.stickerStyling.eventStickerTextFont', font);
      ld.set(clone, this.userId + '.displaySettings.stickerStyling.eventStickerTextSize', size);
    }
    this.settings = clone;
  }

  @Mutation
  setSchoolYearListLoading(schoolYearListLoading: boolean) {
    this.schoolYearListLoading = schoolYearListLoading;
  }

  @Action({ rawError: true })
  async loadSettings(settingsData?: any) {
    try {
      if (this.haveSettings) {
        return Promise.resolve({ data: {} });
      }
      if (!settingsData) {
        settingsData = await Settings.validateSettingsData();
      } else if (settingsData.reloadSchools) {
        settingsData = await Settings.validateSettingsData(settingsData);
      } else {
        if (settingsData.notLoggedIn === 'true' || settingsData.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (settingsData.error === 'true' || settingsData.error === true) {
          return Promise.reject(new AppError('contactSupport', { data: settingsData }));
        }
      }
      this.context.commit('setAdditionalInfoMsgs', []);
      this.context.commit('setAdditionalErrorMsgs', []);
      this.context.commit('storeSettings', settingsData);
      this.context.commit('setUserId', settingsData.userId);
      this.context.commit('setTeacherId', settingsData.teacherId);
      this.context.commit('setStudentId', settingsData.studentId);
      this.context.commit('setStudentKey', settingsData.studentKey);
      this.context.commit('setStudentFirstName', settingsData.studentFirstName);
      this.context.commit('setStudentLastName', settingsData.studentLastName);
      this.context.commit('setStudentEmailAddress', settingsData.studentEmailAddress);
      this.context.commit('setStudentPhotoUrl', settingsData.studentPhotoUrl);
      this.context.commit('setStudentTeachers', settingsData.studentTeachers);
      this.context.commit('setTeacherEmail', settingsData.teacherEmail);
      this.context.commit('setShowLessonStatus', settingsData.showLessonStatus);
      this.context.commit('drawer/setDrawerMode', settingsData.otherSettings.drawerMode, { root: true });
      // this.context.commit('setFirstLoad', true);
      this.context.commit('setCustomFontsKey', CommonUtils.generateUUID());
      await this.context.dispatch('storeSettingsList', settingsData.studentTeachers);
      await this.context.dispatch('initUserMode', settingsData);
      // Planbook Storage Initialization: START
      this.context.commit('setShowDetails', CommonUtils.get(PlanbookStorage.get('showDetails'), true));
      this.context.commit('setStudentViewStandardsId', PlanbookStorage.get('studentViewStandardsId') || 'Y');
      this.context.commit('setStudentViewStandardsDesc', PlanbookStorage.get('studentViewStandardsDesc') || 'Y');
      this.context.commit('setStudentViewAttachments', PlanbookStorage.get('studentViewAttachments') || 'Y');
      // Planbook Storage Initialization: END
      this.context.commit('setHaveSettings', true);
      this.context.commit('plans/setHaveList', false, { root: true });
      this.context.commit('plans/setHaveMonth', false, { root: true });
      this.context.commit('plans/setHaveWeeks', false, { root: true });
      this.context.commit('plans/setHaveCycle', false, { root: true });
      this.context.commit('plans/setHaveCycleSchedule', false, { root: true });
      this.context.commit('classwork/setHaveClasswork', false, { root: true });
      this.context.commit('files/setHaveFiles', false, { root: true });
      this.context.commit('files/setHaveAllTeacherFiles', false, { root: true });
      this.context.commit('files/clearAllTeacherFiles', null, { root: true });
      this.context.commit('classes/setHaveClass', false, { root: true });
      this.context.commit('classes/setHaveSpecialDays', false, { root: true });
      this.context.commit('standards/setHaveStandards', false, { root: true });
      this.context.commit('standards/setHaveStrategies', false, { root: true });
      this.context.commit('standards/setHaveMyList', false, { root: true });
      this.context.commit('standards/setHaveSchoolList', false, { root: true });
      this.context.commit('notestodo/setHaveTodos', false, { root: true });
      this.context.commit('notestodo/setNotes', [], { root: true });
      this.context.commit('students/setHasNotesStudents', false, { root: true });
      this.context.commit('teachers/setHaveTeachers', false, { root: true });
      this.context.commit('stickers/setHaveStickers', false, { root: true });
      this.context.commit('standards/resetHoveredStandardsData', [], { root: true });
      this.context.commit('reporting/init', {}, { root: true });
      this.context.commit('lessonlayouts/setHaveLayouts', false, { root: true });
      if (this.context.getters.getPushNotificationsEnabled) {
        this.context.dispatch('firebase/initializeFirebase', {}, { root: true });
      }
      localStorage.darkMode = this.context.rootGetters['settings/darkMode'];
      const globalLanguage = this.context.rootGetters['settings/getGlobalLanguage'];
      i18n.locale = getLocaleFromURL() || globalLanguage || 'en';
      moment.locale(getLocaleFromURL() || globalLanguage || 'en');
      eventBus.$emit('settingsLoaded');
      return Promise.resolve({ data: settingsData });
    } catch (e) {
      if (e instanceof SessionExpiredError) {
        return Promise.reject(e);
      }
      return Promise.reject(new AppError('contactSupport', { data: { error: 'true', message: e } }));
    }
  }

  @Action({ rawError: true })
  async save(params?: any) {
    try {
      const request = Settings.createBaseSettingsRequest(this.updatedSettings);
      if (params) ld.assign(request, params);
      const resp = await SettingsServices.save(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          if (data.errorCode === 'E0001') {
            eventBus.$emit('viewKeyError');
            return Promise.reject();
          } else {
            return Promise.reject(new AppError('contactSupport', { data }));
          }
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async saveSharedTeacher(params?: any) {
    try {
      let data: any = {};
      if (params) {
        const resp = await SettingsServices.saveSharedTeacher(params);
        if (resp) {
          data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            console.log('SAVE SHARED TEACHER EXPIRED');
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else if (data.invalidTeacher === 'true') {
            return Promise.resolve({ data });
          } else {
            this.context.commit('setSharedList', data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async saveStatus(request?: any) {
    try {
      const resp = await SettingsServices.saveStatus(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          const statuses = ld.cloneDeep(this.context.getters.getStatuses);
          let exists = false;
          for (const i in statuses) {
            const status = statuses[i];
            if (status.lessonStatusId === data.lessonStatusId) {
              exists = true;
              status.teacherId = data.teacherId;
              status.color = data.color;
              status.statusText = data.statusText;
              status.lessonStatusId = data.lessonStatusId;
              status.overrideDelete = data.overrideDelete;
            }
          }
          if (!exists) {
            statuses.unshift(data);
          }
          this.context.commit('addStatusSetting', data);
          this.context.commit('setStatuses', statuses);
          return Promise.resolve({ statuses });
        }
      } else {
        return Promise.resolve({ statuses: [] });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async deleteStatus(request?: any) {
    try {
      const resp = await SettingsServices.deleteStatus(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          const statuses = ld.cloneDeep(this.context.getters.getStatuses);
          for (const i in statuses) {
            const status = statuses[i];
            if (status.lessonStatusId === request.lessonStatusId) {
              statuses.splice(i, 1);
              break;
            }
          }
          this.context.commit('setStatuses', statuses);
          this.context.commit('deleteStatusSetting', request.lessonStatusId);
          return Promise.resolve({ statuses });
        }
      } else {
        return Promise.resolve({ statuses: [] });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async saveSchoolStatus(request?: any) {
    try {
      const resp = await SettingsServices.saveSchoolStatus(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          const schoolStatuses = ld.cloneDeep(this.context.getters.getSchoolStatuses);
          let exists = false;
          for (const i in schoolStatuses) {
            const status = schoolStatuses[i];
            if (status.schoolStatusId === data.schoolStatusId) {
              exists = true;
              status.schoolId = data.schoolId;
              status.color = data.color;
              status.statusText = data.statusText;
              status.schoolStatusId = data.schoolStatusId;
              status.overrideDelete = data.overrideDelete;
            }
          }
          if (!exists) {
            schoolStatuses.unshift(data);
          }
          this.context.commit('setSchoolStatuses', schoolStatuses);
          return Promise.resolve({ schoolStatuses });
        }
      } else {
        return Promise.resolve({ statuses: [] });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async restoreSchoolStatus(request: any) {
    try {
      const resp = await SettingsServices.restoreSchoolStatus(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          const statuses = ld.cloneDeep(this.context.getters.getSchoolStatuses);
          for (const i in statuses) {
            const status = statuses[i];
            if (status.schoolStatusId === request.schoolStatusId) {
              status.overrideDelete = false;
              break;
            }
          }
          this.context.commit('setSchoolStatuses', statuses);
          return Promise.resolve({ statuses });
        }
      } else {
        return Promise.resolve({ statuses: [] });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async deleteSchoolStatus(request?: any) {
    try {
      const resp = await SettingsServices.deleteSchoolStatus(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          const statuses = ld.cloneDeep(this.context.getters.getSchoolStatuses);
          for (const i in statuses) {
            const status = statuses[i];
            if (status.schoolStatusId === request.schoolStatusId) {
              if (request.schoolStatusId < 3) {
                status.overrideDelete = true;
              } else {
                statuses.splice(i, 1);
              }
              break;
            }
          }
          this.context.commit('setSchoolStatuses', statuses);
          return Promise.resolve({ statuses });
        }
      } else {
        return Promise.resolve({ statuses: [] });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async saveViewSettings() {
    try {
      const userInfo = CommonUtils.getOrDefaultTo(this.context.getters.getUserInfo, {});
      const viewSettings = CommonUtils.getOrDefaultTo(userInfo.viewSettings, {});
      const statusProfile = CommonUtils.getOrDefaultTo(viewSettings.statusProfileSettings, {});
      let statusSettings = CommonUtils.getOrDefaultTo(statusProfile.statusSettings, [])
      const schoolStatusSettings = CommonUtils.getOrDefaultTo(statusProfile.schoolStatusSettings, [])
      const displayView = this.context.rootGetters['plans/currentDisplayView'];
      const drawerMode = this.context.rootState.drawer.drawerMode;
      if (this.userMode === 'A') {
        statusSettings = statusSettings.filter((ss: any) => ss.teacherId === this.currentTeacherId);
      }
      const request = {
        displayItems: viewSettings.displayItems,
        hideClassIds: (viewSettings.hideClassIds || []).join(','),
        displayView,
        displayListClassId: viewSettings.displayListClass,
        viewNoStatus: statusProfile.viewNoStatus,
        statuses: JSON.stringify(statusSettings),
        viewNoSchoolStatus: statusProfile.viewNoSchoolStatus,
        schoolStatuses: JSON.stringify(schoolStatusSettings),
        drawerMode
      };
      const resp = await SettingsServices.saveViewSettings(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('settings/setDisplayView', displayView, { root: true });
          return Promise.resolve({ statusSettings });
        }
      } else {
        return Promise.resolve({ statusSettings: [] });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async addSchoolYear(params?: any) {
    try {
      const request = Settings.createBaseSettingsRequest(this.updatedSettings);
      if (params) ld.assign(request, params);
      const resp = await SchoolYearServices.addSchoolYear(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data: { ...data, yearName: request.yearName } });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async verifySchoolYear(params?: any) {
    try {
      const request = Settings.createBaseSettingsRequest(this.updatedSettings);
      if (params) ld.assign(request, params);
      const resp = await SchoolYearServices.verifySchoolYear(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else if (CommonUtils.isNotEmpty(data.deletedLessons)) {
          let deleteText = '';
          if (data.deletedLessons.length > 1) {
            deleteText += data.deletedLessons.length + ' lessons'
          } else {
            deleteText += 'a lesson'
          }
          return Promise.resolve({ isValid: true, message: `This schedule change will cause ${deleteText} to be deleted. Do you want to continue?` });
        } else {
          return Promise.resolve({ isValid: true });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async saveAdminYear(params?: any) {
    try {
      const request = Settings.createBaseSettingsRequest(this.updatedSettings);
      if (params) ld.assign(request, params);
      const resp = await SchoolYearServices.updateAdminYear(request);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          await this.context.dispatch('loadAdminSchoolYears');
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async getAdminSchoolYear(yearId: number) {
    try {
      const resp = await SchoolYearServices.getAdminYear(yearId);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve(data);
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async connectTeacherSchools(params: any) {
    try {
      const resp = await SettingsServices.connectTeacherSchools(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          await this.context.dispatch('reloadSettings');
          return Promise.resolve(data);
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadAdminSchoolYears() {
    try {
      const schoolId = this.schoolYearCurrentSchoolId || +this.context.getters.getSchoolId;
      if (schoolId === 0) {
        return Promise.resolve();
      }
      const resp = await SchoolYearServices.getAdminYears(schoolId);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setAdminSchoolYears', data.years || []);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadGroupAccountSchoolYears() {
    try {
      const resp = await SchoolYearServices.getGroupAccountSchoolYears(+this.context.getters.getSchoolId);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setGroupAccountSchoolYears', data || []);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadAdminPayments() {
    try {
      const resp = await PaymentServices.getAdminPayments();
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setAdminPayments', data.payments);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async loadPayments() {
    try {
      const resp = await PaymentServices.getPayments();
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setPayments', data.payments);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async deleteSchoolYear(params: any) {
    try {
      const resp = await SchoolYearServices.deleteSchoolYear(params.yearId);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setSchoolYears', data.years || []);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async deleteSchoolYears(params: any) {
    try {
      const resp = await SchoolYearServices.deleteSchoolYears(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          this.context.commit('setSchoolYears', data.years || []);
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async pay(params: any) {
    try {
      const userMode = this.userMode;
      const resp = await PaymentServices.processPayment(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true || data.status < 0) {
          eventBus.$emit('errorWithMessage', data);
          return Promise.reject(new FormError('contactSupport', { data }));
        } else {
          this.context.commit('setPaidThroughDate', data.paidThroughDate);
          if (userMode === 'A') {
            await this.context.dispatch('loadAdminPayments');
          } else {
            await this.context.dispatch('loadPayments');
          }
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async applyVoucher(params: any) {
    try {
      const resp = await PaymentServices.applyVoucher(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true || data.status < 0) {
          eventBus.$emit('errorWithMessage', data);
          return Promise.reject(new FormError('contactSupport', { data }));
        } else {
          this.context.commit('setPaidThroughDate', data.paidThroughDate);
          await this.context.dispatch('loadPayments');
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async editOtherSettings(params?: any) {
    try {
      const resp = await SettingsServices.editOtherSettings(params);
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async updateBannerInfo(params: any) {
    try {
      const resp = await (params.closeMode === '1' ? Promise.resolve() : SettingsServices.updateBannerInfo(params));
      if (resp) {
        const data = resp.data;
        if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
          return Promise.reject(new SessionExpiredError());
        } else if (data.error === 'true' || data.error === true) {
          return Promise.reject(new AppError('contactSupport', { data }));
        } else {
          if (params.closeMode === '3') {
            this.context.commit('setShowBanner', 'N');
          }
          return Promise.resolve({ data });
        }
      } else {
        return Promise.resolve();
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async signOut(params: any) {
    try {
      this.context.commit('setSchoolYearCurrentSchoolId', 0);
      this.context.commit('plans/reset', null, { root: true });
      if (this.userMode === 'A') this.context.commit('classlink/resetClasslinkData', null, { root: true });
      await this.context.dispatch('firebase/signOut', null, { root: true });
      this.context.commit('setHaveSettings', false);
      return UserServices.signOut(params).then(() => {
        return AuthServices.logout();
      }).finally(() => {
        UserSession.removeCurrentUser();
      });
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  @Action({ rawError: true })
  async signIn(params: any) {
    this.context.commit('plans/reset', null, { root: true });
    this.context.commit('setHaveSettings', false);
    let resp = null;
    if (params.registerStudent) {
      resp = await UserServices.registerStudent(params);
    } else if (params.connect) {
      resp = await UserServices.connectLoginTeacher(params);
    } else {
      resp = await UserServices.login(params);
    }
    this.context.commit('setSchoolYearCurrentSchoolId', 0);
    this.context.commit('welcome/reset', null, { root: true })
    return Promise.resolve(resp);
  }

  @Action({ rawError: true })
  async signUp(params: any) {
    this.context.commit('plans/reset', null, { root: true });
    this.context.commit('setHaveSettings', false);
    let resp = null;
    if (params.registerStudent) {
      resp = await UserServices.registerStudent(params);
    } else {
      resp = await UserServices.register(params);
    }
    this.context.commit('welcome/reset', null, { root: true })
    return Promise.resolve(resp);
  }

  @Action({ rawError: true })
  async updateGlobalFontStyle(params: any) {
    try {
      let data: any = {};
      if (params) {
        params = ld.merge(params, { userMode: this.context.rootState.settings.userMode });
        const resp = await TeacherServices.updateGlobalFontStyle(params);
        if (resp) {
          data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (data.classes) {
              this.context.commit('classes/setClasses', data.classes, { root: true });
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data });
      }
    } catch (e) {
      return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
    }
  }

  private static createSettings(): any {
    return {
      infoSettings: {},
      schoolSettings: {},
      accountSettings: {},
      viewSettings: {},
      schoolYearSettings: {},
      standardSettings: {},
      otherSettings: {},
      sharingOptionsSettings: {},
      displaySettings: {
        global: {},
        lessonSections: {
          currentLessonLayoutId: 0,
          lessonLayouts: [],
          lesson: {},
          tab2: {},
          tab3: {},
          tab4: {},
          tab5: {},
          tab6: {},
          sharedStandards: {},
          myStandards: {},
          schoolStandards: {},
          strategies: {}
        },
        lessonStyling: {
          lessonTitle: {},
          attachments: {},
          assignments: {},
          assessments: {}
        },
        lessonRetrieval: {},
        lessonOrientation: {},
        lessonHeadings: {},
        lessonStatus: {},
        lessonToolTips: {},
        eventStyling: {},
        dateStyling: {},
        classworkStyling: {},
        customFonts: [],
        printLayout: {},
        stickerStyling: {},
        notesTodosStyling: {},
        stickerSettings: {}
      }
    };
  }

  private static createBaseSettingsRequest(updatedSettings?: any) {
    const userInfo = store.getters['settings/getUserInfo'];
    const currentYear = store.getters['settings/getCurrentYear'];
    const grouping = userInfo.otherSettings.lessonSectionsGrouping || {
      defaultGroupingLessonSectionOrder: 1,
      defaultGroupingLinkedLessonSectionOrder: 2,
      defaultGroupingLinkedUnitSectionOrder: 4,
      defaultGroupingUnitSectionOrder: 3,
      groupedGroupingLessonOrder: 1,
      groupedGroupingLinkedLessonOrder: 2,
      groupedGroupingLinkedUnitOrder: 4,
      groupedGroupingUnitOrder: 3,
      lessonSectionsGroupingValue: 1
    };
    const scoreCodes = userInfo.accountSettings.scoreCodes || {
      E: {
        code: 'E',
        label: 'Excused'
      },
      I: {
        code: 'I',
        label: 'Incomplete'
      },
      M: {
        code: 'M',
        label: 'Missing',
        value: 0
      }
    };
    const printLayout = userInfo.displaySettings.printLayout;
    const request = {} as any;
    request.currentStrategyGroup = userInfo.accountSettings.currentStrategyGroup || 0;
    request.haveAdminLetterGrades = userInfo.accountSettings.haveAdminLetterGrades;
    request.newCommentA = userInfo.infoSettings.newCommentA || 'N';
    request.currentLessonLayoutId = userInfo.displaySettings.lessonSections.currentLessonLayoutId || 0;
    request.displayView = userInfo.viewSettings.displayView || 'week';
    request.adjustToFit = userInfo.displaySettings.lessonOrientation.adjustToFit;
    request.showCount = userInfo.displaySettings.dateStyling.showCount;
    request.viewOrientation = userInfo.displaySettings.lessonOrientation.viewOrientation;
    request.equalSize = userInfo.displaySettings.lessonOrientation.equalSize;
    request.boxSize = userInfo.displaySettings.lessonOrientation.boxSize;
    request.viewNoStatus = userInfo.viewSettings.statusProfileSettings.viewNoStatus;
    request.viewNoSchoolStatus = userInfo.viewSettings.statusProfileSettings.viewNoSchoolStatus;
    request.shareNoStatus = userInfo.viewSettings.statusProfileSettings.shareNoStatus;
    request.displayStatusText = CommonUtils.booleanToString(userInfo.displaySettings.lessonStatus.displayStatusText);
    request.fetchWeekSize = userInfo.displaySettings.lessonRetrieval.fetchWeekSize;
    request.hideImages = CommonUtils.booleanToString(userInfo.otherSettings.hideImages);
    request.statusTextFontSize = userInfo.displaySettings.lessonStatus.statusTextFontSize;
    request.defaultSharingView = userInfo.otherSettings.defaultSharingView;
    request.displayToolTipStandardDetails = CommonUtils.booleanToString(userInfo.otherSettings.displayToolTipStandardDetails);
    request.displaySesctionTags = CommonUtils.booleanToString(userInfo.otherSettings.displaySesctionTags);
    request.giftLimit = userInfo.otherSettings.giftLimit;
    request.sharingProfiles = JSON.stringify(userInfo.otherSettings.sharingProfiles);
    request.center = printLayout[0].selection;
    request.topLeftPrint = printLayout[1].selection;
    request.topRightPrint = printLayout[2].selection;
    request.topLeftPrint2 = printLayout[3].selection;
    request.topRightPrint2 = printLayout[4].selection;
    request.topLeftCustom = printLayout[1].showCustomText;
    request.topRightCustom = printLayout[2].showCustomText;
    request.topLeftCustom2 = printLayout[3].showCustomText;
    request.topRightCustom2 = printLayout[4].showCustomText;
    request.currentDateBackgroundColor = userInfo.displaySettings.dateStyling.currentDateBackgroundColor;
    request.currentDateTextColor = userInfo.displaySettings.dateStyling.currentDateTextColor;
    request.currentAssessmentColor = userInfo.displaySettings.classworkStyling.currentAssessmentColor;
    request.currentAssignmentColor = userInfo.displaySettings.classworkStyling.currentAssignmentColor;
    request.globalFontType = userInfo.otherSettings.globalFontType;
    request.globalFontSize = userInfo.otherSettings.globalFontSize;
    request.darkMode = userInfo.otherSettings.darkMode;
    request.autoColorScheme = userInfo.otherSettings.autoColorScheme;
    request.highContrastMode = userInfo.otherSettings.highContrastMode;
    request.defaultEditorMode = userInfo.otherSettings.defaultEditorMode;
    request.notesIconColor = userInfo.displaySettings.notesTodosStyling.notesIconColor;
    request.toDosIconColor = userInfo.displaySettings.notesTodosStyling.toDosIconColor;
    request.lowPriorityColor = userInfo.displaySettings.notesTodosStyling.lowPriorityColor;
    request.mediumPriorityColor = userInfo.displaySettings.notesTodosStyling.mediumPriorityColor;
    request.highPriorityColor = userInfo.displaySettings.notesTodosStyling.highPriorityColor;
    request.nameOrder = userInfo.otherSettings.nameOrder;
    request.isNameCommaSeparated = userInfo.otherSettings.isNameCommaSeparated;
    request.leftDrawerMenuType = userInfo.otherSettings.leftDrawerMenuType || 0;
    request.lessonSectionsGroupingValue = grouping.lessonSectionsGroupingValue;
    request.defaultGroupingLessonSectionOrder = grouping.defaultGroupingLessonSectionOrder;
    request.defaultGroupingLinkedLessonSectionOrder = grouping.defaultGroupingLinkedLessonSectionOrder;
    request.defaultGroupingLinkedUnitSectionOrder = grouping.defaultGroupingLinkedUnitSectionOrder;
    request.defaultGroupingUnitSectionOrder = grouping.defaultGroupingUnitSectionOrder;
    request.groupedGroupingLessonOrder = grouping.groupedGroupingLessonOrder;
    request.groupedGroupingLinkedLessonOrder = grouping.groupedGroupingLinkedLessonOrder;
    request.groupedGroupingLinkedUnitOrder = grouping.groupedGroupingLinkedUnitOrder;
    request.groupedGroupingUnitOrder = grouping.groupedGroupingUnitOrder;
    request.scoreCodes = JSON.stringify(scoreCodes);
    request.dateFormat = userInfo.displaySettings.dateStyling.dateFormat;
    request.dateDelimiter = userInfo.displaySettings.dateStyling.dateDelimiter;
    request.reduceBrightnessOfPastDays = CommonUtils.get(userInfo.otherSettings.reduceBrightnessOfPastDays, false);
    request.showSnackbarNotifications = CommonUtils.get(userInfo.otherSettings.showSnackbarNotifications, true);
    request.sendEmail = CommonUtils.booleanToString(userInfo.accountSettings.sendEmail);
    request.sendEmail2 = CommonUtils.booleanToString(userInfo.otherSettings.sendEmail2);
    request.sendEmail3 = CommonUtils.booleanToString(userInfo.otherSettings.sendEmail3);
    request.sendEmail4 = CommonUtils.booleanToString(userInfo.otherSettings.sendEmail4);
    request.sendEmail5 = CommonUtils.booleanToString(userInfo.otherSettings.sendEmail5);
    request.sendEmail6 = CommonUtils.booleanToString(userInfo.otherSettings.sendEmail6);
    request.sendEmail7 = CommonUtils.booleanToString(userInfo.otherSettings.sendEmail7);
    request.sendEmail8 = CommonUtils.booleanToString(userInfo.otherSettings.sendEmail8);
    request.allowPush = CommonUtils.booleanToString(userInfo.otherSettings.allowPush);
    request.eventEmail = CommonUtils.booleanToString(userInfo.otherSettings.eventEmail);
    request.showBanner = CommonUtils.booleanToString(userInfo.accountSettings.showBanner);
    request.autosaveInterval = userInfo.displaySettings.lessonRetrieval.autosaveInterval;
    request.haveAdminGradingPeriods = userInfo.accountSettings.haveAdminGradingPeriods;
    request.currentState = userInfo.standardSettings.currentState;
    request.currentSubject = userInfo.standardSettings.currentSubject;
    request.currentGrade = userInfo.standardSettings.currentGrade;
    request.userId = userInfo.userId;
    request.emailAddress = userInfo.accountSettings.emailAddress;
    request.firstName = userInfo.accountSettings.firstName;
    request.lastName = userInfo.accountSettings.lastName;
    request.schoolId = userInfo.schoolSettings.schoolId;
    request.promoCode = userInfo.schoolSettings.promoCode;
    request.freeCount = userInfo.schoolSettings.freeCount;
    request.displayName = userInfo.accountSettings.displayName;
    request.schoolName = userInfo.schoolSettings.schoolName;
    request.schoolDistrict = userInfo.schoolSettings.schoolDistrict;
    request.studentPassword = userInfo.sharingOptionsSettings.studentPassword;
    request.sharedUserKey = userInfo.sharingOptionsSettings.sharedUserKey;
    request.viewLength = userInfo.sharingOptionsSettings.viewLength;
    request.viewLessons = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewHomework = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewNotes = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewTab4 = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewTab5 = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewTab6 = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewStrategies = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewStandards = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewEvents = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewGoogleEvents = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewMyList = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewAssignments = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewAssessments = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.viewGrades = userInfo.sharingOptionsSettings.viewLessons || 'Y';
    request.registeredDate = userInfo.accountSettings.registeredDate;
    request.paidThroughDate = userInfo.accountSettings.paidThroughDate;
    request.classCycle = userInfo.schoolYearSettings.classCycle;
    request.cycleDays = (userInfo.schoolYearSettings.cycleDayNames || []).map((d: any, i: number) => {
      if (d && CommonUtils.hasText(d.day)) {
        return d.day;
      } else {
        return `Day ${i}`;
      }
    });
    request.userType = userInfo.accountSettings.userType;
    request.lessonEnabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.lesson.lessonEnabled);
    request.lessonLabel = userInfo.displaySettings.lessonSections.lesson.lessonLabel;
    request.lessonFont = userInfo.displaySettings.lessonSections.lesson.lessonFont;
    request.lessonSize = userInfo.displaySettings.lessonSections.lesson.lessonSize;
    request.lessonColor = userInfo.displaySettings.lessonSections.lesson.lessonColor;
    request.lessonHide = userInfo.displaySettings.lessonSections.lesson.lessonHide;
    request.lessonTextFont = userInfo.displaySettings.lessonSections.lesson.lessonTextFont;
    request.lessonTextSize = userInfo.displaySettings.lessonSections.lesson.lessonTextSize;
    request.lessonTextColor = userInfo.displaySettings.lessonSections.lesson.lessonTextColor;
    request.lessonLabelFillColor = userInfo.displaySettings.lessonSections.lesson.lessonLabelFillColor;
    request.lessonTextFillColor = userInfo.displaySettings.lessonSections.lesson.lessonTextFillColor;
    request.tab1Order = userInfo.displaySettings.lessonSections.lesson.lessonDisplayOrder;
    request.lessonLabelBold = userInfo.displaySettings.lessonSections.lesson.lessonLabelBold;
    request.lessonLabelItalic = userInfo.displaySettings.lessonSections.lesson.lessonLabelItalic;
    request.lessonLabelUnderline = userInfo.displaySettings.lessonSections.lesson.lessonLabelUnderline;
    request.lessonTextBold = userInfo.displaySettings.lessonSections.lesson.lessonTextBold;
    request.lessonTextItalic = userInfo.displaySettings.lessonSections.lesson.lessonTextItalic;
    request.lessonTextUnderline = userInfo.displaySettings.lessonSections.lesson.lessonTextUnderline;
    request.eventEnabled = userInfo.displaySettings.eventStyling.eventEnabled;
    request.eventLabel = userInfo.displaySettings.eventStyling.eventLabel;
    request.eventLabelBold = userInfo.displaySettings.eventStyling.eventLabelBold;
    request.eventLabelColor = userInfo.displaySettings.eventStyling.eventLabelColor;
    request.eventLabelFillColor = userInfo.displaySettings.eventStyling.eventLabelFillColor;
    request.eventLabelFont = userInfo.displaySettings.eventStyling.eventLabelFont;
    request.eventLabelItalic = userInfo.displaySettings.eventStyling.eventLabelItalic;
    request.eventLabelSize = userInfo.displaySettings.eventStyling.eventLabelSize;
    request.eventLabelUnderline = userInfo.displaySettings.eventStyling.eventLabelUnderline;
    request.eventTextBold = userInfo.displaySettings.eventStyling.eventTextBold;
    request.eventTextColor = userInfo.displaySettings.eventStyling.eventTextColor;
    request.eventTextFillColor = userInfo.displaySettings.eventStyling.eventTextFillColor;
    request.eventTextFont = userInfo.displaySettings.eventStyling.eventTextFont;
    request.eventTextItalic = userInfo.displaySettings.eventStyling.eventTextItalic;
    request.eventTextSize = userInfo.displaySettings.eventStyling.eventTextSize;
    request.eventTextUnderline = userInfo.displaySettings.eventStyling.eventTextUnderline;
    request.classHeadCentered = userInfo.displaySettings.lessonHeadings.classHeadCentered;
    request.classHeadOrder = userInfo.displaySettings.lessonHeadings.classHeadOrder;
    request.tab2Enabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.tab2.tab2Enabled);
    request.tab2Label = userInfo.displaySettings.lessonSections.tab2.tab2Label;
    request.tab2Font = userInfo.displaySettings.lessonSections.tab2.tab2Font;
    request.tab2Size = userInfo.displaySettings.lessonSections.tab2.tab2Size;
    request.tab2Color = userInfo.displaySettings.lessonSections.tab2.tab2Color;
    request.tab2Hide = userInfo.displaySettings.lessonSections.tab2.tab2Hide;
    request.tab2TextFont = userInfo.displaySettings.lessonSections.tab2.tab2TextFont;
    request.tab2TextSize = userInfo.displaySettings.lessonSections.tab2.tab2TextSize;
    request.tab2TextColor = userInfo.displaySettings.lessonSections.tab2.tab2TextColor;
    request.tab2LabelFillColor = userInfo.displaySettings.lessonSections.tab2.tab2LabelFillColor;
    request.tab2TextFillColor = userInfo.displaySettings.lessonSections.tab2.tab2TextFillColor;
    request.tab2Order = userInfo.displaySettings.lessonSections.tab2.tab2DisplayOrder;
    request.tab2LabelBold = userInfo.displaySettings.lessonSections.tab2.tab2LabelBold;
    request.tab2LabelItalic = userInfo.displaySettings.lessonSections.tab2.tab2LabelItalic;
    request.tab2LabelUnderline = userInfo.displaySettings.lessonSections.tab2.tab2LabelUnderline;
    request.tab2TextBold = userInfo.displaySettings.lessonSections.tab2.tab2TextBold;
    request.tab2TextItalic = userInfo.displaySettings.lessonSections.tab2.tab2TextItalic;
    request.tab2TextUnderline = userInfo.displaySettings.lessonSections.tab2.tab2TextUnderline;
    request.tab3Enabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.tab3.tab3Enabled);
    request.tab3Label = userInfo.displaySettings.lessonSections.tab3.tab3Label;
    request.tab3Font = userInfo.displaySettings.lessonSections.tab3.tab3Font;
    request.tab3Size = userInfo.displaySettings.lessonSections.tab3.tab3Size;
    request.tab3Color = userInfo.displaySettings.lessonSections.tab3.tab3Color;
    request.tab3Hide = userInfo.displaySettings.lessonSections.tab3.tab3Hide;
    request.tab3TextFont = userInfo.displaySettings.lessonSections.tab3.tab3TextFont;
    request.tab3TextSize = userInfo.displaySettings.lessonSections.tab3.tab3TextSize;
    request.tab3TextColor = userInfo.displaySettings.lessonSections.tab3.tab3TextColor;
    request.tab3LabelFillColor = userInfo.displaySettings.lessonSections.tab3.tab3LabelFillColor;
    request.tab3TextFillColor = userInfo.displaySettings.lessonSections.tab3.tab3TextFillColor;
    request.tab3Order = userInfo.displaySettings.lessonSections.tab3.tab3DisplayOrder;
    request.tab3LabelBold = userInfo.displaySettings.lessonSections.tab3.tab3LabelBold;
    request.tab3LabelItalic = userInfo.displaySettings.lessonSections.tab3.tab3LabelItalic;
    request.tab3LabelUnderline = userInfo.displaySettings.lessonSections.tab3.tab3LabelUnderline;
    request.tab3TextBold = userInfo.displaySettings.lessonSections.tab3.tab3TextBold;
    request.tab3TextItalic = userInfo.displaySettings.lessonSections.tab3.tab3TextItalic;
    request.tab3TextUnderline = userInfo.displaySettings.lessonSections.tab3.tab3TextUnderline;
    request.tab4Enabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.tab4.tab4Enabled);
    request.tab4Label = userInfo.displaySettings.lessonSections.tab4.tab4Label;
    request.tab4Font = userInfo.displaySettings.lessonSections.tab4.tab4Font;
    request.tab4Size = userInfo.displaySettings.lessonSections.tab4.tab4Size;
    request.tab4Color = userInfo.displaySettings.lessonSections.tab4.tab4Color;
    request.tab4Hide = userInfo.displaySettings.lessonSections.tab4.tab4Hide;
    request.tab4TextFont = userInfo.displaySettings.lessonSections.tab4.tab4TextFont;
    request.tab4TextSize = userInfo.displaySettings.lessonSections.tab4.tab4TextSize;
    request.tab4TextColor = userInfo.displaySettings.lessonSections.tab4.tab4TextColor;
    request.tab4LabelFillColor = userInfo.displaySettings.lessonSections.tab4.tab4LabelFillColor;
    request.tab4TextFillColor = userInfo.displaySettings.lessonSections.tab4.tab4TextFillColor;
    request.tab4Order = userInfo.displaySettings.lessonSections.tab4.tab4DisplayOrder;
    request.tab4LabelBold = userInfo.displaySettings.lessonSections.tab4.tab4LabelBold;
    request.tab4LabelItalic = userInfo.displaySettings.lessonSections.tab4.tab4LabelItalic;
    request.tab4LabelUnderline = userInfo.displaySettings.lessonSections.tab4.tab4LabelUnderline;
    request.tab4TextBold = userInfo.displaySettings.lessonSections.tab4.tab4TextBold;
    request.tab4TextItalic = userInfo.displaySettings.lessonSections.tab4.tab4TextItalic;
    request.tab4TextUnderline = userInfo.displaySettings.lessonSections.tab4.tab4TextUnderline;
    request.tab5Enabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.tab5.tab5Enabled);
    request.tab5Label = userInfo.displaySettings.lessonSections.tab5.tab5Label;
    request.tab5Font = userInfo.displaySettings.lessonSections.tab5.tab5Font;
    request.tab5Size = userInfo.displaySettings.lessonSections.tab5.tab5Size;
    request.tab5Color = userInfo.displaySettings.lessonSections.tab5.tab5Color;
    request.tab5Hide = userInfo.displaySettings.lessonSections.tab5.tab5Hide;
    request.tab5TextFont = userInfo.displaySettings.lessonSections.tab5.tab5TextFont;
    request.tab5TextSize = userInfo.displaySettings.lessonSections.tab5.tab5TextSize;
    request.tab5TextColor = userInfo.displaySettings.lessonSections.tab5.tab5TextColor;
    request.tab5LabelFillColor = userInfo.displaySettings.lessonSections.tab5.tab5LabelFillColor;
    request.tab5TextFillColor = userInfo.displaySettings.lessonSections.tab5.tab5TextFillColor;
    request.tab5Order = userInfo.displaySettings.lessonSections.tab5.tab5DisplayOrder;
    request.tab5LabelBold = userInfo.displaySettings.lessonSections.tab5.tab5LabelBold;
    request.tab5LabelItalic = userInfo.displaySettings.lessonSections.tab5.tab5LabelItalic;
    request.tab5LabelUnderline = userInfo.displaySettings.lessonSections.tab5.tab5LabelUnderline;
    request.tab5TextBold = userInfo.displaySettings.lessonSections.tab5.tab5TextBold;
    request.tab5TextItalic = userInfo.displaySettings.lessonSections.tab5.tab5TextItalic;
    request.tab5TextUnderline = userInfo.displaySettings.lessonSections.tab5.tab5TextUnderline;
    request.tab6Enabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.tab6.tab6Enabled);
    request.tab6Label = userInfo.displaySettings.lessonSections.tab6.tab6Label;
    request.tab6Font = userInfo.displaySettings.lessonSections.tab6.tab6Font;
    request.tab6Size = userInfo.displaySettings.lessonSections.tab6.tab6Size;
    request.tab6Color = userInfo.displaySettings.lessonSections.tab6.tab6Color;
    request.tab6Hide = userInfo.displaySettings.lessonSections.tab6.tab6Hide;
    request.tab6TextFont = userInfo.displaySettings.lessonSections.tab6.tab6TextFont;
    request.tab6TextSize = userInfo.displaySettings.lessonSections.tab6.tab6TextSize;
    request.tab6TextColor = userInfo.displaySettings.lessonSections.tab6.tab6TextColor;
    request.tab6LabelFillColor = userInfo.displaySettings.lessonSections.tab6.tab6LabelFillColor;
    request.tab6TextFillColor = userInfo.displaySettings.lessonSections.tab6.tab6TextFillColor;
    request.tab6Order = userInfo.displaySettings.lessonSections.tab6.tab6DisplayOrder;
    request.tab6LabelBold = userInfo.displaySettings.lessonSections.tab6.tab6LabelBold;
    request.tab6LabelItalic = userInfo.displaySettings.lessonSections.tab6.tab6LabelItalic;
    request.tab6LabelUnderline = userInfo.displaySettings.lessonSections.tab6.tab6LabelUnderline;
    request.tab6TextBold = userInfo.displaySettings.lessonSections.tab6.tab6TextBold;
    request.tab6TextItalic = userInfo.displaySettings.lessonSections.tab6.tab6TextItalic;
    request.tab6TextUnderline = userInfo.displaySettings.lessonSections.tab6.tab6TextUnderline;
    request.strategiesEnabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.strategies.strategiesEnabled);
    request.strategiesLabel = userInfo.displaySettings.lessonSections.strategies.strategiesLabel;
    request.strategiesFont = userInfo.displaySettings.lessonSections.strategies.strategiesFont;
    request.strategiesSize = userInfo.displaySettings.lessonSections.strategies.strategiesSize;
    request.strategiesColor = userInfo.displaySettings.lessonSections.strategies.strategiesColor;
    request.strategiesHide = userInfo.displaySettings.lessonSections.strategies.strategiesHide;
    request.strategiesTextFont = userInfo.displaySettings.lessonSections.strategies.strategiesTextFont;
    request.strategiesTextSize = userInfo.displaySettings.lessonSections.strategies.strategiesTextSize;
    request.strategiesTextColor = userInfo.displaySettings.lessonSections.strategies.strategiesTextColor;
    request.strategiesLabelFillColor = userInfo.displaySettings.lessonSections.strategies.strategiesLabelFillColor;
    request.strategiesTextFillColor = userInfo.displaySettings.lessonSections.strategies.strategiesTextFillColor;
    request.strategiesOrder = userInfo.displaySettings.lessonSections.strategies.strategiesDisplayOrder;
    request.strategiesLabelBold = userInfo.displaySettings.lessonSections.strategies.strategiesLabelBold;
    request.strategiesLabelItalic = userInfo.displaySettings.lessonSections.strategies.strategiesLabelItalic;
    request.strategiesLabelUnderline = userInfo.displaySettings.lessonSections.strategies.strategiesLabelUnderline;
    request.strategiesTextBold = userInfo.displaySettings.lessonSections.strategies.strategiesTextBold;
    request.strategiesTextItalic = userInfo.displaySettings.lessonSections.strategies.strategiesTextItalic;
    request.strategiesTextUnderline = userInfo.displaySettings.lessonSections.strategies.strategiesTextUnderline;
    request.sharedStandardsEnabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsEnabled);
    request.sharedStandardsLabel = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabel;
    request.sharedStandardsFont = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsFont;
    request.sharedStandardsSize = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsSize;
    request.sharedStandardsColor = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsColor;
    request.sharedStandardsHide = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsHide;
    request.sharedStandardsTextFont = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextFont;
    request.sharedStandardsTextSize = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextSize;
    request.sharedStandardsTextColor = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextColor;
    request.sharedStandardsLabelFillColor = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabelFillColor;
    request.sharedStandardsTextFillColor = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextFillColor;
    request.sharedStandardsOrder = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsDisplayOrder;
    request.sharedStandardsLabelBold = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabelBold;
    request.sharedStandardsLabelItalic = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabelItalic;
    request.sharedStandardsLabelUnderline = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsLabelUnderline;
    request.sharedStandardsTextBold = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextBold;
    request.sharedStandardsTextItalic = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextItalic;
    request.sharedStandardsTextUnderline = userInfo.displaySettings.lessonSections.sharedStandards.sharedStandardsTextUnderline;
    request.myStandardsEnabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.myStandards.myStandardsEnabled);
    request.myStandardsLabel = userInfo.displaySettings.lessonSections.myStandards.myStandardsLabel;
    request.myStandardsFont = userInfo.displaySettings.lessonSections.myStandards.myStandardsFont;
    request.myStandardsSize = userInfo.displaySettings.lessonSections.myStandards.myStandardsSize;
    request.myStandardsColor = userInfo.displaySettings.lessonSections.myStandards.myStandardsColor;
    request.myStandardsHide = userInfo.displaySettings.lessonSections.myStandards.myStandardsHide;
    request.myStandardsTextFont = userInfo.displaySettings.lessonSections.myStandards.myStandardsTextFont;
    request.myStandardsTextSize = userInfo.displaySettings.lessonSections.myStandards.myStandardsTextSize;
    request.myStandardsTextColor = userInfo.displaySettings.lessonSections.myStandards.myStandardsTextColor;
    request.myStandardsLabelFillColor = userInfo.displaySettings.lessonSections.myStandards.myStandardsLabelFillColor;
    request.myStandardsTextFillColor = userInfo.displaySettings.lessonSections.myStandards.myStandardsTextFillColor;
    request.myStandardsOrder = userInfo.displaySettings.lessonSections.myStandards.myStandardsDisplayOrder;
    request.myStandardsLabelBold = userInfo.displaySettings.lessonSections.myStandards.myStandardsLabelBold;
    request.myStandardsLabelItalic = userInfo.displaySettings.lessonSections.myStandards.myStandardsLabelItalic;
    request.myStandardsLabelUnderline = userInfo.displaySettings.lessonSections.myStandards.myStandardsLabelUnderline;
    request.myStandardsTextBold = userInfo.displaySettings.lessonSections.myStandards.myStandardsTextBold;
    request.myStandardsTextItalic = userInfo.displaySettings.lessonSections.myStandards.myStandardsTextItalic;
    request.myStandardsTextUnderline = userInfo.displaySettings.lessonSections.myStandards.myStandardsTextUnderline;
    request.schoolStandardsEnabled = CommonUtils.booleanToString(userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsEnabled);
    request.schoolStandardsLabel = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabel;
    request.schoolStandardsFont = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsFont;
    request.schoolStandardsSize = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsSize;
    request.schoolStandardsColor = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsColor;
    request.schoolStandardsHide = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsHide;
    request.schoolStandardsTextFont = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextFont;
    request.schoolStandardsTextSize = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextSize;
    request.schoolStandardsTextColor = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextColor;
    request.schoolStandardsLabelFillColor = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabelFillColor;
    request.schoolStandardsTextFillColor = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextFillColor;
    request.schoolStandardsOrder = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsDisplayOrder;
    request.schoolStandardsLabelBold = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabelBold;
    request.schoolStandardsLabelItalic = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabelItalic;
    request.schoolStandardsLabelUnderline = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsLabelUnderline;
    request.schoolStandardsTextBold = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextBold;
    request.schoolStandardsTextItalic = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextItalic;
    request.schoolStandardsTextUnderline = userInfo.displaySettings.lessonSections.schoolStandards.schoolStandardsTextUnderline;
    request.lessonTitleFont = userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleFont
    request.lessonTitleSize = userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleSize
    request.lessonTitleColor = userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleColor
    request.lessonTitleLabelFillColor = userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleLabelFillColor
    request.lessonTitleLabelBold = userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleLabelBold
    request.lessonTitleLabelItalic = userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleLabelItalic
    request.lessonTitleLabelUnderline = userInfo.displaySettings.lessonStyling.lessonTitle.lessonTitleLabelUnderline
    request.attachmentsFont = userInfo.displaySettings.lessonStyling.attachments.attachmentsFont;
    request.attachmentsSize = userInfo.displaySettings.lessonStyling.attachments.attachmentsSize;
    request.attachmentsColor = userInfo.displaySettings.lessonStyling.attachments.attachmentsColor;
    request.attachmentsHide = userInfo.displaySettings.lessonStyling.attachments.attachmentsHide;
    request.attachmentsTextFont = userInfo.displaySettings.lessonStyling.attachments.attachmentsTextFont;
    request.attachmentsTextSize = userInfo.displaySettings.lessonStyling.attachments.attachmentsTextSize;
    request.attachmentsTextColor = userInfo.displaySettings.lessonStyling.attachments.attachmentsTextColor;
    request.attachmentsLabelFillColor = userInfo.displaySettings.lessonStyling.attachments.attachmentsLabelFillColor;
    request.attachmentsTextFillColor = userInfo.displaySettings.lessonStyling.attachments.attachmentsTextFillColor;
    request.attachmentsLabelBold = userInfo.displaySettings.lessonStyling.attachments.attachmentsLabelBold;
    request.attachmentsLabelItalic = userInfo.displaySettings.lessonStyling.attachments.attachmentsLabelItalic;
    request.attachmentsLabelUnderline = userInfo.displaySettings.lessonStyling.attachments.attachmentsLabelUnderline;
    request.attachmentsTextBold = userInfo.displaySettings.lessonStyling.attachments.attachmentsTextBold;
    request.attachmentsTextItalic = userInfo.displaySettings.lessonStyling.attachments.attachmentsTextItalic;
    request.attachmentsTextUnderline = userInfo.displaySettings.lessonStyling.attachments.attachmentsTextUnderline;
    request.assignmentsFont = userInfo.displaySettings.lessonStyling.assignments.assignmentsFont;
    request.assignmentsSize = userInfo.displaySettings.lessonStyling.assignments.assignmentsSize;
    request.assignmentsColor = userInfo.displaySettings.lessonStyling.assignments.assignmentsColor;
    request.assignmentsHide = userInfo.displaySettings.lessonStyling.assignments.assignmentsHide;
    request.assignmentsTextFont = userInfo.displaySettings.lessonStyling.assignments.assignmentsTextFont;
    request.assignmentsTextSize = userInfo.displaySettings.lessonStyling.assignments.assignmentsTextSize;
    request.assignmentsTextColor = userInfo.displaySettings.lessonStyling.assignments.assignmentsTextColor;
    request.assignmentsLabelFillColor = userInfo.displaySettings.lessonStyling.assignments.assignmentsLabelFillColor;
    request.assignmentsTextFillColor = userInfo.displaySettings.lessonStyling.assignments.assignmentsTextFillColor;
    request.assignmentsLabelBold = userInfo.displaySettings.lessonStyling.assignments.assignmentsLabelBold;
    request.assignmentsLabelItalic = userInfo.displaySettings.lessonStyling.assignments.assignmentsLabelItalic;
    request.assignmentsLabelUnderline = userInfo.displaySettings.lessonStyling.assignments.assignmentsLabelUnderline;
    request.assignmentsTextBold = userInfo.displaySettings.lessonStyling.assignments.assignmentsTextBold;
    request.assignmentsTextItalic = userInfo.displaySettings.lessonStyling.assignments.assignmentsTextItalic;
    request.assignmentsTextUnderline = userInfo.displaySettings.lessonStyling.assignments.assignmentsTextUnderline;
    request.assessmentsFont = userInfo.displaySettings.lessonStyling.assessments.assessmentsFont;
    request.assessmentsSize = userInfo.displaySettings.lessonStyling.assessments.assessmentsSize;
    request.assessmentsColor = userInfo.displaySettings.lessonStyling.assessments.assessmentsColor;
    request.assessmentsHide = userInfo.displaySettings.lessonStyling.assessments.assessmentsHide;
    request.assessmentsTextFont = userInfo.displaySettings.lessonStyling.assessments.assessmentsTextFont;
    request.assessmentsTextSize = userInfo.displaySettings.lessonStyling.assessments.assessmentsTextSize;
    request.assessmentsTextColor = userInfo.displaySettings.lessonStyling.assessments.assessmentsTextColor;
    request.assessmentsLabelFillColor = userInfo.displaySettings.lessonStyling.assessments.assessmentsLabelFillColor;
    request.assessmentsTextFillColor = userInfo.displaySettings.lessonStyling.assessments.assessmentsTextFillColor;
    request.assessmentsLabelBold = userInfo.displaySettings.lessonStyling.assessments.assessmentsLabelBold;
    request.assessmentsLabelItalic = userInfo.displaySettings.lessonStyling.assessments.assessmentsLabelItalic;
    request.assessmentsLabelUnderline = userInfo.displaySettings.lessonStyling.assessments.assessmentsLabelUnderline;
    request.assessmentsTextBold = userInfo.displaySettings.lessonStyling.assessments.assessmentsTextBold;
    request.assessmentsTextItalic = userInfo.displaySettings.lessonStyling.assessments.assessmentsTextItalic;
    request.assessmentsTextUnderline = userInfo.displaySettings.lessonStyling.assessments.assessmentsTextUnderline;
    request.currentDateBackgroundColor = userInfo.otherSettings.currentDateBackgroundColor || '#ffe45c';
    request.currentDateTextColor = userInfo.otherSettings.currentDateTextColor || vuetify.framework.theme.currentTheme.primary;
    request.currentAssessmentColor = userInfo.otherSettings.currentAssessmentColor || 'purple';
    request.currentAssignmentColor = userInfo.otherSettings.currentAssignmentColor || '#5DA500';
    request.globalFontType = userInfo.otherSettings.globalFontType || '2';
    request.globalFontSize = userInfo.otherSettings.globalFontSize || '10';
    request.globalLanguage = userInfo.otherSettings.globalLanguage || 'en';
    request.darkMode = CommonUtils.get(userInfo.otherSettings.darkMode, false);
    request.autoColorScheme = CommonUtils.get(userInfo.otherSettings.autoColorScheme, true);
    request.highContrastMode = CommonUtils.get(userInfo.otherSettings.highContrastMode, false);
    request.defaultEditorMode = userInfo.otherSettings.defaultEditorMode || 'split';
    request.eventStickerLabelFont = userInfo.displaySettings.stickerStyling.eventStickerLabelFont;
    request.eventStickerLabelSize = userInfo.displaySettings.stickerStyling.eventStickerLabelSize;
    request.eventStickerLabelColor = userInfo.displaySettings.stickerStyling.eventStickerLabelColor;
    request.eventStickerLabelFillColor = userInfo.displaySettings.stickerStyling.eventStickerLabelFillColor;
    request.eventStickerLabelBold = userInfo.displaySettings.stickerStyling.eventStickerLabelBold;
    request.eventStickerLabelItalic = userInfo.displaySettings.stickerStyling.eventStickerLabelItalic;
    request.eventStickerLabelUnderline = userInfo.displaySettings.stickerStyling.eventStickerLabelUnderline;
    request.eventStickerTextFont = userInfo.displaySettings.stickerStyling.eventStickerTextFont;
    request.eventStickerTextSize = userInfo.displaySettings.stickerStyling.eventStickerTextSize;
    request.eventStickerTextColor = userInfo.displaySettings.stickerStyling.eventStickerTextColor;
    request.eventStickerTextFillColor = userInfo.displaySettings.stickerStyling.eventStickerTextFillColor;
    request.eventStickerTextBold = userInfo.displaySettings.stickerStyling.eventStickerTextBold;
    request.eventStickerTextItalic = userInfo.displaySettings.stickerStyling.eventStickerTextItalic;
    request.eventStickerTextUnderline = userInfo.displaySettings.stickerStyling.eventStickerTextUnderline;
    request.stickerBackgroundColor = userInfo.displaySettings.stickerStyling.stickerBackgroundColor;
    request.stickerBackgroundStyle = userInfo.displaySettings.stickerStyling.stickerBackgroundStyle;
    request.stickerSize = userInfo.displaySettings.stickerStyling.stickerSize === '150' ? '40' : userInfo.displaySettings.stickerStyling.stickerSize || '40';
    request.notesIconColor = userInfo.displaySettings.notesTodosStyling.notesIconColor || '#f29a25';
    request.toDosIconColor = userInfo.displaySettings.notesTodosStyling.toDosIconColor || '#3e985c';
    request.lowPriorityColor = userInfo.displaySettings.notesTodosStyling.lowPriorityColor || '#FFA726';
    request.mediumPriorityColor = userInfo.displaySettings.notesTodosStyling.mediumPriorityColor || '#F57C00';
    request.highPriorityColor = userInfo.displaySettings.notesTodosStyling.highPriorityColor || '#BF360C';
    request.hasStandards = userInfo.standardSettings.hasStandards;
    request.schoolLabel = userInfo.schoolSettings.schoolLabel;
    request.districtId = userInfo.schoolSettings.districtId;
    request.districtName = userInfo.schoolSettings.districtName;
    request.parentYearId = currentYear.parentYearId;
    request.parentYearName = currentYear.parentYearName;
    request.schoolStartTime = currentYear.schoolStartTime;
    request.schoolEndTime = currentYear.schoolEndTime;
    request.yearId = currentYear.yearId;
    request.firstDay = currentYear.firstDay;
    request.lastDay = currentYear.lastDay;
    request.yearCreated = currentYear.created;
    request.yearName = currentYear.yearName;
    request.sunCycle = currentYear.sunCycle;
    request.monCycle = currentYear.monCycle;
    request.tueCycle = currentYear.tueCycle;
    request.wedCycle = currentYear.wedCycle;
    request.thuCycle = currentYear.thuCycle;
    request.friCycle = currentYear.friCycle;
    request.satCycle = currentYear.satCycle;
    if (currentYear.terms) {
      request.termName = currentYear.terms.map((t: any) => { return t.termName });
      request.termFirstDay = currentYear.terms.map((t: any) => { return t.firstDay });
      request.termStartsOn = currentYear.terms.map((t: any) => { return t.startsOn });
    }
    request.customFonts = JSON.stringify(userInfo.otherSettings.customFonts || []);
    request.printHeaders = JSON.stringify(printLayout.map((p: any) => {
      const header = {
        ...p,
        customText: p.showCustomText && CommonUtils.hasNoText(p.customText) ? p.selection : p.customText
      };
      header.selection = header.selection || '';
      header.customText = header.customText || '';
      header.showCustomText = header.showCustomText || false;
      return header;
    }));
    request.userPassword = '';
    request.newPassword = '';
    request.googleEmail = userInfo.accountSettings.enableCalendars;
    if (updatedSettings && Object.keys(updatedSettings).length > 0) {
      ld.assign(request, updatedSettings);

      if (request.googleEmail.length === 0) {
        request.googleEmail = [''];
      }

      const customFonts = updatedSettings.customFonts;
      if (customFonts instanceof Array) {
        request.customFonts = JSON.stringify(customFonts);
      }

      const printHeaders = updatedSettings.printHeaders;
      if (printHeaders instanceof Array) {
        request.printHeaders = JSON.stringify(printHeaders.map((p: any) => {
          const header = {
            ...p,
            customText: p.showCustomText && CommonUtils.hasNoText(p.customText) ? p.selection : p.customText
          };
          header.selection = header.selection || '';
          header.customText = header.customText || '';
          header.showCustomText = header.showCustomText || false;
          return header;
        }));
      }

      request.sunShowSchoolYears = JSON.stringify(request.sunShowSchoolYears);
      request.monShowSchoolYears = JSON.stringify(request.monShowSchoolYears);
      request.tueShowSchoolYears = JSON.stringify(request.tueShowSchoolYears);
      request.wedShowSchoolYears = JSON.stringify(request.wedShowSchoolYears);
      request.thuShowSchoolYears = JSON.stringify(request.thuShowSchoolYears);
      request.friShowSchoolYears = JSON.stringify(request.friShowSchoolYears);
      request.satShowSchoolYears = JSON.stringify(request.satShowSchoolYears);
    }

    return request;
  }

  private static getPaidThroughDate(paidThroughDate: string, registeredDate: string) {
    if (DateTimeUtils.daysDiff(registeredDate, paidThroughDate) < 60) {
      return moment(registeredDate, 'MM/DD/YYYY').add(60, 'days').format('MM/DD/YYYY');
    }
    return paidThroughDate;
  }

  private static async validateSettingsData(settingsData?: any) {
    const user = UserSession.getCurrentUser();
    let resp: any;
    if (settingsData) {
      resp = await UserServices.getSettings(user && user.type === 'S' ? user : null, settingsData);
    } else {
      resp = await UserServices.getSettings(user && user.type === 'S' ? user : null);
    }

    if (resp) {
      const data = resp.data;
      if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
        return Promise.reject(new SessionExpiredError());
      } else if (data.error === 'true' || data.error === true) {
        return Promise.reject(new AppError('contactSupport', { data }));
      } else {
        return data;
      }
    } else {
      return Promise.resolve({ data: {} });
    }
  }
}

export default Settings
