



















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { VForm } from 'vuetify/lib';
import CommonUtils from '@/utils/common-utils';
import IntegrationServices from '@/services/integration-services';
import Fuse from 'fuse.js';
import moment from 'moment';
import { AxiosBuilder } from '@/axios';
import ld from 'lodash';
import AppError, { SessionExpiredError } from '@/errors';
import DateTimeUtils from '@/utils/date-time-utils';

const settings = namespace('settings');
const integration = namespace('integration');
const teachers = namespace('teachers');
const classlink = namespace('classlink');
@Component
export default class Integrations extends Vue {
  validStep1 = true;
  validStep2 = true;
  snackBar = false;
  snackBarText: any = '';
  academicSessionMappingSetUpSearch = '';
  teacherMappingSetUpSearch = '';
  orgMappingSetUpSearch = '';
  isTableLoading = false;
  orgMappingResultList: any = [];
  schoolYearsMappingResultList: any = [];
  planbookTeacherLists: any = [];
  id = 0;
  syncTimeItems: any = [];
  connectedSyncTimeItems: any = [];
  tab = 0;
  polling = AxiosBuilder.buildPolling();
  isSyncDone = false;
  orgsPreviousValue = '';
  schoolYearsPreviousValue = '';
  teachersPreviousValue = '';
  overlay = false;
  walkthrough1 = false;
  walkthrough2 = false;
  walkthrough3 = false;
  walkthrough4 = false;
  walkthrough5 = false;
  noDataFound = false;
  mappingDistrictName = '';
  confirmationModal = false;
  agreeToAcceptChanges = false;
  totalTeachersAddedCounter = 0;
  pageInitializing = false;

  alreadyExistModal = false;
  isOrgTableCompleteMatchHidden = false;
  isTeacherTableCompleteMatchHidden = false;
  isSchoolYearWithoutClassesHidden = false;
  hiddenSchoolYearWithoutClasses:any[] = [];
  isOrgsCheckBoxTrueorFalse = false; // this variable is to determine whether to hide or show the associated data of the org that is currently being updated.
  uncheckedAndCheckedOrgSourcedId:any = null;
  hiddenTeacherForCheckbox:any[] = [];
  hiddenSelectedTeacherForCheckbox:any[] = [];
  hiddenSchoolYearForCheckbox:any[] = [];
  hiddenSelectedSchoolYearForCheckbox:any[] = [];
  isSyncCompleted = true;

  statusArray = [
    {
      dataName: 'Orgs'
    },
    {
      dataName: 'Users'
    },
    {
      dataName: 'Academic Sessions'
    },
    {
      dataName: 'Enrollments'
    }
  ];

  headersStep2 = [
    {
      text: this.$t('headersStep2ClasslinkLabel'),
      value: 'setUpSchoolName',
      align: 'start',
      width: 250
    },
    {
      text: this.$t('headersStep2PlanbookLabel'),
      value: 'planBookOrgArray',
      align: 'center',
      width: 300
    },
    {
      text: this.$t('headersMatchLabel'),
      value: 'score',
      width: 80,
      align: 'center'
    }
  ];

  headersStep3 = [
    {
      text: this.$t('headersStep3ClasslinkLabel'),
      value: 'setUpTeacherName',
      width: 170
    },
    { text: this.$t('headersStep3OrgNameLabel'), value: 'orgNames', width: 200, align: 'center' },
    {
      text: this.$t('headersStep3PlanbookLabel'),
      value: 'setUpPBTeacherName',
      align: 'center',
      width: 250
    },
    {
      text: this.$t('headersMatchLabel'),
      value: 'score',
      width: 80,
      align: 'center'
    }
  ];

  headersStep4 = [
    {
      text: this.$t('headersStep4ClasslinkLabel'),
      value: 'setUpSchoolYear',
      width: 200
    },
    {
      text: this.$t('firstDayLabel') + ' - ' + this.$t('lastDayLabel'),
      value: 'setUpFirstAndLastDay',
      align: 'center'
    },
    {
      text: this.$t('headersStep4PlanbookLabel'),
      value: 'setUpPBSchoolYear',
      align: 'center'
    }
  ];

  headersManageMapping = [
    { text: this.$t('headersStep2ClasslinkLabel'), value: 'classLinkOrgName' },
    {
      text: this.$t('headersStep2PlanbookLabel'),
      value: 'manageMappingPBSchoolName',
      align: 'center'
    }
  ];

  headersManageMappingSchoolYears = [
    {
      text: this.$t('headersStep4ClasslinkLabel'),
      value: 'classlinkSchoolYearName',
      width: 200
    },
    {
      text: this.$t('firstDayLabel') + ' - ' + this.$t('lastDayLabel'),
      value: 'classlinkFirstAndLastDay',
      width: 300,
      align: 'center'
    },
    {
      text: this.$t('headersSchoolYearsPlanbookLabel'),
      value: 'manageMappingPBSchoolYearName',
      width: 250,
      align: 'center'
    }
  ];

  headersManageMappingTeachers = [
    {
      text: this.$t('headersStep3ClasslinkLabel'),
      value: 'classLinkTeacherName',
      width: 250
    },
    { text: this.$t('headersStep3OrgNameLabel'), value: 'orgNames', width: 300, align: 'center' },
    {
      text: this.$t('headersStep3PlanbookLabel'),
      value: 'manageMappingPBTeacherName',
      width: 250,
      align: 'center'
    }
  ];

  statusHeaders = [
    { text: this.$t('statusHeadersDataNameLabel'), value: 'dataName', sortable: false },
    { text: this.$t('statusHeadersStatusLabel'), value: 'status', sortable: false },
    { text: this.$t('statusHeadersLastSyncDateLabel'), value: 'lastSync', sortable: false }
  ];

  @settings.State
  userMode!: string;

  @settings.State
  groupAccountSchoolYears!: any;

  @settings.State
  adminSchoolYears!:any;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter('getSchools')
  schools!: any;

  @settings.Getter('getPrimarySchool')
  primarySchool!: any;

  @teachers.Action
  loadTeachers!: () => Promise<any>;

  @teachers.Mutation
  setHaveTeachers!: (boolean:any) => void;

  @teachers.Mutation
  setCurrentSchoolId!: (number:any) => void;

  @teachers.Getter
  getTeachersAll!: Array<any>;

  @settings.Action
  loadAdminSchoolYears!: () => Promise<any>;

  @settings.Action
  loadGroupAccountSchoolYears!: () => Promise<any>;

  @settings.Action
  reloadSettings!: (params?: any) => Promise<any>;

  @settings.Action
  getAdminSchoolYear!: (params?: any) => Promise<any>;

  @integration.Action
  getOrgs!: (params?: any) => Promise<any>;

  @integration.Action
  getOrg!: (params?: any) => Promise<any>;

  @integration.Action
  getTeachersForSchool!: (params?: any) => Promise<any>;

  @integration.Action
  getTeacher!: (params?: any) => Promise<any>;

  @integration.Action
  getAcademicSessions!: (params?: any) => Promise<any>;

  @integration.Action
  getAcademicSession!: (params?: any) => Promise<any>;

  @integration.Action
  refOrgs!: (params?: any) => Promise<any>;

  @integration.Action
  refUsers!: (params?: any) => Promise<any>;

  @integration.Action
  refOrgsRefUsers!: (params?: any) => Promise<any>;

  @integration.Action
  refAcademicSessions!: (params?: any) => Promise<any>;

  @integration.Action
  updateRefOrgs!: (params?: any) => Promise<any>;

  @integration.Action
  updateRefAcademicSession!: (params?: any) => Promise<any>;

  @integration.Action
  updateRefUser!: (params?: any) => Promise<any>;

  @integration.Action
  getAllRosteredItems!: () => Promise<any>;

  @integration.Action
  sync!: (params?: any) => Promise<any>;

  @integration.Action
  changeRefOrgSettings!: (params?: any) => Promise<any>;

  @integration.Action
  getrefOrgsByDistrictId!: (params?: any) => Promise<any>;

  @integration.Action
  getrefOrgsBySchoolId!: (params?: any) => Promise<any>;

  @classlink.Getter
  getMainOrgJsonObject!: any;

  @classlink.Getter
  getOrgsMappingArray!: Array<any>;

  @classlink.Getter
  getTeachersMappingArray!: Array<any>;

  @classlink.Getter
  getSchoolYearsMappingArray!: Array<any>;

  @classlink.Getter
  getSelectedOrgs!: Array<any>;

  @classlink.Getter
  getSelectedTeachers!: Array<any>;

  @classlink.Getter
  getSelectedSchoolYears!: Array<any>;

  @classlink.Getter
  getManageMappingOrgsArray!: Array<any>;

  @classlink.Getter
  getConnectedTeachersArray!: Array<any>;

  @classlink.Getter
  getManageMappingSchoolYearsArray!: Array<any>;

  @classlink.Getter
  getManageMappingOrgsSelected!: Array<any>;

  @classlink.Getter
  getManageMappingTeachersSelected!: Array<any>;

  @classlink.Getter
  getManageMappingSchoolYearsSelected!: Array<any>;

  @classlink.Getter('getAllMappingTeachers')
  allMappingTeachers!: Array<any>;

  @classlink.Mutation
  pushElementInOrgsMappingArray!: (element:any) => void;

  @classlink.Mutation
  pushElementInTeachersMappingArray!: (element:any) => void;

  @classlink.Mutation
  pushElementInSchoolYearsMappingArray!: (element:any) => void;

  @classlink.Mutation
  spliceElementInTeachersMappingArray!: (start:number) => void;

  @classlink.Mutation
  spliceElementInSchoolYearsMappingArray!: (start:number) => void;

  @classlink.Mutation
  pushElementInSelectedOrgs!: (element:any) => void;

  @classlink.Mutation
  spliceElementInSelectedOrgs!: (start:number) => void;

  @classlink.Mutation
  pushElementInSelectedTeacher!: (element:any) => void;

  @classlink.Mutation
  spliceElementInSelectedTeacher!: (start:number) => void;

  @classlink.Mutation
  pushElementInSelectedSchoolYears!: (element:any) => void;

  @classlink.Mutation
  spliceElementInSelectedSchoolYears!: (start:number) => void;

  @classlink.Mutation
  pushElementInManageMappingOrgsSelected!: (element:any) => void;

  @classlink.Mutation
  spliceElementInManageMappingOrgsSelected!: (start:number) => void;

  @classlink.Mutation
  pushElementInConnectedTeachersArray!: (element:any) => void;

  @classlink.Mutation
  spliceElementInConnectedTeachersArray!: (start:number) => void;

  @classlink.Mutation
  pushElementInManageMappingTeachersSelected!: (element:any) => void;

  @classlink.Mutation
  spliceElementInManageMappingTeachersSelected!: (start:number) => void;

  @classlink.Mutation
  pushElementInManageMappingSchoolYearsArray!: (element:any) => void;

  @classlink.Mutation
  spliceElementInManageMappingSchoolYearsArray!: (start:number) => void;

  @classlink.Mutation
  pushElementInManageMappingSchoolYearsSelected!: (element:any) => void;

  @classlink.Mutation
  spliceElementInManageMappingSchoolYearsSelected!: (start:number) => void;

  $refs!: {
    integrationStep1Form: InstanceType<typeof VForm>;
    integrationStep2Form: InstanceType<typeof VForm>;
  };

  localRules = [(v: any) => !!v || 'Required'];

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getSettings')
  settings!: any;

  get syncStatusDate(): any {
    return this.$store.state.classlink.syncStatusDate;
  }

  set syncStatusDate(syncStatusDate: any) {
    this.$store.commit('classlink/setSyncStatusDate', syncStatusDate);
  }

  get syncStatusObject(): any {
    return this.$store.state.classlink.syncStatusObject;
  }

  set syncStatusObject(syncStatusObject: any) {
    this.$store.commit('classlink/setSyncStatusObject', syncStatusObject);
  }

  get isManageMappingOrgsNotDirty(): boolean {
    return this.$store.state.classlink.isManageMappingOrgsNotDirty;
  }

  set isManageMappingOrgsNotDirty(val: boolean) {
    this.$store.commit('classlink/setIsManageMappingOrgsNotDirty', val);
  }

  get isManageMappingSchoolYearsNotDirty(): boolean {
    return this.$store.state.classlink.isManageMappingSchoolYearsNotDirty;
  }

  set isManageMappingSchoolYearsNotDirty(val: boolean) {
    this.$store.commit('classlink/setIsManageMappingSchoolYearsNotDirty', val);
  }

  get isConnectedSettingsNotDirty(): boolean {
    return this.$store.state.classlink.isConnectedSettingsNotDirty;
  }

  set isConnectedSettingsNotDirty(val: boolean) {
    this.$store.commit('classlink/setIsConnectedSettingsNotDirty', val);
  }

  get isManageMappingTeachersNotDirty(): boolean {
    return this.$store.state.classlink.isManageMappingTeachersNotDirty;
  }

  set isManageMappingTeachersNotDirty(val: boolean) {
    this.$store.commit('classlink/setIsManageMappingTeachersNotDirty', val);
  }

  get isConnected(): boolean {
    return this.$store.state.classlink.isConnected;
  }

  set isConnected(val: boolean) {
    this.$store.commit('classlink/setIsConnected', val);
  }

  get isDisconnected(): boolean {
    return this.$store.state.classlink.isDisconnected;
  }

  set isDisconnected(val: boolean) {
    this.$store.commit('classlink/setIsDisconnected', val);
  }

  get connectedDomain() {
    const url = new URL(this.connectedEndPoint);
    return url.hostname;
  }

  get connectedEndPoint(): string {
    return this.$store.state.classlink.connectedEndPoint;
  }

  set connectedEndPoint(val: string) {
    this.$store.commit('classlink/setConnectedEndPoint', val);
  }

  get connectedAccessKey(): string {
    return this.$store.state.classlink.connectedAccessKey;
  }

  set connectedAccessKey(val: string) {
    this.$store.commit('classlink/setConnectedAccessKey', val);
  }

  get connectedSecretKey(): string {
    return this.$store.state.classlink.connectedSecretKey;
  }

  set connectedSecretKey(val: string) {
    this.$store.commit('classlink/setConnectedSecretKey', val);
  }

  get connectedSyncTime(): any {
    return this.$store.state.classlink.connectedSyncTime;
  }

  set connectedSyncTime(val: any) {
    this.$store.commit('classlink/setConnectedSyncTime', val);
  }

  get manageMappingOrgsArray():Array<any> {
    return this.getManageMappingOrgsArray;
  }

  set manageMappingOrgsArray(manageMappingOrgsArray:Array<any>) {
    this.$store.commit('classlink/setManageMappingOrgsArray', manageMappingOrgsArray);
  }

  get connectedTeachersArray():Array<any> {
    return this.getConnectedTeachersArray;
  }

  set connectedTeachersArray(connectedTeachersArray:Array<any>) {
    this.$store.commit('classlink/setConnectedTeachersArray', connectedTeachersArray);
  }

  get manageMappingSchoolYearsArray():Array<any> {
    return this.getManageMappingSchoolYearsArray;
  }

  set manageMappingSchoolYearsArray(manageMappingSchoolYearsArray:Array<any>) {
    this.$store.commit('classlink/setManageMappingSchoolYearsArray', manageMappingSchoolYearsArray);
  }

  get manageMappingOrgsSelected():Array<any> {
    return this.getManageMappingOrgsSelected;
  }

  set manageMappingOrgsSelected(manageMappingOrgsSelected:Array<any>) {
    this.$store.commit('classlink/setManageMappingOrgsSelected', manageMappingOrgsSelected);
  }

  get manageMappingTeachersSelected():Array<any> {
    return this.getManageMappingTeachersSelected;
  }

  set manageMappingTeachersSelected(manageMappingTeachersSelected:Array<any>) {
    this.$store.commit('classlink/setManageMappingTeachersSelected', manageMappingTeachersSelected);
  }

  get manageMappingSchoolYearsSelected():Array<any> {
    return this.getManageMappingSchoolYearsSelected;
  }

  set manageMappingSchoolYearsSelected(manageMappingSchoolYearsSelected:Array<any>) {
    this.$store.commit('classlink/setManageMappingSchoolYearsSelected', manageMappingSchoolYearsSelected);
  }

  get setUpEndPoint(): string {
    return this.$store.state.classlink.setUpEndPoint;
  }

  set setUpEndPoint(val: string) {
    this.$store.commit('classlink/setSetUpEndPoint', val);
  }

  get setUpAccessKey(): string {
    return this.$store.state.classlink.setUpAccessKey;
  }

  set setUpAccessKey(val: string) {
    this.$store.commit('classlink/setSetUpAccessKey', val);
  }

  get setUpSecretKey(): string {
    return this.$store.state.classlink.setUpSecretKey;
  }

  set setUpSecretKey(val: string) {
    this.$store.commit('classlink/setSetUpSecretKey', val);
  }

  get setUpSyncTime(): string {
    return this.$store.state.classlink.setUpSyncTime;
  }

  set setUpSyncTime(val: string) {
    this.$store.commit('classlink/setSetUpSyncTime', val);
  }

  get setupStepCount(): number {
    return this.$store.state.classlink.setupStepCount;
  }

  set setupStepCount(val: number) {
    this.$store.commit('classlink/setSetupStepCount', val);
  }

  get setupWizard(): boolean {
    return this.$store.state.classlink.setupWizard;
  }

  set setupWizard(val: boolean) {
    this.$store.commit('classlink/setSetupWizard', val);
  }

  get orgsMappingArray(): Array<any> {
    return this.getOrgsMappingArray.filter(org => {
      if (this.isOrgTableCompleteMatchHidden) {
        if (org.score === 100) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
  }

  set orgsMappingArray(orgsMappingArray: Array<any>) {
    this.$store.commit('classlink/setOrgsMappingArray', orgsMappingArray);
  }

  get teachersMappingArray(): Array<any> {
    return this.getTeachersMappingArray.filter(teacher => {
      if (this.isTeacherTableCompleteMatchHidden) {
        if (teacher.score === 100) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
  }

  set teachersMappingArray(teachersMappingArray: Array<any>) {
    this.$store.commit('classlink/setTeachersMappingArray', teachersMappingArray);
  }

  get schoolYearsMappingArray(): Array<any> {
    return this.getSchoolYearsMappingArray.filter(schoolYear => {
      if (this.isSchoolYearWithoutClassesHidden) {
        if (!schoolYear.withClasses) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    });
  }

  set schoolYearsMappingArray(schoolYearsMappingArray: Array<any>) {
    this.$store.commit('classlink/setSchoolYearsMappingArray', schoolYearsMappingArray);
  }

  get selectedOrgs(): Array<any> {
    return this.getSelectedOrgs;
  }

  set selectedOrgs(selectedOrgs: Array<any>) {
    this.$store.commit('classlink/setSelectedOrgs', selectedOrgs);
  }

  get selectedTeachers(): Array<any> {
    return this.getSelectedTeachers;
  }

  set selectedTeachers(selectedTeachers: Array<any>) {
    this.$store.commit('classlink/setSelectedTeacher', selectedTeachers);
  }

  get selectedSchoolYears(): Array<any> {
    return this.getSelectedSchoolYears;
  }

  set selectedSchoolYears(selectedSchoolYears: Array<any>) {
    this.$store.commit('classlink/setSelectedSchoolYears', selectedSchoolYears);
  }

  get mainOrgJsonObject(): any {
    return this.getMainOrgJsonObject;
  }

  get orgsSourcedIdArray():Array<any> {
    if (this.setupWizard) {
      return this.orgsMappingArray.map(org => org.sourcedId);
    } else {
      return this.manageMappingOrgsArray.map((org:any) => org.sourcedId);
    }
  }

  get isSystemLock():boolean {
    return this.mainOrgJsonObject.systemLock;
  }

  get styles() {
    return `
      <style>
        .v-application, body {
          font-family: Raleway;
        }
        .arrow-right {
          border-left: 17px solid ${this.darkMode ? '#1e1e1e' : 'white'};
        }
        .arrow-down {
          border-top: 17px solid ${this.darkMode ? '#1e1e1e' : 'white'};
        }
        .arrow-up {
          border-bottom: 17px solid ${this.darkMode ? '#1e1e1e' : 'white'};
        }
      </style>
    `;
  }

  get darkMode(): boolean {
    return this.$vuetify.theme.dark;
  }

  get isEmbedded(): boolean {
    return (CommonUtils.hasText(this.$router.currentRoute.query.embedded) && this.$router.currentRoute.query.embedded === 'true');
  }

  get totalTeachersAdded() {
    let ctr = 0;
    if (this.setupWizard) {
      this.teachersMappingArray.forEach((element:any) => {
        if (element.setUpPBTeacherName === 'add') {
          ctr++;
        }
      });
      return ctr;
    } else {
      this.connectedTeachersArray.forEach((element:any) => {
        if (element.manageMappingPBTeacherName === 'add') {
          ctr++;
        }
      });
      return ctr;
    }
  }

  get getWalkthrough1Left() {
    if (this.isEmbedded) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '7%'
        case 'sm': return '65%'
        case 'md': return '65%'
        case 'lg': return '65%'
        case 'xl': return '65%'
      }
    } else {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '25%'
        case 'sm': return '71%'
        case 'md': return '71%'
        case 'lg': return '71%'
        case 'xl': return '71%'
      }
    }
  }

  get getWalkthrough1Top() {
    if (this.isEmbedded) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '13%'
        case 'sm': return '8%'
        case 'md': return '9%'
        case 'lg': return '9%'
        case 'xl': return '8%'
      }
    } else {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '16%'
        case 'sm': return '8%'
        case 'md': return '10%'
        case 'lg': return '10%'
        case 'xl': return '10%'
      }
    }
  }

  get getWalkthrough2Left() {
    if (this.isEmbedded) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '63%'
        case 'sm': return '14%'
        case 'md': return '13%'
        case 'lg': return '9%'
        case 'xl': return '8.5%'
      }
    } else {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '40%'
        case 'sm': return '5%'
        case 'md': return '3%'
        case 'lg': return '4%'
        case 'xl': return '4%'
      }
    }
  }

  get getWalkthrough2Top() {
    if (this.isEmbedded) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '42%'
        case 'sm': return '48%'
        case 'md': return '46%'
        case 'lg': return '48%'
        case 'xl': return '45%'
      }
    } else {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '31%'
        case 'sm': return '32%'
        case 'md': return '38%'
        case 'lg': return '38%'
        case 'xl': return '38%'
      }
    }
  }

  get getWalkthrough3Left() {
    if (this.isEmbedded) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '30%'
        case 'sm': return '40%'
        case 'md': return '43%'
        case 'lg': return '46%'
        case 'xl': return '43%'
      }
    } else {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '47%'
        case 'sm': return '41%'
        case 'md': return '47%'
        case 'lg': return '47%'
        case 'xl': return '47%'
      }
    }
  }

  get getWalkthrough3Bottom() {
    if (this.isEmbedded) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '10%'
        case 'sm': return '15%'
        case 'md': return '15%'
        case 'lg': return '14%'
        case 'xl': return '13%'
      }
    } else {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '4%'
        case 'sm': return '17%'
        case 'md': return '14%'
        case 'lg': return '13%'
        case 'xl': return '15%'
      }
    }
  }

  get getWalkthrough4Right() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '4%'
      case 'sm': return '13%'
      case 'md': return '22%'
      case 'lg': return '22%'
      case 'xl': return '22%'
    }
  }

  get getWalkthrough4Top() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '40%'
      case 'sm': return '40%'
      case 'md': return '23%'
      case 'lg': return '23%'
      case 'xl': return '23%'
    }
  }

  get getWalkthrough5Right() {
    if (this.isEmbedded) {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '6%'
        case 'sm': return '5%'
        case 'md': return '5%'
        case 'lg': return '4%'
        case 'xl': return '4%'
      }
    } else {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return '5%'
        case 'sm': return '5%'
        case 'md': return '5%'
        case 'lg': return '5%'
        case 'xl': return '5%'
      }
    }
  }

  get stepperHeight() {
    return this.browserHeight - 170;
  }

  get isAllowedToSetUp() {
    if (this.settings.schoolSettings.districtId !== 0) {
      if (this.userType === 'D' || this.schools.length > 1) {
        return true;
      } else {
        return false;
      }
    } else {
      if (this.userMode === 'A') {
        return true;
      } else {
        return false;
      }
    }
  }

  get orgsPreviousSavedValue() {
    return this.orgsPreviousValue;
  }

  get formatToDisplay() {
    return DateTimeUtils.formatToDisplay;
  }

  get totalSchoolYearsToBeAdded():number {
    let ctr = 0;
    if (this.setupWizard) {
      this.schoolYearsMappingArray.forEach((element:any) => {
        if (element.setUpPBSchoolYear === 'add') {
          ctr++;
        }
      });
    } else {
      this.manageMappingSchoolYearsArray.forEach((element:any) => {
        if (element.manageMappingPBSchoolYearName === 'add') {
          ctr++;
        }
      });
    }
    return ctr;
  }

  get getAllActiveOrgsSourcedId():Array<any> {
    if (this.setupWizard) {
      return this.orgsMappingArray.filter(org => this.isActive(org.status)).map(org => org.sourcedId);
    } else {
      return this.manageMappingOrgsArray.filter(org => this.isActive(org.status)).map(org => org.sourcedId);
    }
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get isNotEmpty() {
    return CommonUtils.isNotEmpty;
  }

  get hasValue() {
    return CommonUtils.hasValue;
  }

  get getClasslinkListItems(): (item:any) => Array<any> {
    return (item:any) => {
      const listItems:Array<any> = [];
      if (item.duplicate) {
        this.allMappingTeachers.forEach(user => {
          if (user.email === item.email) {
            listItems.push({
              displayValue: (user.email || user.username) + ' (' + user.sourcedId + ')',
              value: {
                teacherEmail: user.email,
                sourcedId: user.sourcedId
              },
              disabled: CommonUtils.isFalse(this.isActive(user.status))
            });
          }
        })
      }
      return listItems;
    }
  }

  get getAllConnectedOrSetupTeachersSourcedIds():Array<string> {
    if (this.setupWizard) {
      return this.teachersMappingArray.map(teacher => teacher.sourcedId);
    } else {
      return this.connectedTeachersArray.map(teacher => teacher.sourcedId);
    }
  }

  get getAllConnectedOrSetupSelectedTeachersSourcedIds(): Array<string> {
    if (this.setupWizard) {
      return this.selectedTeachers.map(teacher => teacher.sourcedId);
    } else {
      return this.manageMappingTeachersSelected.map(teacher => teacher.sourcedId);
    }
  }

  @Watch('confirmationModal')
  updateTotalTeachersAdded(v: boolean) {
    if (v) {
      let ctr = 0;
      if (this.setupWizard) {
        this.teachersMappingArray.forEach((element:any) => {
          if (element.setUpPBTeacherName === 'add') {
            ctr++;
          }
        });
        this.totalTeachersAddedCounter = ctr;
      } else {
        this.connectedTeachersArray.forEach((element:any) => {
          if (element.manageMappingPBTeacherName === 'add') {
            ctr++;
          }
        });
        this.totalTeachersAddedCounter = ctr;
      }
    }
  }

  @Watch('manageMappingOrgsSelected')
  checkBoxChangeValue() {
    if (this.isOrgsCheckBoxTrueorFalse) {
      this.showHiddenTeacherForCheckbox();
      this.showHiddenSchoolYearForCheckbox();
    } else {
      this.hideHiddenTeacherForCheckbox();
      this.hideHiddenSchoolYearForCheckbox();
    }
  }

  @Watch('isSyncCompleted')
  syncCompleted() {
    console.log('called' + this.isSyncCompleted);
    if (this.isSyncCompleted) {
      CommonUtils.showLoading();
      this.$store.commit('integration/clearRosteredData', {});
      this.getAllRosteredItems();
      this.reloadSettings({ reloadSchools: true })
        .then(() => {
          return this.loadTeachers();
        })
        .then(() => {
          return this.loadGroupAccountSchoolYears()
        }).then(() => {
          return this.initialize();
        })
    }
  }

  async removeSchoolYearsWithoutOrgSourcedId() {
    const that = this;
    const schoolYearToRemoveIndex:any = [];

    if (this.setupWizard) {
      that.schoolYearsMappingArray.forEach((element:any, index:number) => {
        if (CommonUtils.hasNoText(element.orgSourcedId)) {
          schoolYearToRemoveIndex.push(index);
        }
      });

      schoolYearToRemoveIndex.reverse();

      if (schoolYearToRemoveIndex.length > 0) {
        schoolYearToRemoveIndex.forEach((indexOfSchoolYear:number) => {
          that.spliceElementInSchoolYearsMappingArray(indexOfSchoolYear);
        });
      }
    } else {
      that.manageMappingSchoolYearsArray.forEach((element:any, index:number) => {
        if (CommonUtils.hasNoText(element.orgSourcedId)) {
          schoolYearToRemoveIndex.push(index);
        }
      });

      schoolYearToRemoveIndex.reverse();

      if (schoolYearToRemoveIndex.length > 0) {
        schoolYearToRemoveIndex.forEach((indexOfSchoolYear:number) => {
          that.spliceElementInManageMappingSchoolYearsArray(indexOfSchoolYear);
        });
      }
    }

    Promise.resolve();
  }

  isItemNotAddorDoNothing(item:any) {
    if (item !== 'do-nothing' || item !== 'add') {
      return true;
    } else {
      return false;
    }
  }

  orgsPreviousSavedValueSetter(val: any) {
    if (val !== null) {
      this.orgsPreviousValue = val;
    }
  }

  teachersPreviousSavedValueSetter(val: any) {
    if (val !== null) {
      this.teachersPreviousValue = val;
    }
  }

  schoolYearsPreviousSavedValueSetter(val: any) {
    if (val !== null) {
      this.schoolYearsPreviousValue = val;
    }
  }

  cancelSetUp() {
    this.setupWizard = false;
    this.refresh();
  }

  openConfirmationModal() {
    let ctr = 0;
    this.teachersMappingArray.forEach((element:any) => {
      if (element.setUpPBTeacherName === 'add') {
        ctr++;
      }
    });
    if (ctr > 0 || this.totalSchoolYearsToBeAdded > 0) {
      this.confirmationModal = true;
    } else {
      this.finishSetUporSaveTeacher();
    }
  }

  finishSetUporSaveTeacher(willDisconnect?:boolean) {
    CommonUtils.showLoading();
    const that = this;

    let tempDistrictId = 0;
    let tempSchoolId = 0;
    if (that.userType === 'D') {
      tempSchoolId = 0;
      tempDistrictId = this.settings.schoolSettings.districtId;
    } else {
      tempDistrictId = 0;
      tempSchoolId = this.settings.schoolSettings.schoolId;
    }

    if (this.setupWizard) {
      const tempTeacherArray = ld.cloneDeep(this.teachersMappingArray);
      this.teachersMappingArray = [];
      this.teachersMappingArray = tempTeacherArray.concat(this.hiddenTeacherForCheckbox);

      const tempSchoolYearArray = ld.cloneDeep(this.schoolYearsMappingArray);
      this.schoolYearsMappingArray = [];
      this.schoolYearsMappingArray = tempSchoolYearArray.concat(this.hiddenSchoolYearForCheckbox);

      const url = new URL(this.setUpEndPoint);
      const domain = url.hostname;
      let authorId: any = null;
      if (that.userType === 'D') {
        authorId = that.settings.schoolSettings.districtId;
      } else {
        authorId = that.settings.schoolSettings.schoolId;
      }
      this.refOrgs({
        selectedOrgs: that.selectedOrgs,
        endpoint: that.setUpEndPoint,
        accessKey: that.setUpAccessKey,
        secretKey: that.setUpSecretKey,
        authorId: authorId,
        orgs: that.orgsMappingArray,
        districtId: that.settings.schoolSettings.districtId,
        syncTime: that.setUpSyncTime
      }).then(() => {
        this.refUsers({
          selectedTeachers: that.selectedTeachers,
          teachers: that.teachersMappingArray,
          endpoint: this.setUpEndPoint,
          domain: domain,
          unselectedDuplicateTeachers: this.allMappingTeachers.filter(teacher => teacher.duplicate && CommonUtils.isFalse(this.getAllConnectedOrSetupTeachersSourcedIds.includes(teacher.sourcedId)))
        }).then(() => {
          this.refAcademicSessions({
            gradingPeriodId: that.settings.accountSettings.currentGradingPeriodId,
            schoolId: tempSchoolId,
            districtId: tempDistrictId,
            selectedSchoolYears: that.selectedSchoolYears,
            schoolYears: that.schoolYearsMappingArray,
            endpoint: this.setUpEndPoint,
            domain: domain
          }).then(() => {
            this.initialize();
          });
        });
      });
    } else {
      this.updateRefUser({
        selectedUsers: this.manageMappingTeachersSelected,
        teachers: this.connectedTeachersArray,
        domain: this.connectedDomain,
        unselectedDuplicateTeachers: this.allMappingTeachers.filter(teacher => teacher.duplicate && CommonUtils.isFalse(this.getAllConnectedOrSetupTeachersSourcedIds.includes(teacher.sourcedId)))
      }).then(() => {
        if (!willDisconnect) {
          CommonUtils.hideLoading();
          const tempHiddenTeacherForCheckbox = that.hiddenTeacherForCheckbox;
          const tempHiddenSelectedTeacherForCheckbox = that.hiddenSelectedTeacherForCheckbox;
          const tempHiddenSchoolYearForCheckbox = that.hiddenSchoolYearForCheckbox;
          const tempHiddenSelectedSchoolYearForCheckbox = that.hiddenSelectedSchoolYearForCheckbox;
          that.refresh();
          that.hiddenTeacherForCheckbox = tempHiddenTeacherForCheckbox;
          that.hiddenSelectedTeacherForCheckbox = tempHiddenSelectedTeacherForCheckbox;
          that.hiddenSelectedSchoolYearForCheckbox = tempHiddenSelectedSchoolYearForCheckbox;
          that.hiddenSchoolYearForCheckbox = tempHiddenSchoolYearForCheckbox;
        }
      });
    }
  }

  disconnectClassLink() {
    CommonUtils.showLoading();
    const that = this;
    this.manageMappingOrgsSelected = [];
    this.manageMappingOrgsArray.forEach((element:any) => {
      element.dropDownDisabled = true;
      if (element.manageMappingPBSchoolName === 'add') {
        element.manageMappingPBSchoolName = 'do-nothing';
      }
    });
    this.setupWizard = false;
    // this.isConnected = false;
    this.isDisconnected = true;
    this.setupStepCount = 1;
    that.connectedOrgMappingSave(false, true);
    this.snackBar = true;
    this.snackBarText = this.$t('classLinkDisconnect');
  }

  reconnectClassLink() {
    const that = this;
    CommonUtils.showLoading();
    this.showHiddenTeacherForCheckbox(true);
    this.showHiddenSchoolYearForCheckbox(true);

    that.manageMappingOrgsArray.forEach((element:any) => {
      if (element.manageMappingPBSchoolName === 'add') {
        element.manageMappingPBSchoolName = 'do-nothing';
        const found = that.manageMappingOrgsSelected.some((e:any) => e.sourcedId === element.sourcedId);
        if (found) {
          that.spliceElementInManageMappingOrgsSelected(element);
        }
      }
      if (element.manageMappingPBSchoolName !== 'add' && element.manageMappingPBSchoolName !== 'do-nothing') {
        const found = that.manageMappingOrgsSelected.some((e:any) => e.sourcedId === element.sourcedId);
        if (!found) {
          element.dropDownDisabled = false;
          that.pushElementInManageMappingOrgsSelected(element);
        }
      }
      if (element.type === 'district') {
        const found = that.manageMappingOrgsSelected.some((e:any) => e.sourcedId === element.sourcedId);
        if (!found) {
          that.pushElementInManageMappingOrgsSelected(element);
        }
      }
    });
    that.connectedTeachersArray.forEach((element:any) => {
      if (element.manageMappingPBTeacherName !== 'add' && element.manageMappingPBTeacherName !== 'do-nothing') {
        const found = that.manageMappingTeachersSelected.some((e:any) => e.sourcedId === element.sourcedId);
        if (!found) {
          element.dropDownDisabled = false;
          that.pushElementInManageMappingTeachersSelected(element);
        }
      }
    });

    that.manageMappingSchoolYearsArray.forEach((element:any) => {
      if (element.manageMappingPBSchoolYearName !== 'add' && element.manageMappingPBSchoolYearName !== 'do-nothing') {
        const found = that.manageMappingSchoolYearsSelected.some((e:any) => e.sourcedId === element.sourcedId);
        if (!found) {
          element.dropDownDisabled = false;
          that.pushElementInManageMappingSchoolYearsSelected(element);
        }
      }
    });
    this.setupWizard = false;
    this.isConnected = true;
    this.isDisconnected = false;
    this.setupStepCount = 1;
    this.connectedOrgMappingSave(true, false);
    this.snackBar = true;
    this.snackBarText = this.$t('reconnectClassLinkLabel');
  }

  syncNow() {
    this.tab = 5;
    this.isSyncDone = false;
    this.isSyncCompleted = false;
    this.syncStatusObject = {
      orgs: 1,
      sessions: 1,
      users: 1,
      enrollments: 1,
      lastSyncDate: ''
    };
    CommonUtils.showLoading();
    this.sync(this.mainOrgJsonObject).then((resp: any) => {
      CommonUtils.hideLoading();
      console.log('sync Now - > ' + resp);
      const url = new URL(this.connectedEndPoint);
      const domain = url.hostname;
      return IntegrationServices.getSyncStatus(
        {
          orgSourcedId: this.mainOrgJsonObject.sourcedId,
          domain: domain
        },
        (resp: any) => {
          this.getSyncCallBack(resp);
        }
      );
    });
  }

  getSyncStatus() {
    const url = new URL(this.connectedEndPoint);
    const domain = url.hostname;
    IntegrationServices.getSyncStatus(
      {
        orgSourcedId: this.mainOrgJsonObject.sourcedId,
        domain: domain
      },
      (resp: any) => {
        this.getSyncCallBack(resp);
      }
    );
  }

  getSyncCallBack(resp: any) {
    const data = resp.data;
    this.syncStatusObject = data.status;
    this.syncStatusDate = data.lastSync;
    if (Object.keys(data.lastSync).length !== 0) {
      this.syncStatusDate = {
        orgs: moment.utc(this.syncStatusDate.orgs).local().format('llll'),
        users: moment.utc(this.syncStatusDate.users).local().format('llll'),
        sessions: moment.utc(this.syncStatusDate.sessions).local().format('llll'),
        enrollments: moment.utc(this.syncStatusDate.enrollments).local().format('llll')
      }
    }
    this.syncDoneCheck();
    if (
      this.syncStatusObject.enrollments === 2 &&
      this.syncStatusObject.orgs === 2 &&
      this.syncStatusObject.users === 2 &&
      this.syncStatusObject.sessions === 2 &&
      !this.isSyncCompleted &&
      !this.pageInitializing
    ) {
      this.isSyncCompleted = true;
    }
  }

  syncDoneCheck() {
    if (
      this.syncStatusObject.enrollments === 1 ||
      this.syncStatusObject.orgs === 1 ||
      this.syncStatusObject.users === 1 ||
      this.syncStatusObject.sessions === 1
    ) {
      this.isSyncDone = false;
    } else {
      this.isSyncDone = true;
    }
  }

  showHiddenTeacherForCheckbox(showAll?:boolean) {
    const that = this;
    if (this.hiddenTeacherForCheckbox.length > 0) {
      const indexToRemove:any = []
      this.hiddenTeacherForCheckbox.forEach((element:any, index:number) => {
        if (showAll) {
          const found = this.connectedTeachersArray.some((e:any) => e.sourcedId === element.sourcedId);
          if (!found) {
            element.manageMappingPBTeacherName = element.tempmanageMappingPBTeacherName;
            element.isRowSelectable = element.tempisRowSelectable;
            element.dropDownDisabled = element.tempdropDownDisabled;
            indexToRemove.push(index);
            if (that.getAllActiveOrgsSourcedId.includes(element.orgSourcedId)) {
              that.pushElementInConnectedTeachersArray(element);
            }
          }
        } else if (element.orgSourcedId === this.uncheckedAndCheckedOrgSourcedId) {
          const found = this.connectedTeachersArray.some((e:any) => e.sourcedId === element.sourcedId);
          if (!found) {
            element.manageMappingPBTeacherName = element.tempmanageMappingPBTeacherName;
            element.isRowSelectable = element.tempisRowSelectable;
            element.dropDownDisabled = element.tempdropDownDisabled;
            indexToRemove.push(index);
            that.pushElementInConnectedTeachersArray(element);
          }
        }
      });
      // indexToRemove.sort();
      indexToRemove.reverse();
      indexToRemove.forEach((index:any) => {
        that.hiddenTeacherForCheckbox.splice(index, 1);
      });
    }
    if (this.hiddenSelectedTeacherForCheckbox.length > 0) {
      const indexToRemove:any = []
      this.hiddenSelectedTeacherForCheckbox.forEach((element:any, index:number) => {
        if (showAll) {
          const found = that.manageMappingTeachersSelected.some((e:any) => e.sourcedId === element.sourcedId);
          if (!found) {
            element.manageMappingPBTeacherName = element.tempSelectedmanageMappingPBTeacherName;
            element.isRowSelectable = element.tempSelectedisRowSelectable;
            element.dropDownDisabled = element.tempdropDownDisabled;
            indexToRemove.push(index);
            if (this.isActive(element.status)) {
              that.pushElementInManageMappingTeachersSelected(element);
            }
          }
        } else if (element.orgSourcedId === this.uncheckedAndCheckedOrgSourcedId) {
          const found = that.manageMappingTeachersSelected.some((e:any) => e.sourcedId === element.sourcedId);
          if (!found) {
            element.manageMappingPBTeacherName = element.tempSelectedmanageMappingPBTeacherName;
            element.isRowSelectable = element.tempSelectedisRowSelectable;
            element.dropDownDisabled = element.tempdropDownDisabled;
            indexToRemove.push(index);
            if (this.isActive(element.status)) {
              that.pushElementInManageMappingTeachersSelected(element);
            }
          }
        }
      });
      // indexToRemove.sort();
      indexToRemove.reverse();
      indexToRemove.forEach((index:any) => {
        that.hiddenSelectedTeacherForCheckbox.splice(index, 1);
      });
    }
  }

  showHiddenSchoolYearForCheckbox(showAll?:boolean) {
    const that = this;
    if (this.hiddenSchoolYearForCheckbox.length > 0) {
      const indexToRemove:any = []
      this.hiddenSchoolYearForCheckbox.forEach((element:any, index:number) => {
        if (showAll && element.orgSourcedId !== '') {
          const found = this.manageMappingSchoolYearsArray.some((e:any) => e.sourcedId === element.sourcedId);
          if (!found) {
            element.manageMappingPBSchoolYearName = element.tempmanageMappingPBSchoolYearName;
            element.isRowSelectable = element.tempisRowSelectable;
            element.dropDownDisabled = element.tempdropDownDisabled;
            indexToRemove.push(index);
            if (that.getAllActiveOrgsSourcedId.includes(element.orgSourcedId)) {
              that.pushElementInManageMappingSchoolYearsArray(element);
            }
          }
        } else if (element.orgSourcedId === this.uncheckedAndCheckedOrgSourcedId) {
          const found = this.manageMappingSchoolYearsArray.some((e:any) => e.sourcedId === element.sourcedId);
          if (!found) {
            element.manageMappingPBSchoolYearName = element.tempmanageMappingPBSchoolYearName;
            element.isRowSelectable = element.tempisRowSelectable;
            element.dropDownDisabled = element.tempdropDownDisabled;
            indexToRemove.push(index);
            that.pushElementInManageMappingSchoolYearsArray(element);
          }
        }
      });
      // indexToRemove.sort();
      indexToRemove.reverse();
      indexToRemove.forEach((index:any) => {
        that.hiddenSchoolYearForCheckbox.splice(index, 1);
      });
    }
    if (this.hiddenSelectedSchoolYearForCheckbox.length > 0) {
      const indexToRemove:any = []
      this.hiddenSelectedSchoolYearForCheckbox.forEach((element:any, index:number) => {
        if (showAll && element.orgSourcedId !== '') {
          const found = that.manageMappingSchoolYearsSelected.some((e:any) => e.sourcedId === element.sourcedId);
          if (!found) {
            element.manageMappingPBSchoolYearName = element.tempSelectedmanageMappingPBSchoolYearName;
            element.isRowSelectable = element.tempSelectedisRowSelectable;
            element.dropDownDisabled = element.tempdropDownDisabled;
            indexToRemove.push(index);
            that.pushElementInManageMappingSchoolYearsSelected(element);
          }
        } else if (element.orgSourcedId === this.uncheckedAndCheckedOrgSourcedId) {
          const found = that.manageMappingSchoolYearsSelected.some((e:any) => e.sourcedId === element.sourcedId);
          if (!found) {
            element.manageMappingPBSchoolYearName = element.tempSelectedmanageMappingPBSchoolYearName;
            element.isRowSelectable = element.tempSelectedisRowSelectable;
            element.dropDownDisabled = element.tempdropDownDisabled;
            indexToRemove.push(index);
            that.pushElementInManageMappingSchoolYearsSelected(element);
          }
        }
      });
      // indexToRemove.sort();
      indexToRemove.reverse();
      indexToRemove.forEach((index:any) => {
        that.hiddenSelectedSchoolYearForCheckbox.splice(index, 1);
      });
    }
  }

  hideHiddenSchoolYearForCheckbox(hideAll?:boolean) {
    const that = this;
    const schoolYearIndex:any = [];
    const schoolYearIndexinSelectedArrays:any = [];
    this.manageMappingSchoolYearsArray.forEach((e:any, index:number) => {
      if (hideAll && e.orgSourcedId !== '') {
        schoolYearIndex.push(index);
      } else if (e.orgSourcedId === this.uncheckedAndCheckedOrgSourcedId) {
        schoolYearIndex.push(index);
      }
    });
    this.manageMappingSchoolYearsSelected.forEach((e:any, index:number) => {
      if (hideAll && e.orgSourcedId !== '') {
        schoolYearIndexinSelectedArrays.push(index);
      } else if (e.orgSourcedId === this.uncheckedAndCheckedOrgSourcedId) {
        schoolYearIndexinSelectedArrays.push(index);
      }
    });
    schoolYearIndex.reverse();
    schoolYearIndexinSelectedArrays.reverse();
    if (schoolYearIndex.length > 0) {
      schoolYearIndex.forEach((index:number) => {
        try {
          const manageMappingPBSchoolYearName = that.manageMappingSchoolYearsArray[index].manageMappingPBSchoolYearName;
          that.manageMappingSchoolYearsArray[index].tempmanageMappingPBSchoolYearName = manageMappingPBSchoolYearName;
          that.manageMappingSchoolYearsArray[index].tempisRowSelectable = that.manageMappingSchoolYearsArray[index].isRowSelectable;
          that.manageMappingSchoolYearsArray[index].tempdropDownDisabled = that.manageMappingSchoolYearsArray[index].dropDownDisabled;
          if (manageMappingPBSchoolYearName === 'add') {
            that.manageMappingSchoolYearsArray[index].manageMappingPBSchoolYearName = 'do-nothing';
          }
          const found = that.hiddenSchoolYearForCheckbox.some((e:any) => e.sourcedId === that.manageMappingSchoolYearsArray[index].sourcedId);
          if (!found) {
            that.hiddenSchoolYearForCheckbox.push(that.manageMappingSchoolYearsArray[index]);
            that.spliceElementInManageMappingSchoolYearsArray(index);
          }
        } catch (e:any) {
          console.log(e);
        }
      });
    }
    if (schoolYearIndexinSelectedArrays.length > 0) {
      schoolYearIndexinSelectedArrays.forEach((index:number) => {
        try {
          const manageMappingPBSchoolYearName = that.manageMappingSchoolYearsSelected[index].manageMappingPBSchoolYearName;
          that.manageMappingSchoolYearsSelected[index].tempSelectedmanageMappingPBSchoolYearName = that.manageMappingSchoolYearsSelected[index].tempmanageMappingPBSchoolYearName;
          that.manageMappingSchoolYearsSelected[index].tempSelectedisRowSelectable = that.manageMappingSchoolYearsSelected[index].isRowSelectable;
          that.manageMappingSchoolYearsSelected[index].tempSelecteddropDownDisabled = that.manageMappingSchoolYearsSelected[index].dropDownDisabled;
          if (manageMappingPBSchoolYearName === 'add') {
            that.manageMappingSchoolYearsSelected[index].manageMappingPBSchoolYearName = 'do-nothing';
          }
          const found = that.hiddenSelectedSchoolYearForCheckbox.some((e:any) => e.sourcedId === that.manageMappingSchoolYearsSelected[index].sourcedId);
          if (!found) {
            that.hiddenSelectedSchoolYearForCheckbox.push(that.manageMappingSchoolYearsSelected[index]);
            that.spliceElementInManageMappingSchoolYearsSelected(index);
          }
        } catch (e:any) {
          console.log(e);
        }
      });
    }
  }

  hideHiddenTeacherForCheckbox(hideAll?:boolean) {
    const that = this;
    const teaherIndex:any = [];
    const teaherIndexinSelectedArrays:any = [];
    this.connectedTeachersArray.forEach((e:any, index:number) => {
      if (hideAll) {
        teaherIndex.push(index);
      } else if (e.orgSourcedId === this.uncheckedAndCheckedOrgSourcedId) {
        teaherIndex.push(index);
      }
    });
    this.manageMappingTeachersSelected.forEach((e:any, index:number) => {
      if (hideAll) {
        teaherIndexinSelectedArrays.push(index);
      } else if (e.orgSourcedId === this.uncheckedAndCheckedOrgSourcedId) {
        teaherIndexinSelectedArrays.push(index);
      }
    });
    // teaherIndex.sort();
    teaherIndex.reverse();
    // teaherIndexinSelectedArrays.sort();
    teaherIndexinSelectedArrays.reverse();
    if (teaherIndex.length > 0) {
      teaherIndex.forEach((index:number) => {
        const manageMappingPBTeacherName = that.connectedTeachersArray[index].manageMappingPBTeacherName;
        that.connectedTeachersArray[index].tempmanageMappingPBTeacherName = manageMappingPBTeacherName;
        that.connectedTeachersArray[index].tempisRowSelectable = that.connectedTeachersArray[index].isRowSelectable;
        that.connectedTeachersArray[index].tempdropDownDisabled = that.connectedTeachersArray[index].dropDownDisabled;
        if (manageMappingPBTeacherName === 'add') {
          that.connectedTeachersArray[index].manageMappingPBTeacherName = 'do-nothing';
        }
        const found = that.hiddenTeacherForCheckbox.some((e:any) => e.sourcedId === that.connectedTeachersArray[index].sourcedId);
        if (!found) {
          that.hiddenTeacherForCheckbox.push(that.connectedTeachersArray[index]);
          that.spliceElementInConnectedTeachersArray(index);
        }
      });
    }
    if (teaherIndexinSelectedArrays.length > 0) {
      teaherIndexinSelectedArrays.forEach((index:number) => {
        const manageMappingPBTeacherName = that.manageMappingTeachersSelected[index].manageMappingPBTeacherName;
        that.manageMappingTeachersSelected[index].tempSelectedmanageMappingPBTeacherName = that.manageMappingTeachersSelected[index].tempmanageMappingPBTeacherName;
        that.manageMappingTeachersSelected[index].tempSelectedisRowSelectable = that.manageMappingTeachersSelected[index].isRowSelectable;
        that.manageMappingTeachersSelected[index].tempSelecteddropDownDisabled = that.manageMappingTeachersSelected[index].dropDownDisabled;
        if (manageMappingPBTeacherName === 'add') {
          that.manageMappingTeachersSelected[index].manageMappingPBTeacherName = 'do-nothing';
        }
        const found = that.hiddenSelectedTeacherForCheckbox.some((e:any) => e.sourcedId === that.manageMappingTeachersSelected[index].sourcedId);
        if (!found) {
          that.hiddenSelectedTeacherForCheckbox.push(that.manageMappingTeachersSelected[index]);
          that.spliceElementInManageMappingTeachersSelected(index);
        }
      });
    }
  }

  hideSchoolYearWithoutClasses() {
    let loopHere = [];
    if (!this.setupWizard) {
      loopHere = ld.cloneDeep(this.manageMappingSchoolYearsArray);
    }

    this.isSchoolYearWithoutClassesHidden = true;

    const tempArrayForSchoolYearsArray:any[] = [];

    const tempHiddenArray:any[] = [];

    loopHere.forEach((element:any) => {
      if (!element.withClasses) {
        tempHiddenArray.push(element);
      } else {
        tempArrayForSchoolYearsArray.push(element);
      }
    });

    this.hiddenSchoolYearWithoutClasses = tempHiddenArray;

    if (!this.setupWizard) {
      this.manageMappingSchoolYearsArray = tempArrayForSchoolYearsArray;
    }
  }

  showSchoolYearWithoutClasses() {
    this.isSchoolYearWithoutClassesHidden = false;

    let tempSchoolYearWithoutClassesArray = [];

    if (!this.setupWizard) {
      tempSchoolYearWithoutClassesArray = ld.cloneDeep(this.manageMappingSchoolYearsArray);
      this.manageMappingSchoolYearsArray = [];
      this.manageMappingSchoolYearsArray = tempSchoolYearWithoutClassesArray.concat(this.hiddenSchoolYearWithoutClasses);
    }

    this.hiddenSchoolYearWithoutClasses = [];
  }

  hideCompleteMatchOrgsSetup() {
    this.isOrgTableCompleteMatchHidden = true;
  }

  showCompleteMatchOrgsSetup() {
    this.isOrgTableCompleteMatchHidden = false;
  }

  hideCompleteMatchTeacherSetup() {
    this.isTeacherTableCompleteMatchHidden = true;
  }

  showCompleteMatchTeacherSetup() {
    this.isTeacherTableCompleteMatchHidden = false;
  }

  connectedSettingsSave() {
    CommonUtils.showLoading();
    const that = this;
    let authorId: any = null;
    if (this.userType === 'D') {
      authorId = this.settings.schoolSettings.districtId;
    } else {
      authorId = this.settings.schoolSettings.schoolId;
    }
    const url = new URL(this.connectedEndPoint);
    this.changeRefOrgSettings({
      endpoint: this.connectedEndPoint,
      accessKey: this.connectedAccessKey,
      secretKey: this.connectedSecretKey,
      syncTime: typeof this.connectedSyncTime === 'object' ? this.connectedSyncTime.timeValue : this.connectedSyncTime,
      authorId: authorId,
      domain: url.hostname,
      oldDomain: this.mainOrgJsonObject.domain
    }).then((result: any) => {
      console.log(result);
      CommonUtils.hideLoading();
      const tempHiddenTeacherForCheckbox = that.hiddenTeacherForCheckbox;
      const tempHiddenSelectedTeacherForCheckbox = that.hiddenSelectedTeacherForCheckbox;
      const tempHiddenSchoolYearForCheckbox = that.hiddenSchoolYearForCheckbox;
      const tempHiddenSelectedSchoolYearForCheckbox = that.hiddenSelectedSchoolYearForCheckbox;
      that.hiddenTeacherForCheckbox = tempHiddenTeacherForCheckbox;
      that.hiddenSelectedTeacherForCheckbox = tempHiddenSelectedTeacherForCheckbox;
      that.hiddenSelectedSchoolYearForCheckbox = tempHiddenSelectedSchoolYearForCheckbox;
      that.hiddenSchoolYearForCheckbox = tempHiddenSchoolYearForCheckbox;
      that.initialize();
    });
  }

  connectedOrgMappingSave(willReconnect?:boolean, willDisconnect?:boolean) {
    CommonUtils.showLoading();
    const that = this;
    const tempTeacherArray = ld.cloneDeep(this.connectedTeachersArray);
    this.connectedTeachersArray = [];
    this.connectedTeachersArray = tempTeacherArray.concat(this.hiddenTeacherForCheckbox);

    const tempSchoolYearArray = ld.cloneDeep(this.manageMappingSchoolYearsArray);
    this.manageMappingSchoolYearsArray = [];
    this.manageMappingSchoolYearsArray = tempSchoolYearArray.concat(this.hiddenSchoolYearForCheckbox);

    let authorId: any = null;
    if (this.userType === 'D') {
      authorId = this.settings.schoolSettings.districtId;
    } else {
      authorId = this.settings.schoolSettings.schoolId;
    }
    this.finishSetUporSaveTeacher(true);
    this.connectedSchoolYearMappingSave(true);
    this.updateRefOrgs({
      selectedOrgs: this.manageMappingOrgsSelected,
      orgs: this.manageMappingOrgsArray,
      districtId: this.settings.schoolSettings.districtId,
      authorId: authorId
    }).then(() => {
      const found = this.manageMappingOrgsSelected.some((e:any) => e.type === 'district');
      if (found) {
        this.isDisconnected = false;
      } else {
        this.isDisconnected = true;
      }
      this.connectedTeachersArray = this.connectedTeachersArray.filter((element:any) => {
        const found = this.hiddenTeacherForCheckbox.some((e:any) => e.sourcedId === element.sourcedId);
        if (!found) {
          return element;
        }
      });

      this.manageMappingSchoolYearsArray = this.manageMappingSchoolYearsArray.filter((element:any) => {
        const found = this.hiddenSchoolYearForCheckbox.some((e:any) => e.sourcedId === element.sourcedId);
        if (!found) {
          return element;
        }
      });
      if (!willDisconnect && !willReconnect) {
        CommonUtils.hideLoading();
      }
      const tempHiddenTeacherForCheckbox = that.hiddenTeacherForCheckbox;
      const tempHiddenSelectedTeacherForCheckbox = that.hiddenSelectedTeacherForCheckbox;
      const tempHiddenSchoolYearForCheckbox = that.hiddenSchoolYearForCheckbox;
      const tempHiddenSelectedSchoolYearForCheckbox = that.hiddenSelectedSchoolYearForCheckbox;
      that.refresh();
      that.hiddenTeacherForCheckbox = tempHiddenTeacherForCheckbox;
      that.hiddenSelectedTeacherForCheckbox = tempHiddenSelectedTeacherForCheckbox;
      that.hiddenSelectedSchoolYearForCheckbox = tempHiddenSelectedSchoolYearForCheckbox;
      that.hiddenSchoolYearForCheckbox = tempHiddenSchoolYearForCheckbox;
      if (willReconnect) {
        that.initialize(true);
      }
      if (willDisconnect) {
        this.hideHiddenTeacherForCheckbox(true);
        this.hideHiddenSchoolYearForCheckbox(true);
        CommonUtils.showLoading();
        that.initialize(true);
      }
    });
  }

  connectedOpenConfirmationModal() {
    const found = this.connectedTeachersArray.some((e:any) => e.manageMappingPBTeacherName === 'add');
    let ctr = 0;
    this.connectedTeachersArray.forEach((element:any) => {
      if (element.manageMappingPBTeacherName === 'add') {
        ctr++;
      }
    });
    if ((found && ctr > 0) || this.totalSchoolYearsToBeAdded > 0) {
      this.confirmationModal = true;
    } else {
      this.finishSetUporSaveTeacher();
    }
  }

  connectedSchoolYearMappingSave(willDisconnect?:boolean) {
    CommonUtils.showLoading();
    const that = this;
    let tempDistrictId = 0;
    let tempSchoolId = 0;
    if (this.userType === 'D') {
      tempSchoolId = 0;
      tempDistrictId = this.settings.schoolSettings.districtId;
    } else {
      tempDistrictId = 0;
      tempSchoolId = this.settings.schoolSettings.schoolId;
    }
    this.updateRefAcademicSession({
      selectedSchoolYears: this.manageMappingSchoolYearsSelected,
      schoolYears: this.manageMappingSchoolYearsArray,
      domain: this.connectedDomain,
      gradingPeriodId: this.settings.accountSettings.currentGradingPeriodId,
      districtId: tempDistrictId,
      schoolId: tempSchoolId
    }).then(() => {
      if (!willDisconnect) {
        CommonUtils.hideLoading();
        const tempHiddenTeacherForCheckbox = that.hiddenTeacherForCheckbox;
        const tempHiddenSelectedTeacherForCheckbox = that.hiddenSelectedTeacherForCheckbox;
        const tempHiddenSchoolYearForCheckbox = that.hiddenSchoolYearForCheckbox;
        const tempHiddenSelectedSchoolYearForCheckbox = that.hiddenSelectedSchoolYearForCheckbox;
        that.refresh();
        that.hiddenTeacherForCheckbox = tempHiddenTeacherForCheckbox;
        that.hiddenSelectedTeacherForCheckbox = tempHiddenSelectedTeacherForCheckbox;
        that.hiddenSelectedSchoolYearForCheckbox = tempHiddenSelectedSchoolYearForCheckbox;
        that.hiddenSchoolYearForCheckbox = tempHiddenSchoolYearForCheckbox;
      }
    });
  }

  preventEmptyOrgs(item: any, setupOrNot: string) {
    if (item.setUpPBSchoolName === null && setupOrNot === 'setup') {
      item.setUpPBSchoolName = this.orgsPreviousSavedValue;
    } else if (
      item.manageMappingPBSchoolName === null &&
      setupOrNot === 'not'
    ) {
      item.manageMappingPBSchoolName = this.orgsPreviousSavedValue;
    }
  }

  preventEmptyTeachers(item: any, setupOrNot: string) {
    if (item.setUpPBTeacherName === null && setupOrNot === 'setup') {
      item.setUpPBTeacherName = this.teachersPreviousValue;
    } else if (
      item.manageMappingPBTeacherName === null &&
      setupOrNot === 'not'
    ) {
      item.manageMappingPBTeacherName = this.teachersPreviousValue;
    }
  }

  preventEmptySchoolYears(item: any, setupOrNot: string) {
    if (item.setUpPBSchoolYear === null && setupOrNot === 'setup') {
      item.setUpPBSchoolYear = this.schoolYearsPreviousValue;
    } else if (
      item.manageMappingPBSchoolYearName === null &&
      setupOrNot === 'not'
    ) {
      item.manageMappingPBSchoolYearName = this.schoolYearsPreviousValue;
    }
  }

  checkBoxOrgClick(item:any, isHeadersCheckboxClick?:boolean) {
    const that = this;
    const currentItem = item.item || item.currentItem;
    if (!this.setupWizard) {
      this.isOrgsCheckBoxTrueorFalse = item.value;
      this.uncheckedAndCheckedOrgSourcedId = currentItem.sourcedId;
      if (currentItem.type === 'district' && !item.value) {
        that.hideHiddenTeacherForCheckbox(true);
        that.hideHiddenSchoolYearForCheckbox(true);
      } else if (currentItem.type === 'district' && item.value) {
        that.showHiddenTeacherForCheckbox(true);
        that.showHiddenSchoolYearForCheckbox(true);
      }
    }
    this.isManageMappingOrgsNotDirty = false;
    if (item.value && this.isActive(currentItem.status)) {
      currentItem.dropDownDisabled = false;
      if (this.setupWizard) {
        if (currentItem.setUpPBSchoolName === 'do-nothing') {
          currentItem.setUpPBSchoolName = 'add'
        }
      } else {
        if (currentItem.manageMappingPBSchoolName === 'do-nothing') {
          currentItem.manageMappingPBSchoolName = 'add'
        }
      }
    } else if (!item.value) {
      if (this.setupWizard) {
        if (currentItem.setUpPBSchoolName === 'add') {
          currentItem.setUpPBSchoolName = 'do-nothing'
        }
      } else {
        if (currentItem.manageMappingPBSchoolName === 'add') {
          currentItem.manageMappingPBSchoolName = 'do-nothing'
        }
      }
      currentItem.dropDownDisabled = true;
    }

    if (currentItem.type === 'district' && !item.value) {
      if (this.setupWizard) {
        this.selectedOrgs = [];
        this.orgsMappingArray.forEach((element:any) => {
          if (element.type !== 'district') {
            if (element.setUpPBSchoolName === 'add') {
              element.setUpPBSchoolName = 'do-nothing';
            }
            element.dropDownDisabled = true;
          }
        });
      } else {
        this.manageMappingOrgsSelected = [];
        this.manageMappingOrgsArray.forEach((element:any) => {
          if (element.type !== 'district') {
            if (element.manageMappingPBSchoolName === 'add') {
              element.manageMappingPBSchoolName = 'do-nothing';
            }
            element.dropDownDisabled = true;
          }
        });
      }
    } else if (currentItem.type === 'district' && item.value) {
      if (this.setupWizard) {
        this.selectedOrgs = [];
        this.orgsMappingArray.forEach((element:any) => {
          if (element.type !== 'district' && this.isActive(element.status)) {
            this.pushElementInSelectedOrgs(element);
            if (element.setUpPBSchoolName === 'do-nothing') {
              element.setUpPBSchoolName = 'add';
            }
            element.dropDownDisabled = false;
          }
        });
      } else {
        this.manageMappingOrgsSelected = [];
        this.manageMappingOrgsArray.forEach((element:any) => {
          if (element.type !== 'district' && this.isActive(element.status)) {
            this.pushElementInManageMappingOrgsSelected(element);
            if (element.manageMappingPBSchoolName === 'do-nothing') {
              element.manageMappingPBSchoolName = 'add';
            }
            element.dropDownDisabled = false;
          }
        });
      }
    }

    if (!isHeadersCheckboxClick) {
      if (item.value) {
        if (this.setupWizard) {
          const found = this.selectedOrgs.some((e:any) => e.type === 'district');
          if (!found) {
            const districtOrg = this.orgsMappingArray.filter((e:any) => e.type === 'district');
            if (districtOrg !== undefined) {
              this.pushElementInSelectedOrgs(districtOrg[0]);
              if (currentItem.type !== 'district') {
                this.pushElementInSelectedOrgs(currentItem);
              }
            }
          }
        } else {
          const found = this.manageMappingOrgsSelected.some((e:any) => e.type === 'district');
          if (!found) {
            const districtOrg = this.manageMappingOrgsArray.filter((e:any) => e.type === 'district');
            if (districtOrg !== undefined) {
              this.pushElementInManageMappingOrgsSelected(districtOrg[0]);
              if (currentItem.type !== 'district') {
                this.pushElementInManageMappingOrgsSelected(currentItem);
              }
            }
          }
        }
      }
    }
  }

  orgsToggleAllCheckBox(items:any) {
    this.isManageMappingOrgsNotDirty = false;
    items.items.forEach((item:any) => {
      this.checkBoxOrgClick({ item: item, value: items.value }, true);
    });
    if (items.value) {
      if (this.setupWizard) {
        const found = this.selectedOrgs.some((e:any) => e.type === 'district');
        if (!found) {
          const districtOrg = this.orgsMappingArray.filter((e:any) => e.type === 'district');
          if (districtOrg !== undefined) {
            if (CommonUtils.isNotEmpty(districtOrg)) {
              this.pushElementInSelectedOrgs(districtOrg[0]);
            }
            items.items.forEach((item:any) => {
              if (item.type !== 'district' && this.isActive(item.status)) {
                this.pushElementInSelectedOrgs(item);
              }
            });
          }
        }
      } else {
        const found = this.manageMappingOrgsSelected.some((e:any) => e.type === 'district');
        if (!found) {
          const districtOrg = this.manageMappingOrgsArray.filter((e:any) => e.type === 'district');
          if (districtOrg !== undefined) {
            if (CommonUtils.isNotEmpty(districtOrg)) {
              this.pushElementInManageMappingOrgsSelected(districtOrg[0]);
            }
            items.items.forEach((item:any) => {
              if (item.type !== 'district' && this.isActive(item.status)) {
                this.pushElementInManageMappingOrgsSelected(item);
              }
            });
          }
        }
      }
    }
  }

  checkBoxTeacherClick(item:any) {
    this.isManageMappingTeachersNotDirty = false;
    const currentItem = item.item || item.currentItem;
    if (item.value && this.isActive(currentItem.status)) {
      currentItem.dropDownDisabled = false;
      if (this.setupWizard) {
        if (currentItem.setUpPBTeacherName === 'do-nothing') {
          currentItem.setUpPBTeacherName = 'add'
        }
      } else {
        if (currentItem.manageMappingPBTeacherName === 'do-nothing') {
          currentItem.manageMappingPBTeacherName = 'add'
        }
      }
    } else if (!item.value) {
      if (this.setupWizard) {
        if (currentItem.setUpPBTeacherName === 'add') {
          currentItem.setUpPBTeacherName = 'do-nothing'
        }
      } else {
        if (currentItem.manageMappingPBTeacherName === 'add') {
          currentItem.manageMappingPBTeacherName = 'do-nothing'
        }
      }
      currentItem.dropDownDisabled = true;
    }
  }

  teachersToggleAllCheckBox(items:any) {
    this.isManageMappingTeachersNotDirty = false;
    items.items.forEach((item:any) => {
      this.checkBoxTeacherClick({ item: item, value: items.value });
    });
  }

  triggerIsDirtyCheckSchoolYearsArchive(item:any) {
    const currentItem = item.item || item.currentItem;
    if (this.isActive(currentItem.status)) {
      this.isManageMappingSchoolYearsNotDirty = false;
      if (item.value) {
        currentItem.dropDownDisabled = false;
        if (this.setupWizard) {
          if (currentItem.setUpPBSchoolYear === 'do-nothing') {
            currentItem.setUpPBSchoolYear = 'add'
          }
        } else {
          if (currentItem.manageMappingPBSchoolYearName === 'do-nothing') {
            currentItem.manageMappingPBSchoolYearName = 'add'
          }
        }
      } else {
        currentItem.dropDownDisabled = true;
        if (this.setupWizard) {
          if (currentItem.setUpPBSchoolYear === 'add') {
            currentItem.setUpPBSchoolYear = 'do-nothing'
          }
        } else {
          if (currentItem.manageMappingPBSchoolYearName === 'add') {
            currentItem.manageMappingPBSchoolYearName = 'do-nothing'
          }
        }
      }
    }
  }

  schoolYearsToggleAllCheckBox(items:any) {
    items.items.forEach((item:any) => {
      this.triggerIsDirtyCheckSchoolYearsArchive({ item: item, value: items.value });
    });
  }

  whenConditionsMetAddDistrictOrgToSelectedArray(selectedLength:any) {
    if (selectedLength > 0) {
      if (this.setupWizard) {
        const found = this.selectedOrgs.some((e:any) => e.type === 'district');
        if (!found) {
          const districtOrg = this.orgsMappingArray.filter((e:any) => e.type === 'district');
          this.pushElementInSelectedOrgs(districtOrg[0]);
        }
      } else {
        const found = this.manageMappingOrgsSelected.some((e:any) => e.type === 'district');
        if (!found) {
          const districtOrg = this.manageMappingOrgsArray.filter((e:any) => e.type === 'district');
          this.pushElementInManageMappingOrgsSelected(districtOrg[0]);
        }
      }
    }
  }

  changeOrgsSelect(item: any, event: any) {
    if (event === 'do-nothing') {
      this.isOrgsCheckBoxTrueorFalse = false;
    } else if (event === 'add') {
      this.isOrgsCheckBoxTrueorFalse = true;
    }
    this.uncheckedAndCheckedOrgSourcedId = item.sourcedId;
    try {
      if (event === 'do-nothing') {
        const indexOfSelected = this.selectedOrgs.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (indexOfSelected !== -1) {
          this.spliceElementInSelectedOrgs(indexOfSelected);
        }
        item.score = 0;
        item.dropDownDisabled = true;
        item.setUpPBSchoolName = event;
      } else {
        item.dropDownDisabled = false;
        const indexOfSelected = this.selectedOrgs.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (event === 'do-nothing' || event === 'add') {
          item.setUpPBSchoolName = event;
        } else {
          item.setUpPBSchoolName = ld.cloneDeep(event);
          item.setUpPBSchoolName.value = ld.cloneDeep(event);
          item.setUpPBSchoolName.displayValue = item.setUpPBSchoolName.value.schoolName + ' (' + item.setUpPBSchoolName.value.schoolId + ')';
        }
        if (indexOfSelected === -1) {
          this.pushElementInSelectedOrgs(item);
        }
      }

      this.whenConditionsMetAddDistrictOrgToSelectedArray(this.selectedOrgs.length);
    } catch (e) {
      console.log('Null');
    }
  }

  changeTeachersSelect(item: any, event: any) {
    try {
      if (event === 'do-nothing') {
        const indexOfSelected = this.selectedTeachers.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (indexOfSelected !== -1) {
          this.spliceElementInSelectedTeacher(indexOfSelected);
        }
        // item.isRowSelectable = false;
        item.dropDownDisabled = true;
        item.setUpPBTeacherName = event;
      } else {
        item.dropDownDisabled = false;
        const indexOfSelected = this.selectedTeachers.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (event === 'do-nothing' || event === 'add') {
          item.setUpPBTeacherName = event;
        } else {
          item.setUpPBTeacherName = ld.cloneDeep(event);
          item.setUpPBTeacherName.value = ld.cloneDeep(event);
          item.setUpPBTeacherName.displayValue = item.setUpPBTeacherName.value.teacherName + ' (' + item.setUpPBTeacherName.value.teacherId + ')'
        }
        if (indexOfSelected === -1) {
          this.pushElementInSelectedTeacher(item);
        }
      }
    } catch (e) {
      console.log('Null');
    }
  }

  changeSchoolYearsSelect(item: any, event: any) {
    try {
      if (event === 'do-nothing') {
        const indexOfSelected = this.selectedSchoolYears.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (indexOfSelected !== -1) {
          this.spliceElementInSelectedSchoolYears(indexOfSelected);
        }
        // item.isRowSelectable = false;
        item.dropDownDisabled = true;
        item.setUpPBSchoolYear = event;
      } else {
        item.dropDownDisabled = false;
        const indexOfSelected = this.selectedSchoolYears.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (event === 'do-nothing' || event === 'add') {
          item.setUpPBSchoolYear = event;
        } else {
          item.setUpPBSchoolYear = ld.cloneDeep(event);
          item.setUpPBSchoolYear.value = ld.cloneDeep(event);
        }
        if (indexOfSelected === -1) {
          this.pushElementInSelectedSchoolYears(item);
        }
      }
    } catch (e) {
      console.log('Null');
    }
  }

  triggerIsDirtyCheckSettings() {
    this.isConnectedSettingsNotDirty = false;
  }

  triggerIsDirtyCheckOrgsSelect(item: any, event: any) {
    if (event === 'do-nothing') {
      this.isOrgsCheckBoxTrueorFalse = false;
    } else if (event === 'add') {
      this.isOrgsCheckBoxTrueorFalse = true;
    }
    this.uncheckedAndCheckedOrgSourcedId = item.sourcedId;
    try {
      this.isManageMappingOrgsNotDirty = false;
      if (event === 'do-nothing') {
        const indexOfSelected = this.manageMappingOrgsSelected.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (indexOfSelected !== -1) {
          this.spliceElementInManageMappingOrgsSelected(indexOfSelected);
        }
        item.dropDownDisabled = true;
        item.manageMappingPBSchoolName = event;
      } else {
        item.dropDownDisabled = false;
        const indexOfSelected = this.manageMappingOrgsSelected.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (event === 'do-nothing' || event === 'add') {
          item.manageMappingPBSchoolName = event;
        } else {
          item.manageMappingPBSchoolName = ld.cloneDeep(event);
          item.manageMappingPBSchoolName.value = ld.cloneDeep(event);
          item.manageMappingPBSchoolName.displayValue = item.manageMappingPBSchoolName.value.schoolName + ' (' + item.manageMappingPBSchoolName.value.schoolId + ')'
        }
        if (indexOfSelected === -1) {
          this.pushElementInManageMappingOrgsSelected(item);
        }
      }

      this.whenConditionsMetAddDistrictOrgToSelectedArray(this.manageMappingOrgsSelected.length);
    } catch (e) {
      console.log('Null');
    }
  }

  triggerIsDirtyCheckSchoolYearsSelect(item: any, event: any) {
    try {
      this.isManageMappingSchoolYearsNotDirty = false;
      if (event === 'do-nothing') {
        const indexOfSelected = this.manageMappingSchoolYearsSelected.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (indexOfSelected !== -1) {
          this.spliceElementInManageMappingSchoolYearsSelected(indexOfSelected);
        }
        // item.isRowSelectable = false;
        item.dropDownDisabled = true;
        item.manageMappingPBSchoolYearName = event;
      } else {
        item.dropDownDisabled = false;
        const indexOfSelected = this.manageMappingSchoolYearsSelected.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (event === 'do-nothing' || event === 'add') {
          item.manageMappingPBSchoolYearName = event;
        } else {
          item.manageMappingPBSchoolYearName = ld.cloneDeep(event);
          item.manageMappingPBSchoolYearName.value = ld.cloneDeep(event);
        }
        if (indexOfSelected === -1) {
          this.pushElementInManageMappingSchoolYearsSelected(item);
        }
      }
    } catch (e) {
      console.log('Null');
    }
  }

  triggerIsDirtyCheckTeachersSelect(item: any, event: any) {
    try {
      this.isManageMappingTeachersNotDirty = false;
      if (event === 'do-nothing') {
        const indexOfSelected = this.manageMappingTeachersSelected.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (indexOfSelected !== -1) {
          this.spliceElementInManageMappingTeachersSelected(indexOfSelected);
        }
        // item.isRowSelectable = false;
        item.dropDownDisabled = true;
        item.manageMappingPBTeacherName = event;
      } else {
        item.dropDownDisabled = false;
        const indexOfSelected = this.manageMappingTeachersSelected.findIndex(
          (e: any) => e.sourcedId === item.sourcedId
        );
        if (event === 'do-nothing' || event === 'add') {
          item.manageMappingPBTeacherName = event;
        } else {
          item.manageMappingPBTeacherName = ld.cloneDeep(event);
          item.manageMappingPBTeacherName.value = ld.cloneDeep(event);
          item.manageMappingPBTeacherName.displayValue = item.manageMappingPBTeacherName.value.teacherName + ' (' + item.manageMappingPBTeacherName.value.teacherId + ')'
        }
        if (indexOfSelected === -1) {
          this.pushElementInManageMappingTeachersSelected(item);
        }
      }
    } catch (e) {
      console.log('Null');
    }
  }

  skipWalkthrough() {
    this.overlay = false;
    this.walkthrough1 = false;
    this.walkthrough2 = false;
    this.walkthrough3 = false;
    this.walkthrough4 = false;
    this.walkthrough5 = false;
  }

  backStep2() {
    this.setupStepCount = 1;
    if (this.isOrgTableCompleteMatchHidden) {
      this.showCompleteMatchOrgsSetup();
    }
  }

  backStep3() {
    this.setupStepCount = 2;
    if (this.isTeacherTableCompleteMatchHidden) {
      this.showCompleteMatchTeacherSetup();
    }
  }

  backStep4() {
    this.setupStepCount = 3;
    if (this.isSchoolYearWithoutClassesHidden) {
      this.showSchoolYearWithoutClasses();
    }
  }

  step1Validate() {
    const form: any = this.$refs.integrationStep1Form;
    const that = this;
    const planBookSchoolData: any = [...that.settings.schoolSettings.schools];
    this.orgsMappingArray = [];
    // this.setPlanBookOrgArray();
    this.selectedOrgs = [];
    if (form.validate()) {
      CommonUtils.showLoading();
      this.setUpEndPoint = this.setUpEndPoint.trim();
      this.setUpAccessKey = this.setUpAccessKey.trim();
      this.setUpSecretKey = this.setUpSecretKey.trim();
      if (this.setUpEndPoint.endsWith('/')) {
        this.setUpEndPoint = this.setUpEndPoint.slice(0, -1);
      }
      this.getOrgs({
        endpoint: this.setUpEndPoint,
        accessKey: this.setUpAccessKey,
        secretKey: this.setUpSecretKey,
        firstTimeSetUp: true
      }).catch((error) => {
        console.log(error);
        that.noDataFound = true;
        CommonUtils.hideLoading();
      })
        .then((result: any) => {
          if (result.data.length === 0) {
            that.noDataFound = true;
          } else {
            that.noDataFound = false;
          }
          that.overlay = true;
          that.walkthrough1 = true;
          console.log(result);
          that.orgMappingResultList = result.data;
          that.orgMappingResultList.forEach((element: any) => {
            element.isRowSelectable = false;
            const planbookListArray = this.setPlanBookOrgArray(element.name);
            if (element.type === 'district') {
              that.mappingDistrictName = element.name;
              let tempPBSchoolName:any = 'add';
              element.isRowSelectable = true;
              let setUpPBName = '';
              setUpPBName = that.settings.schoolSettings.districtName;
              tempPBSchoolName =
              {
                displayValue: setUpPBName +
              ' (' +
              that.settings.schoolSettings.districtId +
              ')',
                value: {
                  schoolName: setUpPBName,
                  schoolId: that.settings.schoolSettings.districtId
                }
              }
              ;
              if (CommonUtils.isFalse(this.isActive(element.status))) {
                if (tempPBSchoolName === 'add') {
                  tempPBSchoolName = 'do-nothing';
                }
                element.isRowSelectable = false;
              }
              if (this.isActive(element.status)) {
                that.pushElementInSelectedOrgs({
                  setUpPBSchoolName: tempPBSchoolName,
                  setUpSchoolName: element.name + ' (' + element.sourcedId + ')',
                  sourcedId: element.sourcedId,
                  planBookOrgArray: [],
                  type: 'district',
                  isRowSelectable: element.isRowSelectable,
                  status: element.status
                });
              }
              that.pushElementInOrgsMappingArray({
                setUpPBSchoolName: tempPBSchoolName,
                setUpSchoolName: element.name + ' (' + element.sourcedId + ')',
                sourcedId: element.sourcedId,
                planBookOrgArray: [],
                type: 'district',
                isRowSelectable: element.isRowSelectable,
                dropDownDisabled: false,
                status: element.status
              });
              var indexDistrict = planBookSchoolData
                .map(function (e: any) {
                  return e.schoolName;
                })
                .indexOf(setUpPBName);
              if (indexDistrict !== -1) {
                planBookSchoolData.splice(indexDistrict, 1);
              }
            } else if (element.type === 'school') {
              element.isRowSelectable = false;
              const found = that.orgsMappingArray.some((e:any) => e.sourcedId === element.sourcedId);
              if (
                !found
              ) {
                let output: any = [];
                const options: any = {
                  includeScore: true,
                  keys: ['schoolName']
                };
                const fuse = new Fuse(planBookSchoolData, options);
                output = fuse.search(element.name);

                let score = null;
                let tempPBSchoolName:any = 'add';
                let tempResultSchoolName;
                let isRowSelected = false;
                element.isRowSelectable = true;
                if (output === undefined || output.length === 0) {
                  tempPBSchoolName = 'add';
                  score = 0;
                } else {
                  isRowSelected = true;
                  score = Number(output[0].score).toFixed(2);
                  score = 100 - (+score * 100);
                  score = Number(score).toFixed(2);
                  score = +score;
                  if (score > 80) {
                    tempResultSchoolName = output[0].item.schoolName;
                    tempPBSchoolName =
                   {
                     displayValue: output[0].item.schoolName +
                  ' (' +
                  output[0].item.schoolId +
                  ')',
                     value: {
                       schoolName: output[0].item.schoolName,
                       schoolId: output[0].item.schoolId
                     }
                   };
                  }
                }
                let dropDownDisabled = false;
                if (tempPBSchoolName === 'do-nothing' || !isRowSelected) {
                  dropDownDisabled = true;
                }
                if (tempPBSchoolName === 'add') {
                  dropDownDisabled = false;
                }

                if (CommonUtils.isFalse(this.isActive(element.status))) {
                  if (tempPBSchoolName === 'add') {
                    tempPBSchoolName = 'do-nothing';
                  }
                  dropDownDisabled = true;
                  element.isRowSelectable = false;
                }
                that.pushElementInOrgsMappingArray({
                  setUpPBSchoolName: tempPBSchoolName,
                  setUpSchoolName: element.name + ' (' + element.sourcedId + ')',
                  sourcedId: element.sourcedId,
                  planBookOrgArray: [],
                  planbookListArray: planbookListArray,
                  type: 'school',
                  isRowSelectable: element.isRowSelectable,
                  score: score,
                  dropDownDisabled: dropDownDisabled,
                  status: element.status
                });
                if ((isRowSelected || tempPBSchoolName === 'add') && this.isActive(element.status)) {
                  that.pushElementInSelectedOrgs({
                    setUpPBSchoolName: tempPBSchoolName,
                    setUpSchoolName:
                    element.name + ' (' + element.sourcedId + ')',
                    sourcedId: element.sourcedId,
                    planBookOrgArray: [],
                    planbookListArray: planbookListArray,
                    type: 'school',
                    isRowSelectable: element.isRowSelectable,
                    score: score,
                    dropDownDisabled: false,
                    status: element.status
                  });
                  var index = planBookSchoolData
                    .map(function (e: any) {
                      return e.schoolName;
                    })
                    .indexOf(tempResultSchoolName);
                  if (index !== -1) {
                    planBookSchoolData.splice(index, 1);
                  }
                }
              }
            }
          });
          CommonUtils.hideLoading();
        });
      this.setupStepCount = 2;
    }
  }

  step2Validate() {
    const that = this;
    this.teachersMappingArray = [];
    // this.setPlanBookTeacherArray();
    this.selectedTeachers = [];
    CommonUtils.showLoading();
    this.getTeachersForSchool({
      endpoint: this.setUpEndPoint,
      accessKey: this.setUpAccessKey,
      secretKey: this.setUpSecretKey,
      orgs: this.orgsSourcedIdArray,
      firstTimeSetUp: true
    }).then(() => {
      that.planbookTeacherLists = ld.cloneDeep(this.getTeachersAll);
      ld.cloneDeep(this.allMappingTeachers).forEach((element: any) => {
        const planbookListArray = this.setPlanBookTeacherArray(element.email || element.username);
        element.isRowSelectable = true;
        let tempPBTeacherName;
        let options2: any = {
          includeScore: true,
          keys: ['emailAddress']
        };
        let tempResultTeachersName: any;
        let fuse2 = new Fuse(that.planbookTeacherLists, options2);
        let output2: any = fuse2.search(element.email || element.username);
        let isRowSelected = false;
        let score = null;
        tempPBTeacherName = 'add';

        if (output2.length > 0 && output2[0].item.emailAddress.toLowerCase() === element.email.toLowerCase()) {
          tempPBTeacherName =
              {
                displayValue: output2[0].item.emailAddress +
              ' (' +
              output2[0].item.userId +
              ')',
                value: {
                  teacherName: output2[0].item.emailAddress,
                  teacherId: output2[0].item.userId
                }
              };
          tempResultTeachersName = output2[0].item.emailAddress;
          isRowSelected = true;
          // element.isRowSelectable = true;
          score = 100
        } else {
          options2 = {
            includeScore: true,
            keys: [
              { name: 'emailAddress', weight: 10 },
              { name: 'firstName', weight: 0.25 },
              { name: 'lastName', weight: 0.25 }
            ]
          };
          fuse2 = new Fuse(that.planbookTeacherLists, options2);
          output2 = fuse2.search({
            $or: [{
              $and: [
                { emailAddress: element.email || element.username },
                { firstName: element.givenName },
                { lastName: element.familyName }
              ]
            },
            {
              $and: [
                { firstName: element.givenName },
                { lastName: element.familyName }
              ]
            }
            ]
          })
          if (output2.length === 0) {
            tempPBTeacherName = 'add';
            score = 0;
          } else {
            score = Number(output2[0].score).toFixed(2);
            score = 100 - (+score * 100);
            score = Number(score).toFixed(2);
            score = +score;
            if (score > 95) {
              tempPBTeacherName =
              {
                displayValue: output2[0].item.emailAddress +
              ' (' +
              output2[0].item.userId +
              ')',
                value: {
                  teacherName: output2[0].item.emailAddress,
                  teacherId: output2[0].item.userId
                }
              }
              ;
              tempResultTeachersName = output2[0].item.emailAddress;
              isRowSelected = true;
              // element.isRowSelectable = true;
            } else {
              tempPBTeacherName = 'add';
            }
          }
        }

        let dropDownDisabled = false;
        if (tempPBTeacherName === 'do-nothing' || !isRowSelected) {
          dropDownDisabled = true;
        }

        if (tempPBTeacherName === 'add') {
          dropDownDisabled = false;
        }

        if (CommonUtils.isFalse(this.isActive(element.status))) {
          if (tempPBTeacherName === 'add') {
            tempPBTeacherName = 'do-nothing';
          }
          dropDownDisabled = true;
          element.isRowSelectable = false;
        }
        if (CommonUtils.isFalse(element.duplicate) ||
            CommonUtils.isFalse(this.teachersMappingArray.some((e:any) => e.email === element.email)) // Check if the current element's email is not already in the table's array, since we now allow only one instance of each duplicate.
        ) {
          if (element.duplicate) {
            // sets the initial selected classlink teacher
            element.classlinkTeacherValue = {
              displayValue: (element.email || element.username) + ' (' + element.sourcedId + ')',
              value: {
                teacherEmail: element.email,
                sourcedId: element.sourcedId
              },
              disabled: CommonUtils.isFalse(this.isActive(element.status))
            }
          }
          that.pushElementInTeachersMappingArray({
            setUpPBTeacherName: tempPBTeacherName,
            setUpTeacherName: (element.email || element.username) + ' (' + element.sourcedId + ')',
            sourcedId: element.sourcedId,
            planbookListArray: planbookListArray,
            orgSourcedId: element.orgs[0].sourcedId,
            orgsSourcedIdArray: element.orgs,
            orgNames: element.orgNames,
            isRowSelectable: element.isRowSelectable,
            email: element.email,
            duplicate: element.duplicate,
            score: score,
            dropDownDisabled: dropDownDisabled,
            status: element.status,
            classlinkTeacherValue: element.classlinkTeacherValue
          });
        }
        if ((isRowSelected || tempPBTeacherName === 'add') &&
            (CommonUtils.isFalse(element.duplicate) ||
              CommonUtils.isFalse(this.selectedTeachers.some((e:any) => e.email === element.email)) // Check if the current element's email is not already in the table's array, since we now allow only one instance of each duplicate.
            )
        ) {
          that.pushElementInSelectedTeacher({
            setUpPBTeacherName: tempPBTeacherName,
            setUpTeacherName: (element.email || element.username) + ' (' + element.sourcedId + ')',
            sourcedId: element.sourcedId,
            planbookListArray: planbookListArray,
            orgSourcedId: element.orgs[0].sourcedId,
            orgsSourcedIdArray: element.orgs,
            orgNames: element.orgNames,
            isRowSelectable: element.isRowSelectable,
            email: element.email,
            duplicate: element.duplicate,
            score: score,
            dropDownDisabled: false,
            status: element.status,
            classlinkTeacherValue: element.classlinkTeacherValue
          });
          var index = that.planbookTeacherLists
            .map(function (e: any) {
              return e.emailAddress;
            })
            .indexOf(tempResultTeachersName);
          if (index !== -1) {
            that.planbookTeacherLists.splice(index, 1);
          }
        }
      });
      const teaherIndex:any = [];
      that.hiddenSelectedTeacherForCheckbox = [];
      that.hiddenTeacherForCheckbox = [];

      // REMOVE THE UNSELECTED ORGS IN TEACHERS ARRAY

      that.teachersMappingArray.forEach((element:any, index:number) => {
      // If found it means the org of this teacher is selected
        const found = that.selectedOrgs.some((e:any) => e.sourcedId === element.orgSourcedId);
        if (!found) {
          teaherIndex.push(index);
        }
      });

      teaherIndex.reverse();

      if (teaherIndex.length > 0) {
        teaherIndex.forEach((indexOfTeacher:number) => {
          const setUpPBTeacherName = that.teachersMappingArray[indexOfTeacher].setUpPBTeacherName;
          if (setUpPBTeacherName === 'add') {
            that.teachersMappingArray[indexOfTeacher].setUpPBTeacherName = 'do-nothing';
          }
          const found = that.hiddenTeacherForCheckbox.some((e:any) => e.sourcedId === that.teachersMappingArray[indexOfTeacher].sourcedId);
          if (!found) {
            that.hiddenTeacherForCheckbox.push(that.teachersMappingArray[indexOfTeacher]);
            // If found it means the teacher that you're removing in teachersMappingArray also exist in selectedTeachers
            let found = false;
            let teaherIndexinSelected = 0;
            found = that.selectedTeachers.some((e:any, index:number) => {
              if (e.sourcedId === that.teachersMappingArray[indexOfTeacher].sourcedId) {
                teaherIndexinSelected = index;
                return true;
              }
            });
            if (found) {
              that.hiddenSelectedTeacherForCheckbox.push(that.selectedTeachers[teaherIndexinSelected]);
              that.spliceElementInSelectedTeacher(teaherIndexinSelected);
            }
            that.spliceElementInTeachersMappingArray(indexOfTeacher);
          }
        });
      }
      CommonUtils.hideLoading();
    });

    if (this.isOrgTableCompleteMatchHidden) {
      this.showCompleteMatchOrgsSetup();
    }
    this.setupStepCount = 3;
  }

  step3Validate() {
    CommonUtils.showLoading();
    const that = this;
    this.schoolYearsMappingArray = [];
    this.selectedSchoolYears = [];
    // this.setPlanBookSchoolYearArray();
    // const planBookSchoolYearsData = [...this.$store.state.settings.adminSchoolYears];
    this.getAcademicSessions({
      endpoint: this.setUpEndPoint,
      accessKey: this.setUpAccessKey,
      secretKey: this.setUpSecretKey,
      firstTimeSetUp: true
    }).then((result: any) => {
      console.log(result);
      that.schoolYearsMappingResultList = result.data;
      let tempPBSchoolYear: any = 'do-nothing';
      let isRowSelected = false;
      let dropDownDisabled = true;
      // let tempResultSchoolYearsName:any;
      that.schoolYearsMappingResultList.forEach((element: any) => {
        if (+element.schoolYear >= moment().year() && that.isActive(element.status)) {
          tempPBSchoolYear = 'add';
          isRowSelected = true;
          dropDownDisabled = false;
        }
        const planBookSchoolYearArray: any[] = [];
        planBookSchoolYearArray.push({
          displayValue: this.$t('doNothingLabel'),
          value: 'do-nothing'
        });
        planBookSchoolYearArray.push({
          displayValue: this.$t('addLabel'),
          value: 'add'
        });
        if (CommonUtils.isFalse(that.isActive(element.status)) && tempPBSchoolYear === 'add') {
          tempPBSchoolYear = 'do-nothing';
          dropDownDisabled = true;
        }
        const jsonObjectToSave = {
          setUpSchoolYear: element.title + ' (' + element.sourcedId + ')',
          sourcedId: element.sourcedId,
          setUpPBSchoolYear: tempPBSchoolYear,
          planBookSchoolYearArray: planBookSchoolYearArray,
          schoolYear: element.schoolYear,
          title: element.title,
          withClasses: element.withClasses,
          isRowSelectable: true,
          orgSourcedId: element.orgSourcedId,
          dropDownDisabled: dropDownDisabled,
          status: element.status,
          startDate: element.startDate,
          endDate: element.endDate
        };
        if (CommonUtils.isFalse(that.isActive(element.status))) {
          jsonObjectToSave.isRowSelectable = false;
        }
        if (
          isRowSelected &&
            element.withClasses === true &&
            tempPBSchoolYear !== 'do-nothing'
        ) {
          jsonObjectToSave.isRowSelectable = true;
          that.pushElementInSelectedSchoolYears(jsonObjectToSave);
        }
        that.pushElementInSchoolYearsMappingArray(jsonObjectToSave);
      });

      that.removeSchoolYearsWithoutOrgSourcedId().then(() => {
        const schoolYearIndex:any = [];
        that.hiddenSelectedSchoolYearForCheckbox = [];
        that.hiddenSchoolYearForCheckbox = [];

        // REMOVE THE UNSELECTED ORGS IN SCHOOL YEARS ARRAY

        that.schoolYearsMappingArray.forEach((element:any, index:number) => {
          // If found it means the org of this school year is selected
          const found = that.selectedOrgs.some((e:any) => e.sourcedId === element.orgSourcedId);
          // ONLY HIDE THE SCHOOL YEARS IF THAT SCHOOL YEARS HAVE CLASSES AND IF IT'S ORG IS NOT SELECTED
          if (!found) {
            schoolYearIndex.push(index);
          }
        });

        schoolYearIndex.reverse();

        if (schoolYearIndex.length > 0) {
          schoolYearIndex.forEach((indexOfSchoolYear:number) => {
            const setUpPBSchoolYear = that.schoolYearsMappingArray[indexOfSchoolYear].setUpPBSchoolYear;
            if (setUpPBSchoolYear === 'add') {
              that.schoolYearsMappingArray[indexOfSchoolYear].setUpPBSchoolYear = 'do-nothing';
            }
            const found = that.hiddenSchoolYearForCheckbox.some((e:any) => e.sourcedId === that.schoolYearsMappingArray[indexOfSchoolYear].sourcedId);
            if (!found) {
              that.hiddenSchoolYearForCheckbox.push(that.schoolYearsMappingArray[indexOfSchoolYear]);
              // If found it means the school year that you're removing in schoolYearsMappingArray also exist in selectedSchoolYears
              let found = false;
              let schoolYearIndexinSelected = 0;
              found = that.selectedSchoolYears.some((e:any, index:number) => {
                if (e.sourcedId === that.schoolYearsMappingArray[indexOfSchoolYear].sourcedId) {
                  schoolYearIndexinSelected = index;
                  return true;
                }
              });
              if (found) {
                that.hiddenSelectedSchoolYearForCheckbox.push(that.selectedSchoolYears[schoolYearIndexinSelected]);
                that.spliceElementInSelectedSchoolYears(schoolYearIndexinSelected);
              }
              that.spliceElementInSchoolYearsMappingArray(indexOfSchoolYear);
            }
          });
        }
        CommonUtils.hideLoading();
        if (this.isTeacherTableCompleteMatchHidden) {
          this.showCompleteMatchTeacherSetup();
        }
        this.setupStepCount = 4;
      });
    });
  }

  step4Validate() {
    CommonUtils.showLoading();
    this.showSchoolYearWithoutClasses();
    // this.setLocalTime(this.syncTimeItems);
    this.setUpSyncTime = '08';
    this.setupStepCount = 5;
    CommonUtils.hideLoading();
  }

  refresh() {
    this.isManageMappingOrgsNotDirty = true;
    this.isManageMappingSchoolYearsNotDirty = true;
    this.isConnectedSettingsNotDirty = true;
    this.isManageMappingTeachersNotDirty = true;
    this.confirmationModal = false;
    this.isSchoolYearWithoutClassesHidden = false;
    this.hiddenTeacherForCheckbox = [];
    this.hiddenSelectedTeacherForCheckbox = [];
    this.hiddenSchoolYearForCheckbox = [];
    this.hiddenSelectedSchoolYearForCheckbox = [];
  }

  async initialize(willDisconnect?:boolean) {
    console.log('%c INITIALIZED! ', 'background: #222; color: #bada55');
    this.refresh();
    this.tab = 4;
    if (this.isAllowedToSetUp) {
      return this.doGetRefOrgsByDistrictOrSchool()
        .then((result: any) => {
          console.log(result);
          this.initializeByDistrictOrSchool(result, willDisconnect);
          return Promise.resolve();
        });
    } else {
      this.pageInitializing = false;
      CommonUtils.hideLoading();
      return Promise.resolve();
    }
  }

  doGetRefOrgsByDistrictOrSchool(): Promise<any> {
    if (this.settings.schoolSettings.districtId !== 0) {
      return this.getrefOrgsByDistrictId({
        districtId: this.settings.schoolSettings.districtId
      });
    } else {
      return this.getrefOrgsBySchoolId({
        schoolId: this.settings.schoolSettings.schoolId
      });
    }
  }

  initializeByDistrictOrSchool(result: any, willDisconnect?:boolean) {
    const that = this;
    const data = result.data;
    if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
      console.log('Log In EXPIRED');
      CommonUtils.hideLoading();
      this.pageInitializing = false;
      throw new SessionExpiredError();
    } else if (data.error === 'true' || data.error === true) {
      CommonUtils.hideLoading();
      this.pageInitializing = false;
      throw new AppError('contactSupport', { data });
    } else {
      if (CommonUtils.isNotEmpty(result.data)) {
        if (result.data[0].archivalInd === false) {
          that.isDisconnected = false;
        } else if (result.data[0].archivalInd === true) {
          that.isDisconnected = true;
          console.log('ID TO RECONNECT -> ' + result.data[0].id);
        }
        that.isConnected = true;
        that.setupWizard = false;
        that.connectedEndPoint = result.data[0].endPointURL;
        that.connectedAccessKey = result.data[0].accessKey;
        that.connectedSecretKey = result.data[0].secretKey;
        const rawSyncTime = result.data[0].syncTime;
        const rawDomain = result.data[0].domain;
        const rawAuthorId = result.data[0].authorId;
        var dt = moment.utc(result.data[0].syncTime, ['YYYY-MM-DD HH']);
        var timeValue = moment
          .utc(result.data[0].syncTime, ['YYYY-MM-DD HH'])
          .format('HH');
        that.connectedSyncTime = {
          timeToDisplay: dt.local().format('h:mm A'),
          timeValue: timeValue
        };
        that.id = result.data[0].id;
        that.setLocalTime(that.connectedSyncTimeItems);
        let authorIdToBePassed = null;
        if (that.userType === 'D') {
          authorIdToBePassed = that.settings.schoolSettings.districtId;
        } else {
          authorIdToBePassed = that.settings.schoolSettings.schoolId;
        }
        that.getSyncStatus();
        that
          .getOrgs({
            endpoint: result.data[0].endPointURL,
            accessKey: result.data[0].accessKey,
            secretKey: result.data[0].secretKey,
            firstTimeSetUp: false,
            authorId: authorIdToBePassed
          })
          .then((result: any) => {
            console.log('Connected Mapping Orgs');
            console.log(result.data);
            that.manageMappingOrgsArray = [];
            that.manageMappingOrgsArray = result.data;
            that
              .storeMappingOrgs(
                that.connectedEndPoint,
                that.connectedAccessKey,
                that.connectedSecretKey,
                rawDomain,
                rawSyncTime,
                rawAuthorId
              )
              .then(() => {
                that
                  .getAcademicSessions({
                    endpoint: that.connectedEndPoint,
                    accessKey: that.connectedAccessKey,
                    secretKey: that.connectedSecretKey,
                    firstTimeSetUp: false,
                    domain: rawDomain
                  })
                  .then((result: any) => {
                    console.log('Connected Mapping Academic Sessions');
                    console.log(result.data);
                    that.manageMappingSchoolYearsArray = [];
                    that.manageMappingSchoolYearsArray = result.data;
                    that.storeMappingSchoolYears().then(() => {
                      that
                        .getTeachersForSchool({
                          endpoint: that.connectedEndPoint,
                          accessKey: that.connectedAccessKey,
                          secretKey: that.connectedSecretKey,
                          orgs: that.orgsSourcedIdArray,
                          firstTimeSetUp: false,
                          domain: rawDomain
                        })
                        .then((result: any) => {
                          console.log('Connected Mapping Teachers');
                          console.log(result.data);
                          that.connectedTeachersArray = [];
                          that.storeMappingTeachers().finally(() => {
                            if (!willDisconnect) {
                              that.snackBar = true;
                              that.snackBarText = that.$t('classLinkFinish');
                            }
                            CommonUtils.hideLoading();
                            that.pageInitializing = false;
                          });
                        });
                    });
                  });
              });
          });
      } else {
        CommonUtils.hideLoading();
        this.pageInitializing = false;
        this.isDisconnected = true;
        this.setLocalTime(this.syncTimeItems);
      }
    }
  }

  created() {
    CommonUtils.showLoading();
    this.pageInitializing = true;
    this.goBackToSavedSetupStepState();
    Promise.resolve().then(() => {
      this.setHaveTeachers(false);
      this.setCurrentSchoolId(0);
      return this.loadTeachers();
    })
      .then(() => {
        this.loadGroupAccountSchoolYears().then(() => {
          if (this.isConnected && !this.setupWizard) {
            this.setLocalTime(this.connectedSyncTimeItems);
            this.pageInitializing = false;
            this.syncDoneCheck();
            CommonUtils.hideLoading();
            return Promise.resolve();
          } else {
            return this.initialize();
          }
        }).finally(() => {
          this.isSyncCompleted = false;
        });
      })
  }

  goBackToSavedSetupStepState() {
    // We need to go back to step 2, because we are not saving steps 3 and 4 in local storage.
    if (this.setupStepCount > 2 && CommonUtils.isEmpty(this.teachersMappingArray) && CommonUtils.isEmpty(this.schoolYearsMappingArray)) {
      this.setupStepCount = 2;
    }
  }

  destroyed() {
    this.polling.stop();
  }

  beforeDestroy() {
    this.setHaveTeachers(false);
    this.setCurrentSchoolId(this.primarySchool.schoolId);
  }

  setLocalTime(saveHere: any) {
    var gmtDateTime = moment.utc('01:00', 'HH');
    var local = gmtDateTime.local().format('h:mm A');
    saveHere.push({
      timeToDisplay: local,
      timeValue: '01'
    });
    gmtDateTime = moment.utc('02:00', 'HH');
    local = gmtDateTime.local().format('h:mm A');
    saveHere.push({
      timeToDisplay: local,
      timeValue: '02'
    });
    gmtDateTime = moment.utc('03:00', 'HH');
    local = gmtDateTime.local().format('h:mm A');
    saveHere.push({
      timeToDisplay: local,
      timeValue: '03'
    });
    gmtDateTime = moment.utc('04:00', 'HH');
    local = gmtDateTime.local().format('h:mm A');
    saveHere.push({
      timeToDisplay: local,
      timeValue: '04'
    });
    gmtDateTime = moment.utc('05:00', 'HH');
    local = gmtDateTime.local().format('h:mm A');
    saveHere.push({
      timeToDisplay: local,
      timeValue: '05'
    });
    gmtDateTime = moment.utc('06:00', 'HH');
    local = gmtDateTime.local().format('h:mm A');
    saveHere.push({
      timeToDisplay: local,
      timeValue: '06'
    });
    gmtDateTime = moment.utc('07:00', 'HH');
    local = gmtDateTime.local().format('h:mm A');
    saveHere.push({
      timeToDisplay: local,
      timeValue: '07'
    });
    gmtDateTime = moment.utc('08:00', 'HH');
    local = gmtDateTime.local().format('h:mm A');
    saveHere.push({
      timeToDisplay: local,
      timeValue: '08'
    });
  }

  storeMappingOrgs(
    endpoint: any,
    accessKey: any,
    secretKey: any,
    domain: any,
    syncTime: any,
    authorId: any
  ) {
    const planBookSchoolData: any = [...this.settings.schoolSettings.schools];
    // this.setPlanBookOrgArray();
    this.manageMappingOrgsSelected = [];
    const deletePromises = this.manageMappingOrgsArray.map((element: any) => {
      const planbookListArray = this.setPlanBookOrgArray(element.name);
      element.planbookListArray = planbookListArray;

      element.dropDownDisabled = false;
      if (element.domain !== undefined) {
        element.isRowSelectable = false;
        element.isNew = false;
        element.classLinkOrgName =
          element.name + ' (' + element.sourcedId + ')';
        if (element.districtId !== 0 && element.schoolId === 0) {
          this.mappingDistrictName = element.name;
          element.manageMappingPBSchoolName =
            {
              displayValue: this.settings.schoolSettings.districtName +
            ' (' +
            this.settings.schoolSettings.districtId +
            ')',
              value: {
                schoolName: this.settings.schoolSettings.districtName,
                schoolId: this.settings.schoolSettings.districtId
              }
            };
          element.planBookOrgArray = [];
          if (this.isActive(element.status)) {
            element.isRowSelectable = true;
            if (element.archivalInd === false) {
              this.pushElementInManageMappingOrgsSelected(element);
            }
          }
        } else if (element.districtId === 0 && element.schoolId !== 0) {
          element.isRowSelectable = true;
          planBookSchoolData.forEach((pbElement: any) => {
            if (element.schoolId === pbElement.schoolId) {
              element.manageMappingPBSchoolName =
                   {
                     displayValue: pbElement.schoolName + ' (' + pbElement.schoolId + ')',
                     value: {
                       schoolName: pbElement.schoolName,
                       schoolId: pbElement.schoolId
                     }
                   };
              var index = planBookSchoolData
                .map(function (e: any) {
                  return e.schoolName;
                })
                .indexOf(pbElement.schoolName);
              if (index !== -1) {
                planBookSchoolData.splice(index, 1);
              }
            }
          });
          element.planBookOrgArray = [];
          if (CommonUtils.isFalse(this.isActive(element.status))) {
            element.isRowSelectable = false;
            element.dropDownDisabled = true;
          } else {
            // if Active
            if (element.archivalInd === false) {
              this.pushElementInManageMappingOrgsSelected(element);
            } else {
              element.dropDownDisabled = true;
            }
          }
        } else if (element.districtId === 0 && element.schoolId === 0) {
          element.manageMappingPBSchoolName = 'do-nothing';
          if (element.type === 'district') {
            element.manageMappingPBSchoolName = 0;
          }
          if (element.archivalInd === true) {
            element.dropDownDisabled = true;
          }
          element.planBookOrgArray = [];
          element.isRowSelectable = true;
          if (element.archivalInd === false && this.isActive(element.status)) {
            this.pushElementInManageMappingOrgsSelected(element);
          } else if (CommonUtils.isFalse(this.isActive(element.status))) {
            element.isRowSelectable = false;
            element.dropDownDisabled = true;
          }
        }
      } else {
        element.isNew = true;
        element.isRowSelectable = false;
        element.dropDownDisabled = true;
        element.endPointURL = endpoint;
        element.accessKey = accessKey;
        element.secretKey = secretKey;
        element.domain = domain;
        element.syncTime = syncTime;
        element.authorId = authorId;
        element.classLinkOrgName = element.name + ' (' + element.sourcedId + ')';
        element.manageMappingPBSchoolName = 'do-nothing';
        if (this.isActive(element.status)) {
          element.isRowSelectable = true;
          element.dropDownDisabled = false;
          if (element.type === 'district') {
            element.manageMappingPBSchoolName = 'add';
            element.planBookOrgArray = [];
            this.pushElementInManageMappingOrgsSelected(element);
          } else if (element.type === 'school') {
            element.manageMappingPBSchoolName = 'add';
            // planBookOrgArray.push(element.manageMappingPBSchoolName);
            element.planBookOrgArray = [];
            this.pushElementInManageMappingOrgsSelected(element);
          }
        }
      }
    });
    return Promise.all(deletePromises);
  }

  async storeMappingSchoolYears() {
    this.manageMappingSchoolYearsSelected = [];
    const that = this;
    this.removeSchoolYearsWithoutOrgSourcedId().then(() => {
      const deletePromises = this.manageMappingSchoolYearsArray.map(
        (element: any) => {
          if (element.domain !== undefined) {
            element.isNew = false;
            element.isRowSelectable = true;
            element.classlinkSchoolYearName =
            element.title + ' (' + element.sourcedId + ')';
            const planBookSchoolYearArray: any[] = [];
            planBookSchoolYearArray.push({
              displayValue: this.$t('doNothingLabel'),
              value: 'do-nothing'
            });
            planBookSchoolYearArray.push({
              displayValue: this.$t('addLabel'),
              value: 'add'
            });
            element.manageMappingPBSchoolYearName = 'do-nothing';
            this.groupAccountSchoolYears.forEach((pbElement:any) => {
              if (+pbElement.yearId === +element.yearId) {
                element.manageMappingPBSchoolYearName = pbElement.yearName + ' (' + pbElement.yearId + ')';
              }
            });

            element.planBookSchoolYearArray = planBookSchoolYearArray;
            if (element.archivalInd === true) {
              element.dropDownDisabled = true;
            }
            if (element.withClasses === false) {
              element.isRowSelectable = false;
            }

            if (CommonUtils.isFalse(that.isActive(element.status))) {
              element.isRowSelectable = false;
              if (element.yearId === 0) {
                element.manageMappingPBSchoolYearName = 'do-nothing';
              }
            }
            if (
              element.archivalInd === false &&
            element.withClasses === true &&
            element.manageMappingPBSchoolYearName !== 'do-nothing' &&
            that.isActive(element.status)
            ) {
              that.pushElementInManageMappingSchoolYearsSelected(element);
            }
          } else {
            element.domain = this.connectedDomain;
            element.isNew = true;
            element.isRowSelectable = true;
            element.yearId = 0;
            const planBookSchoolYearArrayNoDomain: any[] = [];
            planBookSchoolYearArrayNoDomain.push({
              displayValue: this.$t('doNothingLabel'),
              value: 'do-nothing'
            });
            planBookSchoolYearArrayNoDomain.push({
              displayValue: this.$t('addLabel'),
              value: 'add'
            });
            element.classlinkSchoolYearName =
            element.title + ' (' + element.sourcedId + ')';
            element.manageMappingPBSchoolYearName = 'do-nothing';
            if (+element.schoolYear >= moment().year() && that.isActive(element.status)) {
              element.manageMappingPBSchoolYearName = 'add';
            }
            if (element.withClasses === false || CommonUtils.isFalse(that.isActive(element.status))) {
              element.isRowSelectable = false;
              element.dropDownDisabled = true;
            }
            element.planBookSchoolYearArray = planBookSchoolYearArrayNoDomain;
            if (element.withClasses === true && element.manageMappingPBSchoolYearName !== 'do-nothing' && that.isActive(element.status)) {
              that.pushElementInManageMappingSchoolYearsSelected(element);
            }
          }
        }
      );
      this.manageMappingOrgsArray.forEach((element:any) => {
        const found = that.manageMappingOrgsSelected.some((e:any) => e.sourcedId === element.sourcedId);
        if (!found) {
          that.uncheckedAndCheckedOrgSourcedId = element.sourcedId;
          that.isOrgsCheckBoxTrueorFalse = false;
          that.hideHiddenSchoolYearForCheckbox();
        }
      });
      return Promise.all(deletePromises);
    })
  }

  storeMappingTeachers() {
    this.manageMappingTeachersSelected = [];
    // this.setPlanBookTeacherArray();
    const that = this;
    const deletePromises = ld.cloneDeep(this.allMappingTeachers).map(this.mapTeacherElement);
    this.manageMappingOrgsArray.forEach((element:any) => {
      const found = that.manageMappingOrgsSelected.some((e:any) => e.sourcedId === element.sourcedId);
      if (!found) {
        that.uncheckedAndCheckedOrgSourcedId = element.sourcedId;
        that.isOrgsCheckBoxTrueorFalse = false;
        that.hideHiddenTeacherForCheckbox();
      }
    });
    return Promise.all(deletePromises);
  }

  mapTeacherElement(element:any) {
    const planbookListArray = this.setPlanBookTeacherArray(element.email || element.username);
    element.planbookListArray = planbookListArray;
    element.dropDownDisabled = false;

    if (CommonUtils.hasValue(element.domain)) {
      this.handleMappedTeacher(element);
    } else {
      this.handleNewTeacher(element);
    }
    const duplicateRefUserMappingRecord = this.getDuplicateRefUserMappingRecord(element);
    const duplicateRefUserMappedToAdd = this.getDuplicateRefUserMappedToAdd(element);
    if (CommonUtils.isFalse(element.duplicate) ||
            (CommonUtils.isFalse(this.connectedTeachersArray.some((e:any) => e.email === element.email)) && // Check if the current element's email is not already in the table's array, since we now allow only one instance of each duplicate.
              ((!CommonUtils.hasValue(duplicateRefUserMappingRecord) && !CommonUtils.hasValue(duplicateRefUserMappedToAdd)) || // check if all the duplicates does NOT have any mapping record in refuser and does NOT have any to be added record.
              (!CommonUtils.hasValue(duplicateRefUserMappingRecord) && CommonUtils.hasValue(duplicateRefUserMappedToAdd) && duplicateRefUserMappedToAdd.sourcedId === element.sourcedId) || // check if all the duplicates does NOT have any mapping record in refuser and does have to be added record.
              (CommonUtils.hasValue(duplicateRefUserMappingRecord) && duplicateRefUserMappingRecord.sourcedId === element.sourcedId)) // check if any of the duplicates already have mapping record in refuser and that mapping record is the current element
            )
    ) {
      if (element.duplicate) {
        // sets the initial selected classlink teacher
        element.classlinkTeacherValue = {
          displayValue: (element.email || element.username) + ' (' + element.sourcedId + ')',
          value: {
            teacherEmail: element.email,
            sourcedId: element.sourcedId
          },
          disabled: CommonUtils.isFalse(this.isActive(element.status))
        }
      }
      this.pushElementInConnectedTeachersArray(element);
    }
    if ((element.isNew || CommonUtils.isFalse(element.archivalInd)) && this.isActive(element.status) &&
            (CommonUtils.isFalse(element.duplicate) ||
              (CommonUtils.isFalse(this.manageMappingTeachersSelected.some((e:any) => e.email === element.email)) && // Check if the current element's email is not already in the table's array, since we now allow only one instance of each duplicate.
                ((!CommonUtils.hasValue(duplicateRefUserMappingRecord) && !CommonUtils.hasValue(duplicateRefUserMappedToAdd)) || // check if all the duplicates does NOT have any mapping record in refuser and does NOT have any to be added record.
                (!CommonUtils.hasValue(duplicateRefUserMappingRecord) && CommonUtils.hasValue(duplicateRefUserMappedToAdd) && duplicateRefUserMappedToAdd.sourcedId === element.sourcedId) || // check if all the duplicates does NOT have any mapping record in refuser and does have to be added record.
                (CommonUtils.hasValue(duplicateRefUserMappingRecord) && duplicateRefUserMappingRecord.sourcedId === element.sourcedId)) // check if any of the duplicates already have mapping record in refuser and that mapping record is the current element
              )
            )
    ) {
      this.pushElementInManageMappingTeachersSelected(element);
    }
    return element;
  }

  handleMappedTeacher(element:any) {
    element.isRowSelectable = true;
    element.isNew = false;
    element.classLinkTeacherName = (element.email || element.username) + ' (' + element.sourcedId + ')';
    element.orgSourcedId = element.orgs[0].sourcedId;
    element.orgsSourcedIdArray = element.orgs;
    element.manageMappingPBTeacherName = 'do-nothing';
    const pbTeacher = this.getTeachersAll.find(pbElement => pbElement.userId === element.teacherId);
    if (pbTeacher) {
      element.manageMappingPBTeacherName = {
        displayValue: pbTeacher.emailAddress + ' (' + pbTeacher.userId + ')',
        value: {
          teacherName: pbTeacher.emailAddress,
          teacherId: pbTeacher.userId
        }
      };
    }

    // This means the teacher in Planbook side has been previously removed from the school, that is why it will not be available in Dropdown Teacher List.
    if (this.isTeacherRemoved(element.teacherId)) {
      this.restoreTeacher(element);
    }

    if (CommonUtils.isFalse(this.isActive(element.status))) {
      element.isRowSelectable = false;
    }
    if (CommonUtils.isTrue(element.archivalInd) || CommonUtils.isFalse(this.isActive(element.status))) {
      element.dropDownDisabled = true;
    }
  }

  isTeacherRemoved(teacherId:any):boolean {
    return teacherId > 0 && CommonUtils.isFalse(this.getTeachersAll.some((t:any) => t.userId === teacherId));
  }

  restoreTeacher(element:any) {
    const teacherValue = { teacherName: element.emailAddress, teacherId: element.teacherId };
    const teacherForRestore = element.emailAddress + ' (' + element.teacherId + ')';
    element.manageMappingPBTeacherName = {
      displayValue: teacherForRestore,
      value: teacherValue
    };
    element.planbookListArray.push({
      displayValue: teacherForRestore,
      value: teacherValue
    });
  }

  handleNewTeacher(element:any) {
    element.domain = this.connectedDomain;
    element.classLinkTeacherName = (element.email || element.username) + ' (' + element.sourcedId + ')';
    element.orgSourcedId = element.orgs[0].sourcedId;
    element.orgsSourcedIdArray = element.orgs;
    element.manageMappingPBTeacherName = 'add';
    element.isNew = true;
    element.isRowSelectable = true;
    if (CommonUtils.isFalse(this.isActive(element.status))) {
      element.isRowSelectable = false;
      element.dropDownDisabled = true;
      element.manageMappingPBTeacherName = 'do-nothing';
    }
  }

  getDuplicateRefUserMappingRecord(element:any) {
    let duplicateRefUserMappingRecord = null;
    if (element.duplicate) {
      const duplicates = this.allMappingTeachers.filter(user => user.email === element.email);
      duplicateRefUserMappingRecord = duplicates.find(d => CommonUtils.hasValue(d.domain) && d.teacherId > 0);
    }
    return duplicateRefUserMappingRecord;
  }

  getDuplicateRefUserMappedToAdd(element:any) {
    let duplicateRefUserMappedToAdd = null;
    if (element.duplicate) {
      const duplicates = this.allMappingTeachers.filter(user => user.email === element.email);
      duplicateRefUserMappedToAdd = duplicates.find(d => !CommonUtils.hasValue(d.domain) && this.isActive(d.status));
    }
    return duplicateRefUserMappedToAdd;
  }

  setPlanBookOrgArray(elementTobeSearched:any):Array<any> {
    const localPlanBookOrgArray:Array<any> = [];
    localPlanBookOrgArray.push({
      displayValue: this.$t('doNothingLabel'),
      value: 'do-nothing'
    });
    localPlanBookOrgArray.push({
      displayValue: this.$t('addLabel'),
      value: 'add'
    });

    const options = {
      includeScore: true,
      keys: ['schoolName']
    };
    const fuse = new Fuse(this.settings.schoolSettings.schools, options);
    const output: any = fuse.search(elementTobeSearched);

    output.forEach((element:any) => {
      localPlanBookOrgArray.push(
        {
          displayValue: element.item.schoolName + ' (' + element.item.schoolId + ')',
          value: {
            schoolName: element.item.schoolName,
            schoolId: element.item.schoolId
          }
        }
      );
    });

    this.settings.schoolSettings.schools.forEach((element: any) => {
      try {
        const displayValue = element.schoolName + ' (' + element.schoolId + ')';
        const found = localPlanBookOrgArray.some((e:any) => e.displayValue === displayValue);
        if (!found) {
          localPlanBookOrgArray.push(
            {
              displayValue: displayValue,
              value: {
                schoolName: element.schoolName,
                schoolId: element.schoolId
              }
            }
          );
        }
      } catch (e) {
        console.log(e);
      }
    });

    return localPlanBookOrgArray;
  }

  setPlanBookTeacherArray(elementTobeSearched:any) {
    const localPlanBookTeacherArray:any = [];
    localPlanBookTeacherArray.push({
      displayValue: this.$t('doNothingLabel'),
      value: 'do-nothing'
    });
    localPlanBookTeacherArray.push({
      displayValue: this.$t('addLabel'),
      value: 'add'
    });

    const options = {
      includeScore: true,
      keys: ['emailAddress']
    };
    const fuse = new Fuse(this.getTeachersAll, options);
    const output: any = fuse.search(elementTobeSearched);

    output.forEach((element:any) => {
      localPlanBookTeacherArray.push(
        {
          displayValue: element.item.emailAddress + ' (' + element.item.userId + ')',
          value: {
            teacherName: element.item.emailAddress,
            teacherId: element.item.userId
          }
        }
      );
    });

    this.getTeachersAll.forEach(
      (pbElement: any) => {
        const displayValue = pbElement.emailAddress + ' (' + pbElement.userId + ')';
        const found = localPlanBookTeacherArray.some((e:any) => e.displayValue === displayValue);
        if (!found) {
          localPlanBookTeacherArray.push(
            {
              displayValue: displayValue,
              value: {
                teacherName: pbElement.emailAddress,
                teacherId: pbElement.userId
              }
            }
          );
        }
      }
    );

    return localPlanBookTeacherArray;
  }

  formatDate(date:string) {
    return moment(date, 'YYYY-MM-DD').format('MM/DD/YYYY');
  }

  isActive(status:string):boolean {
    return CommonUtils.hasValue(status) && status.toLowerCase() === 'active';
  }

  onClasslinkDuplicateTeacherChange(item:any, event:any) {
    this.isManageMappingTeachersNotDirty = false;
    const clonedEvent = ld.cloneDeep(event);
    const clonedTeachers:Array<any> = ld.cloneDeep(this.allMappingTeachers);
    const clonedTargetTeacher = clonedTeachers.find(t => t.sourcedId === event.sourcedId);
    const isTargetInactive:boolean = CommonUtils.isFalse(this.isActive(clonedTargetTeacher.status));
    item.classlinkTeacherValue = {
      displayValue: clonedEvent.teacherEmail + ' (' + clonedEvent.sourcedId + ')',
      value: clonedEvent,
      disabled: isTargetInactive
    };
    if (CommonUtils.hasValue(clonedTargetTeacher)) {
      const isCurrentRecordSelected:boolean = this.getAllConnectedOrSetupSelectedTeachersSourcedIds.includes(item.sourcedId);
      if (isTargetInactive || isCurrentRecordSelected) {
        this.handleInactiveOrSelectedTeacher(item, isTargetInactive);
      }
      // update the element data of the table item to the selected classlink record
      this.updateItemWithTargetTeacherData(item, clonedTargetTeacher, isTargetInactive, isCurrentRecordSelected);
      if (isCurrentRecordSelected && CommonUtils.isFalse(isTargetInactive) &&
          CommonUtils.isFalse(this.getAllConnectedOrSetupSelectedTeachersSourcedIds.includes(item.sourcedId)) // check if the updated sourcedId is not yet in selected arrays
      ) {
        if (this.setupWizard) {
          this.pushElementInSelectedTeacher(item);
        } else {
          this.pushElementInManageMappingTeachersSelected(item);
        }
      }
    }
  }

  handleInactiveOrSelectedTeacher(item:any, isTargetInactive:boolean) {
    const indexOfSelected = this.getAllConnectedOrSetupSelectedTeachersSourcedIds.findIndex((sourcedId: any) => sourcedId === item.sourcedId);
    if (indexOfSelected !== -1) {
      if (this.setupWizard) {
        if (isTargetInactive && item.setUpPBTeacherName === 'add') {
          item.setUpPBTeacherName = 'do-nothing';
        }
        this.spliceElementInSelectedTeacher(indexOfSelected);
      } else {
        if (isTargetInactive && item.manageMappingPBTeacherName === 'add') {
          item.manageMappingPBTeacherName = 'do-nothing';
        }
        this.spliceElementInManageMappingTeachersSelected(indexOfSelected);
      }
    }
  }

  updateItemWithTargetTeacherData(item: any, clonedTargetTeacher: any, isTargetInactive:boolean, isCurrentRecordSelected:boolean) {
    const { manageMappingPBTeacherName, setUpPBTeacherName, planbookListArray, classlinkTeacherValue, ...remaining } = item; // exclude elements that do not need to be updated
    Object.keys(remaining).forEach(key => {
      item[key] = CommonUtils.getOrDefaultTo(clonedTargetTeacher[key], item[key]);
    });
    item.isRowSelectable = this.isActive(item.status);
    item.dropDownDisabled = isTargetInactive || CommonUtils.isFalse(isCurrentRecordSelected);
  }
}
