

















































































































































































































































































































































































































































































































































































































































































































import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import moment, { Moment } from 'moment';
import { englishAlphabet, swatches, allowedGracePeriodDays, planbookColors } from '../../constants';
import Confirm from './../../components/core/Confirm.vue';
import ClassAlternateDayEditor from './../../components/classes/ClassAlternateDayEditor.vue';
import ld from 'lodash';
import { VForm } from 'vuetify/lib';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import ClassesMixin from '@/mixins/classes-mixin';
import ManageLessons from '@/components/classes/ManageLessons.vue';
import RosteredItemIndicator from '@/components/integration/RosteredItemIndicator.vue';
import ClassActions from '@/components/classes/ClassActions.vue';
import html2canvas from 'html2canvas';
import JsPDF from 'jspdf';
import { PlanbookStorage } from '@/common/planbook-storage';

const settings = namespace('settings');
const classes = namespace('classes');
const plans = namespace('plans')
const google = namespace('google');
const index = namespace('index');
const integration = namespace('integration');
@Component({
  mixins: [PageLifeCycleMixin, ClassesMixin],
  components: {
    ClassAlternateDayEditor,
    ManageLessons,
    RosteredItemIndicator,
    ClassActions
  }
})
export default class Classes extends Vue {
  editMode!: boolean;
  fullEditorEditMode!: boolean;
  scheduleName!: string;
  isRegularSchedule!: boolean;
  scheduleAsOfFC!: string;
  isEditingFromClassStart!: boolean;
  localRefreshKey = CommonUtils.generateUUID();
  localFCEvents: any[] = [];
  localIsEditingClass = false;
  localSelectedClassClone: any = {};
  localOldSchedule = [];
  localSelectedClassScheduleArrayClone: any = [];
  localClassScheduleArrayStartDates: any = [];
  localIsGoogleConnected = false;
  localOldAddedClassDays = [];
  localOldNoClassDays = [];
  localClassScheduleFrequencySelected = 'D';
  isDeletingSchedule = false;
  rescheduleClass = false;
  noTimeSpecified:any = null;
  rescheduleClassStartTime = '';
  rescheduleClassEndTime = '';
  daysToRescheduleClass:any[] = [];
  eventClickInfo: any = {};
  isApplyingOnlyThisDay = false;
  isApplyingToAllDays = false;
  scheduleShiftToAnotherDay = false;
  isEventDrop = false;
  eventDropInfo: any = {};
  externalDrop = false;
  oldEventDropTeachDayName = '';
  externalDropClassId:any = '';
  externalDropTime:any = '';
  externalDropResourceId:any = '';
  externalDuplicateSchedule = false;
  externalAllDay = false;
  rescheduleClassTimeRangeAvailable = false;
  localIsModal = true;
  alternateDayModal = false;
  specialDayIdSelected:any = 0;
  specialDayDescription:any = '';
  localAlternateDayTitle = '';
  localSpecialDayId = -1;
  localSpecialDayDescription = '';
  localSpecialDayAction = '';
  extraDayClassName = '';
  extraDayScheduleId:any = '';
  newDropLocation:any = '';
  isDroppingToAllDay = false;
  scheduleClassModal = false;
  localDayTeachAll = true;
  revertStorageForExternalEvent:any = null;
  revertStorageForFCEvent:any = null;
  updatingExtraDayCode = null;
  localInput = { action: '', isModal: false, className: '', color: '' };
  isClassExtraDay = false;
  localNewClass: any = null;
  localFoundInOtherDays = false;
  localCycleDaysMap: Map<string, string> = new Map();
  localDayTeach: boolean[] = [];
  showManageLessons = false;
  viewListSchedule = 0;
  alternateDayClassTimes: any = [];
  focusItem = '';
  isPrinting = '';
  printWidth = 800;

  slotDurationOptions = [
    { text: ('1 ' + this.$t('minuteLabel')).toLocaleLowerCase(), slot: '00:01:00', length: 1, value: '1', lineHeight: '0.15em' },
    { text: ('5 ' + this.$t('minutesLabel')).toLocaleLowerCase(), slot: '00:05:00', length: 5, value: '5', lineHeight: '0.4em' },
    { text: ('10 ' + this.$t('minutesLabel')).toLocaleLowerCase(), slot: '00:10:00', length: 10, value: '10', lineHeight: '.96em' },
    { text: ('15 ' + this.$t('minutesLabel')).toLocaleLowerCase(), slot: '00:15:00', length: 15, value: '15', lineHeight: '1.5em' },
    { text: ('30 ' + this.$t('minutesLabel')).toLocaleLowerCase(), slot: '00:30:00', length: 30, value: '30', lineHeight: '3.14em' },
    { text: ('1 ' + this.$t('hourLabel')).toLocaleLowerCase(), slot: '01:00:00', length: 60, value: '60', lineHeight: '6.4em' }
  ];

  defaultSlot = this.slotDurationOptions[4];
  slotDuration = this.defaultSlot;

  $refs!: {
    classesConfirm: Confirm,
    fullCalendar: any,
    classEditor: any & { isDirty: () => boolean },
    classEditorForm: InstanceType<typeof VForm>,
    rescheduleObserver: any,
    applyScheduleObserver: any
  }

  localRules = [
    (v: any) => !!v || 'Required'
  ];

  @classes.State
  selectedClass!: any;

  @index.Getter
  hasBanner!: boolean;

  @settings.Getter
  getCurrentYear!: any;

  @settings.Getter
  getUserMode!: any;

  @settings.Getter
  getSettings!: any;

  @settings.Getter
  getSchoolYearSettings!: any;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter('getTimeFormat')
  timeFormat!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter('darkMode')
  darkMode!: boolean;

  @google.Getter
  getIsConnectedToGoogle!: boolean;

  @classes.Getter
  startTime24HrFmt!: any;

  @classes.Getter
  endTime24HrFmt!: any;

  @classes.Getter
  cycleDays!: any;

  @classes.Getter
  getClassesMap!: any;

  @classes.Getter
  classScheduleDefault!: any;

  @classes.State
  classes!: any;

  @classes.Mutation
  reset!: any;

  @classes.Action
  addClass!: (params?: any) => Promise<any>;

  @classes.Action
  updateClass!: (params?: any) => Promise<any>;

  @classes.Action
  loadClasses!: () => Promise<any>;

  @classes.Action
  getClass!: (params?: any) => Promise<any>;

  @classes.Action
  deleteClass!: (params?: any) => Promise<any>;

  @classes.Action
  loadSpecialDays!: () => Promise<any>;

  @classes.Action
  updateSpecialDays!: (params?: any) => Promise<any>;

  @classes.Action
  removeLinkedLessons!: (params?: any) => Promise<any>;

  @classes.Action('export')
  doExport!: (params: any) => Promise<any>;

  @classes.State
  specialDays!: any;

  @classes.Mutation
  resetClass!: any;

  @classes.Mutation
  setClass!: any;

  @classes.Mutation
  resetSpecialDays!: any;

  @plans.Action
  changePlans!: (param?: any) => Promise<any>;

  @google.Action
  loadGoogleClasses!: () => Promise<any>;

  @integration.Getter
  isSubjectRostered!: (param: any) => boolean;

  @Watch('editMode')
  warnUserThatSchedulesWillBeRemoved() {
    if (this.isEditingFromClassStart) {
      this.scheduleAsOfFC = moment().format('MM/DD/YYYY');
      this.$refs.classesConfirm.confirm({
        title: this.$t('editScheduleLabel'),
        text: this.$t('startNewScheduleOnClassStartDateMsg'),
        option1ButtonAlternativeText: this.$t('continueLabel')
      }).then((result) => {
        if (result !== 1) {
          this.isEditingFromClassStart = false;
          this.editMode = false;
        }
      });
    }
  }

  @Watch('slotDuration')
  updateSlotDuration() {
    PlanbookStorage.set('classesSlotDuration', this.slotDuration);
  }

  @Watch('scheduleAsOfFC')
  scheduleAsOfFCChanged() {
    this.localFCEvents = this.getFCEvents();
    this.localRefreshKey = CommonUtils.generateUUID();
  }

  @Watch('rescheduleClass')
  updateFormVariable(v: any) {
    if (!v) {
      this.isApplyingOnlyThisDay = false;
      this.isApplyingToAllDays = false;
      this.isDeletingSchedule = false;
      this.rescheduleClassTimeRangeAvailable = false;
    }
  }

  get mCurrentDate() {
    return moment();
  }

  get mCurrentYearFirstDay() {
    return moment(this.getCurrentYear.firstDay, 'MM/DD/YYYY');
  }

  get mCurrentYearLastDay() {
    return moment(this.getCurrentYear.lastDay, 'MM/DD/YYYY');
  }

  get mCurrentYearName() {
    return this.getCurrentYear.yearName;
  }

  get dateFormat() {
    let showFormat = 'MM/DD/YYYY';
    const dateFormat = +this.userInfo.displaySettings.dateStyling.dateFormat;
    if (dateFormat === 2) {
      showFormat = 'DD/MM/YYYY';
    }
    return showFormat;
  }

  get dateDelimiter() {
    return this.userInfo.displaySettings.dateStyling.dateDelimiter;
  }

  get dateFormatForMoment() {
    let dateFormat = 'MM' + this.dateDelimiter + 'DD' + this.dateDelimiter + 'YYYY';
    if (+this.userInfo.displaySettings.dateStyling.dateFormat === 2) {
      dateFormat = 'DD' + this.dateDelimiter + 'MM' + this.dateDelimiter + 'YYYY';
    }

    return dateFormat;
  }

  get shiftLessons() {
    if (this.externalDrop && this.externalDuplicateSchedule) {
      return true;
    } else {
      if (this.isDeletingSchedule) {
        return true;
      } else {
        return false;
      }
    }
  }

  get slidingSheetWidth() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return '100%'
      case 'sm': return '64%'
      case 'md': return '64%'
      case 'lg': return '64%'
      case 'xl': return '74%'
    }
  }

  get slidingSheetMarginTop() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs': return 'mt-0'
      case 'sm': return 'mt-4'
      case 'md': return 'mt-4'
      case 'lg': return 'mt-4'
      case 'xl': return 'mt-4'
    }
  }

  get localFilteredClasses() {
    return this.classes.filter((c: any) => !c.collaborateType || c.collaborateType !== 4).map((c:any) => {
      c.isItemRostered = this.isSubjectRostered(c);
      return c;
    });
  }

  get externalClasses() {
    const that = this;
    if (this.isRegularSchedule) {
      return this.localFilteredClasses;
    } else {
      // subjectId
      const filteredClasses:any[] = [];
      const specialDays = this.specialDays;
      specialDays.forEach((specialDay:any) => {
        if (that.specialDayIdSelected === specialDay.specialDayId) {
          that.localFilteredClasses.forEach((c:any) => {
            const found = specialDay.classes.some((e:any) => e.subjectId === c.cId);
            if (!found) {
              filteredClasses.push(c);
            }
          });
        }
      });
      return filteredClasses;
    }
  }

  get scheduleAsOfLabel() {
    if (this.editMode) {
      return this.$t('editingScheduleAsOfLabel')
    } else {
      return this.$t('viewingScheduleAsOfLabel')
    }
  }

  get noTimeSpecifiedTrue() {
    if (this.noTimeSpecified === null || this.noTimeSpecified === undefined) {
      return false;
    } else {
      return true;
    }
  }

  get isNewClass(): boolean {
    return !(this.localSelectedClassClone.classId && this.localSelectedClassClone.classId > 0);
  }

  get contentHeight(): string {
    return (CommonUtils.getBrowserHeight() - 112) + 'px';
  }

  get classSchedule(): any {
    if (this.getSchoolYearSettings.classCycle === 'oneWeek') {
      return this.classScheduleDefault[0];
    } else if (this.getSchoolYearSettings.classCycle === 'twoWeeks') {
      return this.classScheduleDefault[1]
    } else {
      return this.classScheduleDefault[2]
    }
  }

  get fullCalendarClass() {
    if (this.slotDuration.length === 15) {
      return '';
    }
    return 'slotSizing' + this.slotDuration.value;
  }

  get calendarOptions(): any {
    const that = this;
    const dataTobeReturned = {
      schedulerLicenseKey: this.$t('schedulerLicenseKey'),
      editable: this.editMode,
      droppable: !this.editMode,
      plugins: [
        interactionPlugin, resourceTimeGridPlugin
      ],
      height: this.isPrinting === 'pdf' ? 'auto' : '94%',
      headerToolbar: {
        left: '',
        center: '',
        right: ''
      },
      initialView: 'resourceTimeGridDay',
      slotDuration: this.slotDuration.slot,
      slotLabelInterval: !this.isRegularSchedule || this.slotDuration.length < 10 ? '00:30' : '01:00',
      expandRows: true,
      allDaySlot: true,
      allDayContent: this.$t('noTimeSpecifiedLabel'),
      weekends: true,
      nowIndicator: false,
      resources: this.isRegularSchedule ? this.cycleDays
        : [
          {
            businessHours: {
              endTime: '24:00:00',
              startTime: '00:00:00'
            },
            id: 'alternateDay',
            title: this.scheduleName
          }
        ],
      slotMinTime: this.startTime24HrFmt,
      slotMaxTime: this.endTime24HrFmt,
      events: this.isRegularSchedule ? this.localFCEvents : this.fcAlternateDayEvents,
      eventResizableFromStart: this.editMode,
      eventTimeFormat: this.timeFormat === 1 ? {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      } : undefined,
      slotLabelFormat: this.timeFormat === 1 ? {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      } : undefined,
      eventDidMount: function(arg:any) {
        arg.event.setExtendedProp('el', arg.el);
        var elementToAppend = '';
        if (that.editMode) {
          elementToAppend = '<div class="fcDeleteEventBtn"><span class="fa-stack"><i class="fas fa-square"></i><i class="fas fa-trash-alt"></i></span></div>';
          arg.el.insertAdjacentHTML('beforeend', elementToAppend);
          arg.el.lastChild.onclick = function(event:any) {
            event.stopPropagation();
            if (that.isRegularSchedule) {
              that.onEventDelete(arg);
            } else {
              arg.event.remove();
              that.changeAlternateDaySchedule();
            }
          }
        }
      },
      eventReceive: function(info:any) {
        that.revertStorageForExternalEvent = info;
      },
      drop: function(dropInfo:any) {
        that.externalDrop = true;
        that.externalDropClassId = dropInfo.draggedEl.childNodes[0].children[1].children[1].innerText;
        that.externalDropResourceId = dropInfo.resource.id;
        that.externalAllDay = dropInfo.allDay;
        that.externalDropTime = moment(dropInfo.dateStr);

        if (!that.isRegularSchedule) {
          that.scheduleClassModal = false;
          setTimeout(() => {
            that.changeAlternateDaySchedule().then(() => {
              that.revertStorageForExternalEvent.revert();
            });
          }, 300);
        } else {
          if (that.externalDuplicateSchedule) {
            that.changeSchedule();
          } else {
            if (dropInfo.allDay) {
              that.noTimeSpecified = 0;
              that.rescheduleClassStartTime = '';
              that.rescheduleClassEndTime = '';
              that.rescheduleClassTimeRangeAvailable = true;
            } else {
              that.noTimeSpecified = null;
              that.rescheduleClassTimeRangeAvailable = true;
              that.rescheduleClassStartTime = moment(that.externalDropTime).format('hh:mm A');
              that.rescheduleClassEndTime = moment(that.externalDropTime).add(1, 'hours').format('hh:mm A');
            }
            that.scheduleClassModal = true;
            that.localDayTeachAll = true;
            that.toggleLocalDayTeachCheckboxes();
          }
        }
      },
      eventDrop: function(eventDropInfo:any) {
        that.revertStorageForFCEvent = eventDropInfo;
        that.isEventDrop = true;
        if (that.isRegularSchedule) {
          that.eventDropInfo = eventDropInfo;
          const resourceId = eventDropInfo.oldEvent.id.split('.')[2];
          that.oldEventDropTeachDayName = resourceId.split('-')[1];
          that.checkEventInOtherDays(eventDropInfo);
          that.getOtherImpactedDays(eventDropInfo);
          if (eventDropInfo.event.allDay) {
            that.noTimeSpecified = 0;
            that.rescheduleClassStartTime = '';
            that.rescheduleClassEndTime = '';
            that.rescheduleClassTimeRangeAvailable = true;
          } else {
            that.noTimeSpecified = null;
            that.rescheduleClassStartTime = moment(eventDropInfo.event.startStr).format('hh:mm A');
            that.rescheduleClassEndTime = eventDropInfo.event.endStr && eventDropInfo.event.endStr !== '' ? moment(eventDropInfo.event.endStr).format('hh:mm A') : moment(eventDropInfo.event.startStr).add(1, 'hours').format('hh:mm A');
            that.rescheduleClassTimeRangeAvailable = true;
          }
          that.rescheduleClass = true;
        } else {
          that.changeAlternateDaySchedule();
        }
      },
      eventResize: function(event:any) {
        if (event.event.classNames[0] === 'fc-extra') {
          that.updatingExtraDayCode = event.event.extendedProps.extraClass;
        }
        that.onEventResize(event);
      },
      eventMouseEnter: function(mouseEnterInfo:any) {
        if (mouseEnterInfo.event.classNames[0] === 'fc-extra') {
          that.isClassExtraDay = true;
        } else {
          that.isClassExtraDay = false;
        }
        if (!that.editMode) {
          return false;
        }
        mouseEnterInfo.el.lastChild.style.display = 'block';
      },
      eventMouseLeave: function(mouseLeaveInfo:any) {
        if (!that.editMode) {
          return false;
        }
        mouseLeaveInfo.el.lastChild.style.display = 'none';
      },
      eventAllow: function(dropLocation:any, draggedEvent:any) {
        if (that.isRegularSchedule) {
          const cSt = draggedEvent.extendedProps.cSt;
          const cEt = draggedEvent.extendedProps.cEt;

          if (that.allowedToEdit) {
            if (dropLocation.allDay) {
              that.isDroppingToAllDay = true;
            } else {
              that.isDroppingToAllDay = false;
            }
            let sourceResourceId = '';
            let dropLocationResourceId = '';
            if (dropLocation.resource.id && dropLocation.resource.id !== '') {
              dropLocationResourceId = dropLocation.resource.id;
            }
            if (draggedEvent.id && draggedEvent.id !== '') {
              sourceResourceId = draggedEvent.id.split('.')[2];
            }
            if (dropLocation.resource.id === sourceResourceId) {
              that.scheduleShiftToAnotherDay = false;
            } else {
              that.scheduleShiftToAnotherDay = true;
              that.newDropLocation = dropLocation.resource.id.split('-')[1];
            }

            const resource = that.$refs.fullCalendar.getApi().getResourceById(dropLocationResourceId);
            const events = resource.getEvents();

            if (cSt !== undefined && cEt !== undefined) {
              if ((moment(that.scheduleAsOfFC, 'MM/DD/YYYY').isSameOrBefore(moment(cEt, that.dateFormatForMoment)) && moment(that.scheduleAsOfFC, 'MM/DD/YYYY').isSameOrAfter(moment(cSt, that.dateFormatForMoment)))) {
                that.externalDuplicateSchedule = false;
                events.forEach((eventVal:any) => {
                  if (eventVal.extendedProps.classId === +draggedEvent.extendedProps.classId) {
                    that.externalDuplicateSchedule = true;
                  }
                });
                return true;
              } else {
                return false;
              }
            } else {
              if (draggedEvent.extendedProps.extraClass === 0) {
                var existingOrigScheduleFound = false;
                events.forEach((eventVal:any) => {
                  if (draggedEvent.extendedProps.classId === eventVal.extendedProps.classId && eventVal.extendedProps.extraClass === 0 && dropLocationResourceId !== sourceResourceId) {
                    existingOrigScheduleFound = true;
                    return false;
                  }
                });
                return !existingOrigScheduleFound;
              } else {
                return true;
              }
            }
          } else {
            return false;
          }
        } else {
          if (dropLocation.allDay) {
            that.isDroppingToAllDay = true;
          } else {
            that.isDroppingToAllDay = false;
          }
          return true;
        }
      },
      eventClick: function(eventClickInfo:any) {
        if (!that.editMode) return false;
        if (that.isRegularSchedule) {
          that.checkEventInOtherDays(eventClickInfo);
          that.onEventClick(eventClickInfo);
        } else {
          that.onEventClickAlternateSchedule(eventClickInfo);
        }
      }
    };
    return dataTobeReturned;
  }

  isColorReadable(color: string) {
    return CommonUtils.isColorReadable(color);
  }

  checkEventInOtherDays(eventInfo: any) {
    this.localFoundInOtherDays = false;
    if (eventInfo && eventInfo.event) {
      var selector = eventInfo.event.classNames[3];
      var elements = document.getElementsByClassName(selector);
      if (elements && elements.length > 1) {
        this.localFoundInOtherDays = true;
      }
    }
  }

  @Watch('specialDayIdSelected')
  viewAlternateSchedule() {
    this.alternateDayClassTimes = this.alternateDayEventsList;
  }

  get alternateDayEventsList() {
    const that = this;
    const fcAlternateDayEvents: any = []
    const specialDays = this.specialDays;
    specialDays.forEach((specialDay:any) => {
      const allSpecialDayClasses = specialDay.classes;
      if (that.specialDayIdSelected === specialDay.specialDayId) {
        for (const i in this.localFilteredClasses) {
          const related = allSpecialDayClasses.find((c: any) => c.subjectId === this.localFilteredClasses[i].cId);
          if (related) {
            related.className = this.localFilteredClasses[i].cN;
            related.classColor = this.localFilteredClasses[i].cC;
            fcAlternateDayEvents.push(related);
          } else {
            fcAlternateDayEvents.push({
              endTime: '',
              specialDayId: 0,
              specialDaySubjectId: 0,
              startTime: '',
              subjectId: this.localFilteredClasses[i].cId,
              className: this.localFilteredClasses[i].cN,
              classColor: this.localFilteredClasses[i].cC,
              teach: 'N'
            })
          }
        }
      }
    });
    return fcAlternateDayEvents;
  }

  async updateAlternateScheduleTeach(classId: any) {
    const associatedTime = this.alternateDayClassTimes.find((c: any) => c.subjectId === classId);
    associatedTime.teach = (associatedTime && associatedTime.teach) === 'Y' ? 'N' : 'Y';
    this.changeAlternateDayScheduleFrom();
  }

  async updateAlternateScheduleTimes(classId: any) {
    const associatedTime = this.alternateDayClassTimes.find((c: any) => c.subjectId === classId);
    const startTime = associatedTime.startTime;
    const endTime = associatedTime.endTime;
    if ((CommonUtils.hasText(startTime) && CommonUtils.hasText(endTime)) || (CommonUtils.hasNoText(startTime) && CommonUtils.hasNoText(endTime))) {
      this.changeAlternateDayScheduleFrom();
    }
  }

  async changeAlternateDayScheduleFrom() {
    const that = this;
    var alternateScheduleInformation:any = {};
    alternateScheduleInformation.name = this.scheduleName;
    alternateScheduleInformation.description = this.specialDayDescription;
    alternateScheduleInformation.teacherId = this.userInfo.userId;
    alternateScheduleInformation.schoolId = this.schoolId;
    if (this.getUserMode === 'A') {
      alternateScheduleInformation.specialDaySchoolId = this.schoolId;
    } else {
      alternateScheduleInformation.specialDaySchoolId = 0
    }
    alternateScheduleInformation.action = 'U';
    alternateScheduleInformation.specialDayId = this.specialDayIdSelected;
    alternateScheduleInformation.yearId = this.userInfo.schoolYearSettings.currentYearId;

    alternateScheduleInformation.subjects = [];
    alternateScheduleInformation.startTimes = [];
    alternateScheduleInformation.endTimes = [];

    const resourceId = 'alternateDay';

    this.alternateDayClassTimes.forEach((classTime:any) => {
      if (classTime.teach === 'Y') {
        alternateScheduleInformation.subjects.push(classTime.subjectId);
        alternateScheduleInformation.startTimes.push(classTime.startTime);
        alternateScheduleInformation.endTimes.push(classTime.endTime);
      }
    });

    CommonUtils.showLoading();
    return this.updateSpecialDays(alternateScheduleInformation).then(resp => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg50') as string);
      }
      return Promise.resolve(resp);
    }).finally(() => {
      that.refresh();
      CommonUtils.hideLoading();
    });
  }

  get fcStartOfDay(): Moment {
    return moment().startOf('day');
  }

  get fcAlternateDayEvents() {
    const that = this;
    const fcAlternateDayEvents: any = []
    const specialDays = this.specialDays;
    specialDays.forEach((specialDay:any) => {
      const allSpecialDayClasses = specialDay.classes;
      if (that.specialDayIdSelected === specialDay.specialDayId) {
        allSpecialDayClasses.forEach((specialDayClasses:any) => {
          const specialDayClass = this.getClassesMap[specialDayClasses.subjectId];
          if (specialDayClass) {
            const resourceId = 'alternateDay';
            const clazz = specialDayClass;
            const titleColor = specialDayClass.titleColor;
            const start24HrFmt = moment(specialDayClasses.startTime, ['h:mma']).format('HH:mm');
            const end24HrFmt = moment(specialDayClasses.endTime, ['h:mma']).format('HH:mm');
            const startTimeAddlHrs = Number(start24HrFmt.substring(0, start24HrFmt.indexOf(':')));
            const startTimeAddlMins = Number(start24HrFmt.substring(start24HrFmt.indexOf(':') + 1, start24HrFmt.length));
            const endTimeAddlHrs = Number(end24HrFmt.substring(0, end24HrFmt.indexOf(':')));
            const endimeAddlMins = Number(end24HrFmt.substring(end24HrFmt.indexOf(':') + 1, end24HrFmt.length));
            const startTime = moment(that.fcStartOfDay).add(startTimeAddlHrs, 'hours').add(startTimeAddlMins, 'minutes');
            const endTime = moment(that.fcStartOfDay).add(endTimeAddlHrs, 'hours').add(endimeAddlMins, 'minutes');
            let allDay = false;
            if (startTime.format('HH:mm:ss') === '00:00:00' && endTime.format('HH:mm:ss') === '00:00:00') {
              allDay = true;
            } else {
              allDay = false;
            }
            const fcEvent = {
              id: specialDayClasses.specialDaySubjectId + '.' + resourceId,
              resourceId: resourceId,
              start: startTime.format('YYYY-MM-DDTHH:mm:ss'),
              end: endTime.format('YYYY-MM-DDTHH:mm:ss'),
              allDay: allDay,
              title: clazz.cN,
              backgroundColor: clazz.cC,
              borderColor: clazz.cC,
              textColor: titleColor,
              classId: specialDayClasses.subjectId,
              resourceEditable: this.editMode,
              description: specialDay.description,
              specialDaySubjectId: specialDayClasses.specialDaySubjectId,
              specialDayId: specialDay.specialDayId,
              specialDayName: specialDay.name,
              subjectsArray: allSpecialDayClasses
            };
            fcAlternateDayEvents.push(fcEvent);
          }
        });
      }
    });
    return fcAlternateDayEvents;
  }

  getSelectedSched(schedules: any[], mScheduleAsOfDate: moment.Moment) {
    var selectedSched: any = {};
    for (var w = 0; w < schedules.length; w++) {
      const mScheduleStart = moment(schedules[w].scheduleStart, 'MM/DD/YYYY');
      if (mScheduleStart.isBefore(mScheduleAsOfDate) || mScheduleStart.isSame(mScheduleAsOfDate)) {
        selectedSched = ld.cloneDeep(schedules[w]);
        break;
      }
    }
    return selectedSched;
  }

  getFCEvents() {
    const fcEvents: any = []
    if (this.localFilteredClasses && this.localFilteredClasses.length > 0) {
      const mScheduleAsOfDate = moment(this.scheduleAsOfFC, 'MM/DD/YYYY');
      for (let j = 0; j < this.localFilteredClasses.length; j++) {
        const clazz = this.getClassesMap[this.localFilteredClasses[j].cId];
        if (clazz) {
          const mClassStart = moment(clazz.cSd, 'MM/DD/YYYY');
          const mClassEnd = moment(clazz.cEd, 'MM/DD/YYYY');
          const schedules = ld.cloneDeep(clazz.classSchedule);
          schedules.sort(function (a: any, b: any) {
            const ma = moment(a.scheduleStart, 'MM/DD/YYYY');
            const mb = moment(b.scheduleStart, 'MM/DD/YYYY');
            return mb.diff(ma, 'days');
          });
          var selectedSched: any = this.getSelectedSched(schedules, mScheduleAsOfDate);
          if ((mScheduleAsOfDate.isAfter(mClassStart) && mScheduleAsOfDate.isBefore(mClassEnd)) || mScheduleAsOfDate.isSame(mClassStart) || mScheduleAsOfDate.isSame(mClassEnd)) {
            if (mClassStart !== null) {
              // const fcEvent = {};
              if (this.getSchoolYearSettings.classCycle === 'oneWeek' || this.getSchoolYearSettings.classCycle === 'twoWeeks') {
                const week1SundayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'A-teachDay1', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day1Teach), selectedSched.day1StartTime, selectedSched.day1EndTime, this.fcStartOfDay, clazz.classSchedule);
                if (week1SundayEvent) {
                  fcEvents.push(week1SundayEvent);
                }
                const week1MondayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'B-teachDay2', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day2Teach), selectedSched.day2StartTime, selectedSched.day2EndTime, this.fcStartOfDay, clazz.classSchedule);
                if (week1MondayEvent) {
                  fcEvents.push(week1MondayEvent);
                }
                const week1TuesdayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'C-teachDay3', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day3Teach), selectedSched.day3StartTime, selectedSched.day3EndTime, this.fcStartOfDay, clazz.classSchedule);
                if (week1TuesdayEvent) {
                  fcEvents.push(week1TuesdayEvent);
                }
                const week1WednesdayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'D-teachDay4', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day4Teach), selectedSched.day4StartTime, selectedSched.day4EndTime, this.fcStartOfDay, clazz.classSchedule);
                if (week1WednesdayEvent) {
                  fcEvents.push(week1WednesdayEvent);
                }
                const week1ThursdayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'E-teachDay5', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day5Teach), selectedSched.day5StartTime, selectedSched.day5EndTime, this.fcStartOfDay, clazz.classSchedule);
                if (week1ThursdayEvent) {
                  fcEvents.push(week1ThursdayEvent);
                }
                const week1FridayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'F-teachDay6', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day6Teach), selectedSched.day6StartTime, selectedSched.day6EndTime, this.fcStartOfDay, clazz.classSchedule);
                if (week1FridayEvent) {
                  fcEvents.push(week1FridayEvent);
                }
                const week1SaturdayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'G-teachDay7', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day7Teach), selectedSched.day7StartTime, selectedSched.day7EndTime, this.fcStartOfDay, clazz.classSchedule);
                if (week1SaturdayEvent) {
                  fcEvents.push(week1SaturdayEvent);
                }
                if (this.getSchoolYearSettings.classCycle === 'twoWeeks') {
                  const week2SundayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'H-teachDay8', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day8Teach), selectedSched.day8StartTime, selectedSched.day8EndTime, this.fcStartOfDay, clazz.classSchedule);
                  if (week2SundayEvent) {
                    fcEvents.push(week2SundayEvent);
                  }
                  const week2MondayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'I-teachDay9', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day9Teach), selectedSched.day9StartTime, selectedSched.day9EndTime, this.fcStartOfDay, clazz.classSchedule);
                  if (week2MondayEvent) {
                    fcEvents.push(week2MondayEvent);
                  }
                  const week2TuesdayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'J-teachDay10', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day10Teach), selectedSched.day10StartTime, selectedSched.day10EndTime, this.fcStartOfDay, clazz.classSchedule);
                  if (week2TuesdayEvent) {
                    fcEvents.push(week2TuesdayEvent);
                  }
                  const week2WednesdayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'K-teachDay11', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day11Teach), selectedSched.day11StartTime, selectedSched.day11EndTime, this.fcStartOfDay, clazz.classSchedule);
                  if (week2WednesdayEvent) {
                    fcEvents.push(week2WednesdayEvent);
                  }
                  const week2ThursdayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'L-teachDay12', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day12Teach), selectedSched.day12StartTime, selectedSched.day12EndTime, this.fcStartOfDay, clazz.classSchedule);
                  if (week2ThursdayEvent) {
                    fcEvents.push(week2ThursdayEvent);
                  }
                  const week2FridayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'M-teachDay13', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day13Teach), selectedSched.day13StartTime, selectedSched.day13EndTime, this.fcStartOfDay, clazz.classSchedule);
                  if (week2FridayEvent) {
                    fcEvents.push(week2FridayEvent);
                  }
                  const week2SaturdayEvent = this.createFullCalendarEvent(clazz.cId, 0, 'N-teachDay14', clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched.day14Teach), selectedSched.day14StartTime, selectedSched.day14EndTime, this.fcStartOfDay, clazz.classSchedule);
                  if (week2SaturdayEvent) {
                    fcEvents.push(week2SaturdayEvent);
                  }
                }
              } else {
                if (clazz.dTs) {
                  for (let l = 0; l < clazz.dTs.length; l++) {
                    const tempEvent = this.createFullCalendarEvent(clazz.cId, 0, englishAlphabet[l] + '-teachDay' + (l + 1), clazz.cC, clazz.cN, CommonUtils.booleanToString(selectedSched['day' + (l + 1) + 'Teach']), selectedSched['day' + (l + 1) + 'StartTime'], selectedSched['day' + (l + 1) + 'EndTime'], this.fcStartOfDay, clazz.classSchedule);
                    if (tempEvent) {
                      fcEvents.push(tempEvent);
                    }
                  }
                }
              }
              if (selectedSched.additionalClassDays && selectedSched.additionalClassDays.length > 0) {
                for (let d = 0; d < selectedSched.additionalClassDays.length; d++) {
                  const additionalClassDay = selectedSched.additionalClassDays[d];
                  if (this.getSchoolYearSettings.classCycle === 'oneWeek' || this.getSchoolYearSettings.classCycle === 'twoWeeks') {
                    let resourceId = '';
                    let dayTeach = 'N';
                    if (additionalClassDay.dayCode === 'U') {
                      resourceId = 'A-teachDay1';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'M') {
                      resourceId = 'B-teachDay2';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'T') {
                      resourceId = 'C-teachDay3';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'W') {
                      resourceId = 'D-teachDay4';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'H') {
                      resourceId = 'E-teachDay5';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'F') {
                      resourceId = 'F-teachDay6';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'S') {
                      resourceId = 'G-teachDay7';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'UU') {
                      resourceId = 'H-teachDay8';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'MM') {
                      resourceId = 'I-teachDay9';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'TT') {
                      resourceId = 'J-teachDay10';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'WW') {
                      resourceId = 'K-teachDay11';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'HH') {
                      resourceId = 'L-teachDay12';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'FF') {
                      resourceId = 'M-teachDay13';
                      dayTeach = 'Y';
                    } else if (additionalClassDay.dayCode === 'SS') {
                      resourceId = 'N-teachDay14';
                      dayTeach = 'Y';
                    }
                    const tempEvent = this.createFullCalendarEvent(clazz.cId, additionalClassDay.extraDay, resourceId, clazz.cC, clazz.cN, dayTeach, additionalClassDay.startTime, additionalClassDay.endTime, this.fcStartOfDay, clazz.classSchedule, additionalClassDay.scheduleId);
                    if (tempEvent) {
                      fcEvents.push(tempEvent);
                    }
                  } else {
                    const nDayCode = Number(additionalClassDay.dayCode);
                    const tempEvent = this.createFullCalendarEvent(clazz.cId, additionalClassDay.extraDay, englishAlphabet[nDayCode] + '-teachDay' + (nDayCode + 1), clazz.cC, clazz.cN, additionalClassDay.dayCode !== '' ? 'Y' : 'N', additionalClassDay.startTime, additionalClassDay.endTime, this.fcStartOfDay, clazz.classSchedule, additionalClassDay.scheduleId);
                    if (tempEvent) {
                      fcEvents.push(tempEvent);
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return fcEvents;
  }

  get colors(): any {
    return swatches;
  }

  get allowedToEdit() {
    if (this.isDateWithinGracePeriod(this.scheduleAsOfFC)) {
      return true;
    } else {
      return false;
    }
  }

  toggleLocalDayTeachCheckboxes() {
    if (this.localDayTeachAll) {
      for (var w = 0; w < this.cycleDays.length; w++) {
        this.localDayTeach[w] = true;
      }
    } else {
      for (var q = 0; q < this.cycleDays.length; q++) {
        this.localDayTeach[q] = false;
      }
    }
  }

  toggleLocalDayTeachAllCheckbox() {
    for (var o = 0; o < this.cycleDays.length; o++) {
      if (!this.localDayTeach[o]) {
        this.localDayTeachAll = false;
        return;
      }
    }
    this.localDayTeachAll = true;
  }

  isDateWithinGracePeriod(date: string) {
    var mFieldDate = moment(date, 'MM/DD/YYYY');
    var elapsedDaysFromDate = this.fcStartOfDay.diff(mFieldDate, 'days');
    return this.fcStartOfDay.isBefore(mFieldDate) || this.fcStartOfDay.isSame(mFieldDate) || elapsedDaysFromDate <= allowedGracePeriodDays;
  }

  async applyToAllDays() {
    const dateFieldsValid = await this.$refs.rescheduleObserver.validate();
    if (!dateFieldsValid) {
      return;
    }
    if (this.isRegularSchedule) {
      this.isApplyingToAllDays = true;
      if (this.isEventDrop) {
        this.changeSchedule(this.eventDropInfo);
      } else {
        this.changeSchedule(this.eventClickInfo);
      }
    }
  }

  async applyToOnlyThisDay() {
    const dateFieldsValid = await this.$refs.rescheduleObserver.validate();
    if (!dateFieldsValid) {
      return;
    }
    if (this.isRegularSchedule) {
      this.isApplyingOnlyThisDay = true;
      if (this.isEventDrop) {
        this.changeSchedule(this.eventDropInfo);
      } else {
        this.changeSchedule(this.eventClickInfo);
      }
    } else {
      this.changeAlternateDaySchedule();
    }
  }

  async applyEventDrop(event:any) {
    const dateFieldsValid = await this.$refs.rescheduleObserver.validate();
    if (this.isRegularSchedule && dateFieldsValid) {
      this.changeSchedule(event);
    }
  }

  doesStartEndMatch(event: any, selectedSchedStart: string, selectedSchedEnd: string) {
    const eventStart = event.startStr && event.startStr.length <= 10 ? '' : moment(event.startStr).format('h:mm A');
    const eventEnd = event.endStr !== '' ? moment(event.endStr).format('h:mm A') : '';
    return eventStart.toLowerCase() === selectedSchedStart.toLowerCase() && eventEnd.toLowerCase() === selectedSchedEnd.toLowerCase();
  }

  getOtherImpactedDays(event: any) {
    let editingTeachDayName = event.event.id.split('.')[2];
    editingTeachDayName = editingTeachDayName.split('-')[1];
    const eventId = event.event.id.split('.');
    const schedulesArray = ld.cloneDeep(this.getClassesMap[eventId[0]].classSchedule);
    if (schedulesArray && schedulesArray.length > 0) {
      this.daysToRescheduleClass = [];
      const schedules: any[] = [];
      schedulesArray.sort(function (a: any, b: any) {
        const ma = moment(a.scheduleStart, 'MM/DD/YYYY');
        const mb = moment(b.scheduleStart, 'MM/DD/YYYY');
        return mb.diff(ma, 'days');
      });
      schedulesArray.forEach((sched: any) => {
        schedules.push(this.prepareSchedule(sched, editingTeachDayName, null, null, null, event));
      });
      const selectedSched: any = this.getSelectedSched(schedules, moment(this.scheduleAsOfFC, 'MM/DD/YYYY'));
      for (let x = 1; x <= 21; x++) {
        const teachDayName = 'teachDay' + x;
        const startDayName = 'startDay' + x;
        const endDayName = 'endDay' + x;
        const dayCode = this.getSchoolYearSettings.classCycle === 'oneWeek' || this.getSchoolYearSettings.classCycle === 'twoWeeks' ? this.teachDayNameToDayCode(teachDayName) : String(x - 1);
        if (event.event.classNames[0] === 'fc-extra') {
          for (var h = 0; h < selectedSched.additionalClassDays.length; h++) {
            if (selectedSched[teachDayName] && !event.event.id.includes(teachDayName) && selectedSched.additionalClassDays[h].dayCode === dayCode && this.doesStartEndMatch(this.isEventDrop ? event.oldEvent : event.event, moment(selectedSched.additionalClassDays[h].startTime, 'h:mma').format('h:mm A'), moment(selectedSched.additionalClassDays[h].endTime, 'h:mma').format('h:mm A'))) {
              this.daysToRescheduleClass.push(this.localCycleDaysMap.get(teachDayName));
            }
          }
        } else {
          if (selectedSched[teachDayName] && !event.event.id.includes(teachDayName) && this.doesStartEndMatch(this.isEventDrop ? event.oldEvent : event.event, selectedSched[startDayName], selectedSched[endDayName])) {
            this.daysToRescheduleClass.push(this.localCycleDaysMap.get(teachDayName));
          }
        }
      }
    }
  }

  onEventClick(event:any) {
    this.eventClickInfo = event;
    let editingTeachDayName = event.event.id.split('.')[2];
    editingTeachDayName = editingTeachDayName.split('-')[1];
    this.getOtherImpactedDays(event);
    for (let x = 1; x <= 21; x++) {
      const teachDayName = 'teachDay' + x;
      if (editingTeachDayName === teachDayName) {
        if (event.event.allDay) {
          this.noTimeSpecified = 0;
          this.rescheduleClassStartTime = '';
          this.rescheduleClassEndTime = '';
          this.rescheduleClassTimeRangeAvailable = true;
        } else {
          this.noTimeSpecified = null;
          this.rescheduleClassStartTime = moment(event.event.startStr).format('hh:mm A');
          this.rescheduleClassEndTime = moment(event.event.endStr).format('hh:mm A');
          this.rescheduleClassTimeRangeAvailable = true;
        }
      }
    }
    this.rescheduleClass = true;
  }

  onEventClickAlternateSchedule(event:any) {
    this.eventClickInfo = event;
    const editingTeachDayName = event.event.id.split('.')[1];
    if (editingTeachDayName === 'alternateDay') {
      if (event.event.allDay) {
        this.noTimeSpecified = 0;
        this.rescheduleClassStartTime = '';
        this.rescheduleClassEndTime = '';
      } else {
        this.noTimeSpecified = null;
        this.rescheduleClassStartTime = moment(event.event.startStr).format('hh:mm A');
        this.rescheduleClassEndTime = moment(event.event.endStr).format('hh:mm A');
      }
    }
    this.rescheduleClass = true;
  }

  async doApplyScheduleClass() {
    const dateFieldsValid = await this.$refs.applyScheduleObserver.validate();
    if (!dateFieldsValid) {
      return;
    }
    this.changeSchedule();
  }

  async doCloseScheduleClass() {
    this.revertStorageForExternalEvent.revert();
    return Promise.resolve(false);
  }

  async doCloseRescheduleClass() {
    if (this.isEventDrop) {
      this.revertStorageForFCEvent.revert();
      this.isEventDrop = false;
    }
    return Promise.resolve(false);
  }

  hideAlternateDay() {
    this.alternateDayModal = false;
    this.isRegularSchedule = true;
    this.resetSpecialDays();
    this.beforeInitialize();
  }

  addAlternateDayModalShow() {
    this.alternateDayModal = true;
    this.localAlternateDayTitle = '';
    this.localSpecialDayDescription = '';
    this.localSpecialDayAction = 'A';
    this.localSpecialDayId = -1;
  }

  editAlternateDayModalShow(item:any) {
    this.alternateDayModal = true;
    this.localAlternateDayTitle = item.text;
    this.localSpecialDayId = item.value;
    this.localSpecialDayDescription = item.description;
    this.localSpecialDayAction = 'U';
  }

  deleteAlternateDayModalShow(item:any) {
    this.alternateDayModal = true;
    this.localSpecialDayAction = 'D';
    this.localSpecialDayId = item.value;
  }

  switchToRegularOrAlternateSchedule(item: any) {
    if (item.value === 0) {
      this.isRegularSchedule = true;
    } else {
      this.isRegularSchedule = false;
    }
    this.specialDayIdSelected = item.value;
    this.scheduleName = item.text;
    this.specialDayDescription = item.description;
  }

  isOverflowing(arg: any) {
    if (CommonUtils.hasText(this.isPrinting)) {
      return false;
    }
    if (arg) {
      let el = null;

      if (arg.event.allDay) {
        el = arg.event.extendedProps.el ? (arg.event.extendedProps.el.querySelector('#all-day-title') as HTMLElement) : null;
      } else {
        el = (arg.event.extendedProps.el as HTMLElement);
      }

      if (el) {
        return el.scrollHeight > el.clientHeight || el.scrollWidth > el.clientWidth;
      } else {
        return false;
      }
    }

    return false;
  }

  onEventDelete(arg:any) {
    this.eventClickInfo = arg;
    if (arg.event.classNames[0] === 'fc-extra') {
      this.updatingExtraDayCode = arg.event.extendedProps.extraClass;
    }
    this.checkEventInOtherDays(arg);
    this.getOtherImpactedDays(arg);
    this.isDeletingSchedule = true;
    this.rescheduleClass = true;
  }

  getDisplayDate(date: string) {
    if (CommonUtils.hasText(date)) {
      return DateTimeUtils.formatToShow(date, this.getSettings);
    } else {
      return '....'
    }
  }

  formatTimeToShow(s: string) {
    const userInfo = this.userInfo || { otherSettings: { timeFormat: 1 } };
    const otherSettings = userInfo.otherSettings;
    const timeFormat = +otherSettings.timeFormat;
    let showFormat = 'h:mm A';
    if (timeFormat === 1) {
      showFormat = 'HH:mm';
    }
    const mToShowTime = moment(s, 'h:mm A');

    return mToShowTime.format(showFormat);
  }

  getEventDurationMinutes(start: string, end: string) {
    const mStart = moment(start);
    const mEnd = moment(end);
    return moment.duration(mEnd.diff(mStart)).asMinutes();
  }

  async initialize(schoolYearChanged: boolean, param: any) {
    this.localNewClass = null;
    this.slotDuration = PlanbookStorage.get('classesSlotDuration') || this.defaultSlot;
    this.scheduleName = this.$t('regularScheduleLabel') as string;
    this.scheduleAsOfFC = moment().format('MM/DD/YYYY');
    if (CommonUtils.isNotEmpty(this.cycleDays)) {
      this.cycleDays.forEach((cycleDay: any) => {
        const id = cycleDay.id.split('-');
        this.localCycleDaysMap.set(id[1], cycleDay.title);
      });
    }
    if (this.mCurrentDate.isBefore(this.mCurrentYearFirstDay) || this.mCurrentDate.isAfter(this.mCurrentYearLastDay)) {
      this.scheduleAsOfFC = this.getCurrentYear.firstDay;
    }
    await this.loadSpecialDays();
    if (schoolYearChanged) {
      CommonUtils.showLoading();
      this.changePlans(param).then(() => {
        this.reset();
        this.loadClasses().then(() => {
          this.localFCEvents = this.getFCEvents();
        }).finally(() => {
          this.localRefreshKey = CommonUtils.generateUUID();
          CommonUtils.hideLoading();
        });
      });
    } else {
      CommonUtils.showLoading();
      this.reset();
      this.loadClasses().then(() => {
        this.localFCEvents = this.getFCEvents();
      }).finally(() => {
        if (this.getIsConnectedToGoogle) {
          this.loadGoogleClasses();
        }
        this.localRefreshKey = CommonUtils.generateUUID();
        CommonUtils.hideLoading();
      });
    }
  }

  createFullCalendarEvent(classId: number, extraClass: number, resourceId: string, color: string, text: string, dayTeach: string, dayStartTime: string, dayEndTime: string, momentStartOfDay: Moment, classSchedule:any, scheduleId?:any) {
    let fcEvent = null;
    const titleColor = this.getClassesMap[classId].titleColor;
    if (dayTeach && dayTeach === 'Y') {
      const classNames = [];
      if (extraClass > 0) {
        classNames.push('fc-extra');
      } else {
        classNames.push('');
      }
      classNames.push(classId);
      if (!dayStartTime || dayStartTime === '' || !dayEndTime || dayEndTime === '') {
        classNames.push('fc-allDay');
        let className = classId + '-allDay-extra';
        if (extraClass === 0) {
          className = classId + '-allDay';
        }
        classNames.push(className);
        fcEvent = {
          id: classId + '.' + extraClass + '.' + resourceId,
          resourceId: resourceId,
          start: momentStartOfDay.format('YYYY-MM-DD'),
          allDay: true,
          title: text,
          backgroundColor: color,
          borderColor: color,
          textColor: titleColor,
          classId: classId,
          extraClass: extraClass,
          resourceEditable: this.editMode,
          className: classNames,
          schedules: classSchedule,
          scheduleId: scheduleId
        };
      } else {
        const start24HrFmt = moment(dayStartTime, ['h:mma']).format('HH:mm');
        const end24HrFmt = moment(dayEndTime, ['h:mma']).format('HH:mm');
        const startTimeAddlHrs = Number(start24HrFmt.substring(0, start24HrFmt.indexOf(':')));
        const startTimeAddlMins = Number(start24HrFmt.substring(start24HrFmt.indexOf(':') + 1, start24HrFmt.length));
        const endTimeAddlHrs = Number(end24HrFmt.substring(0, end24HrFmt.indexOf(':')));
        const endimeAddlMins = Number(end24HrFmt.substring(end24HrFmt.indexOf(':') + 1, end24HrFmt.length));
        const startTime = moment(momentStartOfDay).add(startTimeAddlHrs, 'hours').add(startTimeAddlMins, 'minutes');
        const endTime = moment(momentStartOfDay).add(endTimeAddlHrs, 'hours').add(endimeAddlMins, 'minutes');
        classNames.push('');
        classNames.push(classId + '-' + startTime.format('HH_mm') + '-' + endTime.format('HH_mm'));
        fcEvent = {
          id: classId + '.' + extraClass + '.' + resourceId,
          resourceId: resourceId,
          start: startTime.format('YYYY-MM-DDTHH:mm:ss'),
          end: endTime.format('YYYY-MM-DDTHH:mm:ss'),
          allDay: false,
          title: text,
          backgroundColor: color,
          borderColor: color,
          textColor: titleColor,
          classId: classId,
          extraClass: extraClass,
          resourceEditable: this.editMode,
          className: classNames,
          schedules: classSchedule,
          scheduleId: scheduleId
        };
      }
    }
    return fcEvent;
  }

  addClassAdvancedMode() {
    if (this.$store.state.index.openSubpage === 'class' && this.$store.state.index.subpageReference.isDirty()) {
      this.$refs.classesConfirm.confirm({
        title: this.$t('unsavedChangesLabel'),
        text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
        option1ButtonAlternativeText: this.$t('saveChangesLabel'),
        option2ButtonAlternativeText: this.$t('discardChangesLabel'),
        cancelButtonText: this.$t('cancelLabel')
      }).then((result) => {
        if (result === 1) {
          this.$store.state.index.subpageReference.doApply().then((res: any) => {
            if (!res) {
              this.resetClass();
              this.$eventBus.$emit('selectedClassLoaded');
              this.localIsEditingClass = true;
              this.localInput.action = 'A';
              this.localInput.className = ld.clone(this.localNewClass.className);
              this.localInput.color = ld.clone(this.localNewClass.color);
              this.removeQuicklyAddedClass();
            }
          });
        } else if (result === 2) {
          this.resetClass();
          this.$eventBus.$emit('selectedClassLoaded');
          this.localIsEditingClass = true;
          this.localInput.action = 'A';
          this.localInput.className = ld.clone(this.localNewClass.className);
          this.localInput.color = ld.clone(this.localNewClass.color);
          this.removeQuicklyAddedClass();
        }
      });
    } else {
      this.resetClass();
      this.$eventBus.$emit('selectedClassLoaded');
      this.localIsEditingClass = true;
      this.localInput.action = 'A';
      this.localInput.className = ld.clone(this.localNewClass.className);
      this.localInput.color = ld.clone(this.localNewClass.color);
      this.removeQuicklyAddedClass();
    }
  }

  @Watch('localIsEditingClass')
  setNavigationGuard() {
    if (this.localIsEditingClass) {
      this.$store.commit('index/setOpenSubpage', 'class');
      this.$store.commit('index/setSubpageReference', this.$refs.classEditor);
      this.$store.commit('index/setConfirmFunction', this.$refs.classesConfirm.confirm);
    } else {
      this.$store.commit('index/reset');
    }
  }

  focus(subject: any) {
    this.focusItem = subject.cId;
    this.setClass(subject);
  }

  get manageStartDate() {
    return (this.selectedClass) ? (this.selectedClass.classStartDate || this.selectedClass.cSd || '') : '';
  }

  get manageEndDate() {
    return (this.selectedClass) ? (this.selectedClass.classEndDate || this.selectedClass.cEd || '') : '';
  }

  get manageClassId() {
    return (this.selectedClass) ? (this.selectedClass.classId || this.selectedClass.cId || 0) : 0;
  }

  executeOption(option: string) {
    const that = this;
    if (option === 'delete') {
      this.removeClass(this.selectedClass);
    } else if (option === 'unlink') {
      this.$refs.classesConfirm.confirm({
        title: this.$t('classLabel'),
        text: this.$t('retainContentsOfLessonsUnlinkedMsg'),
        option1ButtonAlternativeText: this.$t('yesLabel'),
        option2ButtonAlternativeText: this.$t('noLabel')
      }).then((result) => {
        const request = {
          subjectId: that.selectedClass.classId || that.selectedClass.cId,
          linkAction: undefined as any
        };
        if (result === 1) {
          request.linkAction = 1;
        }
        if (result !== 0) {
          CommonUtils.showLoading();
          that.removeLinkedLessons(request).finally(() => {
            CommonUtils.hideLoading();
          });
        }
      });
    } else if (option === 'manageLessons') {
      this.showManageLessons = true;
    } else if (option === 'export') {
      this.$refs.classesConfirm.confirm({
        title: this.$t('exportEventsLabel'),
        text: this.$t('exportEventsMsg'),
        option1ButtonAlternativeText: this.$t('includeHTMLLabel'),
        option2ButtonAlternativeText: this.$t('excludeHTMLLabel')
      }).then((result) => {
        if (result === 1) {
          this.exportClass(false);
        } else if (result === 2) {
          this.exportClass(true)
        }
      });
    }
  }

  exportClass(removeHTML: boolean) {
    CommonUtils.showLoading();
    this.doExport({ removeHTML, classId: this.selectedClass.classId || this.selectedClass.cId }).finally(() => {
      CommonUtils.hideLoading();
    })
  }

  editClass(subjectId: number) {
    if (this.$store.state.index.openSubpage === 'class' && this.$store.state.index.subpageReference.isDirty()) {
      this.$refs.classesConfirm.confirm({
        title: this.$t('unsavedChangesLabel'),
        text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
        option1ButtonAlternativeText: this.$t('saveChangesLabel'),
        option2ButtonAlternativeText: this.$t('discardChangesLabel'),
        cancelButtonText: this.$t('cancelLabel')
      }).then((result) => {
        if (result === 1) {
          this.$store.state.index.subpageReference.doApply().then((res: any) => {
            if (!res) {
              this.openClass(subjectId)
            }
          });
        } else if (result === 2) {
          this.openClass(subjectId);
        }
      });
    } else {
      this.openClass(subjectId);
    }
  }

  get allowExportClasses() {
    return !this.fullEditorEditMode && !(this.specialDayIdSelected && this.viewListSchedule);
  }

  openClass(subjectId: number) {
    CommonUtils.showLoading();
    this.resetClass();
    this.getClass({ classId: subjectId }).then(() => {
      this.$eventBus.$emit('selectedClassLoaded');
      CommonUtils.hideLoading();
      this.localIsEditingClass = true;
      this.fullEditorEditMode = true;
      this.localInput.action = 'E';
    });
  }

  changeSchedule(event?:any) {
    CommonUtils.showLoading();
    const that = this;
    let newStartTime = '';
    let newEndTime:any = '';
    let classId:any = '';
    let editingTeachDayName = '';
    if (!this.externalDrop) {
      this.extraDayClassName = event.event.classNames[0];
      this.extraDayScheduleId = event.event.extendedProps.scheduleId;
      if (this.rescheduleClassTimeRangeAvailable) {
        newStartTime = this.rescheduleClassStartTime;
        newEndTime = this.rescheduleClassEndTime;
      } else {
        newStartTime = event.event.startStr;
        newStartTime = moment(newStartTime).format('h:mm A')
        newEndTime = event.event.endStr;
        newEndTime = moment(newEndTime).format('h:mm A')
      }
      classId = event.event.classNames[1];
      editingTeachDayName = event.event.id.split('.')[2];
      editingTeachDayName = editingTeachDayName.split('-')[1];
    } else {
      classId = this.externalDropClassId;
      if (this.externalAllDay) {
        newStartTime = '';
        newEndTime = '';
      } else {
        if (this.rescheduleClassTimeRangeAvailable) {
          newStartTime = this.rescheduleClassStartTime;
          newEndTime = this.rescheduleClassEndTime;
        } else {
          newStartTime = moment(that.externalDropTime).format('h:mm A');
          newEndTime = moment(that.externalDropTime).add(1, 'hours').format('h:mm A');
        }
      }
      editingTeachDayName = this.externalDropResourceId.split('-')[1];
    }
    this.getClass({ classId: classId }).then(() => {
      if (typeof this.selectedClass.sourceId === 'undefined') {
        this.selectedClass.source = '';
        this.selectedClass.sourceId = '';
        this.selectedClass.sourceSettings = {
          connectStudents: false,
          connectAssignments: false,
          connectGrades: false
        };
        this.localIsGoogleConnected = false;
      } else {
        this.localIsGoogleConnected = true;
      }
      this.localSelectedClassClone = ld.cloneDeep(this.selectedClass);
      this.localOldAddedClassDays = ld.cloneDeep(this.localSelectedClassClone.extraLessons);
      this.localOldSchedule = ld.cloneDeep(this.localSelectedClassClone.classSchedule);
      this.localOldNoClassDays = ld.cloneDeep(this.localSelectedClassClone.noClass);
      if (this.localSelectedClassClone.classSchedule && this.localSelectedClassClone.classSchedule.length > 0) {
        this.localSelectedClassScheduleArrayClone = this.localSelectedClassClone.classSchedule;
        this.localSelectedClassScheduleArrayClone.sort(function (a: any, b: any) {
          const ma = moment(a.scheduleStart, 'MM/DD/YYYY');
          const mb = moment(b.scheduleStart, 'MM/DD/YYYY');
          return mb.diff(ma, 'days');
        });
        this.localClassScheduleArrayStartDates = [];
        this.localSelectedClassScheduleArrayClone.forEach((sched: any) => {
          this.localClassScheduleArrayStartDates.push(sched.scheduleStart);
          if (sched.additionalClassDays && sched.additionalClassDays.length > 0) {
            sched.additionalClassDays.sort(function (a: any, b: any) {
              return a.extraDay - b.extraDay;
            });
          }
        });
      }
      const classInfo = {
        updateNoClass: 'Y',
        oldNoClass: this.localSelectedClassClone.noClass,
        shiftLessons: this.shiftLessons,
        className: this.localSelectedClassClone.className,
        classStartDate: this.localSelectedClassClone.classStartDate,
        classEndDate: this.localSelectedClassClone.classEndDate,
        color: this.localSelectedClassClone.color,
        titleColor: this.localSelectedClassClone.titleColor,
        titleSize: this.localSelectedClassClone.titleSize,
        titleFont: this.localSelectedClassClone.titleFont,
        classLabelBold: (this.localSelectedClassClone.classLabelBold) ? 'Y' : 'N',
        classLabelItalic: (this.localSelectedClassClone.classLabelItalic) ? 'Y' : 'N',
        classLabelUnderline: (this.localSelectedClassClone.classLabelUnderline) ? 'Y' : 'N',
        scheduleStart: this.localSelectedClassClone.scheduleStart,
        noStudents: this.localSelectedClassClone.noStudents,
        classDesc: this.localSelectedClassClone.classDesc,
        source: this.localSelectedClassClone.source,
        sourceId: this.localSelectedClassClone.sourceId,
        sourceSettings: this.localSelectedClassClone.sourceSettings,
        useSchoolStart: this.localSelectedClassClone.useSchoolStart,
        useSchoolEnd: this.localSelectedClassClone.useSchoolEnd,
        mondayTeach: this.localSelectedClassClone.mondayTeach,
        tuesdayTeach: this.localSelectedClassClone.tuesdayTeach,
        wednesdayTeach: this.localSelectedClassClone.wednesdayTeach,
        thursdayTeach: this.localSelectedClassClone.thursdayTeach,
        fridayTeach: this.localSelectedClassClone.fridayTeach,
        saturdayTeach: this.localSelectedClassClone.saturdayTeach,
        sundayTeach: this.localSelectedClassClone.sundayTeach,
        wk2mondayTeach: this.localSelectedClassClone.wk2mondayTeach,
        wk2tuesdayTeach: this.localSelectedClassClone.wk2tuesdayTeach,
        wk2wednesdayTeach: this.localSelectedClassClone.wk2wednesdayTeach,
        wk2thursdayTeach: this.localSelectedClassClone.wk2thursdayTeach,
        wk2fridayTeach: this.localSelectedClassClone.wk2fridayTeach,
        wk2saturdayTeach: this.localSelectedClassClone.wk2saturdayTeach,
        wk2sundayTeach: this.localSelectedClassClone.wk2sundayTeach,
        classId: (this.localSelectedClassClone.classId === 0) ? null : this.localSelectedClassClone.classId,
        userMode: this.getUserMode,
        lessons: this.localSelectedClassClone.lessons,
        noClass: [] as any,
        noClass2: undefined as any,
        dayTeach: [] as any,
        startTime: [] as any,
        endTime: [] as any,
        schedules: undefined as any,
        extraLessons: [] as any,
        lessonLayoutId: this.localSelectedClassClone.lessonLayoutId,
        scheduleChange: true
      };

      const extraLessons: Array<any> = [];
      if (this.localSelectedClassClone.extraLessons != null) {
        this.localSelectedClassClone.extraLessons.forEach((lesson: any) => {
          extraLessons.push('' + lesson.customDayId);
        });
      }
      classInfo.extraLessons = extraLessons;

      const schedulesAndNoClassDaysToCauseShift: moment.MomentInput[] = [];
      if (this.localSelectedClassScheduleArrayClone && this.localSelectedClassScheduleArrayClone.length > 0) {
        var tempSchedToBeDuplicated: any = null;
        const schedules: any[] = [];
        const mScheduleAsOfDate = moment(that.scheduleAsOfFC, 'MM/DD/YYYY');
        if (this.isEditingFromClassStart) {
          tempSchedToBeDuplicated = this.getSelectedSched(this.localSelectedClassScheduleArrayClone, mScheduleAsOfDate);
          schedules.push(this.prepareSchedule(tempSchedToBeDuplicated, editingTeachDayName, newStartTime, newEndTime, null, event));
        } else {
          var scheduleAsOfMatchesExistingScheduleStart = false;
          this.localSelectedClassScheduleArrayClone.forEach((sched: any) => {
            const mScheduleStart = moment(sched.scheduleStart, 'MM/DD/YYYY');
            const classStartDate = moment(classInfo.classStartDate, 'MM/DD/YYYY');
            if (mScheduleStart.isSame(mScheduleAsOfDate)) {
              scheduleAsOfMatchesExistingScheduleStart = true;
              schedules.push(this.prepareSchedule(sched, editingTeachDayName, newStartTime, newEndTime, null, event));
            } else {
              schedules.push(this.prepareSchedule(sched, editingTeachDayName, null, null, null, event));
            }
            if (tempSchedToBeDuplicated === null && (mScheduleStart.isSameOrBefore(this.fcStartOfDay) || (mScheduleStart.isAfter(this.fcStartOfDay) && classStartDate.isAfter(this.fcStartOfDay) && mScheduleStart.isSameOrBefore(mScheduleAsOfDate)))) {
              tempSchedToBeDuplicated = ld.cloneDeep(sched);
            }
            if (!sched.scheduleId || sched.scheduleId === 0) {
              schedulesAndNoClassDaysToCauseShift.push(moment(sched.scheduleStart, 'MM/DD/YYYY'));
            }
          });
          if (!scheduleAsOfMatchesExistingScheduleStart) {
            schedules.push(this.prepareSchedule(tempSchedToBeDuplicated, editingTeachDayName, newStartTime, newEndTime, that.scheduleAsOfFC, event));
          }
        }
        classInfo.schedules = JSON.stringify(schedules);
      }

      if (this.localIsGoogleConnected) {
        classInfo.sourceSettings = JSON.stringify(classInfo.sourceSettings);
      }

      if (this.localSelectedClassClone.noClass && this.localSelectedClassClone.noClass.length > 0) {
        this.localSelectedClassClone.noClass.forEach((item: any, index: number) => {
          classInfo.noClass[index] = {};
          classInfo.noClass[index].customDate = moment(this.localSelectedClassClone.noClass[index].customDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
          classInfo.noClass[index].extraClass = this.localSelectedClassClone.noClass[index].extraClass;
          if (!item.customDayId || item.customDayId === 0) {
            schedulesAndNoClassDaysToCauseShift.push(moment(this.localSelectedClassClone.noClass[index].customDate, 'YYYY-MM-DD'));
          }
        });
        if (classInfo.noClass.length > 0) {
          classInfo.noClass2 = JSON.stringify(classInfo.noClass);
        }
      }

      if (this.getSchoolYearSettings.classCycle !== 'oneWeek' && this.getSchoolYearSettings.classCycle !== 'twoWeeks') {
        for (let x = 0; x < parseInt(this.getSchoolYearSettings.classCycle); x++) {
          classInfo.dayTeach[x] = 'N';
          classInfo.startTime[x] = '';
          classInfo.endTime[x] = '';
        }
      }
      this.updateClass(classInfo).finally(() => {
        CommonUtils.hideLoading();
        that.refresh();
        this.localFCEvents = this.getFCEvents();
      });
    });
  }

  refresh() {
    if (this.scheduleClassModal || this.shiftLessons) {
      if (!this.isDeletingSchedule) {
        this.revertStorageForExternalEvent.revert();
      }
    }
    this.isDeletingSchedule = false;
    this.isApplyingOnlyThisDay = false;
    this.isApplyingToAllDays = false;
    this.rescheduleClass = false;
    this.isEventDrop = false;
    this.oldEventDropTeachDayName = '';
    this.externalDrop = false;
    this.externalDuplicateSchedule = false;
    this.scheduleClassModal = false;
    this.localDayTeachAll = false;
    this.toggleLocalDayTeachCheckboxes();
    this.rescheduleClassTimeRangeAvailable = false;
    this.eventClickInfo = {};
    this.eventDropInfo = {};
    this.noTimeSpecified = false;
  }

  onEventResize(event:any) {
    if (this.isRegularSchedule) {
      this.changeSchedule(event);
    } else {
      this.changeAlternateDaySchedule();
    }
  }

  async changeAlternateDaySchedule() {
    const that = this;
    var alternateScheduleInformation:any = {};
    alternateScheduleInformation.name = this.scheduleName;
    alternateScheduleInformation.description = this.specialDayDescription;
    alternateScheduleInformation.teacherId = this.userInfo.userId;
    alternateScheduleInformation.schoolId = this.schoolId;
    if (this.getUserMode === 'A') {
      alternateScheduleInformation.specialDaySchoolId = this.schoolId;
    } else {
      alternateScheduleInformation.specialDaySchoolId = 0
    }
    alternateScheduleInformation.action = 'U';
    alternateScheduleInformation.specialDayId = this.specialDayIdSelected;
    alternateScheduleInformation.yearId = this.userInfo.schoolYearSettings.currentYearId;

    alternateScheduleInformation.subjects = [];
    alternateScheduleInformation.startTimes = [];
    alternateScheduleInformation.endTimes = [];

    const resourceId = 'alternateDay';

    const resource = this.$refs.fullCalendar.getApi().getResourceById(resourceId)
    const events = resource.getEvents();

    events.forEach((eventVal:any) => {
      alternateScheduleInformation.subjects.push(eventVal.extendedProps.classId);
      if (this.eventClickInfo && this.eventClickInfo.event && this.eventClickInfo.event.extendedProps.classId === eventVal.extendedProps.classId && this.eventClickInfo.event.startStr === eventVal.startStr && this.eventClickInfo.event.endStr === eventVal.endStr) {
        if (this.noTimeSpecifiedTrue) {
          alternateScheduleInformation.startTimes.push('');
          alternateScheduleInformation.endTimes.push('');
        } else {
          alternateScheduleInformation.startTimes.push(this.rescheduleClassStartTime);
          alternateScheduleInformation.endTimes.push(this.rescheduleClassEndTime);
        }
      } else {
        if (eventVal.allDay) {
          alternateScheduleInformation.startTimes.push('');
          alternateScheduleInformation.endTimes.push('');
        } else {
          const start24HrFmt = moment(eventVal.startStr).format('hh:mm A');
          const end24HrFmt = moment(eventVal.endStr).format('hh:mm A');
          alternateScheduleInformation.startTimes.push(start24HrFmt);
          alternateScheduleInformation.endTimes.push(end24HrFmt);
        }
      }
    });

    CommonUtils.showLoading();
    return this.updateSpecialDays(alternateScheduleInformation).then(resp => {
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg50') as string);
      }
      return Promise.resolve(resp);
    }).finally(() => {
      that.refresh();
      CommonUtils.hideLoading();
    });
  }

  prepareSchedule(inputSchedule: any, editingTeachDayName:any, newStartTime?:any, newEndTime?:any, scheduleAsOf?:any, event?: any) {
    const schedule:any = {
      scheduleStart: inputSchedule.scheduleStart,
      additionalClassDays: []
    };

    if (CommonUtils.isNotEmpty(inputSchedule.additionalClassDays)) {
      for (let r = 0; r < inputSchedule.additionalClassDays.length; r++) {
        schedule.additionalClassDays.push(ld.cloneDeep(inputSchedule.additionalClassDays[r]));
        schedule.additionalClassDays[r].endTime = moment(schedule.additionalClassDays[r].endTime, 'HH:mm:ss').format('h:mma');
        schedule.additionalClassDays[r].startTime = moment(schedule.additionalClassDays[r].startTime, 'HH:mm:ss').format('h:mma');
      }
    }

    if ((newStartTime && newEndTime && this.isNewClass) || this.isEditingFromClassStart) {
      schedule.scheduleStart = this.localSelectedClassClone.classStartDate ? this.localSelectedClassClone.classStartDate : inputSchedule.scheduleStart;
    } else if (scheduleAsOf) {
      schedule.scheduleStart = scheduleAsOf;
    } else if (newStartTime && newEndTime) {
      schedule.scheduleStart = this.scheduleAsOfFC;
    }

    const week1DefaultDays = [2, 3, 4, 5, 6];
    const week2DefaultDays = [9, 10, 11, 12, 13];

    if (this.getCurrentYear.sunCycle === 'Y') {
      week1DefaultDays.unshift(1);
      week2DefaultDays.unshift(8);
    }

    if (this.getCurrentYear.satCycle === 'Y') {
      week1DefaultDays.push(7);
      week2DefaultDays.push(14);
    }

    const teachDaysNameArrays:any = [];
    for (let x = 1; x < 22; x++) {
      const teachDayName = 'teachDay' + x;
      const dayTeachName = 'day' + x + 'Teach';
      const startDayName = 'startDay' + x;
      const dayStartTimeName = 'day' + x + 'StartTime';
      const endDayName = 'endDay' + x;
      const dayEndTimeName = 'day' + x + 'EndTime';
      const dayCode = this.getSchoolYearSettings.classCycle === 'oneWeek' || this.getSchoolYearSettings.classCycle === 'twoWeeks' ? this.teachDayNameToDayCode(teachDayName) : String(x - 1);
      if (this.scheduleClassModal) {
        if (['oneWeek', 'twoWeeks'].includes(this.getSchoolYearSettings.classCycle)) {
          const index = this.cycleDays.findIndex((d: any) => d.id.substring(2) === teachDayName);
          if (index >= 0 && this.localDayTeach[index]) {
            teachDaysNameArrays.push(teachDayName);
          }
        } else if (this.localDayTeach[x - 1]) {
          teachDaysNameArrays.push(teachDayName);
        }
      }
      if (inputSchedule[dayStartTimeName] === undefined || inputSchedule[dayEndTimeName] === undefined || inputSchedule[dayTeachName] === undefined) {
        schedule[teachDayName] = inputSchedule[teachDayName] ? inputSchedule[teachDayName] : false;
        schedule[startDayName] = inputSchedule[teachDayName] ? inputSchedule[startDayName] : '';
        schedule[endDayName] = inputSchedule[teachDayName] ? inputSchedule[endDayName] : '';
      } else {
        schedule[teachDayName] = inputSchedule[dayTeachName] ? inputSchedule[dayTeachName] : false;
        schedule[startDayName] = inputSchedule[dayStartTimeName] ? inputSchedule[dayStartTimeName] : '';
        schedule[endDayName] = inputSchedule[dayEndTimeName] ? inputSchedule[dayEndTimeName] : '';
      }

      if (newStartTime && newEndTime) {
        // When Deleting Events or resizing
        if (!this.externalDrop && !this.isEventDrop && !this.isDeletingSchedule && editingTeachDayName === teachDayName && !this.isApplyingOnlyThisDay && !this.isApplyingToAllDays) {
          if (this.extraDayClassName === 'fc-extra') {
            for (let i = 0; i < schedule.additionalClassDays.length; i++) {
              if (schedule.additionalClassDays[i].extraDay === this.updatingExtraDayCode) {
                schedule.additionalClassDays[i].endTime = moment(newEndTime, 'h:mm A').format('h:mma');
                schedule.additionalClassDays[i].startTime = moment(newStartTime, 'h:mm A').format('h:mma');
              }
            }
          } else {
            schedule[teachDayName] = true;
            schedule[startDayName] = newStartTime;
            schedule[endDayName] = newEndTime;
          }
          continue;
        } else if (this.isDeletingSchedule && editingTeachDayName === teachDayName) {
          if (this.extraDayClassName === 'fc-extra') {
            const tempSchedule = schedule.additionalClassDays.filter((element:any) => element.extraDay !== this.updatingExtraDayCode);
            schedule.additionalClassDays = tempSchedule;
          } else {
            schedule[teachDayName] = false;
            schedule[startDayName] = '';
            schedule[endDayName] = '';
          }
          continue;
        }

        // Apply To Only This Day
        if (this.isApplyingOnlyThisDay && editingTeachDayName === teachDayName) {
          if (this.extraDayClassName === 'fc-extra' && event) {
            for (let p = 0; p < schedule.additionalClassDays.length; p++) {
              if (schedule.additionalClassDays[p].scheduleId === this.extraDayScheduleId && schedule.additionalClassDays[p].dayCode === dayCode && this.doesStartEndMatch(this.isEventDrop ? event.oldEvent : event.event, moment(schedule.additionalClassDays[p].startTime, 'h:mma').format('h:mm A'), moment(schedule.additionalClassDays[p].endTime, 'h:mma').format('h:mm A'))) {
                schedule.additionalClassDays[p].startTime = newStartTime;
                schedule.additionalClassDays[p].endTime = newEndTime;
              }
            }
          } else {
            if (!this.noTimeSpecifiedTrue) {
              schedule[startDayName] = newStartTime;
              schedule[endDayName] = newEndTime;
              schedule[teachDayName] = true;
              continue;
            } else if (this.noTimeSpecifiedTrue) {
              schedule[startDayName] = '';
              schedule[endDayName] = '';
              schedule[teachDayName] = true;
              continue;
            }
          }
        }

        // Apply To All Days
        if (this.isApplyingToAllDays && schedule[teachDayName] && event) {
          if (this.isDeletingSchedule) {
            if (this.extraDayClassName === 'fc-extra') {
              const tempSchedule = schedule.additionalClassDays.filter((acd:any) => !(acd.scheduleId === this.extraDayScheduleId && this.doesStartEndMatch(this.isEventDrop ? event.oldEvent : event.event, moment(acd.startTime, 'h:mma').format('h:mm A'), moment(acd.endTime, 'h:mma').format('h:mm A'))));
              schedule.additionalClassDays = tempSchedule;
            } else if (this.doesStartEndMatch(this.isEventDrop ? event.oldEvent : event.event, schedule[startDayName], schedule[endDayName])) {
              if (!this.noTimeSpecifiedTrue) {
                schedule[startDayName] = '';
                schedule[endDayName] = '';
                schedule[teachDayName] = false;
                continue;
              }
            }
          } else {
            if (this.extraDayClassName === 'fc-extra') {
              for (let i = 0; i < schedule.additionalClassDays.length; i++) {
                if (schedule.additionalClassDays[i].scheduleId === this.extraDayScheduleId && this.doesStartEndMatch(this.isEventDrop ? event.oldEvent : event.event, moment(schedule.additionalClassDays[i].startTime, 'h:mma').format('h:mm A'), moment(schedule.additionalClassDays[i].endTime, 'h:mma').format('h:mm A'))) {
                  schedule.additionalClassDays[i].startTime = newStartTime;
                  schedule.additionalClassDays[i].endTime = newEndTime;
                }
              }
            } else if (this.doesStartEndMatch(this.isEventDrop ? event.oldEvent : event.event, schedule[startDayName], schedule[endDayName])) {
              if (!this.noTimeSpecifiedTrue) {
                schedule[startDayName] = newStartTime;
                schedule[endDayName] = newEndTime;
                schedule[teachDayName] = true;
                continue;
              } else if (this.noTimeSpecifiedTrue) {
                schedule[startDayName] = '';
                schedule[endDayName] = '';
                schedule[teachDayName] = true;
                continue;
              }
            }
          }
        }

        // Apply Event Drop
        if (this.isEventDrop && editingTeachDayName === teachDayName && !this.scheduleShiftToAnotherDay) {
          if (this.extraDayClassName !== 'fc-extra') {
            schedule[teachDayName] = true;
            schedule[startDayName] = newStartTime;
            schedule[endDayName] = newEndTime;
          }
          continue;
        } else if (this.isEventDrop && editingTeachDayName === teachDayName && this.scheduleShiftToAnotherDay) {
          const dayCode = this.getSchoolYearSettings.classCycle === 'oneWeek' || this.getSchoolYearSettings.classCycle === 'twoWeeks' ? this.teachDayNameToDayCode(this.newDropLocation) : String(x - 1);
          for (let i = 0; i < schedule.additionalClassDays.length; i++) {
            if (schedule.additionalClassDays[i].scheduleId === this.extraDayScheduleId) {
              schedule.additionalClassDays[i].startTime = newStartTime;
              schedule.additionalClassDays[i].endTime = newEndTime;
              schedule.additionalClassDays[i].dayCode = dayCode;
            }
          }
          continue;
        }

        // For External Drop
        if (!this.scheduleClassModal && this.externalDrop && editingTeachDayName === teachDayName && !this.externalDuplicateSchedule) {
          schedule[teachDayName] = true;
          schedule[startDayName] = newStartTime;
          schedule[endDayName] = newEndTime;
          continue;
        } else if (!this.scheduleClassModal && this.externalDrop && editingTeachDayName === teachDayName && this.externalDuplicateSchedule) {
          const dayCode = this.getSchoolYearSettings.classCycle === 'oneWeek' || this.getSchoolYearSettings.classCycle === 'twoWeeks' ? this.teachDayNameToDayCode(teachDayName) : String(x - 1);
          if (schedule.additionalClassDays.length === 0) {
            schedule.additionalClassDays = [{
              extraDay: 1,
              subjectId: this.externalDropClassId,
              dayCode: dayCode,
              startTime: this.externalAllDay ? '' : moment(newStartTime, 'h:mm A').format('h:mma'),
              endTime: this.externalAllDay ? '' : moment(newEndTime, 'h:mm A').format('h:mma')
            }];
          } else {
            let extraDay = schedule.additionalClassDays[schedule.additionalClassDays.length - 1];
            extraDay = extraDay.extraDay;
            extraDay = extraDay + 1;
            schedule.additionalClassDays.push({
              extraDay: extraDay,
              subjectId: this.externalDropClassId,
              dayCode: dayCode,
              startTime: this.externalAllDay ? '' : moment(newStartTime, 'h:mm A').format('h:mma'),
              endTime: this.externalAllDay ? '' : moment(newEndTime, 'h:mm A').format('h:mma')
            });
          }
          continue;
        } else if (this.scheduleClassModal && this.externalDrop && teachDaysNameArrays[0] === teachDayName && !this.externalDuplicateSchedule) {
          teachDaysNameArrays.shift();
          schedule[teachDayName] = true;
          if (this.noTimeSpecifiedTrue) {
            schedule[startDayName] = '';
            schedule[endDayName] = '';
          } else {
            schedule[startDayName] = newStartTime;
            schedule[endDayName] = newEndTime;
          }
        }
      }
    }
    return schedule;
  }

  teachDayNameToDayCode(teachDayName:any) {
    if (teachDayName === 'teachDay1') {
      return 'U';
    } else if (teachDayName === 'teachDay2') {
      return 'M';
    } else if (teachDayName === 'teachDay3') {
      return 'T';
    } else if (teachDayName === 'teachDay4') {
      return 'W';
    } else if (teachDayName === 'teachDay5') {
      return 'H';
    } else if (teachDayName === 'teachDay6') {
      return 'F';
    } else if (teachDayName === 'teachDay7') {
      return 'S';
    } else if (teachDayName === 'teachDay8') {
      return 'UU';
    } else if (teachDayName === 'teachDay9') {
      return 'MM';
    } else if (teachDayName === 'teachDay10') {
      return 'TT';
    } else if (teachDayName === 'teachDay11') {
      return 'WW';
    } else if (teachDayName === 'teachDay12') {
      return 'HH';
    } else if (teachDayName === 'teachDay13') {
      return 'FF';
    } else if (teachDayName === 'teachDay14') {
      return 'SS';
    }
  }

  removeClass(subject: any) {
    const that = this;
    this.$refs.classesConfirm.confirm({
      title: this.$t('classLabel'),
      text: this.$t('deleteClassConfirmMsg', { s: subject.cN || subject.className }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        CommonUtils.showLoading();
        that.deleteClass({ classId: subject.cId || subject.classId }).then(() => {
          CommonUtils.hideLoading();
          this.$eventBus.$emit('deleteClass', subject.cId || subject.classId);
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg7') as string);
          }
          that.initialize(false, null);
        });
      }
    });
  }

  quickAddClass() {
    this.localNewClass = ld.cloneDeep(this.selectedClass);
    this.localNewClass.className = '';
    this.localNewClass.classStartDate = this.getCurrentYear.firstDay;
    this.localNewClass.classEndDate = this.getCurrentYear.lastDay;
    this.localNewClass.source = '';
    this.localNewClass.sourceId = '';
    this.localNewClass.sourceSettings = {
      connectStudents: false,
      connectAssignments: false,
      connectGrades: false
    };
    this.localNewClass.color = planbookColors[Math.floor(Math.random() * planbookColors.length)];
  }

  removeQuicklyAddedClass() {
    this.localNewClass = null;
  }

  applyQuicklyAddedClass() {
    const form: any = this.$refs.classEditorForm;
    if (form.validate() && this.localNewClass.className && this.localNewClass.className !== '') {
      CommonUtils.showLoading();
      const classInfo = {
        updateNoClass: 'Y',
        oldNoClass: [],
        shiftLessons: false,
        scheduleChange: false,
        className: this.localNewClass.className,
        classStartDate: this.localNewClass.classStartDate,
        classEndDate: this.localNewClass.classEndDate,
        color: this.localNewClass.color,
        titleColor: CommonUtils.isColorReadable('#ffffff', this.localNewClass.color) ? '#ffffff' : '#000000',
        titleSize: this.localNewClass.titleSize,
        titleFont: this.localNewClass.titleFont,
        classLabelBold: (this.localNewClass.classLabelBold) ? 'Y' : 'N',
        classLabelItalic: (this.localNewClass.classLabelItalic) ? 'Y' : 'N',
        classLabelUnderline: (this.localNewClass.classLabelUnderline) ? 'Y' : 'N',
        scheduleStart: this.localNewClass.scheduleStart,
        noStudents: this.localNewClass.noStudents,
        classDesc: this.localNewClass.classDesc,
        source: this.localNewClass.source,
        sourceId: this.localNewClass.sourceId,
        sourceSettings: this.localNewClass.sourceSettings,
        useSchoolStart: this.localNewClass.useSchoolStart,
        useSchoolEnd: this.localNewClass.useSchoolEnd,
        mondayTeach: this.localNewClass.mondayTeach,
        tuesdayTeach: this.localNewClass.tuesdayTeach,
        wednesdayTeach: this.localNewClass.wednesdayTeach,
        thursdayTeach: this.localNewClass.thursdayTeach,
        fridayTeach: this.localNewClass.fridayTeach,
        saturdayTeach: this.localNewClass.saturdayTeach,
        sundayTeach: this.localNewClass.sundayTeach,
        wk2mondayTeach: this.localNewClass.wk2mondayTeach,
        wk2tuesdayTeach: this.localNewClass.wk2tuesdayTeach,
        wk2wednesdayTeach: this.localNewClass.wk2wednesdayTeach,
        wk2thursdayTeach: this.localNewClass.wk2thursdayTeach,
        wk2fridayTeach: this.localNewClass.wk2fridayTeach,
        wk2saturdayTeach: this.localNewClass.wk2saturdayTeach,
        wk2sundayTeach: this.localNewClass.wk2sundayTeach,
        classId: null,
        userMode: this.getUserMode,
        collaborateType: this.localNewClass.collaborateType,
        collaborateTeacherEmail: undefined,
        collaborateTeacherId: undefined,
        collaborateYearId: undefined,
        collaborateSubjectId: 0,
        collaborateKey: '',
        lessons: this.localNewClass.lessons,
        noClass: [] as any,
        noClass2: undefined as any,
        dayTeach: [] as any,
        startTime: [] as any,
        endTime: [] as any,
        schedules: undefined as any,
        lessonLayoutId: this.localNewClass.lessonLayoutId
      };

      const schedule = ld.cloneDeep(this.classSchedule);
      schedule.scheduleStart = this.getCurrentYear.firstDay;

      for (let x = 1; x <= 21; x++) {
        const teachDayName = 'teachDay' + x;
        const dayTeachName = 'day' + x + 'Teach';
        const startDayName = 'startDay' + x;
        const dayStartTimeName = 'day' + x + 'StartTime';
        const endDayName = 'endDay' + x;
        const dayEndTimeName = 'day' + x + 'EndTime';
        schedule[teachDayName] = schedule[dayTeachName] ? schedule[dayTeachName] : false;
        schedule[startDayName] = schedule[dayStartTimeName] ? (this.timeFormat === 1 ? moment(schedule[dayStartTimeName], 'HH:mm').format('h:mm A') : schedule[dayStartTimeName]) : '';
        schedule[endDayName] = schedule[dayEndTimeName] ? (this.timeFormat === 1 ? moment(schedule[dayEndTimeName], 'HH:mm').format('h:mm A') : schedule[dayEndTimeName]) : '';
      }

      if (schedule.additionalClassDays && schedule.additionalClassDays.length > 0) {
        schedule.additionalClassDays.forEach((acd: any) => {
          acd.startTime = acd.startTime ? acd.startTime : '';
          acd.endTime = acd.endTime ? acd.endTime : '';
        });
      }

      classInfo.schedules = JSON.stringify([schedule]);

      this.addClass(classInfo).then(() => {
        CommonUtils.hideLoading();
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg5') as string);
        }
        this.initialize(false, null);
        return Promise.resolve();
      });
    }
  }

  created() {
    this.$nextTick(() => {
      this.beforeInitialize();
    });
  }

  mounted() {
    this.renderDraggableElements();
  }

  renderDraggableElements() {
    const that = this;
    try {
      const draggableEl:any = document.getElementById('externalEventsClasses') as HTMLElement;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const draggable = new Draggable(draggableEl, {
        itemSelector: '.draggable-el',
        eventData: function(eventEl:any) {
          let duration = '';
          if (!that.isDroppingToAllDay) {
            if (that.isRegularSchedule) {
              duration = '01:00';
            } else {
              duration = '00:30';
            }
          }
          return {
            title: eventEl.childNodes[0].children[0].innerText,
            backgroundColor: eventEl.childNodes[0].children[1].children[2].innerText,
            borderColor: eventEl.childNodes[0].children[1].children[2].innerText,
            cSt: eventEl.childNodes[0].children[1].children[0].innerText.split(' ')[0],
            cEt: eventEl.childNodes[0].children[1].children[0].innerText.split(' ')[2],
            classId: eventEl.childNodes[0].children[1].children[1].innerText,
            duration: duration
          };
        }
      });
    } catch (e) {}
  }

  onSchoolYearChanged(param: any) {
    this.initialize(true, param);
  }

  beforeInitialize() {
    this.initialize(false, null);
    this.$eventBus.$on('schoolYearChanged', this.onSchoolYearChanged);
    this.$eventBus.$on('doAction', (option:any) => {
      this.executeOption(option);
    })
    this.$eventBus.$on('closeClassEditorMain', (reload: boolean) => {
      this.localIsEditingClass = false;
      this.fullEditorEditMode = false;
      if (reload) {
        this.initialize(false, null);
      }
      this.localRefreshKey = CommonUtils.generateUUID();
    });
  }

  beforeDestroy() {
    this.$eventBus.$off('schoolYearChanged', this.onSchoolYearChanged);
    this.$eventBus.$off('doAction');
    this.$eventBus.$off('closeClassEditorMain');
  }

  portrait: any = { width: 190, height: 282 };
  landscape: any = { width: 277, height: 195 };

  get fileName() {
    return `Planbook - ${this.mCurrentYearName} Schedule`;
  }

  get printFileName() {
    return this.fileName + '.pdf';
  }

  printInPortrait(canvas: any, canvasWidth: any, canvasHeight: any) {
    let printWidth, printHeight;
    if ((canvasWidth / canvasHeight) < (this.portrait.width / this.portrait.height)) {
      printHeight = this.portrait.height;
      printWidth = (canvasWidth / canvasHeight) * printHeight;
    } else {
      printWidth = this.portrait.width;
      printHeight = (canvasHeight / canvasWidth) * printWidth;
    }
    var doc = new JsPDF('p', 'mm');
    doc.addImage(canvas, 'PNG', 10, 3, printWidth, printHeight, '', 'NONE', 0);
    doc.save(this.printFileName);
  }

  printInLandscape(canvas: any, canvasWidth: any, canvasHeight: any) {
    let printWidth, printHeight;
    if ((canvasWidth / canvasHeight) < (this.landscape.width / this.landscape.height)) {
      printHeight = this.landscape.height;
      printWidth = (canvasWidth / canvasHeight) * printHeight;
    } else {
      printWidth = this.landscape.width;
      printHeight = (canvasHeight / canvasWidth) * printWidth;
    }
    var doc = new JsPDF('l', 'mm');
    doc.addImage(canvas, 'PNG', 10, 3, printWidth, printHeight, '', 'NONE', 0);
    doc.save(this.printFileName);
  }

  exportSchedule(mode: string) {
    CommonUtils.showLoading();
    const calendarHeight = document.getElementsByClassName('fc-scrollgrid-section-header')[0].getBoundingClientRect().height + document.getElementsByClassName('fc-timegrid-axis-frame')[0].getBoundingClientRect().height + document.getElementsByClassName('fc-timegrid-body')[0].getBoundingClientRect().height
    if (mode === 'l') {
      this.printWidth = (calendarHeight + 80) * (this.landscape.width / this.landscape.height)
    } else {
      this.printWidth = (calendarHeight + 80) * (this.portrait.width / this.portrait.height)
    }

    this.isPrinting = 'pdf';
    this.localRefreshKey = CommonUtils.generateUUID();

    const that = this;
    this.$nextTick(() => {
      const element = document.getElementById('classesCalendar') as any;
      html2canvas(element).then(function(canvas) {
        document.body.appendChild(canvas);
        that.isPrinting = '';
        that.localRefreshKey = CommonUtils.generateUUID();
        const canvasWidth = parseInt(canvas.style.width);
        const canvasHeight = parseInt(canvas.style.height);
        if (mode === 'l') {
          that.printInLandscape(canvas, canvasWidth, canvasHeight);
        } else {
          that.printInPortrait(canvas, canvasWidth, canvasHeight);
        }
        CommonUtils.hideLoading()
      });
    });
  }

  openScheduleNewTab() {
    const that = this;
    let prtHtml = null;
    this.isPrinting = 'tab';
    this.localRefreshKey = CommonUtils.generateUUID();
    this.$nextTick(() => {
      prtHtml = (document.getElementById('classesCalendar') as any).outerHTML;

      that.isPrinting = '';
      that.localRefreshKey = CommonUtils.generateUUID();

      let stylesHtml = '';
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      const WinPrint: any = window.open('classes', '_blank', '');
      WinPrint.document.write(`<!DOCTYPE html>
      <html>
        <head>
          ${stylesHtml}
          <style>
            .fc-col-header,.fc-daygrid-body,.fc-scrollgrid-sync-table,.fc-timegrid-body { width: 100% !important }
            .fc-timegrid-slots table  { width: 100% !important }
            .fc-timegrid-cols table { width: 100% !important }
            .page-header { display: none !important; }
            .fc-day-grid-container.fc-scroller { height: auto !important; overflow-y: auto; }
            .fc .fc-timegrid-slot { height: ${that.slotDuration.lineHeight} !important; line-height: ${that.slotDuration.lineHeight} !important; border-bottom: 0 !important; }
            ${that.slotDuration.length === 1 ? '.fc .fc-timegrid-slot-minor { border-top-style: none; } .fc-timegrid-slot-label-cushion { position: absolute; padding-top: 8px !important; margin-left: -64px; }' : ''}
          </style>
          <title>${that.printFileName}</title>
        </head>
        <body>
          ${this.darkMode ? '<div class="v-application v-application--is-ltr theme--dark" style="height: 100%">' : ''}
            <div style="height: 100%; width: 100%; overflow: scroll" id="classesContainer">${prtHtml}</div>
          ${this.darkMode ? '</div>' : ''}
        </body>
      </html>`);
      WinPrint.document.close();
      WinPrint.focus();
    });
  }
}
