































































































































import SectionCard from '@/components/plans/card/SectionCard.vue';
import PlansViewMixin from '@/mixins/plans-view-mixin';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import WebUtils from '@/utils/web-utils';
import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const plans = namespace('plans');
const settings = namespace('settings');
const teachers = namespace('teachers');

@Component({
  mixins: [PlansViewMixin],
  components: {
    SectionCard
  }
})
export default class MonthView extends Vue {
  month!: string;
  expandNotesTodo!: boolean;
  bodyHeight!: number;
  weekdays!: any;
  showDetails!: boolean;
  isMounted!: boolean;
  isReduceBrightnessOfPastDays!: boolean;
  zoom!: number;
  nextLessonsEvents!: (param?: any) => Promise<any>;
  prevLessonsEvents!: (param?: any) => Promise<any>;
  gotoLessonsEvents!: (param?: any) => Promise<any>;
  getAppBarBottomOffset!: () => number;

  gotoDateMenu = false;

  @plans.Getter('getCalendarMonth')
  calendarMonth!: any;

  @plans.Getter('getCalendarMonthDays')
  getCalendarMonthDays!: Array<any>;

  @plans.Getter('getCalendarDay')
  calendarDay!: any;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @teachers.Getter
  isCurrentTeacherCycleSchedule!: boolean;

  @settings.Getter
  isCycleSchedule!: boolean;

  @settings.Getter('getClassCycle')
  classCycle!: string;

  @settings.Getter('getGlobalLanguage')
  globalLanguage!: any;

  @settings.Getter('getStudentTeacherId')
  studentTeacherId!: any;

  @settings.Getter
  highContrastMode!: boolean;

  @teachers.Getter('getCurrentTeacherClassCycle')
  currentTeacherClassCycle!: string;

  get getGlobalLanguage() {
    return this.globalLanguage || 'en';
  }

  @Watch('gotoDateMenu')
  focusDatePicker() {
    if (this.gotoDateMenu) {
      this.$nextTick(() => {
        new Promise(resolve => setTimeout(resolve, 100)).then(() => {
          try {
            (this.$refs as any).datePicker.$el.querySelector('.v-date-picker-header__value').querySelector('button').focus()
          } catch (e) {
            console.log(this.$t('focusFailed'));
          }
        });
      });
    }
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get pickerGotoDate() {
    if (CommonUtils.hasText(this.currentDay)) {
      return moment(this.currentDay, 'MM/DD/YYYY').format('YYYY-MM-DD');
    }
    return '';
  }

  get localCalendarMonthEvents() {
    // return this.monthViewType === 'split' ? this.calendarMonth.events : [];
    return [];
  }

  get monthViewType() {
    return this.$vuetify.breakpoint.smAndDown ? 'split' : this.$store.state.local.monthViewType;
  }

  set monthViewType(value: string) {
    this.$store.commit('local/setMonthViewType', value);
  }

  get monthCurrentDay() {
    let currentDay = moment(this.currentDay, 'MM/DD/YYYY', true);
    if (!currentDay.isValid()) {
      currentDay = moment();
    }
    return currentDay.format('YYYY-MM-DD');
  }

  get monthStart() {
    let month = moment(this.month, 'MM/DD/YYYY', true);
    if (!month.isValid()) {
      month = moment();
    }
    return month.format('YYYY-MM-DD');
  }

  get monthEnd() {
    let month = moment(this.month, 'MM/DD/YYYY', true);
    if (!month.isValid()) {
      month = moment();
    }
    return month.endOf('month').format('YYYY-MM-DD');
  }

  get showCalendarDay() {
    return this.$store.state.plans.showCalendarDay && this.monthViewType === 'split';
  }

  set showCalendarDay(value: boolean) {
    this.$store.commit('plans/setShowCalendarDay', value);
    this.$nextTick(() => {
      this.$eventBus.$emit('resize');
    })
  }

  get currentDay() {
    return this.$store.state.plans.currentDay;
  }

  set currentDay(value: string) {
    this.$store.commit('plans/setCurrentDay', value);
  }

  get hasSelectedCalendarDay() {
    return CommonUtils.hasText(this.currentDay);
  }

  get calendarDayObjects() {
    return this.calendarDay.dayObjects || [];
  }

  get displayMonth() {
    return moment(this.month, 'MM/DD/YYYY').format('MMMM');
  }

  get displayYear() {
    return moment(this.month, 'MM/DD/YYYY').format('YYYY');
  }

  get selectedDayOfWeek() {
    if (CommonUtils.hasText(this.currentDay)) {
      return moment(this.currentDay, 'MM/DD/YYYY').format('ddd');
    } else {
      return '...';
    }
  }

  get selectedDate() {
    if (CommonUtils.hasText(this.currentDay)) {
      return moment(this.currentDay, 'MM/DD/YYYY').format('MMM Do');
    } else {
      return '...';
    }
  }

  get calendarHeight() {
    return this.planBoxHeight - 40 - (this.$vuetify.breakpoint.smAndDown ? 51 : 0);
  }

  get calendarDisplay() {
    if (this.showCalendarDay && this.$vuetify.breakpoint.smAndDown) {
      return 'none';
    }
  }

  get calendarWidth() {
    if (this.showCalendarDay) {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '0%';
      }
      return '50%';
    } else {
      return '100%';
    }
  }

  get dayWidth() {
    if (this.showCalendarDay) {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '100%';
      }
      return '50%';
    } else {
      return '0%';
    }
  }

  get calendarStart() {
    return moment(this.month, 'MM/DD/YYYY');
  }

  get calendarEnd() {
    return moment(this.month, 'MM/DD/YYYY').endOf('month');
  }

  getDayObjects(cDay: any) {
    const days = this.getCalendarMonthDays;
    const cDayDate = moment(cDay.date, 'YYYY-MM-DD').format('MM/DD/YYYY');
    for (const key in days) {
      const day = days[key];
      if (day && day.date === cDayDate) {
        return day.dayObjects.filter((c: any) => c.teacherId === this.studentTeacherId || this.studentTeacherId === 0);
      }
    }
    return [];
  }

  getDay(cDay: any) {
    const days = this.getCalendarMonthDays;
    const cDayDate = moment(cDay.date, 'YYYY-MM-DD').format('MM/DD/YYYY');
    for (const key in days) {
      const day = days[key];
      if (day && day.date === cDayDate) {
        return day;
      }
    }
    return {};
  }

  get showCycle() {
    return (this.$currentUser.isAdmin && this.isCurrentTeacherCycleSchedule) || (this.$currentUser.isTeacher && this.isCycleSchedule)
  }

  get twoWeekCycle() {
    return (this.$currentUser.isAdmin && this.currentTeacherClassCycle === 'twoWeeks') || (this.$currentUser.isTeacher && this.classCycle === 'twoWeeks')
  }

  get showDayName() {
    return (this.showCycle || this.twoWeekCycle);
  }

  getDayName(cDay: any) {
    const day = this.getDay(cDay) || {};
    return day.cycleDay || '';
  }

  getDisplayDate(cDay: any) {
    if (cDay.day === 1) {
      return moment(cDay.date, 'YYYY-MM-DD').format('MMM D')
    } else {
      return cDay.day;
    }
  }

  getCalendarEvents(cDay: any) {
    const dayObjects = this.getDayObjects(cDay);
    return dayObjects.map((d: any) => {
      const rawDayObject = d.rawDayObject;
      return {
        title: this.getEventTitle(rawDayObject),
        time: this.getEventTime(rawDayObject),
        color: this.getEventColor(rawDayObject),
        textColor: this.getEventTextColor(rawDayObject),
        textDecoration: this.getEventTextUnderlined(rawDayObject) ? 'underline' : 'none',
        fontWeight: this.getEventTextBold(rawDayObject) ? 'bold' : 'none',
        fontStyle: this.getEventTextItalics(rawDayObject) ? 'italic' : 'none'
      }
    })
  }

  getEventTime(rawDayObject: any) {
    return DateTimeUtils.formatTimeToShow(rawDayObject.startTime || rawDayObject.endTime);
  }

  getEventTitle(rawDayObject: any) {
    const title = rawDayObject.lessonTitle || rawDayObject.eventTitle || rawDayObject.evenTitle || '';
    const unitTitle = rawDayObject.unitNum || (rawDayObject.linkedUnit || {}).unitNum || '';
    let name = unitTitle;
    name += CommonUtils.hasText(unitTitle) && CommonUtils.hasText(title) ? ': ' : '';
    name += title;
    return name;
  }

  getEventColor(rawDayObject: any) {
    const eventStyling: any = this.$store.getters['settings/getEventStyling'];
    return rawDayObject.color || rawDayObject.titleFillColor || (rawDayObject.type === 'E' ? eventStyling.eventLabelFillColor : rawDayObject.eventColor);
  }

  getEventTextColor(rawDayObject: any) {
    const eventStyling: any = this.$store.getters['settings/getEventStyling'];
    return rawDayObject.titleColor || (rawDayObject.type === 'E' ? eventStyling.eventLabelColor : rawDayObject.eventTextColor)
  }

  getEventTextBold(rawDayObject: any) {
    const eventStyling: any = this.$store.getters['settings/getEventStyling'];
    return (rawDayObject.type === 'E' ? eventStyling.eventLabelBold : rawDayObject.classLabelBold) === 'Y';
  }

  getEventTextItalics(rawDayObject: any) {
    const eventStyling: any = this.$store.getters['settings/getEventStyling'];
    return (rawDayObject.type === 'E' ? eventStyling.eventLabelItalic : rawDayObject.classLabelItalic) === 'Y';
  }

  getEventTextUnderlined(rawDayObject: any) {
    const eventStyling: any = this.$store.getters['settings/getEventStyling'];
    return (rawDayObject.type === 'E' ? eventStyling.eventLabelUnderline : rawDayObject.classLabelUnderline) === 'Y'
  }

  isPast(cDay: any) {
    return DateTimeUtils.isPast(moment(cDay.date, 'YYYY-MM-DD').format('MM/DD/YYYY'));
  }

  prevDay() {
    const weekdays = this.calendarMonth.weekdays;
    let day = moment(this.currentDay, 'MM/DD/YYYY');
    let weekday = 0;
    do {
      day = day.add(-1, 'day');
      weekday = day.weekday();
    } while (!weekdays.includes(weekday));
    this.currentDay = day.format('MM/DD/YYYY');
    if (day.isBefore(this.calendarStart)) {
      this.prevLessonsEvents();
    }
  }

  nextDay() {
    const weekdays = this.calendarMonth.weekdays;
    let day = moment(this.currentDay, 'MM/DD/YYYY');
    let weekday = 0;
    do {
      day = day.add(1, 'day');
      weekday = day.weekday();
    } while (!weekdays.includes(weekday));
    this.currentDay = day.format('MM/DD/YYYY');
    if (day.isAfter(this.calendarEnd)) {
      this.nextLessonsEvents();
    }
  }

  doShowCalendarDay(e: any) {
    if (this.monthViewType === 'full') return;
    this.currentDay = moment(e.date, 'YYYY-MM-DD').format('MM/DD/YYYY');
    this.showCalendarDay = !this.showCalendarDay || true;
  }

  get planBoxHeight() {
    if (this.isMounted) {
      return this.bodyHeight - this.getAppBarBottomOffset();
    } else {
      return this.bodyHeight - 64;
    }
  }

  onPickerFirstDayChanged(value: string) {
    this.gotoDateMenu = false;
    this.gotoLessonsEvents(moment(value, 'YYYY-MM-DD').toDate());
  }

  updated() {
    WebUtils.initMathJax();
  }

  stringifyDate() {
    if (this.userInfo.displaySettings.dateStyling.dateFormat === 1) {
      // MM/DD/YYYY
      const formattedDate = moment(this.currentDay, 'MM/DD/YYYY').format('ddd MMM-DD');
      return formattedDate;
    } else if (this.userInfo.displaySettings.dateStyling.dateFormat === 2) {
      // DD/MM/YYYY
      const formattedDate = moment(this.currentDay, 'MM/DD/YYYY').format('ddd DD-MMM');
      return formattedDate;
    }
  }
}
