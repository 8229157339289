var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-form",
    {
      ref: "signUpForm",
      class: { "mt-10": _vm.$vuetify.breakpoint.smAndDown },
      attrs: { "lazy-validation": "" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.doSignUp.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "v-card",
        { attrs: { flat: "" } },
        [
          _vm.$vuetify.breakpoint.mdAndUp
            ? _c(
                "v-toolbar",
                { staticClass: "text-h4 text-center", attrs: { flat: "" } },
                [
                  _c("div", { staticClass: "justify-center fill-width" }, [
                    _c("img", {
                      attrs: {
                        src:
                          "https://cdn.planbook.com/images/planbook-logo-blue.svg",
                        alt: _vm.$t("app.name")
                      }
                    })
                  ])
                ]
              )
            : _vm._e(),
          _vm.hasInfoMsg
            ? _c(
                "div",
                {
                  staticClass:
                    "my-2 text-center font-weight-light font-italic info-message"
                },
                [
                  _c("span", { domProps: { innerHTML: _vm._s(_vm.infoMsg) } }),
                  _c(
                    "pb-btn",
                    {
                      staticClass: "d-inline-block ml-2",
                      attrs: {
                        label: _vm.$t("cancelLabel"),
                        icon: "",
                        "x-small": "",
                        color: "primary"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.doReset.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("v-icon", { attrs: { "x-small": "" } }, [
                        _vm._v("fal fa-times")
                      ])
                    ],
                    1
                  ),
                  _vm.className
                    ? _c("p", { staticClass: "mb-0 mt-1" }, [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.$t("classNameLabel")) +
                              " : " +
                              _vm._s(_vm.className)
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.teacherDisplayName
                    ? _c("p", [
                        _c("b", [
                          _vm._v(
                            _vm._s(_vm.$t("teacherLabel")) +
                              " : " +
                              _vm._s(_vm.teacherDisplayName)
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.hasErrorMsg
            ? _c("div", {
                staticClass:
                  "my-2 text-center font-weight-light font-italic error-message",
                domProps: { innerHTML: _vm._s(_vm.errorMsg) }
              })
            : _vm._e(),
          _vm.hasPasswordValidationMsg
            ? _c("div", {
                staticClass:
                  "my-2 text-center font-weight-light font-italic error-message",
                domProps: { innerHTML: _vm._s(_vm.passwordValidationMsg) }
              })
            : _vm._e(),
          _c(
            "div",
            { staticClass: "px-10 py-2" },
            [
              _c(
                "h2",
                {
                  staticClass: "mb-6 primary--text",
                  class: { "text-left": _vm.$vuetify.breakpoint.smAndDown }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        (_vm.studentSignUpStep > 0
                          ? _vm.$t("studentLabel")
                          : "") + " "
                      ) +
                      " " +
                      _vm._s(_vm.$t("signUpLabel")) +
                      " "
                  )
                ]
              ),
              _vm.studentSignUpStep === 1
                ? [
                    _c("v-text-field", {
                      staticClass: "login-input primary-input",
                      attrs: {
                        "prepend-inner-icon": "fal fa-user",
                        outlined: "",
                        label: _vm.$t("firstNameLabel"),
                        readonly: _vm.loading,
                        rules: _vm.requiredRule
                      },
                      model: {
                        value: _vm.firstName,
                        callback: function($$v) {
                          _vm.firstName = $$v
                        },
                        expression: "firstName"
                      }
                    }),
                    _c("v-text-field", {
                      staticClass: "login-input primary-input",
                      attrs: {
                        "prepend-inner-icon": "fal fa-user",
                        outlined: "",
                        label: _vm.$t("lastNameLabel"),
                        readonly: _vm.loading,
                        rules: _vm.requiredRule
                      },
                      model: {
                        value: _vm.lastName,
                        callback: function($$v) {
                          _vm.lastName = $$v
                        },
                        expression: "lastName"
                      }
                    }),
                    _c("pb-date-field", {
                      attrs: { label: _vm.$t("birthdateLabel") },
                      model: {
                        value: _vm.birthDate,
                        callback: function($$v) {
                          _vm.birthDate = $$v
                        },
                        expression: "birthDate"
                      }
                    }),
                    _c("v-text-field", {
                      staticClass: "login-input primary-input",
                      attrs: {
                        "prepend-inner-icon": "fal fa-at",
                        outlined: "",
                        label: _vm.$t("parentEmailLabel"),
                        readonly: _vm.loading,
                        rules: _vm.emailFormatRule
                      },
                      model: {
                        value: _vm.parentEmail,
                        callback: function($$v) {
                          _vm.parentEmail = $$v
                        },
                        expression: "parentEmail"
                      }
                    })
                  ]
                : [
                    _c("v-text-field", {
                      staticClass: "login-input primary-input",
                      attrs: {
                        "prepend-inner-icon": "fal fa-user",
                        outlined: "",
                        label: _vm.$t("emailAddressLabel"),
                        readonly: _vm.loading,
                        rules: _vm.emailSignUpRules
                      },
                      model: {
                        value: _vm.email,
                        callback: function($$v) {
                          _vm.email = $$v
                        },
                        expression: "email"
                      }
                    }),
                    _vm.studentSignUpStep > 1
                      ? _c("v-text-field", {
                          staticClass: "login-input primary-input",
                          attrs: {
                            "prepend-inner-icon": "fal fa-id-card",
                            outlined: "",
                            label: _vm.$t("studentIDLabel"),
                            readonly: _vm.loading,
                            rules: _vm.codeSignUpRules
                          },
                          model: {
                            value: _vm.code,
                            callback: function($$v) {
                              _vm.code = $$v
                            },
                            expression: "code"
                          }
                        })
                      : _vm._e(),
                    _c("v-text-field", {
                      staticClass: "login-input primary-input",
                      attrs: {
                        "prepend-inner-icon": "fal fa-key",
                        outlined: "",
                        label: _vm.$t("passwordLabel"),
                        readonly: _vm.loading,
                        type: "password",
                        rules: _vm.passwordRule
                      },
                      model: {
                        value: _vm.password,
                        callback: function($$v) {
                          _vm.password = $$v
                        },
                        expression: "password"
                      }
                    }),
                    _c("v-text-field", {
                      staticClass: "login-input primary-input",
                      attrs: {
                        "prepend-inner-icon": "fal fa-key",
                        outlined: "",
                        label: _vm.$t("confirmPasswordLabel2"),
                        readonly: _vm.loading,
                        rules: _vm.confirmPasswordRules,
                        type: "password"
                      },
                      model: {
                        value: _vm.confirmPassword,
                        callback: function($$v) {
                          _vm.confirmPassword = $$v
                        },
                        expression: "confirmPassword"
                      }
                    }),
                    _vm.studentSignUpStep === 0 && _vm.hasPromoCode
                      ? _c("v-text-field", {
                          staticClass: "login-input primary-input",
                          attrs: {
                            "prepend-inner-icon": "fal fa-tag",
                            outlined: "",
                            label: _vm.$t("promoCodeLabel"),
                            readonly: _vm.loading
                          },
                          model: {
                            value: _vm.promoCode,
                            callback: function($$v) {
                              _vm.promoCode = $$v
                            },
                            expression: "promoCode"
                          }
                        })
                      : _vm._e(),
                    _c("div", {
                      ref: "recaptcha",
                      staticClass: "g-recaptcha pb-4",
                      attrs: {
                        "data-theme": _vm.$vuetify.theme.dark
                          ? "dark"
                          : undefined
                      }
                    })
                  ]
            ],
            2
          ),
          _c(
            "v-card-actions",
            {
              staticClass: "px-10 pb-8 pt-0 justify-center welcome-card-actions"
            },
            [
              _vm.studentSignUpStep === 1
                ? _c(
                    "v-btn",
                    {
                      staticClass: "mr-10 mb-3",
                      attrs: {
                        depressed: "",
                        color: "error",
                        dark: "",
                        width: "175px",
                        loading: _vm.loading,
                        type: "submit"
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.doContinue.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("continueLabel")))]
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: {
                        depressed: "",
                        color: "error",
                        dark: "",
                        width: "175px",
                        type: "submit",
                        loading: _vm.loading
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("signUpLabel")))]
                  ),
              _c("v-spacer"),
              _vm.studentSignUpStep > 0
                ? _c(
                    "v-btn",
                    {
                      staticClass: "ml-0 mb-3",
                      attrs: {
                        depressed: "",
                        color: "info",
                        dark: "",
                        width: "175px",
                        disabled: _vm.loading
                      },
                      on: {
                        click: function($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.doCancel.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("cancelLabel")))]
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass:
                        "welcome-btn-forgot text-decoration-underline font-italic",
                      attrs: {
                        text: "",
                        color: "primary",
                        to: "/auth/student-codes"
                      }
                    },
                    [_vm._v(_vm._s(_vm.$t("haveStudentJoinCodeLabel")))]
                  )
            ],
            1
          )
        ],
        1
      ),
      _vm.studentSignUpStep < 2
        ? [
            _c("v-divider", { staticClass: "mx-10" }),
            _c(
              "div",
              { staticClass: "mt-8 text-center fill-width" },
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "white--text",
                    attrs: {
                      color: "accent1",
                      depressed: "",
                      to: _vm.signInPath
                    }
                  },
                  [_vm._v(_vm._s(_vm.$t("signInHaveAnAccountLabel")))]
                )
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }