import { UserSession } from '@/common/user-session';
import AppError, { createErrOptions, SessionExpiredError } from '@/errors';
import EventServices from '@/services/event-services';
import FileServices from '@/services/file-services';
import CommonUtils from '@/utils/common-utils';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import store from '..';
import { BASE_URL } from './../../axios/index';
import CurrentUser from '@/common/current-user';
import moment from 'moment-timezone';

@Module({ namespaced: true })
export default class Events extends VuexModule {
    activeTab = 0;
    eventId = 0;
    customEventId = 0;
    googleId = '';
    googleCalendarId = '';
    eventDate = '';
    endDate = '';
    repeats = 'daily';
    eventText = '';
    eventStartTime = '';
    eventEndTime = '';
    eventTitle = '';
    eventCurrentDate = '';
    specialDayId = 0;
    schoolId = 0;
    districtId = 0;
    noSchool = false;
    noCycle = false;
    notifications: any[] = [];
    privateFlag = false;
    attachments: any[] = [];
    updatedFields: Set<string> = new Set<string>();
    extraDays: any[] = [];
    noEventDays: any[] = [];
    titleFont = null;
    titleFontSize= null;
    titleColor = null;
    titleFillColor = null;
    titleBold = null;
    titleItalic = null;
    titleUnderline = null;
    bodyFont = null;
    bodyFontSize = null;
    bodyColor = null;
    bodyFillColor = null;
    bodyBold = null;
    bodyItalic = null;
    bodyUnderline = null;
    shiftLessons = false;
    updateCurrentEvent = false;
    verifyShift = true;
    editorInitialized = false;
    rawEvent = {};
    deletedLessons: any[] = [];
    haveEvents = false;
    events: any[] = [];
    origEvent: any;
    stickerId = 0;
    stickerBackgroundStyle:any = null;
    stickerBackgroundColor:any = null;
    stickerSize:any = null;
    listLoading = false;
    currentSchoolId: number | null = null;
    isDistrictEvent = false;
    doneFetchingEvents = false;
    limit = 75;
    // toolbar attributes
    searchText = '';
    showImportEvents = false;
    showExportEvents = false;
    selectedRows: Array<any> = [];
    editMode = false;
    selectedEventType = '';

    filterSelections: any = {
      showEventTypes: []
    };

    get getListLoading() {
      return this.listLoading;
    }

    get getDoneFetchingEvents() {
      return this.doneFetchingEvents;
    }

    get getLimit() {
      return this.limit;
    }

    // MUTATIONS: START
    @Mutation
    setFilterSelections(v: any) {
      this.filterSelections = v;
    }

    @Mutation
    setFilterSelectionsShowEventTypes(v: Array<any>) {
      this.filterSelections.showEventTypes = v;
    }

    @Mutation
    setEditMode(editMode: boolean) {
      this.editMode = editMode;
    }

    @Mutation
    setSelectedRows(selectedRows: Array<any>) {
      this.selectedRows = selectedRows;
    }

    @Mutation
    setShowExportEvents(showExportEvents: boolean) {
      this.showExportEvents = showExportEvents;
    }

    @Mutation
    setShowImportEvents(showImportEvents: boolean) {
      this.showImportEvents = showImportEvents;
    }

    @Mutation
    setSearchText(searchText: string) {
      this.searchText = searchText;
    }

    @Mutation
    setSelectedEventType(selectedEventType: string) {
      this.selectedEventType = selectedEventType;
    }

    @Mutation
    setEditorInitialized(editorInitialized: boolean) {
      this.editorInitialized = editorInitialized;
    }

    @Mutation
    setShiftLessons(shiftLessons: boolean) {
      this.shiftLessons = shiftLessons;
    }

    @Mutation
    setActiveTab(tab: number) {
      this.activeTab = tab;
    }

    @Mutation
    setEventDate(eventDate: string) {
      this.eventDate = eventDate;
    }

    @Mutation
    setEndDate(endDate: string) {
      this.endDate = endDate;
    }

    @Mutation
    setRepeats(repeats: string) {
      this.repeats = repeats;
    }

    @Mutation
    setEventText(eventText: string) {
      this.eventText = eventText;
    }

    @Mutation
    setEventStartTime(eventStartTime: string) {
      this.eventStartTime = eventStartTime;
    }

    @Mutation
    setEventEndTime(eventEndTime: string) {
      this.eventEndTime = eventEndTime;
    }

    @Mutation
    setEventTitle(eventTitle: string) {
      this.eventTitle = eventTitle;
    }

    @Mutation
    setCustomEventId(customEventId: number) {
      this.customEventId = customEventId;
    }

    @Mutation
    setEventCurrentDate(eventCurrentDate: string) {
      this.eventCurrentDate = eventCurrentDate;
    }

    @Mutation
    setUpdateCurrentEvent(updateCurrentEvent: boolean) {
      this.updateCurrentEvent = updateCurrentEvent;
    }

    @Mutation
    setSpecialDayId(specialDayId: number) {
      this.specialDayId = specialDayId;
    }

    @Mutation
    setSchoolId(schoolId: number) {
      this.schoolId = schoolId;
    }

    @Mutation
    setDistrictId (districtId: number) {
      this.districtId = districtId;
    }

    @Mutation
    setNoSchool(noSchool: boolean) {
      this.noSchool = noSchool;
    }

    @Mutation
    setNoCycle(noCycle: boolean) {
      this.noCycle = noCycle;
    }

    @Mutation
    setNotifications(val: any) {
      this.notifications = val;
    }

    @Mutation
    appendNotification(val: any) {
      this.notifications.push(val);
      this.updatedFields.add('notifications');
    }

    @Mutation
    setPrivateFlag(privateFlag: boolean) {
      this.privateFlag = privateFlag;
    }

    @Mutation
    setAttachments(attachments: Array<any>) {
      this.attachments = attachments;
    }

    @Mutation
    setUpdatedFields(updatedFields: Set<any>) {
      this.updatedFields = updatedFields;
    }

    @Mutation
    setExtraDays(extraDays: Array<any>) {
      this.extraDays = extraDays;
    }

    @Mutation
    setNoEventDays(noEventDays: Array<any>) {
      this.noEventDays = noEventDays;
    }

    @Mutation
    setTitleFont(titleFont: any) {
      this.titleFont = titleFont;
    }

    @Mutation
    setTitleFontSize(titleFontSize: any) {
      this.titleFontSize = titleFontSize;
    }

    @Mutation
    setTitleColor(titleColor: any) {
      this.titleColor = titleColor;
    }

    @Mutation
    setTitleFillColor(titleFillColor: any) {
      this.titleFillColor = titleFillColor;
    }

    @Mutation
    setTitleBold(titleBold: any) {
      this.titleBold = titleBold;
    }

    @Mutation
    setTitleItalic(titleItalic: any) {
      this.titleItalic = titleItalic;
    }

    @Mutation
    setTitleUnderline(titleUnderline: any) {
      this.titleUnderline = titleUnderline;
    }

    @Mutation
    setBodyFont(bodyFont: any) {
      this.bodyFont = bodyFont;
    }

    @Mutation
    setBodyFontSize(bodyFontSize: any) {
      this.bodyFontSize = bodyFontSize;
    }

    @Mutation
    setBodyColor(bodyColor: any) {
      this.bodyColor = bodyColor;
    }

    @Mutation
    setBodyFillColor(bodyFillColor: any) {
      this.bodyFillColor = bodyFillColor;
    }

    @Mutation
    setBodyBold(bodyBold: any) {
      this.bodyBold = bodyBold;
    }

    @Mutation
    setBodyItalic(bodyItalic: any) {
      this.bodyItalic = bodyItalic;
    }

    @Mutation
    setBodyUnderline(bodyUnderline: any) {
      this.bodyUnderline = bodyUnderline;
    }

    @Mutation
    setEventId(eventId: number) {
      this.eventId = eventId;
    }

    @Mutation
    setVerifyShift(verifyShift: boolean) {
      this.verifyShift = verifyShift;
    }

    @Mutation
    setDeletedLessons(deletedLessons: Array<any>) {
      this.deletedLessons = deletedLessons;
    }

    @Mutation
    setHaveEvents(haveEvents: boolean) {
      this.haveEvents = haveEvents;
    }

    @Mutation
    setEvents(events: Array<any>) {
      this.events = events;
    }

    @Mutation
    setDoneFetchingEvents(doneFetchingEvents: boolean) {
      this.doneFetchingEvents = doneFetchingEvents;
    }

    @Mutation
    updateEvents(newEvents: Array<any>) {
      const prevEventsLength = this.events.length;

      newEvents.map((e: any) => {
        e.key = (e.eventId + e.googleId) + '-' + moment(e.currentDate, 'MM/DD/YYYY').format('YYYY-MM-DD');
        return e;
      });
      for (const e1 of newEvents) {
        if (!this.events.some((e2: any) => {
          return e1.key === e2.key;
        })) {
          this.events.push(e1);
        }
      }

      if (this.events.length <= prevEventsLength) {
        this.doneFetchingEvents = true;
      }
    }

    @Mutation
    spliceEvents(params: any) {
      this.events.sort((e1: any, e2: any) => {
        if (CommonUtils.hasNoText(e1.currentDate) || CommonUtils.hasNoText(e2.currentDate)) {
          return 0;
        }

        const e1Date = moment(e1.currentDate, 'MM/DD/YYYY');
        const e2Date = moment(e2.currentDate, 'MM/DD/YYYY');

        if (e1Date.isBefore(e2Date)) {
          return params.sortDesc ? 1 : -1;
        } else if (e1Date.isAfter(e2Date)) {
          return params.sortDesc ? -1 : 1;
        } else {
          return 0;
        }
      }).splice(params.start, params.deleteCount);
    }

    @Mutation
    setEvent(input: any) {
      if (input && input.data) {
        const stickerStyling = store.getters['settings/getStickerStyling'];
        const rawEvent = input.data;
        const event: any = {};
        const initialEvent = this.origEvent;
        event.eventId = rawEvent.eventId;
        event.customEventId = rawEvent.customEventId;
        event.googleId = rawEvent.googleId;
        event.googleCalendarId = rawEvent.googleCalendarId;
        event.eventDate = rawEvent.eventDate;
        event.endDate = rawEvent.endDate;
        event.repeats = rawEvent.repeats;
        event.eventText = rawEvent.eventText;
        event.eventStartTime = rawEvent.startTime || rawEvent.eventStartTime || '';
        event.eventEndTime = rawEvent.endTime || rawEvent.eventEndTime || '';
        event.eventTitle = rawEvent.eventTitle;
        event.eventCurrentDate = rawEvent.currentDate;
        event.specialDayId = rawEvent.specialDayId;
        event.schoolId = CommonUtils.get(rawEvent.schoolId, 0);
        event.districtId = CommonUtils.get(rawEvent.districtId, 0);
        event.noSchool = rawEvent.noSchool === 'Y' || rawEvent.noSchool === true;
        event.noCycle = rawEvent.noCycle === 'Y' || rawEvent.noCycle === true;
        event.notifications = rawEvent.notifications || [];
        event.privateFlag = rawEvent.privateFlag === 'Y' || rawEvent.privateFlag === true;
        event.attachments = CommonUtils.getOrDefaultTo(rawEvent.attachments, []);
        event.extraDays = CommonUtils.getOrDefaultTo(rawEvent.extraDays, []);
        event.noEventDays = CommonUtils.getOrDefaultTo(rawEvent.noEventDays, []);
        event.titleFont = rawEvent.stickerId > 0 ? rawEvent.titleFont || stickerStyling.eventStickerLabelFont : rawEvent.titleFont || initialEvent.titleFont;
        event.titleFontSize = rawEvent.stickerId > 0 ? rawEvent.titleFontSize || rawEvent.titleSize || stickerStyling.eventStickerLabelSize : rawEvent.titleFontSize || rawEvent.titleSize || initialEvent.titleFontSize;
        event.titleColor = rawEvent.stickerId > 0 ? rawEvent.titleColor || stickerStyling.eventStickerLabelColor : rawEvent.titleColor || initialEvent.titleColor;
        event.titleFillColor = rawEvent.stickerId > 0 ? rawEvent.titleFillColor || stickerStyling.eventStickerLabelFillColor : rawEvent.titleFillColor || initialEvent.titleFillColor;
        event.titleBold = rawEvent.stickerId > 0 ? rawEvent.titleBold || stickerStyling.eventStickerLabelBold : rawEvent.titleBold || initialEvent.titleBold;
        event.titleItalic = rawEvent.stickerId > 0 ? rawEvent.titleItalic || stickerStyling.eventStickerLabelItalic : rawEvent.titleItalic || initialEvent.titleItalic;
        event.titleUnderline = rawEvent.stickerId > 0 ? rawEvent.titleUnderline || stickerStyling.eventStickerLabelUnderline : rawEvent.titleUnderline || initialEvent.titleUnderline;
        event.bodyFont = rawEvent.stickerId > 0 ? rawEvent.bodyFont || stickerStyling.eventStickerTextFont : rawEvent.bodyFont || initialEvent.bodyFont;
        event.bodyFontSize = rawEvent.stickerId > 0 ? rawEvent.bodyFontSize || rawEvent.bodySize || stickerStyling.eventStickerTextSize : rawEvent.bodyFontSize || rawEvent.bodySize || initialEvent.bodyFontSize;
        event.bodyColor = rawEvent.stickerId > 0 ? rawEvent.bodyColor || stickerStyling.eventStickerTextColor : rawEvent.bodyColor || initialEvent.bodyColor;
        event.bodyFillColor = rawEvent.stickerId > 0 ? rawEvent.bodyFillColor || stickerStyling.eventStickerTextFillColor : rawEvent.bodyFillColor || initialEvent.bodyFillColor;
        event.bodyBold = rawEvent.stickerId > 0 ? rawEvent.bodyBold || stickerStyling.eventStickerTextBold : rawEvent.bodyBold || initialEvent.bodyBold;
        event.bodyItalic = rawEvent.stickerId > 0 ? rawEvent.bodyItalic || stickerStyling.eventStickerTextItalic : rawEvent.bodyItalic || initialEvent.bodyItalic;
        event.bodyUnderline = rawEvent.stickerId > 0 ? rawEvent.bodyUnderline || stickerStyling.eventStickerTextUnderline : rawEvent.bodyUnderline || initialEvent.bodyUnderline;
        event.stickerId = rawEvent.stickerId;
        event.stickerBackgroundStyle = rawEvent.stickerBackgroundStyle || initialEvent.stickerBackgroundStyle;
        event.stickerBackgroundColor = rawEvent.stickerBackgroundColor || initialEvent.stickerBackgroundColor;
        event.stickerSize = rawEvent.stickerSize || initialEvent.stickerSize;
        const state: any = this;
        for (const i in event.attachments) event.attachments[i].filename = event.attachments[i].name;
        for (const key in event) state[key] = event[key];
        this.origEvent = event;
      }
    }

    @Mutation
    setAttachmentPrivateFlag(params: any) {
      this.attachments[params.index].privateFlag = params.value;
    }

    @Mutation
    removeAttachment(index: number) {
      if (this.attachments) {
        this.attachments.splice(index, 1);
      }
    }

    @Mutation
    clearEventForm() {
      this.activeTab = 0;
      const eventStyling = store.getters['settings/getEventStyling'];
      const stickerStyling = store.getters['settings/getStickerStyling'];
      const event: any = {};
      event.eventDate = '';
      event.endDate = '';
      event.repeats = 'daily';
      event.eventText = '';
      event.eventStartTime = '';
      event.eventEndTime = '';
      event.eventTitle = '';
      event.customEventId = 0;
      event.eventCurrentDate = '';
      event.specialDayId = 0;
      event.schoolId = 0;
      event.districtId = 0;
      event.noSchool = false;
      event.noCycle = false;
      event.privateFlag = false;
      event.attachments = [];
      event.updatedFields = new Set<string>();
      event.extraDays = [];
      event.noEventDays = [];
      event.notifications = [];
      event.eventId = 0;
      event.googleId = '';
      event.googleCalendarId = '';
      event.titleFont = eventStyling.eventLabelFont;
      event.titleFontSize = eventStyling.eventLabelSize;
      event.titleColor = eventStyling.eventLabelColor;
      event.titleFillColor = eventStyling.eventLabelFillColor;
      event.titleBold = eventStyling.eventLabelBold;
      event.titleItalic = eventStyling.eventLabelItalic;
      event.titleUnderline = eventStyling.eventLabelUnderline;
      event.bodyFont = eventStyling.eventTextFont;
      event.bodyFontSize = eventStyling.eventTextSize;
      event.bodyColor = eventStyling.eventTextColor;
      event.bodyFillColor = eventStyling.eventTextFillColor;
      event.bodyBold = eventStyling.eventTextBold;
      event.bodyItalic = eventStyling.eventTextItalic;
      event.bodyUnderline = eventStyling.eventTextUnderline;
      event.shiftLessons = false;
      event.updateCurrentEvent = false;
      event.verifyShift = true;
      event.stickerId = 0;
      event.stickerBackgroundStyle = stickerStyling.stickerBackgroundStyle;
      event.stickerBackgroundColor = stickerStyling.stickerBackgroundColor;
      event.stickerSize = stickerStyling.stickerSize;
      const state: any = this;
      for (const key in event) state[key] = event[key];
      this.origEvent = event;
      this.deletedLessons = [];
      this.isDistrictEvent = false;
    }

    @Mutation
    setEventStickerId(stickerId:number) {
      this.stickerId = stickerId;
    }

    @Mutation
    setEventStickerBackgroundStyle(stickerBackgroundStyle:string) {
      this.stickerBackgroundStyle = stickerBackgroundStyle;
    }

    @Mutation
    setEventStickerBackgroundColor(stickerBackgroundColor:string) {
      this.stickerBackgroundColor = stickerBackgroundColor;
    }

    @Mutation
    setEventStickerSize(stickerSize:string) {
      this.stickerSize = stickerSize;
    }

    @Mutation
    setListLoading(listLoading: boolean) {
      this.listLoading = listLoading;
    }

    @Mutation
    setCurrentSchoolId(currentSchoolId: number | null) {
      this.currentSchoolId = currentSchoolId;
    }

    @Mutation
    setIsDistrictEvent(isDistrictEvent: boolean) {
      this.isDistrictEvent = isDistrictEvent;
    }
    // MUTATIONS: END

    // ACTIONS: START
    @Action({ rawError: true })
    async init(params?: any) {
      if (params && params.loadData) {
        this.context.commit('clearEventForm');
        this.context.commit('setEvent', params);
      }
      return Promise.resolve();
    }

    @Action({ rawError: true })
    async updateNotifications(params: any) {
      try {
        const request = {
          eventId: params.eventId || this.eventId,
          customEventId: params.customEventId || this.customEventId,
          allEvents: params.allEvents,
          mode: params.mode || 'U',
          notificationUnit: params.notificationUnit || this.notifications.map((n: any) => { return n.display || n.displayUnit }),
          notificaitonMinutesPrior: params.notificaitonMinutesPrior || this.notifications.map((n: any) => { return n.minutesPrior }),
          notificationTime: params.notificationTime || this.notifications.map((n: any) => { return n.sendTime || '' })
        };
        const resp = await EventServices.setNotifications(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async save() {
      try {
        const userMode = this.context.rootState.settings.userMode;
        const request = Events.createSaveRequest();
        request.userMode = userMode;
        if (this.isDistrictEvent) {
          request.districtId = CurrentUser.settings.schoolSettings?.districtId;
          request.schoolId = 0;
        } else if (CurrentUser.isAdmin) {
          request.districtId = 0;
          request.schoolId = this.currentSchoolId || this.context.rootGetters['settings/getSchoolId']
        }
        let resp: any = { data: {} };
        if (this.eventId > 0) {
          resp = await EventServices.updateEvent(request);
        } else {
          resp = await EventServices.addEvent(request);
        }
        console.log(JSON.stringify(request));
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else if (CommonUtils.isNotEmpty(data.deletedLessons)) {
            this.context.commit('setDeletedLessons', data.deletedLessons);
            return Promise.resolve({ data });
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            this.context.commit('setIsDistrictEvent', false);
            this.context.commit('setEvents', data.events || []);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async delete() {
      try {
        const userMode = this.context.rootState.settings.userMode;
        const request = Events.createSaveRequest();
        request.userMode = userMode;
        request.deleteCurrentEvent = request.updateCurrentEvent;
        request.currentSchoolId = this.currentSchoolId;
        delete request.updateCurrentEvent;
        const resp = await EventServices.deleteEvent(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            this.context.commit('setEvents', data.events || []);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async deleteEvents(params: any) {
      try {
        params.userMode = this.context.rootState.settings.userMode;
        params.currentSchoolId = this.currentSchoolId;
        params.limit = this.limit;
        const resp = await EventServices.deleteEvents(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            this.context.commit('setEvents', data.events || []);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async hideGoogleCalendarEvent() {
      try {
        const request = Events.createSaveRequest();
        const resp = await EventServices.hideGoogleCalendarEvent(request);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            this.context.commit('setEvents', data.events || []);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async updateGoogleCalendarEvents(params: any) {
      try {
        const resp = await EventServices.updateGoogleCalendarEvents(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            this.context.commit('setEvents', data.events || []);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async adminEventShift(params?: any) {
      try {
        if (params) {
          const userInfo = this.context.rootGetters['settings/getSettings'];
          params.teacherId = userInfo.userId;
          params.yearId = userInfo.schoolYearSettings.currentYearId;
          params.userMode = this.context.rootState.settings.userMode;
          params.monday = this.context.rootGetters['plans/getMonday'];
          params.fetchWeekSize = 1;
        } else {
          return Promise.reject(new AppError('contactSupport'));
        }
        const resp = await EventServices.adminEventShift(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async loadEvents(params?: any) {
      try {
        let resp = null;
        if (params) {
          resp = await EventServices.getEvents({
            userMode: this.context.rootState.settings.userMode,
            currentSchoolId: this.currentSchoolId,
            start: CommonUtils.hasText(params.start) ? params.start : null,
            end: CommonUtils.hasText(params.end) ? params.end : null,
            limit: Object.prototype.hasOwnProperty.call(params, 'limit') ? params.limit : -1,
            searchText: CommonUtils.hasText(params.searchText) ? params.searchText : '',
            showEventTypes: Object.prototype.hasOwnProperty.call(params, 'filterSelectionsShowEventTypes') ? JSON.stringify(params.filterSelectionsShowEventTypes) : null
          });
        } else {
          resp = await EventServices.getEvents({
            userMode: this.context.rootState.settings.userMode,
            currentSchoolId: this.currentSchoolId
          });
        }
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            if (params && params.addEventPage) {
              this.context.commit('updateEvents', data.events);
            } else {
              this.context.commit('setEvents', data.events || []);
            }
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async getEventNotificationsById(params: any) {
      try {
        const resp = await EventServices.getEventNotificationsById(params);
        if (resp) {
          const data = resp.data;
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            return Promise.resolve(data);
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }

    @Action({ rawError: true })
    async export(params: any) {
      const formData = new URLSearchParams();
      formData.append('removeHTML', CommonUtils.booleanToString(params.removeHTML));
      formData.append('userMode', this.context.rootState.settings.userMode);
      if (CurrentUser.isDistrictAdmin) {
        formData.append('schoolId', params.schoolId);
        if (params.schoolId && params.schoolId > 0) {
          formData.append('excludeDistrictEvents', CommonUtils.booleanToString(params.excludeDistrictEvents));
        }
      }
      formData.append('X-PB-ACCESS-TOKEN', UserSession.getAccessToken());
      formData.append('X-PB-CLIENT-YEAR-ID', UserSession.getCurrentYearIdAsString());
      formData.append('exportType', 'pdf');
      return fetch(BASE_URL + '/downloadEvents', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        credentials: 'include',
        body: formData
      }).then(async (response) => {
        const type = response.headers.get('Content-Type') as string;
        const disposition = response.headers.get('Content-Disposition') as string;
        const fileName = FileServices.getFileName(disposition);
        if (type.includes('json')) {
          // process errors
          return Promise.reject(new AppError('contactSupport'));
        } else {
          const blob = await response.blob();
          FileServices.downloadFile(blob, fileName, type);
        }
        return Promise.resolve();
      });
    }

    @Action({ rawError: true })
    async import(params: any) {
      try {
        const userMode = this.context.rootState.settings.userMode;
        params.userMode = userMode;
        let resp = null
        let uploadResp = null
        if (params.type === 'T') {
          resp = await EventServices.importEvents(params);
        } else {
          if (this.isDistrictEvent) {
            params.districtId = CurrentUser.settings.schoolSettings?.districtId;
            params.schoolId = 0;
          } else if (CurrentUser.isAdmin) {
            params.districtId = 0;
            params.schoolId = this.currentSchoolId || this.context.rootGetters['settings/getSchoolId']
          }
          uploadResp = await EventServices.uploadEvents(params);
          resp = await EventServices.getEvents({ userMode: userMode, currentSchoolId: this.currentSchoolId });
        }
        if (resp) {
          const data = resp.data;
          const uploadData = uploadResp ? uploadResp.data : {};
          if (data.notLoggedIn === 'true' || data.notLoggedIn === true) {
            return Promise.reject(new SessionExpiredError());
          } else if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            await this.context.dispatch('plans/reloadPlans', {}, { root: true });
            this.context.commit('setEvents', data.events || []);
            this.context.commit('setIsDistrictEvent', false);
            return Promise.resolve({ data, uploadData });
          }
        } else {
          return Promise.resolve({ data: {}, uploadData: {} });
        }
      } catch (e) {
        return Promise.reject(new AppError('contactSupport', createErrOptions(e)));
      }
    }
    // ACTIONS: END

    private static createSaveRequest() {
      const state = store.state.events;
      const userInfo: any = store.getters['settings/getSettings']
      const request: any = {
        eventId: 0,
        googleId: '',
        googleCalendarId: '',
        customEventId: 0,
        eventDate: '',
        endDate: '',
        repeats: 'daily',
        eventText: '',
        eventStartTime: '',
        eventEndTime: '',
        eventTitle: '',
        eventCurrentDate: '',
        specialDayId: 0,
        schoolId: 0,
        districtId: 0,
        noSchool: false,
        noCycle: false,
        notifications: [],
        privateFlag: false,
        attachments: [],
        extraDays: [],
        noEventDays: [],
        titleFont: null,
        titleFontSize: null,
        titleColor: null,
        titleFillColor: null,
        titleBold: null,
        titleItalic: null,
        titleUnderline: null,
        bodyFont: null,
        bodyFontSize: null,
        bodyColor: null,
        bodyFillColor: null,
        bodyBold: null,
        bodyItalic: null,
        bodyUnderline: null,
        shiftLessons: false,
        updateCurrentEvent: false,
        verifyShift: true,
        stickerId: 0,
        stickerBackgroundStyle: null,
        stickerBackgroundColor: null,
        stickerSize: null,
        limit: 75
      };
      for (const key in request) request[key] = (state[key] !== undefined) ? state[key] : request[key];
      request.updatedFields = Array.from(state.updatedFields || new Set<string>());
      const attachments = CommonUtils.getOrDefaultTo(request.attachments, []);
      for (const i in attachments) {
        const attachment = attachments[i];
        request['attachments[' + i + '].url'] = attachment.url;
        request['attachments[' + i + '].name'] = attachment.name || attachment.filename;
        request['attachments[' + i + '].privateFlag'] = attachment.privateFlag;
      }
      delete request.attachments;
      request.extraDays = request.extraDays.filter((e: string) => CommonUtils.hasText(e));
      request.noEventDays = request.noEventDays.filter((e: string) => CommonUtils.hasText(e));
      const notifications = CommonUtils.getOrDefaultTo(request.notifications, []);
      request.notificationUnit = notifications.map((n: any) => { return n.display || n.displayUnit });
      request.notificaitonMinutesPrior = notifications.map((n: any) => { return n.minutesPrior });
      request.notificationTime = notifications.map((n: any) => { return n.sendTime || '' });
      delete request.notifications;
      const eventStyling = userInfo.displaySettings.eventStyling;
      const stickerStyling = userInfo.displaySettings.stickerStyling;
      if ((request.titleFont === eventStyling.eventLabelFont &&
        request.titleFontSize === eventStyling.eventLabelSize &&
        request.titleColor === eventStyling.eventLabelColor &&
        request.titleFillColor === eventStyling.eventLabelFillColor &&
        request.titleBold === eventStyling.eventLabelBold &&
        request.titleItalic === eventStyling.eventLabelItalic &&
        request.titleUnderline === eventStyling.eventLabelUnderline &&
        request.bodyFont === eventStyling.eventTextFont &&
        request.bodyFontSize === eventStyling.eventTextSize &&
        request.bodyColor === eventStyling.eventTextColor &&
        request.bodyFillColor === eventStyling.eventTextFillColor &&
        request.bodyBold === eventStyling.eventTextBold &&
        request.bodyItalic === eventStyling.eventTextItalic &&
        request.bodyUnderline === eventStyling.eventTextUnderline) ||
        (request.titleFont === stickerStyling.eventStickerLabelFont &&
          request.titleFontSize === stickerStyling.eventStickerLabelSize &&
          request.titleColor === stickerStyling.eventStickerLabelColor &&
          request.titleFillColor === stickerStyling.eventStickerLabelFillColor &&
          request.titleBold === stickerStyling.eventStickerLabelBold &&
          request.titleItalic === stickerStyling.eventStickerLabelItalic &&
          request.titleUnderline === stickerStyling.eventStickerLabelUnderline &&
          request.bodyFont === stickerStyling.eventStickerTextFont &&
          request.bodyFontSize === stickerStyling.eventStickerTextSize &&
          request.bodyColor === stickerStyling.eventStickerTextColor &&
          request.bodyFillColor === stickerStyling.eventStickerTextFillColor &&
          request.bodyBold === stickerStyling.eventStickerTextBold &&
          request.bodyItalic === stickerStyling.eventStickerTextItalic &&
          request.bodyUnderline === stickerStyling.eventStickerTextUnderline)
      ) {
        delete request.titleFont;
        delete request.titleFontSize;
        delete request.titleColor;
        delete request.titleFillColor;
        delete request.titleBold;
        delete request.titleItalic;
        delete request.titleUnderline;
        delete request.bodyFont;
        delete request.bodyFontSize;
        delete request.bodyColor;
        delete request.bodyFillColor;
        delete request.bodyBold;
        delete request.bodyItalic;
        delete request.bodyUnderline;
      }
      if (request.stickerBackgroundStyle === stickerStyling.stickerBackgroundStyle &&
        request.stickerBackgroundColor === stickerStyling.stickerBackgroundColor &&
        request.stickerSize === stickerStyling.stickerSize) {
        delete request.stickerBackgroundStyle;
        delete request.stickerBackgroundColor;
        delete request.stickerSize;
      }
      return request;
    }
}
