




































































/* eslint-disable no-new */
import ClassEditor from '@/components/classes/ClassEditor.vue';
import ClassActions from '@/components/classes/ClassActions.vue';
import ClassworkActions from '@/components/classwork/ClassworkActions.vue';
import ClassworkEditor from '@/components/classwork/ClassworkEditor.vue';
import Loader from '@/components/common/Loader.vue';
import EventActions from '@/components/events/EventActions.vue';
import GoogleEventActions from '@/components/events/GoogleEventActions.vue';
import EventEditor from '@/components/events/EventEditor.vue';
import LessonBanksActions from '@/components/lessonlists/LessonBanksActions.vue';
import LessonBanksEditor from '@/components/lessonlists/LessonBanksEditor.vue';
import LessonActions from '@/components/lessons/LessonActions.vue';
import LessonEditor from '@/components/lessons/LessonEditor.vue';
import SchoolSubscriptionRenewal from '@/components/payments/SchoolSubscriptionRenewal.vue';
import SubscriptionRenewal from '@/components/payments/SubscriptionRenewal.vue';
import ResourcesEditor from '@/components/resources/ResourcesEditor.vue';
import SchoolYearEditor from '@/components/schoolyears/SchoolYearEditor.vue';
import StudentActions from '@/components/students/StudentActions.vue';
import StudentEditor from '@/components/students/StudentEditor.vue';
import TeacherEditor from '@/components/teachers/TeacherEditor.vue';
import TemplateActions from '@/components/templates/TemplateActions.vue';
import TemplateEditor from '@/components/templates/TemplateEditor.vue';
import UnitActions from '@/components/units/UnitActions.vue';
import UnitEditor from '@/components/units/UnitEditor.vue';
import LessonViewMode from '@/components/lessons/LessonViewMode.vue';
import NotificationPermission from '@/components/notifications/NotificationPermission.vue';
import { ActionsModal } from '@/types/global.types';
import BaseSubPageResizeable from '@/utils/base-sub-page-resizeable';
import CommonUtils from '@/utils/common-utils';
import Reminders from '@/views/pages/Reminders.vue';
import RemindersMobileDrawer from '@/components/reminders/RemindersMobileDrawer.vue';
import StudentJoinClassEditor from '@/components/students/StudentJoinClassEditor.vue';
import StudentManageClassEditor from '@/components/students/StudentManageClassEditor.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { PlanbookStorage } from '@/common/planbook-storage';
import DefaultSubpage from '@/components/core/DefaultSubPage.vue';
import DateTimeUtils from '@/utils/date-time-utils';
import moment from 'moment-timezone';
import AppError from '@/errors';
import { contrastModeColors } from '@/constants';
import { getLocaleFromURL } from '@/plugins/i18n';

const settings = namespace('settings');
const index = namespace('index');
const stickers = namespace('stickers');
const plans = namespace('plans');
const drawer = namespace('drawer');
const integration = namespace('integration');

declare const window: any;

@Component({
  components: {
    Loader,
    LessonEditor,
    SchoolYearEditor,
    UnitEditor,
    EventEditor,
    LessonActions,
    ClassworkActions,
    EventActions,
    GoogleEventActions,
    UnitActions,
    Reminders,
    ClassworkEditor,
    TemplateEditor,
    TemplateActions,
    StudentEditor,
    StudentActions,
    TeacherEditor,
    ResourcesEditor,
    LessonBanksEditor,
    LessonBanksActions,
    ClassActions,
    ClassEditor,
    SubscriptionRenewal,
    SchoolSubscriptionRenewal,
    DefaultSubpage,
    NotificationPermission,
    LessonViewMode,
    RemindersMobileDrawer,
    StudentJoinClassEditor,
    StudentManageClassEditor
  }
})
export default class Index extends Vue {
  localShowRightColumn = false;
  payloadIsModal = false;

  subpagePreferenceWidth = 0;
  defaultPreferenceWidth = 0;
  fullscreenWidth = 0;
  rightColumnWidth = 0;
  localSubpageFullscreen = false;
  localSubpageProportion = 0.3;
  selectedPage = '1';
  localTeacherListDockPosition: any = '';
  isMounted = false;
  plansAddNotesTodo = false;
  lessonAddClasswork = false;

  subPages: any = {
    lesson: false,
    event: false,
    schoolyear: false,
    template: false,
    lessonLayout: false,
    reminder: false,
    notesTodoEditor: false,
    mylist: false,
    message: false,
    mystrategies: false,
    classwork: false,
    student: false,
    teacher: false,
    sharingoptions: false,
    lessonBanks: false,
    resources: false,
    csv: false,
    class: false,
    unit: false,
    default: false,
    lessonViewMode: false,
    joinClass: false,
    manageClass: false
  };

  localInput: any = {
    lesson: undefined,
    classwork: undefined,
    event: undefined,
    schoolyear: undefined,
    template: undefined,
    teacher: undefined,
    lessonBanks: undefined,
    student: undefined,
    studentActions: undefined,
    lessonActions: undefined,
    classworkActions: undefined,
    eventActions: undefined,
    googleEventActions: undefined,
    templateActions: undefined,
    lessonBanksActions: undefined,
    classActions: undefined,
    resources: undefined,
    csv: undefined,
    class: undefined,
    lessonLayout: undefined,
    sharingoptions: undefined,
    mylist: undefined,
    mystrategies: undefined,
    message: undefined,
    unit: undefined,
    default: undefined,
    notesTodoEditor: undefined,
    lessonViewMode: undefined,
    joinClass: undefined,
    manageClass: undefined
  };

  @settings.State
  userMode!: string;

  @settings.Getter('getBrowserWidth')
  browserWidth!: number;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @settings.Getter('getGlobalFontFamily')
  globalFontFamily!: string;

  @settings.Getter('getGlobalFontSize')
  globalFontSize!: string;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter('darkMode')
  darkMode!: boolean;

  @settings.Getter('autoColorScheme')
  autoColorScheme!: boolean;

  @settings.Getter
  highContrastMode!: boolean;

  @stickers.Action
  loadStickers!: () => Promise<any>;

  @stickers.Getter
  getStickerLabel!: (key: string) => string;

  @settings.Getter
  isPaymentNeeded!: string;

  @settings.Getter
  isSubscriptionExpiring!: boolean;

  @settings.Getter
  getDisplaySettings!:any;

  @settings.Getter('getGlobalLanguage')
  globalLanguage!: any;

  @index.Getter
  isTeacherListFullWidth!: any;

  @plans.State
  viewType!: string;

  @drawer.State('remindersMobileDrawer')
  remindersDrawerMobile!: boolean;

  @integration.Action
  getAllRosteredItems!: () => Promise<any>;

  observer?: MutationObserver;

  get localSubPage(): string {
    return this.$store.state.index.openSubpage;
  }

  set localSubPage(page: string) {
    this.$store.commit('index/setOpenSubpage', page);
  }

  get localIsModal() {
    return this.$store.state.index.isModal;
  }

  set localIsModal(modal: boolean) {
    this.$store.commit('index/setIsModal', modal);
  }

  get isEmbedded(): boolean {
    return (CommonUtils.hasText(this.$router.currentRoute.query.embedded) && this.$router.currentRoute.query.embedded === 'true');
  }

  get shouldLogin(): boolean {
    return this.$store.state.settings.shouldLogin;
  }

  set shouldLogin(value: boolean) {
    this.$store.commit('settings/setShouldLogin', value);
  }

  get localIsTeacherListFullWidth() {
    return this.isTeacherListFullWidth;
  }

  set localIsTeacherListFullWidth(val: boolean) {
    this.$store.commit('index/setIsTeacherListFullWidth', val);
  }

  get resizeable() {
    return this.$store.state.index.resizeable;
  }

  set resizeable(val: any) {
    this.$store.commit('index/setResizeable', val);
  }

  get teacherListContentWidth() {
    if (['plans', 'assign-students', 'messages'].includes(this.localPage) && this.userMode === 'A') {
      return this.localIsTeacherListFullWidth ? 317 : 63;
    } else {
      return 0;
    }
  }

  get mainContentWidth() {
    return `calc(100vw - ${this.teacherListContentWidth}px - ${this.wrapperLeftPaddingWidth}px - ${this.subPageContentWidth}px - ${this.dividerAdjustments}px)`;
  }

  get mobileMode() {
    return (this.browserWidth < 960)
  }

  get isListView() {
    return this.viewType === 'L' && this.localPage === 'plans';
  }

  get firstLoad() {
    return this.$store.state.settings.firstLoad;
  }

  set firstLoad(value: boolean) {
    this.$store.commit('settings/setFirstLoad', value);
  }

  get initialSignIn() {
    return this.$store.state.settings.initialSignIn;
  }

  set initialSignIn(value: boolean) {
    this.$store.commit('settings/setInitialSignIn', value);
  }

  @Watch('mainContentWidth')
  updateBreakpoint() {
    const width = window.innerWidth - this.teacherListContentWidth - this.wrapperLeftPaddingWidth - 1 - this.subPageContentWidth;
    this.$store.commit('index/setMobileBreakpoint', width);
  }

  @Watch('mobileMode')
  reopenDefaultSubpage() {
    if (this.localSubPage !== 'default' && CommonUtils.hasText(this.localSubPage)) {
      const subpageRef: any = this.$refs[this.localSubPage];
      if (subpageRef && subpageRef.typeChange) {
        subpageRef.typeChange();
      }
      this.localIsModal = this.mobileMode || this.payloadIsModal;
      this.localShowRightColumn = !this.localIsModal;
    } else if (!this.mobileMode && this.hasDefaultSubpage.includes(this.localPage)) {
      this.openDefaultSubPage();
    } else if (this.localSubPage === 'default') {
      this.closeSubPage();
    }
  }

  get dividerAdjustments() {
    let i = 0;
    if (['plans', 'assign-students', 'messages'].includes(this.localPage) && this.$currentUser.isAdmin) {
      i++;
    }
    if (this.localShowRightColumn && !this.mobileMode) {
      i++;
    }
    return i;
  }

  get wrapperLeftPaddingWidth() {
    if (this.$vuetify.breakpoint.smAndDown || this.isLeftDrawerMenuTypeHidden || this.isGenericView) {
      return 0;
    }
    return 81;
  }

  get isGenericView() {
    return CommonUtils.hasText(this.$route?.query.t) && CommonUtils.hasText(this.$route?.query.k);
  }

  get subPageContentWidth() {
    if (this.localShowRightColumn && !this.mobileMode) {
      return this.localRightColumnWidth;
    } else {
      return 0;
    }
  }

  get customFontsCSSUrl() {
    return this.$store.getters['settings/getCustomFontsCSSUrl'];
  }

  get localPage() {
    return CommonUtils.getPage(this.$route);
  }

  get globalStyles() {
    return `
      <style>
        .v-application, body {
          font-family: ${this.globalFontFamily}, Raleway, Roboto, Arial, Helvetica, sans-serif;
          font-size: ${this.globalFontSize}pt;
        }

        .v-application .title {
          font-family: ${this.globalFontFamily}, Raleway, Roboto, Arial, Helvetica, sans-serif;
        }
      </style>
    `;
  }

  get planbookStorage() {
    return PlanbookStorage;
  }

  get leftDrawerMenuType():number {
    return this.getDisplaySettings.leftDrawerMenuType;
  }

  get isLeftDrawerMenuTypeHidden(): boolean {
    return this.leftDrawerMenuType === 2;
  }

  @Watch('localTeacherListDockPosition')
  onDockPositionChanged() {
    PlanbookStorage.set('teacherListDockPosition', this.localTeacherListDockPosition);
  }

  get withoutLeaveConfirmation() {
    return ['reminder', 'resources', 'csv', 'stickers', 'default']
  }

  get hasDefaultSubpage() {
    return ['classwork', 'events', 'lesson-banks', 'messages', 'notes-todo', 'school-years', 'students', 'teachers', 'templates', 'units', 'lesson-layouts', 'school-list', 'my-list', 'strategies'];
  }

  get localRightColumnWidth() {
    if (this.hasDefaultSubpage.includes(this.localPage)) {
      if (this.localSubpageFullscreen) {
        return this.fullscreenWidth;
      } else {
        if (this.localSubPage === 'default') {
          return this.defaultPreferenceWidth;
        } else {
          return this.subpagePreferenceWidth;
        }
      }
    } else {
      return this.rightColumnWidth
    }
  }

  set localRightColumnWidth(val: any) {
    if (this.hasDefaultSubpage.includes(this.localPage)) {
      if (this.localSubpageFullscreen) {
        this.fullscreenWidth = val;
      }
      this.setPreferenceWidth(this.localSubPage, val);
    } else {
      this.rightColumnWidth = val;
    }
  }

  setPreferenceWidth(page: any, width: any) {
    if (page === 'default') {
      this.defaultPreferenceWidth = width;
    } else {
      this.subpagePreferenceWidth = width;
    }
  }

  resetWidthPreferences() {
    this.subpagePreferenceWidth = 0;
    this.defaultPreferenceWidth = 0;
  }

  get minWidth() {
    if (this.hasDefaultSubpage.includes(this.localPage)) {
      return Math.min(Math.max(500, this.browserWidth * 0.2), this.browserWidth * 0.7);
    } else {
      return Math.min(Math.max(300, this.browserWidth * 0.2), this.browserWidth * 0.7);
    }
  }

  get maxWidth() {
    return this.browserWidth * 0.7;
  }

  get isLoading() {
    return CommonUtils.isLoading();
  }

  openDefaultSubPage() {
    if (this.$currentUser.defaultEditorMode !== 'modal') {
      this.localSubpageFullscreen = false;
      this.localSubPage = 'default';
      this.localRightColumnWidth = (this.localRightColumnWidth > 0) ? this.localRightColumnWidth : (this.browserWidth * 0.25);
      this.localSubpageProportion = this.localRightColumnWidth / this.browserWidth;
      this.localInput.default = {};
      this.openSubPage();
      if (this.$refs.subPage) {
        this.createResizeable();
      }
    }
  }

  openSubPage(modal?: boolean) {
    for (const key in this.subPages) {
      if (this.localSubPage === key) {
        this.subPages[key] = true;
        if (this.withoutLeaveConfirmation.includes(this.localSubPage)) {
          this.$store.commit('index/setSubpageReference', null);
        } else {
          const subpageRef: any = this.$refs[this.localSubPage];
          if (!subpageRef) return;
          this.$store.commit('index/setSubpageReference', subpageRef);
        }
        const confirmRef: any = this.$refs.confirm;
        if (confirmRef) {
          this.$store.commit('index/setConfirmFunction', confirmRef.confirm);
        } else {
          this.$store.commit('index/setConfirmFunction', () => { console.log('No confirm reference.') });
        }
      } else {
        this.subPages[key] = false;
        if (key === 'reminder' && this.plansAddNotesTodo && PlanbookStorage.get('reminders')) this.subPages[key] = true;
        if (key === 'lessonViewMode' && this.$currentUser.defaultEditorMode === 'modal' && this.localSubPage === 'lesson' && this.localInput.lesson?.lessonListsView) this.subPages[key] = true;
        if (key === 'lesson' && this.lessonAddClasswork && this.localSubPage === 'classwork') this.subPages[key] = true;
      }
    }
    this.localIsModal = CommonUtils.isTrue(modal);
    this.localShowRightColumn = !this.localIsModal;
    if ((this.plansAddNotesTodo && PlanbookStorage.get('reminders')) || (this.localInput.lesson?.lessonListsView && this.subPages.lessonViewMode)) {
      this.localShowRightColumn = true;
    }
    this.$eventBus.$emit('resize', { subpage: true });
  }

  reopenSubpage(input: any, modal?: any, skipDirtyCheck?:any) {
    if (!this.withoutLeaveConfirmation.includes(this.localSubPage)) {
      const subpageRef: any = this.$refs[this.localSubPage]
      let subPageIsDirty:boolean;
      let subPageIsEditable:boolean;
      try {
        subPageIsDirty = subpageRef.isDirty();
      } catch (e) {
        subPageIsDirty = false;
      }
      try {
        subPageIsEditable = subpageRef.isEditable;
      } catch (e) {
        subPageIsEditable = true;
      }
      if (subpageRef && subPageIsDirty) {
        if (skipDirtyCheck) return;
        if (subPageIsEditable) {
          const confirm: any = this.$refs.confirm;
          return confirm.confirm({
            title: this.$t('unsavedChangesLabel'),
            text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
            option1ButtonAlternativeText: this.$t('saveChangesLabel'),
            option2ButtonAlternativeText: this.$t('discardChangesLabel'),
            cancelButtonText: this.$t('cancelLabel')
          }).then((result: any) => {
            if (result === 1 && subpageRef.doApply) {
              subpageRef.doApply().then((result: boolean) => {
                if (!result) {
                  this.localInput[this.localSubPage] = input || CommonUtils.generateUUID();
                  this.openSubPage(modal)
                }
              })
            } else if (result === 2) {
              this.localInput[this.localSubPage] = input || CommonUtils.generateUUID();
              this.openSubPage(modal)
            }
          });
        } else {
          this.localInput[this.localSubPage] = input || CommonUtils.generateUUID();
          this.openSubPage(modal)
        }
      }
    }
    this.localInput[this.localSubPage] = input || CommonUtils.generateUUID();
    this.openSubPage(modal);
  }

  closeSubPage(allowReopen?: boolean) {
    const localSubPageCopy = this.localSubPage.slice(0);
    this.subPages[this.localSubPage] = false;
    this.localSubPage = '';
    this.localIsModal = false;
    this.localShowRightColumn = false;
    if (this.plansAddNotesTodo && PlanbookStorage.get('reminders')) {
      this.subPages.reminder = true;
      this.localShowRightColumn = true;
      this.plansAddNotesTodo = false;
    } else if ((this.localInput.lesson?.lessonListsView && this.subPages.lessonViewMode) && this.$currentUser.defaultEditorMode === 'modal') {
      this.subPages.lessonViewMode = true;
      this.localShowRightColumn = true;
      this.localSubPage = 'lessonViewMode';
      if (allowReopen === false) {
        this.localIsModal = true;
      }
    } else if (this.lessonAddClasswork && localSubPageCopy === 'classwork' && this.subPages.lesson) {
      this.localSubPage = 'lesson';
      this.localIsModal = true;
      this.lessonAddClasswork = false;
    }

    if (allowReopen !== false && !this.mobileMode && this.hasDefaultSubpage.includes(this.localPage)) {
      this.openDefaultSubPage();
    }
    if (allowReopen === false) {
      this.resetWidthPreferences();
    }
  }

  closeSubPageLessonBank(payload?: any) {
    if (!this.withoutLeaveConfirmation.includes(this.localSubPage)) {
      const subpageRef: any = this.$refs[this.localSubPage]
      let subPageIsDirty:boolean;
      try {
        subPageIsDirty = subpageRef.isDirty();
      } catch (e) {
        subPageIsDirty = false;
      }
      if (subpageRef && subPageIsDirty && !payload.autoSave) {
        const confirm: any = this.$refs.confirm;
        return confirm.confirm({
          title: this.$t('unsavedChangesLabel'),
          text: this.$t('confirmAbandonUnsavedChangesGenericMsg'),
          option1ButtonAlternativeText: this.$t('saveChangesLabel'),
          option2ButtonAlternativeText: this.$t('discardChangesLabel'),
          cancelButtonText: this.$t('cancelLabel')
        }).then((result: any) => {
          if (result === 1 && subpageRef.doApply) {
            subpageRef.doApply().then((result: boolean) => {
              if (!result) {
                this.$eventBus.$emit('openSubPageLessonBank', payload);
              }
            })
          } else if (result === 2) {
            this.$eventBus.$emit('openSubPageLessonBank', payload);
          }
        });
      } else {
        this.closeSubPage(false);
        this.$eventBus.$emit('openSubPageLessonBank', payload);
      }
    } else {
      this.closeSubPage(false);
      this.$eventBus.$emit('openSubPageLessonBank', payload);
    }
  }

  resetResizeable() {
    if (this.resizeable) {
      this.localRightColumnWidth = this.browserWidth * this.localSubpageProportion;
      if (this.localRightColumnWidth < this.minWidth) {
        this.localRightColumnWidth = this.minWidth;
      }
      this.resizeable.resetBoundaries({ minWidth: this.minWidth, maxWidth: this.maxWidth });
    }
  }

  createResizeable() {
    try {
      if (this.resizeable && this.localSubPage !== 'default') {
        this.resizeable.destroy();
        this.resizeable.setup();
      } else {
        this.localRightColumnWidth = Math.max(Math.min(this.localRightColumnWidth, this.maxWidth), this.minWidth)
        if (this.minWidth >= this.maxWidth) {
          return;
        }
        if (!this.$refs.subPage) return;
        this.resizeable = new BaseSubPageResizeable(
          this.$refs.subPage,
          {
            handles: 'w',
            minWidth: this.minWidth,
            maxWidth: this.maxWidth
          },
          {
            'resize:start': () => {
              document.body.classList.add('prevent-select');
            },
            'resize:change': (e: any) => {
              this.localRightColumnWidth = e.size.width;
              this.localSubpageProportion = e.size.width / this.browserWidth;
              e.cancel();
            },
            'resize:stop': () => {
              document.body.classList.remove('prevent-select');
              this.$eventBus.$emit('resize', { subpage: true });
            }
          }
        )
      }
    } catch (e) {
      console.log(e);
    }
  }

  beforeDestroy() {
    this.$eventBus.$off('resize', this.onResize);
    this.observer?.disconnect();
    this.observer = undefined;
  }

  beforeMount() {
    if (this.initialSignIn && !CommonUtils.hasValue(getLocaleFromURL()) && CommonUtils.hasValue(this.globalLanguage) && this.globalLanguage !== 'en') {
      this.initialSignIn = false;
      location.reload();
    }
  }

  mounted() {
    this.isMounted = true;
    const confirm: any = this.$refs.confirm;
    this.$store.commit('setConfirm', confirm.confirm);
    this.initialSignIn = false;
    this.$eventBus.$on('error', (error: Error) => {
      let message = this.$t('contactSupport');
      if (error instanceof AppError) {
        message = error?.message;
      }
      if (error) {
        console.log('Error: ' + error.message);
        console.log(error.stack);
      }
      CommonUtils.hideLoading();
      return confirm.confirm({
        title: this.$t('messageLabel'),
        text: message || this.$t('contactSupport'),
        cancelButtonText: this.$t('returnLabel'),
        option1ButtonAlternativeText: this.$t('refreshPageLabel')
      }).then((result: any) => {
        if (result === 1) {
          window.location.reload(true);
        }
        return Promise.resolve();
      });
    });

    this.$eventBus.$on('viewKeyError', () => {
      return confirm.alert({
        title: this.$t('messageLabel'),
        text: this.$t('viewKeyCannotEqualPassword'),
        option1ButtonAlternativeText: this.$t('okLabel')
      })
    });

    this.$eventBus.$on('chunkLoadError', () => {
      confirm.alert({
        title: this.$t('chunkLoadErrorLabel'),
        text: this.$t('chunkLoadErrorMsg'),
        option1ButtonAlternativeText: this.$t('reloadLabel')
      }).then(() => {
        window.location.reload(true);
      });
    })

    this.$eventBus.$on('subscriptionExpired', () => {
      if (this.userMode === 'T') {
        return confirm.confirm({
          title: this.$t('subscriptionExpiredLabel'),
          text: this.$t('subscriptionExpiredMsg'),
          option1ButtonAlternativeText: this.$t('renewSubscriptionLabel')
        }).then((result: any) => {
          if (result === 1) {
            const subscriptionRenewal: any = this.$refs.subscriptionRenewal;
            return subscriptionRenewal.renew();
          } else {
            return Promise.resolve();
          }
        });
      } else if (this.userMode === 'A') {
        return confirm.confirm({
          title: this.$t('schoolSubscriptionExpiredLabel'),
          text: this.$t('schoolSubscriptionExpiredMsg'),
          option1ButtonAlternativeText: this.$t('renewSubscriptionLabel')
        }).then((result: any) => {
          if (result === 1) {
            const schoolSubscriptionRenewal: any = this.$refs.schoolSubscriptionRenewal;
            return schoolSubscriptionRenewal.renew();
          } else {
            return Promise.resolve();
          }
        });
      }
    });

    this.$eventBus.$on('subscriptionExpiring', (days: number) => {
      if (this.userMode === 'T') {
        return confirm.confirm({
          title: this.$t('subscriptionExpiringLabel'),
          text: this.$t('teacherSubscriptionExpiringMsg', { days }),
          option1ButtonAlternativeText: this.$t('renewSubscriptionLabel')
        }).then((result: any) => {
          if (result === 1) {
            const subscriptionRenewal: any = this.$refs.subscriptionRenewal;
            return subscriptionRenewal.renew();
          } else {
            return Promise.resolve();
          }
        });
      } else if (this.userMode === 'A') {
        return confirm.confirm({
          title: this.$t('schoolSubscriptionExpiringLabel'),
          text: this.$t('schoolSubscriptionExpiringdMsg', { days }),
          option1ButtonAlternativeText: this.$t('renewOrRequestQuoteLabel')
        }).then((result: any) => {
          if (result === 1) {
            const schoolSubscriptionRenewal: any = this.$refs.schoolSubscriptionRenewal;
            return schoolSubscriptionRenewal.renew();
          } else {
            return Promise.resolve();
          }
        });
      }
    });

    this.$eventBus.$on('notificationReceived', (data: any) => {
      return confirm.alert({
        title: data.type,
        text: data.body,
        option1ButtonAlternativeText: this.$t('okLabel')
      })
    })
  }

  created() {
    if (CommonUtils.isTrue(this.$currentUser.settings?.schoolSettings?.schoolPastDue) && +this.$currentUser.schoolId > 0) {
      this.$store.commit('settings/addAdditionalErrorMsg', this.$t('schoolPastDueMsg'));
    }
    this.firstLoad = true;
    this.localTeacherListDockPosition = PlanbookStorage.get('teacherListDockPosition') || 'left';
    this.updateBreakpoint();
    if (!this.isGenericView) {
      this.getAllRosteredItems();
    }
    Promise.resolve().then(() => {
      return this.loadStickers();
    }).finally(() => {
      // TINYMCE CUSTOM CODE - START
      this.observer = new MutationObserver((mutations: MutationRecord[]) => {
        try {
          mutations.forEach(m => {
            m.addedNodes.forEach(n => {
              if (n instanceof HTMLElement) {
                const imageList = n.querySelectorAll('div[title^="sticker-"].tox-collection__item, div[title^="teacher-"].tox-collection__item, div[title^="student-"].tox-collection__item');
                if (imageList.length > 0) {
                  imageList.forEach(item => {
                    if (!item.querySelector('img')) {
                      const key = item.getAttribute('title') as string;
                      const image = document.createElement('img') as HTMLImageElement;
                      const label = this.getStickerLabel(key);
                      const labelContainer = item.querySelector('.tox-collection__item-label');
                      image.src = `https://cdn.planbook.com/images/${key?.startsWith('sticker-') ? 'planbook-stickers' : key?.startsWith('teacher') ? 'teacheravatars' : 'studentavatars'}/${key}.png`;
                      image.style.width = 'auto';
                      image.style.height = '16px';
                      image.style.paddingRight = '5px';
                      if (CommonUtils.hasText(label)) {
                        if (labelContainer) labelContainer.innerHTML = label;
                      }
                      item.prepend(image);
                    }
                  });
                }
              }
            })
          })
        } catch (e) {
        }
      });
      this.observer.observe(document, { childList: true, subtree: true, attributes: true, attributeOldValue: true, characterData: true, characterDataOldValue: true });
      // TINYMCE CUSTOM CODE - END
    });
    this.$eventBus.$on('openDefaultSubPage', () => {
      this.localSubpageFullscreen = false;
      this.openDefaultSubPage();
    })

    this.$eventBus.$on('openSubPage', (payload: any) => {
      this.localSubpageFullscreen = false;
      payload && payload.plansAddNotesTodo ? this.plansAddNotesTodo = true : this.plansAddNotesTodo = false;
      payload && payload.lessonAddClasswork ? this.lessonAddClasswork = true : this.lessonAddClasswork = false;
      if (this.hasDefaultSubpage.includes(this.localPage)) {
        this.setPreferenceWidth(payload.type, (this.subpagePreferenceWidth > 0) ? this.subpagePreferenceWidth : (this.browserWidth * 0.5));
      } else {
        this.localRightColumnWidth = payload.width;
        if (this.plansAddNotesTodo && PlanbookStorage.get('reminders')) this.localRightColumnWidth = '350';
        if ((payload.input?.lesson?.lessonListsView && this.subPages.lessonViewMode)) {
          this.localRightColumnWidth = '700';
        }
      }
      this.localSubpageProportion = this.localRightColumnWidth / this.browserWidth;
      if (this.localSubPage === payload.type && (!payload.modal && !this.mobileMode) && !payload.input?.skipDirtyCheck) {
        this.reopenSubpage(payload.input, payload.modal, (payload.input?.skipDirtyCheck || false));
      } else {
        this.localInput[payload.type] = payload.input;
        this.localSubPage = payload.type;
        this.payloadIsModal = payload.modal || false;
        this.localIsModal = payload.modal || this.mobileMode;
        this.openSubPage(this.localIsModal);
      }
      if (!payload.modal) {
        this.createResizeable()
      }
      if (payload.type !== 'reminder' && this.localPage === 'plans') {
        if (!this.plansAddNotesTodo) {
          PlanbookStorage.set('reminders', false);
          this.$store.commit('plans/setRemindersFloatingIcon', 'fal fa-angle-left');
        }
      }
    });

    this.$eventBus.$on('resizeSubPage', (payload: any) => {
      this.localSubpageFullscreen = payload.fullScreen
      this.localRightColumnWidth = payload.width;
      if (!this.localSubpageFullscreen) {
        this.resetResizeable();
      }
    });

    this.$eventBus.$on('resize', this.onResize)

    this.$eventBus.$on('closeSubPage', (payload?: any) => {
      this.closeSubPage(payload);
      this.resizeable?.destroy();
      this.resizeable = null;
    });

    this.$eventBus.$on('closeSubPageLessonBank', (payload?: any) => {
      this.closeSubPageLessonBank(payload);
      this.resizeable?.destroy();
      this.resizeable = null;
    });

    this.$eventBus.$on('actions', (payload: any) => {
      const type = payload.type + 'Actions';
      const view = (this.$refs as any)[type] as ActionsModal;
      this.localInput[type] = payload.input;
      view.show(payload.event);
    });

    this.$eventBus.$on('teacherListWidthChanged', (isFull: boolean) => {
      this.localIsTeacherListFullWidth = isFull;
    });

    this.$eventBus.$on('settingsLoaded', () => {
      this.$vuetify.theme.dark = CommonUtils.get(this.darkMode, false);
    });

    if (this.highContrastMode) {
      this.$vuetify.theme.themes.light = contrastModeColors;
    } else if (this.autoColorScheme) {
      this.autoChangeColorScheme();
    } else {
      this.$vuetify.theme.dark = CommonUtils.isTrue(this.darkMode);
    }
  }

  onResize(payload?: any) {
    if (!this.localSubpageFullscreen && (!payload || !payload.subpage)) {
      this.resetResizeable();
      this.updateBreakpoint();
    }
  }

  autoChangeColorScheme() {
    const isDaylight = DateTimeUtils.isDaylight();
    this.$vuetify.theme.dark = !isDaylight;
    const current = moment();
    const midnight = moment('12:00 AM', 'HH:mm A');
    const tss = DateTimeUtils.getTzSunriseAndSunset();
    const mSunrise = moment(tss.sunrise, 'HH:mm A');
    const mSunset = moment(tss.sunset, 'HH:mm A');
    if (isDaylight) {
      mSunset.add(5, 'minutes');
      setTimeout(() => {
        this.autoChangeColorScheme();
      }, mSunset.diff(current));
    } else {
      if (current.isSameOrAfter(midnight)) {
        setTimeout(() => {
          this.autoChangeColorScheme();
        }, mSunrise.diff(current));
      } else {
        mSunrise.add(24, 'hours');
        setTimeout(() => {
          this.autoChangeColorScheme();
        }, mSunrise.diff(current));
      }
    }
  }

  destroyed() {
    this.$store.commit('settings/setAdditionalInfoMsgs', []);
    this.$eventBus.$off('error');
    this.$eventBus.$off('subscriptionExpired');
    this.$eventBus.$off('openSubPage');
    this.$eventBus.$off('closeSubPage');
    this.$eventBus.$off('actions');
    this.$eventBus.$off('notificationReceived');
    if (this.userMode === 'A') {
      this.$eventBus.$off('teacherListWidthChanged');
    }
  }

  get mainContentHeight() {
    return 'calc(100vh - 64px)';
  }

  getAppBarBottomOffset() {
    const element = document.querySelector('.app-bar .v-toolbar__content') as HTMLElement;
    const rect = element.getBoundingClientRect();
    return rect.top + element.offsetHeight;
  }
}
