




















































































































import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import Confirm from '@/components/core/Confirm.vue';
import ld from 'lodash';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FormError } from '@/errors';
import SchoolYearMixin from '@/mixins/school-year-mixin';
import SchoolYearsMixin from '@/mixins/school-years-mixin';
import SchoolYearServices from '@/services/school-year-services';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import { tableWidths } from '@/constants/index';
import RosteredItemIndicator from '@/components/integration/RosteredItemIndicator.vue';

const settings = namespace('settings');
const drawer = namespace('drawer');
const integration = namespace('integration');

@Component({
  mixins: [PageLifeCycleMixin, SchoolYearMixin, SchoolYearsMixin, TableResizeMixin],
  components: {
    RosteredItemIndicator
  }
})
export default class SchoolYears extends Vue {
  schoolYearType = 'school';
  localSchoolYears: Array<any> = [];
  origSchoolYears: Array<any> = [];
  schoolId!: number;
  districtId!: number;
  editMode!: boolean;
  selectedRows!: Array<any>;
  searchText!: string;
  hasSelectedRows!: boolean;
  resetPlans!: () => void;
  setUpdatedSettings!: (params?: any) => void;
  loadAdminSchoolYears!: (params?: any) => Promise<any>;
  getAdminSchoolYear!: (yearId: number) => Promise<any>;
  loadSchoolYearClasses!: (schoolYear?: number) => Promise<any>;
  loadHolidays!: () => Promise<any>;
  editSchoolYear!: (modal: boolean, item: any) => void;
  deleteSchoolYears!: (params: any) => Promise<any>;
  getYearNames!: (item: any) => string;
  changePlans!: (param?: any) => Promise<any>;
  tableHeight!: any;
  selectedFilters: Array<string> = this.fiteredItemValues;
  localCurrentSchoolId = 0;

  @settings.State
  adminSchoolYears!: Array<any>;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getUserType')
  userType!: string;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @drawer.Mutation('setSelected')
  setSelectedPage!: (value: string) => void;

  @settings.Action
  loadSettings!: (params?: any) => Promise<any>;

  @settings.Getter('getSchoolYears')
  teacherSchoolYears!: Array<any>;

  @settings.Getter('getSchoolYearListLoading')
  schoolYearListLoading!: boolean;

  @settings.Getter('getSchools')
  schools!: any;

  @integration.Getter
  isSchoolRostered!: (param: any) => boolean;

  @integration.Getter
  isSchoolYearRostered!: (param: any) => boolean;

  @integration.Getter
  haveRosteredItems!: boolean;

  $refs!: {
    confirm: Confirm;
  };

  get filterItems() {
    const items = [];
    if (this.$currentUser.isTeacher) {
      items.push({ text: this.$t('teacherLabel'), value: 'T' });
    }
    if (this.$currentUser.schoolId > 0) {
      items.push({ text: this.$t('schoolLabel'), value: 'S' });
    }
    if (this.$currentUser.districtId > 0) {
      items.push({ text: this.$t('districtLabel'), value: 'D' });
    }
    return items;
  }

  get fiteredItemValues() {
    return this.filterItems.map(fi => fi.value);
  }

  get showFilter() {
    return this.filterItems.length > 1;
  }

  get isFiltered() {
    return this.selectedFilters.length < this.filterItems.length || CommonUtils.hasText(this.searchText);
  }

  get filteredCount() {
    return (this.filterItems.length - this.selectedFilters.length) + (CommonUtils.hasText(this.searchText) ? 1 : 0);
  }

  resetFilter() {
    this.searchText = '';
    this.selectedFilters = this.fiteredItemValues;
  }

  headers: Array<any> = [
    { text: this.$t('currentLabel'), value: 'yearId', align: 'center', sortable: false, width: this.tableWidths.icon },
    { text: this.$t('firstDayLabel'), value: 'firstDaySort', width: this.tableWidths.fullDate },
    { text: this.$t('lastDayLabel'), value: 'lastDaySort', width: this.tableWidths.fullDate },
    { text: this.$t('typeLabel'), value: 'typeLabel', width: this.tableWidths.shortText },
    { text: this.$t('yearNameLabel'), value: 'yearName' },
    { text: '', value: 'roster-indicator', width: this.tableWidths.shortText, align: 'start', sortable: false },
    { width: this.tableWidths.action2, sortable: false, value: 'action' }
  ]

  get tableHeaders() {
    return this.headers.filter(y => {
      if (y.value === 'roster-indicator' && !this.haveRosteredItems) {
        return false;
      }
      return this.$currentUser.isTeacher || (this.$currentUser.isAdmin && y.value !== 'yearId');
    });
  }

  get currentSchoolId() {
    return this.schoolYearCurrentSchoolId || this.$store.getters['settings/getSchoolId'];
  }

  set currentSchoolId(val: any) {
    this.$store.commit('settings/setSchoolYearCurrentSchoolId', val);
  }

  get tableWidths() {
    return tableWidths;
  }

  get schoolItems() {
    const that = this;
    return this.schools.map((s: any) => {
      return { text: s.schoolName, value: +s.schoolId, isItemRostered: that.isSchoolRostered(s) };
    });
  }

  get filteredSchoolYears(): Array<any> {
    return this.localSchoolYears.filter(sy => {
      return this.selectedFilters.includes(sy.type) || !this.showFilter;
    });
  }

  get currentYearId(): number {
    if (this.$currentUser.isTeacher) {
      return +this.$store.getters['settings/getCurrentSchoolYearId'];
    } else {
      return +this.$store.state.settings.currentYearId;
    }
  }

  get schoolYearsExists(): boolean {
    return CommonUtils.isNotEmpty(this.filteredSchoolYears);
  }

  get listLoading() {
    return this.schoolYearListLoading;
  }

  set listLoading(value: boolean) {
    this.$store.commit('settings/setSchoolYearListLoading', value);
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get schoolYearCurrentSchoolId() {
    const schoolId = this.$store.state.settings.schoolYearCurrentSchoolId;
    if (CommonUtils.isNotEmpty(this.schoolItems)) {
      if (this.schoolItems.find((i: any) => i.value === schoolId)) {
        return schoolId;
      }
      return this.schoolItems[0].value;
    }
    return -1;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  get schoolYears() {
    return (this.$currentUser.isAdmin ? this.adminSchoolYears : this.teacherSchoolYears) || [];
  }

  isCurrentSchoolYear(item: any) {
    return item.yearId === this.currentYearId;
  }

  edit(item: any) {
    if (CommonUtils.hasValue(item) ? item.editable : true) {
      this.editSchoolYear(this.$currentUser.defaultEditorMode === 'modal', item);
    }
  }

  toggleYearNameEditable(item: any) {
    const years = ld.cloneDeep(this.localSchoolYears);
    years.forEach(e => {
      e.editYearName = false;
    });
    if (item.editable) {
      const year = years.find(e => e.yearId === item.yearId);
      year.editYearName = !year.editYearName;
    }
    this.localSchoolYears = years;
  }

  updateYearName(item: any, value: string) {
    const years = ld.cloneDeep(this.localSchoolYears);
    const year = years.find(e => e.yearId === item.yearId);
    year.yearName = value;
    this.localSchoolYears = years;
  }

  saveYearName(item: any) {
    this.listLoading = true;
    const sys = ld.cloneDeep(this.localSchoolYears);
    const sy = sys.find(y => y.yearId === item.yearId);
    const osy = this.origSchoolYears.find(y => y.yearId === item.yearId);
    if (item.yearName !== osy.yearName) {
      sy.unitTitle = item.unitTitle;
      sys.forEach(y => {
        y.editYearName = false;
      });
      SchoolYearServices.updateSchoolYearName(sy).then(() => {
        this.localSchoolYears = sys;
      }).catch(() => {
        this.localSchoolYears = this.origSchoolYears;
      }).finally(() => {
        this.$nextTick(() => {
          this.listLoading = false;
        });
      });
    } else {
      this.$nextTick(() => {
        this.listLoading = false;
      })
    }
  }

  @Watch('currentSchoolId')
  onCurrentSchoolIdChange() {
    if (this.$currentUser.isDistrictAdmin) {
      this.listLoading = true;
      this.loadAdminSchoolYears({ schoolId: this.currentSchoolId }).finally(() => {
        this.listLoading = false;
      });
    }
  }

  async initialize(load?: boolean) {
    CommonUtils.showLoading();
    this.listLoading = true;
    return Promise.resolve().then(async () => {
      await this.loadAdminSchoolYears({ schoolId: this.currentSchoolId });
      if (!this.$currentUser.isAdmin) {
        if (load) {
          return await this.loadSettings();
        } else {
          return new Promise(resolve => setTimeout(resolve, 300));
        }
      }
    }).finally(() => {
      this.listLoading = false;
      CommonUtils.hideLoading();
    });
  }

  deleteSelectedSchoolYears(): void {
    if (this.hasSelectedRows) {
      if (this.selectedRows.length === this.filteredSchoolYears.length) {
        this.preventDeleteAll();
      } else {
        this.deleteMultipleSchoolYears(this.selectedRows);
      }
    }
  }

  preventDeleteAll() {
    this.$refs.confirm.alert({
      title: this.$t('schoolYearLabel'),
      text: this.$t('deleteAllSchoolYearMsg'),
      option1ButtonAlternativeText: this.$t('returnLabel')
    })
  }

  deleteSchoolYear(year: any) {
    if (this.filteredSchoolYears.length === 1) {
      this.preventDeleteAll();
    } else {
      this.deleteMultipleSchoolYears([year]);
    }
  }

  deleteMultipleSchoolYears(years: Array<any>): void {
    this.listLoading = true;
    this.$refs.confirm.confirm({
      title: this.$t('schoolYearLabel'),
      text: this.$t('schoolYearMsg7', { yearName: this.getYearNames(years) }),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then(async result => {
      if (result === 1) {
        return this.deleteSchoolYears({ years }).then(() => {
          this.$eventBus.$emit('deleteYear', years);
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('schoolYearDeletedLabel') as string);
          }
          return Promise.resolve();
        });
      } else {
        return Promise.reject(new FormError());
      }
    }).then(() => {
      return this.initialize(true);
    }).then(() => {
      this.resetPlans();
      return Promise.resolve();
    }).then(() => {
      if (this.$currentUser.isTeacher) {
        const currentYear = this.schoolYears.find(sy => sy.yearId === this.currentYearId);
        if (!currentYear && CommonUtils.isNotEmpty(this.schoolYears)) {
          return this.changePlans({ yearId: this.schoolYears[0].yearId })
        }
      }
      return Promise.resolve();
    }).catch((error) => {
      if (!(error instanceof FormError)) {
        Promise.reject(error);
      }
    }).finally(() => {
      this.listLoading = false;
    });
  }

  @Watch('schoolYears')
  onSchoolYearsChange() {
    this.initLocalValues();
  }

  initLocalValues() {
    const that = this;
    this.localSchoolYears = this.schoolYears.map((y: any) => {
      const type = this.getType(y)
      y.firstDaySort = DateTimeUtils.formatToSort(y.firstDay);
      y.firstDayDisplay = DateTimeUtils.formatToDisplay(y.firstDay, true);
      y.lastDaySort = DateTimeUtils.formatToSort(y.lastDay);
      y.lastDayDisplay = DateTimeUtils.formatToDisplay(y.lastDay, true);
      y.editable = true;
      y.selectable = this.$currentUser.isTeacher || y.numUsers <= 0;
      y.type = type.value;
      y.typeLabel = type.text;
      y.isItemRostered = that.isSchoolYearRostered(y);
      return y;
    });
    this.origSchoolYears = ld.cloneDeep(this.localSchoolYears);
  }

  @Watch('listLoading')
  disableEditable() {
    if (!this.listLoading) {
      const years = ld.cloneDeep(this.localSchoolYears);
      years.forEach(e => {
        e.editYearName = false;
      });
      this.localSchoolYears = years;
    }
  }

  getType(year: any) {
    if (this.$currentUser.isAdmin) {
      return year.districtId > 0 ? { text: this.$t('districtLabel'), value: 'D' } : year.schoolId > 0 ? { text: this.$t('schoolLabel'), value: 'S' } : { text: this.$t('teacherLabel'), value: 'T' };
    } else {
      for (var m = 0; m < this.adminSchoolYears.length; m++) {
        if (year.parentYearId === this.adminSchoolYears[m].yearId) {
          if (this.adminSchoolYears[m].districtId > 0) {
            return { text: this.$t('districtLabel'), value: 'D' };
          } else {
            return { text: this.$t('schoolLabel'), value: 'S' };
          }
        }
      }
      return year.parentYearDistrictId > 0 ? { text: this.$t('districtLabel'), value: 'D' } : year.parentYearSchoolId > 0 ? { text: this.$t('schoolLabel'), value: 'S' } : { text: this.$t('teacherLabel'), value: 'T' };
    }
  }

  doInit() {
    this.initialize().then(() => {
      this.initLocalValues();
    });
  }

  created() {
    this.doInit();
    this.$nextTick(() => {
      this.$eventBus.$on('userModeChanged', this.doInit);
      this.$eventBus.$on('newSchoolYearCreated', (data: any) => {
        this.edit(data);
      });
      this.$eventBus.$emit('openDefaultSubPage');
    })
  }

  destroyed() {
    this.$eventBus.$off('userModeChanged', this.doInit);
    this.$eventBus.$off('newSchoolYearCreated');
  }
}
