import AppError from '@/errors';
import UserServices from '@/services/user-services';
import CommonUtils from '@/utils/common-utils';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({ namespaced: true })
export default class Welcome extends VuexModule {
    joinCode = '';
    joinData: any = null;
    promoCode = '';
    redirected = false;
    studentJoinCode = '';
    studentJoinData: any = null;
    resetCode = '';
    redirect = '';

    @Mutation
    setJoinCode(joinCode: string) {
      this.joinCode = joinCode;
    }

    @Mutation
    setStudentJoinCode(studentJoinCode: string) {
      this.studentJoinCode = studentJoinCode;
    }

    @Mutation
    setJoinData(joinData: any) {
      this.joinData = joinData;
    }

    @Mutation
    setStudentJoinData(studentJoinData: any) {
      this.studentJoinData = studentJoinData;
    }

    @Mutation
    setRedirected(redirected: boolean) {
      this.redirected = redirected;
    }

    @Mutation
    setResetCode(resetCode: string) {
      this.resetCode = resetCode;
    }

    @Mutation
    setPromoCode(promoCode: string) {
      this.promoCode = promoCode;
    }

    @Mutation
    setRedirect(redirect: string) {
      this.redirect = redirect;
    }

    @Mutation
    reset() {
      this.resetCode = '';
      this.joinCode = '';
      this.joinData = null;
      this.redirected = false;
      this.studentJoinCode = '';
      this.studentJoinData = null;
    }

    @Action
    async loadJoinData() {
      this.context.commit('setJoinData', null);
      this.context.commit('setStudentJoinData', null);
      if (CommonUtils.hasText(this.joinCode)) {
        const resp = await UserServices.verifyInviteToken({ token: this.joinCode });
        if (resp) {
          const data = resp.data;
          if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setJoinData', data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else if (CommonUtils.hasText(this.studentJoinCode)) {
        const resp = await UserServices.verifyStudentJoinCode({ joinCodes: this.studentJoinCode });
        if (resp) {
          const data = resp.data;
          if (data.error === 'true' || data.error === true) {
            return Promise.reject(new AppError('contactSupport', { data }));
          } else {
            this.context.commit('setStudentJoinData', data);
            return Promise.resolve({ data });
          }
        } else {
          return Promise.resolve({ data: {} });
        }
      } else {
        return Promise.resolve({ data: {} });
      }
    }
}
