














































































































































































import { accountTypes, emailFormat, tableWidths } from '@/constants';
import SchoolServices from '@/services/school-services';
import CommonUtils from '@/utils/common-utils';
import DateTimeUtils from '@/utils/date-time-utils';
import ld from 'lodash';
import moment from 'moment-timezone';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '../../components/core/Confirm.vue';
import TeacherTagsInput from '../../components/teachers/TeacherTagsInput.vue';
import TeachersMixin from '@/mixins/teachers-mixin';
import TableResizeMixin from '@/mixins/table-resize-mixin';
import PageLifeCycleMixin from '@/mixins/page-lifecycle-mixin';
import CopySchoolYear from '@/components/teachers/CopySchoolYear.vue';
import RosteredItemIndicator from '@/components/integration/RosteredItemIndicator.vue';
import TeachersUtils from '@/store/modules/teachers';

const settings = namespace('settings');
const teachers = namespace('teachers');
const integration = namespace('integration');

@Component({
  mixins: [PageLifeCycleMixin, TeachersMixin, TableResizeMixin],
  components: {
    TeacherTagsInput,
    CopySchoolYear,
    RosteredItemIndicator
  }
})
export default class Teachers extends Vue {
  @settings.Getter('getSettings')
  userInfo!: any;

  @settings.Getter('getUserType')
  userType!: any;

  @settings.Getter('getUserMode')
  userMode!: string;

  @settings.Getter('getBrowserHeight')
  browserHeight!: number;

  @teachers.Getter('getTeachersForAdmin')
  teachers!: Array<any>;

  @settings.Getter('getSchools')
  schools!: any;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter('getDistrictId')
  districtId!: number;

  @settings.Getter('getPrimarySchool')
  primarySchool!: any;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @settings.Getter
  highContrastMode!: boolean;

  @teachers.Getter('getTotalAccounts')
  totalAccounts!: number;

  @teachers.Action
  loadTeachers!: () => Promise<any>;

  @teachers.Action
  save!: () => Promise<any>;

  @teachers.Action
  delete!: (params?: any) => Promise<any>;

  @teachers.Action
  invite!: (params: any) => Promise<any>;

  @integration.Getter
  isSchoolRostered!: (param: any) => boolean;

  @integration.Getter
  isTeacherRostered!: (param: any) => boolean;

  @integration.Getter
  haveRosteredItems!: boolean;

  localTeachers: Array<any> = [];
  localOrigTeachers: Array<any> = [];
  listLoading = false
  joinCodesUUId = '';
  localSchoolId = 0;
  localJoinURL = '';
  localTeachersToInvite = [];
  localShowInviteTeachersDialog = false;
  localShowJoinCodesDialog = false;

  editMode!: boolean;
  searchText!: string;
  selectedRows!: Array<any>;
  hasSelectedRows!: boolean;
  tableHeight!: any;
  filterSelections:any = ['invited'];
  localSlideGroupModel = null;

  showCopySchoolYear = false;

  $refs!: {
    confirm: Confirm,
    importForm: Vue & { validate: () => boolean, resetValidation: () => void },
    inviteTeachersForm: Vue & { validate: () => boolean, resetValidation: () => void }
  }

  get haveMultipleSchools() {
    return this.schools.length > 1;
  }

  get primarySchoolId() {
    return this.primarySchool.schoolId;
  }

  get currentSchoolId() {
    const teachers = this.$store.state.teachers;
    if (teachers.currentSchoolId === undefined || teachers.currentSchoolId === null) {
      return this.primarySchoolId;
    }
    return teachers.currentSchoolId;
  }

  set currentSchoolId(val:any) {
    this.$store.commit('teachers/setCurrentSchoolId', val);
  }

  get schoolItems() {
    const items = [{ text: this.$t('allSchoolsLabel'), value: 0, isItemRostered: false }];
    this.schools.forEach((s: any) => {
      items.push({ text: s.schoolName, value: +s.schoolId, isItemRostered: this.isSchoolRostered(s) });
    });

    return items;
  }

  get localRules() {
    return [
      (v: any) => !!v || this.$t('requiredLabel')
    ];
  }

  get emailRules() {
    return this.localRules.concat([
      (v: string) => emailFormat.test(v) || this.$t('emailMustBeValidMsg')
    ]);
  }

  get footerProps() {
    return {
      'items-per-page-options': [10, 25, 50, 100, -1]
    }
  }

  get itemDataSelect() {
    return 'item.data-table-select'
  }

  get tableHeaders() {
    return [
      {
        width: tableWidths.photo,
        text: '',
        value: 'photoUrl',
        sortable: false,
        filterable: false,
        groupable: false
      },
      {
        width: tableWidths.shortText,
        text: this.$t('roleLabel'),
        value: 'typeName'
      },
      {
        width: tableWidths.email,
        text: this.$t('emailLabel'),
        align: 'start',
        value: 'emailAddress'
      },
      {
        width: tableWidths.mediumText,
        text: this.$t('firstNameLabel'),
        align: 'start',
        value: 'firstName'
      },
      {
        width: tableWidths.mediumText,
        text: this.$t('lastNameLabel'),
        align: 'start',
        value: 'lastName'
      },
      {
        width: tableWidths.fullDate,
        text: this.$t('lastLoginLabel'),
        align: 'start',
        value: 'formattedLastLogonSort'
      },
      {
        width: tableWidths.shortText,
        text: '',
        align: 'start',
        value: 'roster-indicator',
        sortable: false
      },
      {
        value: 'spacer',
        sortable: false
      },
      {
        width: tableWidths.action2,
        sortable: false,
        value: 'action'
      }
    ].filter((h:any) => {
      if (h.value === 'roster-indicator' && !this.haveRosteredItems) {
        return false;
      }
      return true;
    });
  }

  get haveTeachers() {
    return this.$store.state.teachers.haveTeachers;
  }

  set haveTeachers(value: boolean) {
    this.$store.commit('teachers/setHaveTeachers', value);
  }

  get isCycleSchedule() {
    return !isNaN(+this.userInfo.schoolYearSettings.classCycle);
  }

  get teacher() {
    return this.$store.state.teachers.teacher;
  }

  set teacher(val: any) {
    this.$store.commit('teachers/setTeacher', val);
  }

  get formatToShow() {
    return DateTimeUtils.formatToShow
  }

  get stringifyDate() {
    return DateTimeUtils.stringifyDate;
  }

  get hasText() {
    return CommonUtils.hasText;
  }

  get isTrue() {
    return CommonUtils.isTrue;
  }

  get filteredLocalTeachers() {
    return this.localTeachers.filter(t => {
      return ((t.inviteId > 0 && this.showInviteTeachers && t.schoolId === this.currentSchoolId) || (t.teacherId > 0 && (this.userType === 'D' || t.userType !== 'D') && (t.userType !== 'D' || this.districtId > 0)));
    });
  }

  get mobileBreakpoint() {
    return CommonUtils.getMobileBreakpointSize();
  }

  get isMobileMode() {
    return CommonUtils.isMobileMode();
  }

  get mobileTableClass() {
    return CommonUtils.mobileTableClass();
  }

  get mobileRowClass() {
    return CommonUtils.mobileRowClass();
  }

  get mobileHeaderClass() {
    return CommonUtils.mobileHeaderClass();
  }

  get mobileCellClass() {
    return CommonUtils.mobileCellClass();
  }

  get contentBreakpoint() {
    return CommonUtils.mainContentBreakpoint();
  }

  get showInviteTeachers() {
    return this.filterSelections.includes('invited');
  }

  get chipFontWeight() {
    if (this.highContrastMode) {
      return 550;
    } else {
      return 500;
    }
  }

  getDisplayName(teacher: any) {
    return TeachersUtils.getDisplayName(teacher);
  }

  getInitials(teacher: any) {
    try {
      let initials = '';
      if (CommonUtils.hasText(teacher.firstName)) {
        initials += teacher.firstName[0];
      }

      if (CommonUtils.hasText(teacher.lastName)) {
        initials += teacher.lastName[0];
      }

      if (CommonUtils.hasNoText(initials) && CommonUtils.hasText(teacher.displayName)) {
        initials += teacher.displayName[0];
      }

      if (CommonUtils.hasNoText(initials)) {
        initials += teacher.emailAddress[0];
      }
      return initials.toUpperCase();
    } catch (e) {
      console.log(e);
    }
  }

  toggleFirstNameEditable(item: any) {
    const teachers = ld.cloneDeep(this.localTeachers);
    teachers.forEach(e => {
      e.editFirstName = false;
      e.editLastName = false;
    });
    const teacher = teachers.find(e => e.key === item.key);
    teacher.editFirstName = !teacher.editFirstName;
    this.localTeachers = teachers;
  }

  toggleLastNameEditable(item: any) {
    const teachers = ld.cloneDeep(this.localTeachers);
    teachers.forEach(e => {
      e.editFirstName = false;
      e.editLastName = false;
    });
    const teacher = teachers.find(e => e.key === item.key);
    teacher.editLastName = !teacher.editLastName;
    this.localTeachers = teachers;
  }

  @Watch('listLoading')
  disableEditable(lastName?: boolean, firstName?: boolean) {
    if (!this.listLoading) {
      const teachers = ld.cloneDeep(this.localTeachers);
      teachers.forEach(e => {
        e.editLastName = lastName === true || lastName === undefined ? false : e.editLastName;
        e.editFirstName = firstName === true || firstName === undefined ? false : e.editFirstName;
      });
      this.localTeachers = teachers;
    }
  }

  doShowJoinCodes() {
    this.joinCodesUUId = CommonUtils.generateUUID();
    this.localShowJoinCodesDialog = true;
  }

  doShowInviteTeachers() {
    this.joinCodesUUId = CommonUtils.generateUUID();
    this.localShowInviteTeachersDialog = true;
  }

  updateLastName(item: any, value: string) {
    const teachers = ld.cloneDeep(this.localTeachers);
    const teacher = teachers.find(e => e.key === item.key);
    teacher.lastName = value;
    this.localTeachers = teachers;
  }

  saveLastName(item: any) {
    const teachers = ld.cloneDeep(this.localTeachers);
    const teacher = teachers.find(e => e.key === item.key);
    const origTeacher = this.localOrigTeachers.find(e => e.key === item.key);
    this.listLoading = true;
    if (item.lastName !== origTeacher.lastName) {
      teacher.lastName = item.lastName;
      teachers.forEach(e => {
        e.editLastName = false;
        e.editFirstName = false;
      });
      this.teacher = teacher;
      this.save().finally(() => {
        this.listLoading = false;
      });
    } else {
      this.$nextTick(() => {
        this.listLoading = false;
      })
    }
  }

  updateFirstName(item: any, value: string) {
    const teachers = ld.cloneDeep(this.localTeachers);
    const teacher = teachers.find(e => e.key === item.key);
    teacher.firstName = value;
    this.localTeachers = teachers;
  }

  saveFirstName(item: any) {
    const teachers = ld.cloneDeep(this.localTeachers);
    const teacher = teachers.find(e => e.key === item.key);
    const origTeacher = this.localOrigTeachers.find(e => e.key === item.key);
    this.listLoading = true;
    if (item.firstName !== origTeacher.firstName) {
      teacher.lastName = item.lastName;
      teachers.forEach(e => {
        e.editLastName = false;
        e.editFirstName = false;
      });
      this.teacher = teacher;
      this.save().finally(() => {
        this.listLoading = false;
      });
    } else {
      this.$nextTick(() => {
        this.listLoading = false;
      })
    }
  }

  compareInstances(start: string, end: string) {
    const momentStart = moment(start, 'MM/DD/YYYY', true);
    const momentEnd = moment(end, 'MM/DD/YYYY', true);
    if (momentStart > momentEnd) return 1;
    else if (momentStart < momentEnd) return -1;
    else return 0;
  }

  get inviteTeachersRequest() {
    return { emails: JSON.stringify(this.localTeachersToInvite || []), districtAdminSelectSchool: this.localSchoolId }
  }

  inviteTeachers() {
    CommonUtils.showLoading();
    if (this.$refs.inviteTeachersForm.validate()) {
      this.invite(this.inviteTeachersRequest).then(() => {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg30') as string);
        }
        this.localTeachersToInvite = [];
        this.localShowInviteTeachersDialog = false;
      }).finally(CommonUtils.hideLoading);
    } else {
      CommonUtils.hideLoading();
    }
  }

  editTeacher(item: any) {
    if (CommonUtils.hasValue(item) ? item.editable : true) {
      this.$eventBus.$emit('openSubPage', {
        type: 'teacher',
        modal: this.$currentUser.defaultEditorMode === 'modal',
        width: 600,
        input: {
          loadData: true,
          data: item
        }
      });
    }
  }

  getColor(color: any) {
    return CommonUtils.getColor(color) || this.$vuetify.theme.currentTheme.primary;
  }

  async deleteTeachers() {
    return this.$refs.confirm.confirm({
      title: this.$t('removeTeachersLabel'),
      text: this.$t('confirmRemoveTeachersMsg'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        CommonUtils.showLoading();
        this.listLoading = true;
        return this.doDeleteTeachers({
          teachers: this.selectedRows
        }).then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg29') as string);
          }
          return Promise.resolve();
        });
      }
    }).then(() => {
      this.selectedRows = [];
      return Promise.resolve();
    }).finally(() => {
      this.listLoading = false;
      CommonUtils.hideLoading();
    });
  }

  async deleteTeacher(teacher: any) {
    return this.$refs.confirm.confirm({
      title: this.$t('removeTeacherLabel'),
      text: this.$t('confirmRemoveTeacherMsg'),
      option1ButtonAlternativeText: this.$t('continueLabel')
    }).then((result) => {
      if (result === 1) {
        CommonUtils.showLoading();
        this.listLoading = true;
        return this.doDeleteTeachers({
          teachers: [teacher]
        }).then(() => {
          if (this.showSnackbarNotifications) {
            this.$snotify.success(this.$t('statusMsg29') as string);
          }
          return Promise.resolve();
        });
      }
    }).then(() => {
      this.selectedRows = [];
      return Promise.resolve();
    }).finally(() => {
      this.listLoading = false;
      CommonUtils.hideLoading();
    });
  }

  async doDeleteTeachers(params: any) {
    const teachers: Array<any> = params.teachers;
    const teacherIds: Array<number> = [];
    const inviteIds: Array<number> = [];
    teachers.forEach(teacher => {
      if (teacher.inviteId > 0) {
        inviteIds.push(teacher.inviteId);
      } else if (teacher.teacherId > 0) {
        teacherIds.push(teacher.teacherId);
      }
    });
    return this.delete({ teacherIds, inviteIds }).then(() => {
      this.$eventBus.$emit('deleteTeacher', inviteIds);
    });
  }

  formatSortDate(date: string) {
    if (CommonUtils.hasText(date)) {
      return moment(date, 'MM/DD/YYYY').format('YYYY-MM-DD')
    }
    return '01/01/0001'
  }

  @Watch('teachers')
  onTeachersChange() {
    this.localTeachers = this.teachers.map(e => {
      const isInvited = +e.inviteId > 0;
      return ld.merge(e, {
        key: e.teacherId + '-' + e.inviteId,
        editable: true,
        invited: isInvited,
        typeName: this.getTypeName(e.userType),
        formattedLastLogon: isInvited ? 'Invited' : DateTimeUtils.formatToDisplay(e.lastLogon, true),
        formattedLastLogonSort: this.formatSortDate(e.lastLogon),
        isItemRostered: this.isTeacherRostered(e)
      });
    });
    this.localOrigTeachers = ld.cloneDeep(this.localTeachers);
  }

  getTypeName(userType: string) {
    return accountTypes.find(t => t.value === userType)?.text;
  }

  toggleEditMode() {
    this.editMode = !this.editMode;
    if (this.editMode === false) {
      this.selectedRows = [];
    }
  }

  @Watch('currentSchoolId')
  onCurrentSchoolIdChanged() {
    this.haveTeachers = false;
    this.initialize();
  }

  async initialize() {
    this.listLoading = true;
    if (this.haveTeachers) {
      this.onTeachersChange();
      this.listLoading = false;
    } else {
      return this.loadTeachers().then(() => {
        this.listLoading = false;
      });
    }
  }

  emailJoinUrlToMe() {
    CommonUtils.showLoading();
    SchoolServices.generateSchoolJoinCode({ schoolId: this.localSchoolId, sendToMe: true }).then((resp) => {
      this.localJoinURL = this.getJoinUrl(resp.data);
      this.localShowJoinCodesDialog = false;
    }).finally(CommonUtils.hideLoading);
  }

  getJoinUrl(data: any) {
    if (data && CommonUtils.hasText(data.joinUrl)) {
      return window.location.protocol + '//' + window.location.host + '?' + data.joinUrl.split('?')[1];
    }
    return '';
  }

  downloadJoinUrl() {
    const FileSaver = require('file-saver');
    const blob = new Blob([this.localJoinURL], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(blob, 'joinCode.txt');
    this.localShowJoinCodesDialog = false;
  }

  copyJoinUrlToClipboard() {
    CommonUtils.showLoading();
    navigator.clipboard.writeText(this.localJoinURL).then(() => {
      this.localShowJoinCodesDialog = false;
    }).finally(CommonUtils.hideLoading);
  }

  @Watch('localSchoolId')
  onLocalSchoolIdChange() {
    this.joinCodesUUId = CommonUtils.generateUUID();
  }

  @Watch('joinCodesUUId')
  generateSchoolJoinCode() {
    if (this.localSchoolId > 0) {
      SchoolServices.generateSchoolJoinCode({ schoolId: this.localSchoolId }).then((resp) => {
        this.localJoinURL = this.getJoinUrl(resp.data);
      })
    } else {
      this.localJoinURL = '';
    }
  }

  created() {
    if (this.currentSchoolId !== this.primarySchoolId && this.userType !== 'D') {
      this.$nextTick(() => {
        this.currentSchoolId = this.primarySchoolId;
      });
    } else {
      this.initialize().then(() => {
        this.localSchoolId = this.schoolId;
      });
    }
    this.$nextTick(() => {
      this.$eventBus.$on('openCopySchoolYear', () => {
        this.showCopySchoolYear = true;
      })
      this.$eventBus.$emit('openDefaultSubPage');
    });
  }

  destroyed() {
    this.$eventBus.$off('openCopySchoolYear');
  }
}
