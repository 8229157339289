












































































































import { UserSession } from '@/common/user-session';
import { dayActions } from '@/constants';
import { ActionsModal } from '@/types/global.types';
import CommonUtils from '@/utils/common-utils';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import Confirm from '@/components/core/Confirm.vue';
import PlusMinusField from '@/components/core/PlusMinusField.vue';
import CopyItemsPicker from '@/components/pickers/CopyItemsPicker.vue';
import PrintOptions from '@/components/prints/PrintOptions.vue';
import LinkLessonAction from '@/components/lessons/actions/LinkLessonAction.vue';
import MoveLessonAction from '@/components/lessons/actions/MoveLessonAction.vue';
import LessonManageStatus from '@/components/lessons/LessonManageStatus.vue';
import EventEditorForm from '@/components/events/EventEditorForm.vue';
import moment from 'moment';
import ld from 'lodash';
import DateTimeUtils from '@/utils/date-time-utils';

const settings = namespace('settings');
const dayactions = namespace('dayactions');
const classes = namespace('classes');
const prints = namespace('prints');
const students = namespace('students');
const schoolstatus = namespace('schoolstatus');
const events = namespace('events');
const lessons = namespace('lessons');

@Component({
  components: {
    PlusMinusField,
    CopyItemsPicker,
    MoveLessonAction,
    LinkLessonAction,
    PrintOptions,
    LessonManageStatus,
    EventEditorForm
  }
})
export default class DayActions extends Vue implements ActionsModal {
  @Prop({
    required: true,
    type: [Object, Number],
    default: () => {
      return {};
    }
  })
  input!: any;

  @Prop({ required: false, type: String, default: 'absolute' })
  type!: string;

  @Prop({ required: true, type: String })
  localRefreshKey!: string;

  showCopyMenu = false;
  showMenu = false;
  x = 0;
  y = 0;

  save = false;
  initialized = false;
  isAddingNoSchool = false;
  noSchoolEventInput = {};

  @dayactions.State
  day!: any;

  @settings.Getter('getUserInfo')
  userInfo!: any;

  @settings.Getter('getUserMode')
  userMode!: any;

  @settings.Getter('getCurrentYear')
  currentSchoolYear!: any;

  @settings.Getter('getStatuses')
  allLessonStatus!: Array<any>;

  @settings.Getter
  getCurrentTeacherId!: any;

  @settings.Getter('getSchoolStatuses')
  allSchoolStatus!: Array<any>;

  @settings.Getter('getSchoolId')
  schoolId!: number;

  @settings.Getter('getShowSnackbarNotifications')
  showSnackbarNotifications!: boolean;

  @schoolstatus.Action
  applySchoolStatus!: (params?: any) => Promise<any>;

  @schoolstatus.Action
  removeSchoolStatus!: (params?: any) => Promise<any>;

  @dayactions.Getter('getCopyLabel')
  copyLabel!: any;

  @classes.Getter('getAllClassItemValues')
  classIds!: any;

  @dayactions.Getter('getSaveLessonsRequest')
  requests!: Array<any>;

  @dayactions.Action
  init!: (params?: any) => Promise<any>;

  @dayactions.Action('saveLessons')
  doSave!: (params?: any) => Promise<any>;

  @dayactions.Action('bumpLessons')
  doBump!: (params?: any) => Promise<any>;

  @classes.Action
  loadClasses!: (params?: any) => Promise<any>;

  @students.Action
  loadNotesStudents!: (params?: any) => Promise<any>;

  @prints.Action
  initDayPrint!: (value?: any) => Promise<any>;

  @prints.Action
  print!: (value?: any) => Promise<any>;

  @events.Action
  updateGoogleCalendarEvents!: (value?: any) => Promise<any>;

  @dayactions.Action
  substituteDay!: (params: any) => Promise<any>;

  @lessons.Getter
  createNewLesson!: () => any;

  @lessons.Action('init')
  initLesson!: (params?: any) => Promise<any>;

  localShowManageGoogleEvents = false;
  localGoogleEvents: Array<any> = [];
  localShowSubstituteDay = false;
  localKeepSchedule = true;
  localBumpDay = true;
  localSubstituteNotes = '';

  $refs!: {
    confirm: Confirm,
    moveAction: MoveLessonAction,
    linkAction: LinkLessonAction,
    printOptionsAction: PrintOptions,
    manageStatus: LessonManageStatus,
    eventEditorForm: EventEditorForm,
    scrollableList: any
  }

  get currentYearId(): number {
    if (this.userMode === 'T') {
      return +this.$store.getters['settings/getCurrentSchoolYearId'];
    } else {
      return +this.$store.state.settings.currentYearId;
    }
  }

  get formattedDate() {
    if (CommonUtils.hasText(this.dayDate)) {
      return `${moment(this.dayDate, 'MM/DD/YYYY').format('ddd MMM DD')}`;
    }
  }

  get dayDate() {
    return this.day ? this.day.date : '';
  }

  get forward() {
    return +this.$store.state.dayactions.forward;
  }

  set forward(value: number) {
    this.$store.commit('dayactions/setForward', value);
  }

  get printOptions() {
    return this.$store.state.dayactions.printOptions;
  }

  set printOptions(value: any) {
    this.$store.commit('dayactions/setPrintOptions', value);
  }

  get isStatusEmpty() {
    return CommonUtils.isEmpty(this.allLessonStatus);
  }

  get saveLesson() {
    return this.save && this.input.loadData;
  }

  get filteredActions() {
    return dayActions.filter(action => {
      if (action.id === 'substitute') {
        return !this.hideAddSubstituteDay;
      }
      if (action.id === 'manageGoogleEvents') {
        return this.localGoogleEvents.length > 0;
      }
      if (action.id === 'noSchool') {
        return !(DateTimeUtils.daysDiff(this.dayDate, DateTimeUtils.currentDateAddDays(0)) > 30);
      }
      return true;
    });
  }

  get isCycleSchedule() {
    return !isNaN(+this.userInfo.schoolYearSettings.classCycle);
  }

  get localAllSchoolStatus() {
    return this.allSchoolStatus.filter((s: any) => !s.overrideDelete).map(s => {
      s.icon = this.selectedS.includes(s.schoolStatusId) ? 'fas fa-check-circle' : 'fas fa-circle';
      return s;
    });
  }

  get selectedS() {
    return this.schoolStatuses.filter((s:any) => s.subjectId === 0).map((s: any) => s.schoolStatusId);
  }

  get schoolStatuses() {
    return this.day.schoolStatuses || [];
  }

  set schoolStatuses(value: any) {
    this.day.schoolStatuses = value;
  }

  get googleEvents() {
    if (this.day && this.day.dayObjects) {
      return this.day.dayObjects
        .filter((o: any) => o.type === 'E' && o.showGoogle === 'Y')
        .map((o: any) => o.rawDayObject);
    }
    return [];
  }

  get hideAddSubstituteDay() {
    if (this.day && this.day.dayObjects) {
      return this.day.dayObjects.findIndex((d: any) => {
        const dayObject = d.rawDayObject;
        return CommonUtils.isTrue(dayObject.noSchool) || CommonUtils.isTrue(dayObject.noCycle) || dayObject.specialDayId > 0;
      }) > -1;
    }
    return false;
  }

  get getColor() {
    return CommonUtils.getColor;
  }

  addRemoveSchoolStatus(status: any) {
    if (this.selectedS.includes(status.schoolStatusId)) {
      this.schoolStatuses = this.schoolStatuses.filter((s: any) => s.schoolStatusId !== status.schoolStatusId);
      this.removeSchoolStatus({
        yearId: this.currentYearId,
        subjectId: 0,
        startDate: this.day.date,
        type: 'D',
        schoolStatusIds: status.schoolStatusId
      }).then(() => {
        this.$eventBus.$emit('schoolStatusUpdate');
      });
    } else {
      status.subjectId = 0;
      this.schoolStatuses = this.schoolStatuses.concat(status);
      this.applySchoolStatus({
        yearId: this.currentYearId,
        subjectId: 0,
        type: 'D',
        startDate: this.day.date,
        schoolStatusIds: status.schoolStatusId
      }).then(() => {
        this.$eventBus.$emit('schoolStatusUpdate');
      });
    }
  }

  manageStatus() {
    this.showMenu = false;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        this.$refs.manageStatus.manageStatus();
      });
    })
  }

  focusExtraOptions() {
    try {
      const index = this.$refs.scrollableList.$children.findIndex((listItem: any) => {
        return listItem.$el.classList.contains('v-list-item--highlighted');
      }) - 1;
      if (index >= 0) {
        (this.$refs as any)['extraOption' + (index)][0].focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  focusActionButton() {
    try {
      if ((this.$refs as any).actionsButton.$el) {
        (this.$refs as any).actionsButton.$el.focus();
      }
    } catch (e) {
      console.log(this.$t('focusFailed'));
    }
  }

  pickCopy() {
    console.log('Pick fields to copy');
  }

  applyGoogleEvents() {
    CommonUtils.showLoading();
    this.updateGoogleCalendarEvents({
      date: this.dayDate,
      events: this.localGoogleEvents
    }).finally(() => {
      this.localShowManageGoogleEvents = false;
      CommonUtils.hideLoading();
    });
  }

  async applySubstituteDay() {
    CommonUtils.showLoading();
    return this.substituteDay({
      date: this.dayDate,
      bump: this.localBumpDay,
      keep: this.localKeepSchedule,
      notes: this.localSubstituteNotes
    }).finally(() => {
      this.localShowSubstituteDay = false;
      if (this.showSnackbarNotifications) {
        this.$snotify.success(this.$t('statusMsg74') as string);
      }
      CommonUtils.hideLoading();
    });
  }

  public show(e: MouseEvent) {
    e.preventDefault()
    this.showMenu = false;
    this.x = e.clientX;
    this.y = e.clientY;
    this.$nextTick(() => {
      new Promise(resolve => setTimeout(resolve, 10)).then(() => {
        this.showMenu = true;
      });
    })
  }

  async doAction(action: any) {
    CommonUtils.showLoading();
    return Promise.resolve()
      .then(() => {
        if (action.id === 'event') {
          return this.doAddEvent();
        } else if (action.id === 'lesson') {
          return this.doAddLesson();
        } else if (action.id === 'noSchool') {
          return this.doAddNoSchoolEvent();
        } else if (action.id === 'forward') {
          if (this.requests.some(lesson => Object.prototype.hasOwnProperty.call(lesson, 'customTimeClassId'))) {
            const message = this.$t('bumpSharedClassesMessage');
            return this.$refs.confirm.confirm({
              title: this.$t('lessonLabel'),
              text: message,
              option1ButtonAlternativeText: this.$t('continueLabel')
            }).then((result: any) => {
              if (result === 1) {
                return this.doBumpForward();
              } else {
                return Promise.resolve();
              }
            });
          } else {
            return this.doBumpForward();
          }
        } else if (action.id === 'noClass') {
          return Promise.resolve();
        } else if (action.id === 'print') {
          return this.doPrintLesson();
        } else if (action.id === 'sticker') {
          return this.doStickers();
        } else if (action.id === 'manageGoogleEvents') {
          return this.doManageGoogleEvents();
        } else if (action.id === 'substitute') {
          return this.doSubstituteDay();
        }
        return Promise.resolve();
      }).finally(() => {
        CommonUtils.hideLoading();
        this.showMenu = false;
      });
  }

  async doManageGoogleEvents() {
    this.showMenu = false;
    this.$nextTick(() => {
      this.localShowManageGoogleEvents = true;
    })
    return Promise.resolve();
  }

  async doSubstituteDay() {
    this.showMenu = false;
    this.$nextTick(() => {
      this.localShowSubstituteDay = true;
      this.localKeepSchedule = true;
      this.localBumpDay = false;
      this.localSubstituteNotes = '';
    })
    return Promise.resolve();
  }

  async doStickers() {
    this.showMenu = false;
    this.$eventBus.$emit('openSubPage', {
      type: 'event',
      modal: true,
      input: {
        loadData: true,
        openStickerPickerDefault: true,
        data: {
          eventDate: this.dayDate,
          endDate: this.dayDate
        }
      }
    });
  }

  async doAddEvent() {
    this.showMenu = false;
    this.$eventBus.$emit('openSubPage', {
      type: 'event',
      modal: true,
      input: {
        loadData: true,
        data: {
          eventDate: this.dayDate,
          endDate: this.dayDate
        }
      }
    });
  }

  async doAddLesson() {
    this.showMenu = false;
    const newLesson = this.createNewLesson();
    newLesson.date = this.dayDate;
    this.initLesson({ data: newLesson })
    this.$eventBus.$emit('openSubPage', {
      type: 'lesson',
      modal: true,
      input: {
        loadData: true,
        data: newLesson
      }
    });
  }

  async doAddNoSchoolEvent() {
    this.isAddingNoSchool = true;
    this.noSchoolEventInput = {
      loadData: true,
      data: {
        eventDate: this.dayDate,
        endDate: this.dayDate,
        noSchool: true,
        noCycle: this.isCycleSchedule,
        schoolId: 0,
        eventTitle: this.$t('noSchoolDayLabel'),
        eventId: 0
      }
    }
    return new Promise(resolve => {
      this.$nextTick(() => {
        resolve({});
      });
    }).then(() => {
      return this.$refs.eventEditorForm.save();
    }).finally(() => {
      this.isAddingNoSchool = false;
    });
  }

  async doPrintOptions() {
    const that = this;
    this.showMenu = false;
    return that.$refs.printOptionsAction.print({
      type: 'day',
      classId: 0,
      extraLesson: '',
      date: this.dayDate,
      classIds: []
    });
  }

  async doPrintLesson() {
    const that = this;
    this.showMenu = false;
    return this.initDayPrint({
      type: 'day',
      classId: '',
      extraLesson: '',
      date: this.dayDate,
      classIds: []
    }).then(() => {
      if (!this.$currentUser.isStudent) {
        return that.loadNotesStudents();
      }
      return Promise.resolve();
    }).then(() => {
      return that.print({
        authToken: UserSession.getAccessToken()
      });
    });
  }

  async doBumpForward() {
    return this.doBump({ numDays: this.forward, verify: true, mainLessonsOnly: true }).then((resp) => {
      if (resp.confirmDeletes.length > 0) {
        const message = (resp.confirmDeletes.length > 1) ? this.$t('bumpClassesMessage', { count: resp.confirmDeletes.length }) : this.$t('bumpClassMessage')
        return this.$refs.confirm.confirm({
          title: this.$t('lessonLabel'),
          text: message,
          option1ButtonAlternativeText: this.$t('continueLabel')
        }).then((result: any) => {
          if (result === 1) {
            return this.doBump({ numDays: this.forward, verify: false, mainLessonsOnly: true }).then(() => {
              if (this.showSnackbarNotifications) {
                this.$snotify.success(this.$t('statusMsg73') as string);
              }
              return Promise.resolve();
            })
          } else {
            return Promise.resolve();
          }
        });
      } else {
        if (this.showSnackbarNotifications) {
          this.$snotify.success(this.$t('statusMsg73') as string);
        }
        return Promise.resolve();
      }
    });
  }

  async doInit(input?: any) {
    console.log('doInit');
    const that = this;
    const data = input || this.input;
    return Promise.resolve().then(() => {
      if (data instanceof Object) {
        return this.init(data).then(function() {
          that.initialized = true;
          that.save = false;
        });
      } else {
        return Promise.resolve();
      }
    }).then(() => {
      this.localGoogleEvents = ld.cloneDeep(this.googleEvents);
    })
  }

  @Watch('showMenu')
  onShowMenuChange() {
    if (this.showMenu) {
      this.doInit();
    }
  }

  mounted() {
    const that = this;
    this.$nextTick(() => {
      that.$eventBus.$on('dayHeaderRightClicked', (key:string) => {
        key === that.localRefreshKey ? that.showMenu = true : that.showMenu = false;
      })
    });
  }

  destroyed() {
    this.$eventBus.$off('dayHeaderRightClicked');
  }
}
