var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          class: _vm.$vuetify.breakpoint.xs ? "px-3" : "",
          attrs: {
            justify: "center",
            align: _vm.$vuetify.breakpoint.xs ? "start" : "center"
          }
        },
        [
          _c(
            "v-col",
            {
              class: _vm.$vuetify.breakpoint.xs ? "text-left" : "text-center",
              attrs: { cols: _vm.$vuetify.breakpoint.xs ? 9 : 12 }
            },
            [
              _c(
                "v-btn-toggle",
                {
                  attrs: { color: "primary", dense: "", mandatory: "" },
                  model: {
                    value: _vm.localClassEditorSelectedTab,
                    callback: function($$v) {
                      _vm.localClassEditorSelectedTab = $$v
                    },
                    expression: "localClassEditorSelectedTab"
                  }
                },
                [
                  _c("v-btn", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("teachingLabel")))])
                  ]),
                  _c("v-btn", [
                    _c("span", [_vm._v(_vm._s(_vm.$t("nonTeachingLabel")))])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _vm.$vuetify.breakpoint.xs
            ? _c(
                "v-col",
                { staticClass: "text-right", attrs: { cols: "3" } },
                [
                  _c("v-spacer"),
                  _c("pb-color-picker", {
                    attrs: { icon: "fas fa-palette", origin: "top right" },
                    model: {
                      value: _vm.localSelectedClassClone.color,
                      callback: function($$v) {
                        _vm.$set(_vm.localSelectedClassClone, "color", $$v)
                      },
                      expression: "localSelectedClassClone.color"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-row",
        { attrs: { justify: "center", align: "start" } },
        [
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { attrs: { cols: "1" } })
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: _vm.$vuetify.breakpoint.smAndDown ? 12 : 10 } },
            [
              _c(
                "div",
                { staticClass: "class-editor-tab" },
                [
                  _c(
                    "v-card",
                    { staticClass: "mx-8", attrs: { flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "my-0 pt-3" },
                        [
                          _c(
                            "v-form",
                            {
                              ref: "classEditorForm",
                              attrs: { "lazy-validation": "" }
                            },
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pr-0 pb-0 pl-0 ma-0",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { justify: "center" } },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pb-0",
                                              attrs: {
                                                cols: _vm.$vuetify.breakpoint.xs
                                                  ? 12
                                                  : 10
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "class-name",
                                                attrs: {
                                                  label:
                                                    _vm.$t("classNameLabel") +
                                                    "*",
                                                  outlined: "",
                                                  clearable: "",
                                                  "hide-details": "auto",
                                                  rules: _vm.localRules,
                                                  "validate-on-blur": "",
                                                  autofocus: "",
                                                  "aria-required": "true"
                                                },
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "append-outer",
                                                    fn: function() {
                                                      return [
                                                        _c(
                                                          "v-menu",
                                                          {
                                                            attrs: {
                                                              "close-on-content-click": false,
                                                              origin:
                                                                "top right",
                                                              transition:
                                                                "scale-transition",
                                                              absolute: "",
                                                              "offset-overflow":
                                                                "",
                                                              "offset-y": "",
                                                              left: ""
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function(
                                                                    ref
                                                                  ) {
                                                                    var onMenu =
                                                                      ref.on
                                                                    return [
                                                                      _c(
                                                                        "pb-btn",
                                                                        _vm._g(
                                                                          {
                                                                            attrs: {
                                                                              icon:
                                                                                "",
                                                                              "x-large":
                                                                                "",
                                                                              label: _vm.$t(
                                                                                "formatLabel"
                                                                              )
                                                                            },
                                                                            on: {
                                                                              keydown: function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "down",
                                                                                    40,
                                                                                    $event.key,
                                                                                    [
                                                                                      "Down",
                                                                                      "ArrowDown"
                                                                                    ]
                                                                                  )
                                                                                ) {
                                                                                  return null
                                                                                }
                                                                                $event.stopPropagation()
                                                                                $event.preventDefault()
                                                                              },
                                                                              keyup: function(
                                                                                $event
                                                                              ) {
                                                                                if (
                                                                                  !$event.type.indexOf(
                                                                                    "key"
                                                                                  ) &&
                                                                                  _vm._k(
                                                                                    $event.keyCode,
                                                                                    "down",
                                                                                    40,
                                                                                    $event.key,
                                                                                    [
                                                                                      "Down",
                                                                                      "ArrowDown"
                                                                                    ]
                                                                                  )
                                                                                ) {
                                                                                  return null
                                                                                }
                                                                                return _vm.openClassNameFormatter.apply(
                                                                                  null,
                                                                                  arguments
                                                                                )
                                                                              }
                                                                            }
                                                                          },
                                                                          Object.assign(
                                                                            {},
                                                                            onMenu
                                                                          )
                                                                        ),
                                                                        [
                                                                          _c(
                                                                            "v-icon",
                                                                            [
                                                                              _vm._v(
                                                                                "fal fa-font"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ]
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.localClassNameShowFormatter,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.localClassNameShowFormatter = $$v
                                                              },
                                                              expression:
                                                                "localClassNameShowFormatter"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "v-card",
                                                              [
                                                                _c(
                                                                  "v-list",
                                                                  [
                                                                    _c(
                                                                      "v-list-item",
                                                                      [
                                                                        _c(
                                                                          "v-list-item-content",
                                                                          [
                                                                            _c(
                                                                              "pb-format-options",
                                                                              {
                                                                                ref:
                                                                                  "classNameFormatter",
                                                                                attrs: {
                                                                                  origin1:
                                                                                    "top right",
                                                                                  font1:
                                                                                    _vm
                                                                                      .localSelectedClassClone
                                                                                      .titleFont,
                                                                                  fontSize1:
                                                                                    _vm
                                                                                      .localSelectedClassClone
                                                                                      .titleSize,
                                                                                  foregroundColor1:
                                                                                    _vm
                                                                                      .localSelectedClassClone
                                                                                      .titleColor,
                                                                                  bold1:
                                                                                    _vm
                                                                                      .localSelectedClassClone
                                                                                      .classLabelBold,
                                                                                  italic1:
                                                                                    _vm
                                                                                      .localSelectedClassClone
                                                                                      .classLabelItalic,
                                                                                  underline1:
                                                                                    _vm
                                                                                      .localSelectedClassClone
                                                                                      .classLabelUnderline,
                                                                                  dense1:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  "update:font1": function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$set(
                                                                                      _vm.localSelectedClassClone,
                                                                                      "titleFont",
                                                                                      $event
                                                                                    )
                                                                                  },
                                                                                  "update:fontSize1": function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$set(
                                                                                      _vm.localSelectedClassClone,
                                                                                      "titleSize",
                                                                                      $event
                                                                                    )
                                                                                  },
                                                                                  "update:font-size1": function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$set(
                                                                                      _vm.localSelectedClassClone,
                                                                                      "titleSize",
                                                                                      $event
                                                                                    )
                                                                                  },
                                                                                  "update:foregroundColor1": function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$set(
                                                                                      _vm.localSelectedClassClone,
                                                                                      "titleColor",
                                                                                      $event
                                                                                    )
                                                                                  },
                                                                                  "update:foreground-color1": function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$set(
                                                                                      _vm.localSelectedClassClone,
                                                                                      "titleColor",
                                                                                      $event
                                                                                    )
                                                                                  },
                                                                                  "update:bold1": function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$set(
                                                                                      _vm.localSelectedClassClone,
                                                                                      "classLabelBold",
                                                                                      $event
                                                                                    )
                                                                                  },
                                                                                  "update:italic1": function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$set(
                                                                                      _vm.localSelectedClassClone,
                                                                                      "classLabelItalic",
                                                                                      $event
                                                                                    )
                                                                                  },
                                                                                  "update:underline1": function(
                                                                                    $event
                                                                                  ) {
                                                                                    return _vm.$set(
                                                                                      _vm.localSelectedClassClone,
                                                                                      "classLabelUnderline",
                                                                                      $event
                                                                                    )
                                                                                  }
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "v-list-item-action",
                                                                          [
                                                                            _c(
                                                                              "v-btn",
                                                                              {
                                                                                staticClass:
                                                                                  "text-center",
                                                                                attrs: {
                                                                                  small:
                                                                                    ""
                                                                                },
                                                                                on: {
                                                                                  click: function(
                                                                                    $event
                                                                                  ) {
                                                                                    $event.stopPropagation()
                                                                                    _vm.localClassNameShowFormatter = false
                                                                                  }
                                                                                }
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "doneLabel"
                                                                                    )
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ],
                                                                          1
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    },
                                                    proxy: true
                                                  }
                                                ]),
                                                model: {
                                                  value:
                                                    _vm.localSelectedClassClone
                                                      .className,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.localSelectedClassClone,
                                                      "className",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "localSelectedClassClone.className"
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          !_vm.$vuetify.breakpoint.xs
                                            ? _c(
                                                "v-col",
                                                {
                                                  staticClass: "mt-2 pb-0",
                                                  attrs: { cols: "2" }
                                                },
                                                [
                                                  _c("pb-color-picker", {
                                                    attrs: {
                                                      icon: "fas fa-palette",
                                                      origin: "top right"
                                                    },
                                                    model: {
                                                      value:
                                                        _vm
                                                          .localSelectedClassClone
                                                          .color,
                                                      callback: function($$v) {
                                                        _vm.$set(
                                                          _vm.localSelectedClassClone,
                                                          "color",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "localSelectedClassClone.color"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-6",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pr-0 pb-0 pl-0 ma-0",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-1",
                                          attrs: { justify: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: _vm.$vuetify.breakpoint.xs
                                                  ? 6
                                                  : _vm.$vuetify.breakpoint.sm
                                                  ? 8
                                                  : 9
                                              }
                                            },
                                            [
                                              _c("pb-date-field", {
                                                key:
                                                  "classStart-" +
                                                  _vm.localRefreshKey,
                                                attrs: {
                                                  label:
                                                    _vm.$t("classStartLabel") +
                                                    "*",
                                                  value:
                                                    _vm.localSelectedClassClone
                                                      .classStartDate,
                                                  disabled:
                                                    _vm.localClassStartUseSchoolStart ||
                                                    !_vm.isClassEditorFieldEditable(
                                                      _vm
                                                        .localSelectedClassClone
                                                        .classStartDate,
                                                      _vm
                                                        .localSelectedClassClone
                                                        .created
                                                    ),
                                                  messages:
                                                    _vm.localClassStartDateMessages,
                                                  errorMessage:
                                                    _vm.localClassStartDateErrorMessage,
                                                  hideDetails: "auto",
                                                  required: ""
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.localSelectedClassClone,
                                                      "classStartDate",
                                                      $event
                                                    )
                                                  },
                                                  change:
                                                    _vm.handleClassStartChanged,
                                                  click: function($event) {
                                                    _vm.handleClassStartClicked(
                                                      _vm.isClassEditorFieldEditable(
                                                        _vm
                                                          .localSelectedClassClone
                                                          .classStartDate,
                                                        _vm
                                                          .localSelectedClassClone
                                                          .created
                                                      )
                                                    )
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: _vm.$vuetify.breakpoint.xs
                                                  ? 6
                                                  : _vm.$vuetify.breakpoint.sm
                                                  ? 4
                                                  : 3
                                              }
                                            },
                                            [
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mt-3",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    "input-value":
                                                      _vm.localClassStartUseSchoolStart,
                                                    filter: "",
                                                    outlined: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      _vm.localClassStartUseSchoolStart = _vm.handleUseSchoolStartClicked(
                                                        _vm.isClassEditorFieldEditable(
                                                          _vm
                                                            .localSelectedClassClone
                                                            .classStartDate,
                                                          _vm
                                                            .localSelectedClassClone
                                                            .created
                                                        ),
                                                        _vm.localClassStartUseSchoolStart
                                                      )
                                                    },
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.stopPropagation()
                                                      _vm.localClassStartUseSchoolStart = _vm.handleUseSchoolStartClicked(
                                                        _vm.isClassEditorFieldEditable(
                                                          _vm
                                                            .localSelectedClassClone
                                                            .classStartDate,
                                                          _vm
                                                            .localSelectedClassClone
                                                            .created
                                                        ),
                                                        _vm.localClassStartUseSchoolStart
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$vuetify.breakpoint
                                                          .xs
                                                          ? _vm.$t(
                                                              "schoolStartLabel"
                                                            )
                                                          : _vm.$t(
                                                              "useSchoolStartLabel"
                                                            )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pr-0 pb-0 pl-0 ma-0",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        {
                                          staticClass: "mt-1",
                                          attrs: { justify: "center" }
                                        },
                                        [
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: _vm.$vuetify.breakpoint.xs
                                                  ? 6
                                                  : _vm.$vuetify.breakpoint.sm
                                                  ? 8
                                                  : 9
                                              }
                                            },
                                            [
                                              _c("pb-date-field", {
                                                key:
                                                  "classEnd-" +
                                                  _vm.localRefreshKey,
                                                attrs: {
                                                  label:
                                                    _vm.$t("classEndLabel") +
                                                    "*",
                                                  value:
                                                    _vm.localSelectedClassClone
                                                      .classEndDate,
                                                  disabled:
                                                    _vm.localClassEndUseSchoolEnd ||
                                                    !_vm.isClassEditorFieldEditable(
                                                      _vm
                                                        .localSelectedClassClone
                                                        .classEndDate,
                                                      _vm
                                                        .localSelectedClassClone
                                                        .created
                                                    ),
                                                  messages:
                                                    _vm.localClassEndDateMessages,
                                                  errorMessage:
                                                    _vm.localClassEndDateErrorMessage,
                                                  hideDetails: "auto",
                                                  required: ""
                                                },
                                                on: {
                                                  "update:value": function(
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      _vm.localSelectedClassClone,
                                                      "classEndDate",
                                                      $event
                                                    )
                                                  },
                                                  change:
                                                    _vm.handleClassEndChanged
                                                }
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: _vm.$vuetify.breakpoint.xs
                                                  ? 6
                                                  : _vm.$vuetify.breakpoint.sm
                                                  ? 4
                                                  : 3
                                              }
                                            },
                                            [
                                              _c(
                                                "pb-chip",
                                                {
                                                  staticClass: "mt-3",
                                                  staticStyle: { opacity: "1" },
                                                  attrs: {
                                                    "active-class":
                                                      "accent3--text font-weight-bold",
                                                    "input-value":
                                                      _vm.localClassEndUseSchoolEnd,
                                                    disabled: !_vm.isClassEditorFieldEditable(
                                                      _vm
                                                        .localSelectedClassClone
                                                        .classEndDate,
                                                      _vm
                                                        .localSelectedClassClone
                                                        .created
                                                    ),
                                                    filter: "",
                                                    outlined: ""
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      $event.stopPropagation()
                                                      _vm.localClassEndUseSchoolEnd = !_vm.localClassEndUseSchoolEnd
                                                    },
                                                    keyup: function($event) {
                                                      if (
                                                        !$event.type.indexOf(
                                                          "key"
                                                        ) &&
                                                        _vm._k(
                                                          $event.keyCode,
                                                          "enter",
                                                          13,
                                                          $event.key,
                                                          "Enter"
                                                        )
                                                      ) {
                                                        return null
                                                      }
                                                      $event.stopPropagation()
                                                      _vm.localClassEndUseSchoolEnd = !_vm.localClassEndUseSchoolEnd
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.$vuetify.breakpoint
                                                          .xs
                                                          ? _vm.$t(
                                                              "schoolEndLabel"
                                                            )
                                                          : _vm.$t(
                                                              "useSchoolEndLabel"
                                                            )
                                                      )
                                                    )
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.localClassEditorSelectedTab === 0,
                                      expression:
                                        "localClassEditorSelectedTab === 0"
                                    }
                                  ],
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pr-0 pb-0 pl-0 mt-4",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.localLessonLayouts,
                                          "prepend-inner-icon":
                                            "fal fa-window-maximize",
                                          label: _vm.$t("lessonLayoutLabel"),
                                          "hide-details": "auto",
                                          outlined: "",
                                          clearable: ""
                                        },
                                        model: {
                                          value:
                                            _vm.localSelectedClassClone
                                              .lessonLayoutId,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.localSelectedClassClone,
                                              "lessonLayoutId",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localSelectedClassClone.lessonLayoutId"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-8",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pr-0 pb-0 pl-0 ma-0",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          items: _vm.collaborateItems,
                                          label: _vm.$t("collaborateLabel"),
                                          "hide-details": "auto",
                                          outlined: ""
                                        },
                                        model: {
                                          value:
                                            _vm.localSelectedClassClone
                                              .collaborateType,
                                          callback: function($$v) {
                                            _vm.$set(
                                              _vm.localSelectedClassClone,
                                              "collaborateType",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "localSelectedClassClone.collaborateType"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.localSelectedClassClone
                                          .collaborateType === 3,
                                      expression:
                                        "localSelectedClassClone.collaborateType === 3"
                                    }
                                  ],
                                  staticClass: "mt-8",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pr-0 pb-0 pl-0 ma-0",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { outlined: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "title-on-border"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("replicaLabel")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-tabs",
                                                {
                                                  attrs: { centered: "" },
                                                  on: {
                                                    change:
                                                      _vm.handleReplicaSelectedTabChange
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.localReplicaSelectedTab,
                                                    callback: function($$v) {
                                                      _vm.localReplicaSelectedTab = $$v
                                                    },
                                                    expression:
                                                      "localReplicaSelectedTab"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "v-tab",
                                                    {
                                                      staticClass:
                                                        "text-caption"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "replicaClassLabel"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-tab",
                                                    {
                                                      staticClass:
                                                        "text-caption"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "getClassesLabel"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.localReplicaSelectedTab ===
                                                        0,
                                                      expression:
                                                        "localReplicaSelectedTab === 0"
                                                    }
                                                  ],
                                                  attrs: {
                                                    align: "center",
                                                    justify: "center"
                                                  }
                                                },
                                                [
                                                  _c("v-col", {
                                                    attrs: { cols: "2" }
                                                  }),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "8" } },
                                                    [
                                                      _c("v-select", {
                                                        staticClass: "mt-1",
                                                        attrs: {
                                                          label:
                                                            _vm.$t(
                                                              "teacherLabel"
                                                            ) + "*",
                                                          "aria-required":
                                                            "true",
                                                          "hide-details":
                                                            "auto",
                                                          items:
                                                            _vm.localTeacherDropdownItems,
                                                          rules:
                                                            _vm
                                                              .localSelectedClassClone
                                                              .collaborateType ===
                                                            3
                                                              ? _vm.localRules
                                                              : [],
                                                          "error-messages":
                                                            _vm.localSavedCollaborateTeacherEmailError,
                                                          outlined: "",
                                                          dense: ""
                                                        },
                                                        on: {
                                                          change:
                                                            _vm.handleCollaborateTeacherChange
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .localSelectedClassClone
                                                              .collaborateTeacherEmail,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.localSelectedClassClone,
                                                              "collaborateTeacherEmail",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "localSelectedClassClone.collaborateTeacherEmail"
                                                        }
                                                      }),
                                                      _vm.localSavedCollaborateTeacherEmailError
                                                        ? _c("v-text-field", {
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              label:
                                                                _vm.$t(
                                                                  "teacherKeyLabel"
                                                                ) + "*",
                                                              "aria-required":
                                                                "true",
                                                              "hide-details":
                                                                "auto",
                                                              outlined: "",
                                                              clearable: "",
                                                              dense: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .localSelectedClassClone
                                                                  .collaborateKey,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.localSelectedClassClone,
                                                                  "collaborateKey",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "localSelectedClassClone.collaborateKey"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      _vm.localSavedCollaborateTeacherEmailError
                                                        ? _c(
                                                            "v-btn",
                                                            {
                                                              staticClass:
                                                                "mt-2",
                                                              attrs: {
                                                                depressed: "",
                                                                color: "info",
                                                                small: "",
                                                                dark: "",
                                                                width: "150px",
                                                                loading:
                                                                  _vm.localShowCollaborateClassesLoading
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.handleCollaborateTeacherPasswordChange.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "showClassesLabel"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        : _vm._e(),
                                                      !_vm.localSavedCollaborateTeacherEmailError
                                                        ? _c("v-select", {
                                                            key:
                                                              "collaborateyearid-" +
                                                              _vm.localRefreshKey,
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              label:
                                                                _vm.$t(
                                                                  "schoolYearLabel"
                                                                ) + "*",
                                                              "aria-required":
                                                                "true",
                                                              "hide-details":
                                                                "auto",
                                                              items:
                                                                _vm.localSchoolYearDropdownItems,
                                                              loading:
                                                                _vm.localSchoolYearDropdownItemsLoading,
                                                              rules:
                                                                _vm
                                                                  .localSelectedClassClone
                                                                  .collaborateType ===
                                                                3
                                                                  ? _vm.localRules
                                                                  : [],
                                                              outlined: "",
                                                              dense: ""
                                                            },
                                                            on: {
                                                              change:
                                                                _vm.handleCollaborateSchoolYearChange
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .localSelectedClassClone
                                                                  .collaborateYearId,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.localSelectedClassClone,
                                                                  "collaborateYearId",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "localSelectedClassClone.collaborateYearId"
                                                            }
                                                          })
                                                        : _vm._e(),
                                                      !_vm.localSavedCollaborateTeacherEmailError
                                                        ? _c("v-select", {
                                                            key:
                                                              "collaboratesubjectid-" +
                                                              _vm.localRefreshKey,
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              label:
                                                                _vm.$t(
                                                                  "classLabel"
                                                                ) + "*",
                                                              "aria-required":
                                                                "true",
                                                              "hide-details":
                                                                "auto",
                                                              items:
                                                                _vm.localSubjectDropdownItems,
                                                              loading:
                                                                _vm.localSubjectDropdownItemsLoading,
                                                              rules:
                                                                _vm
                                                                  .localSelectedClassClone
                                                                  .collaborateType ===
                                                                3
                                                                  ? _vm.localRules
                                                                  : [],
                                                              outlined: "",
                                                              dense: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .localSelectedClassClone
                                                                  .collaborateSubjectId,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.localSelectedClassClone,
                                                                  "collaborateSubjectId",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "localSelectedClassClone.collaborateSubjectId"
                                                            }
                                                          })
                                                        : _vm._e()
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: { cols: "2" }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.localReplicaSelectedTab ===
                                                        1,
                                                      expression:
                                                        "localReplicaSelectedTab === 1"
                                                    }
                                                  ],
                                                  staticClass: "mt-0",
                                                  attrs: {
                                                    align: "center",
                                                    justify: "center"
                                                  }
                                                },
                                                [
                                                  _c("v-col", {
                                                    attrs: { cols: "2" }
                                                  }),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "8" } },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            align: "center",
                                                            justify: "center"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass: "mt-1",
                                                            attrs: {
                                                              label:
                                                                _vm.$t(
                                                                  "teacherEmailLabel"
                                                                ) + "*",
                                                              "aria-required":
                                                                "true",
                                                              "error-messages":
                                                                _vm.localCollaborateTeacherEmailError,
                                                              "hide-details":
                                                                "auto",
                                                              outlined: "",
                                                              clearable: "",
                                                              dense: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.localCollaborateTeacherEmail,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.localCollaborateTeacherEmail = $$v
                                                              },
                                                              expression:
                                                                "localCollaborateTeacherEmail"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            align: "center",
                                                            justify: "center"
                                                          }
                                                        },
                                                        [
                                                          _c("v-text-field", {
                                                            staticClass: "mt-2",
                                                            attrs: {
                                                              label:
                                                                _vm.$t(
                                                                  "teacherKeyLabel"
                                                                ) + "*",
                                                              "aria-required":
                                                                "true",
                                                              "error-messages":
                                                                _vm.localCollaborateTeacherKeyError,
                                                              "hide-details":
                                                                "auto",
                                                              outlined: "",
                                                              clearable: "",
                                                              dense: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.localCollaborateTeacherKey,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.localCollaborateTeacherKey = $$v
                                                              },
                                                              expression:
                                                                "localCollaborateTeacherKey"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            align: "center",
                                                            justify: "center"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "pb-chip",
                                                            {
                                                              staticClass:
                                                                "my-2",
                                                              staticStyle: {
                                                                opacity: "1"
                                                              },
                                                              attrs: {
                                                                "input-value":
                                                                  _vm.localCollaborateSaveTeacher,
                                                                "active-class":
                                                                  "accent3--text font-weight-bold",
                                                                filter: "",
                                                                outlined: ""
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  _vm.localCollaborateSaveTeacher = !_vm.localCollaborateSaveTeacher
                                                                },
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  $event.stopPropagation()
                                                                  _vm.localCollaborateSaveTeacher = !_vm.localCollaborateSaveTeacher
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "saveTeacherLabel"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          ),
                                                          _c("v-spacer"),
                                                          _c(
                                                            "v-btn",
                                                            {
                                                              attrs: {
                                                                depressed: "",
                                                                color: "info",
                                                                small: "",
                                                                dark: "",
                                                                width: "150px",
                                                                loading:
                                                                  _vm.localShowCollaborateClassesLoading
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  $event.stopPropagation()
                                                                  return _vm.showCollaborateClasses.apply(
                                                                    null,
                                                                    arguments
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c("span", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      "showClassesLabel"
                                                                    )
                                                                  )
                                                                )
                                                              ])
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-col", {
                                                    attrs: { cols: "2" }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-8",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pt-1 pr-0 pb-0 pl-0 ma-0",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        {
                                          key:
                                            "schedule-" + _vm.localRefreshKey,
                                          attrs: { outlined: "" }
                                        },
                                        [
                                          _vm.$vuetify.breakpoint.xs
                                            ? _c(
                                                "v-card-title",
                                                [
                                                  _vm
                                                    .localSelectedClassScheduleArrayClone
                                                    .length > 1 ||
                                                  (!_vm.isNewClass &&
                                                    _vm.localIsEditingSchedule)
                                                    ? _c(
                                                        "v-item-group",
                                                        {
                                                          staticClass:
                                                            "shrink text-left",
                                                          attrs: {
                                                            mandatory: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.localClassScheduleArraySelected,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.localClassScheduleArraySelected = $$v
                                                            },
                                                            expression:
                                                              "localClassScheduleArraySelected"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                [
                                                                  _vm.localIsEditingSchedule &&
                                                                  !_vm.mCurrentDate.isBefore(
                                                                    _vm.mCurrentYearFirstDay
                                                                  )
                                                                    ? _c(
                                                                        "v-menu",
                                                                        {
                                                                          attrs: {
                                                                            "close-on-content-click": false,
                                                                            transition:
                                                                              "scale-transition",
                                                                            "min-width":
                                                                              "auto",
                                                                            "max-width":
                                                                              "450",
                                                                            origin:
                                                                              "auto",
                                                                            "offset-overflow":
                                                                              "",
                                                                            "offset-y":
                                                                              "",
                                                                            left:
                                                                              ""
                                                                          },
                                                                          scopedSlots: _vm._u(
                                                                            [
                                                                              {
                                                                                key:
                                                                                  "activator",
                                                                                fn: function(
                                                                                  ref
                                                                                ) {
                                                                                  var on =
                                                                                    ref.on
                                                                                  var attrs =
                                                                                    ref.attrs
                                                                                  return [
                                                                                    _c(
                                                                                      "pb-btn",
                                                                                      _vm._g(
                                                                                        _vm._b(
                                                                                          {
                                                                                            staticClass:
                                                                                              "ml-4",
                                                                                            attrs: {
                                                                                              icon:
                                                                                                "",
                                                                                              small:
                                                                                                "",
                                                                                              label: _vm.$t(
                                                                                                "createNewScheduleLabel"
                                                                                              )
                                                                                            }
                                                                                          },
                                                                                          "pb-btn",
                                                                                          attrs,
                                                                                          false
                                                                                        ),
                                                                                        on
                                                                                      ),
                                                                                      [
                                                                                        _c(
                                                                                          "v-icon",
                                                                                          [
                                                                                            _vm._v(
                                                                                              "fal fa-circle-plus"
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ]
                                                                                }
                                                                              }
                                                                            ],
                                                                            null,
                                                                            false,
                                                                            2428705287
                                                                          ),
                                                                          model: {
                                                                            value:
                                                                              _vm
                                                                                .localClassEditScheduleMenu[1],
                                                                            callback: function(
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                _vm.localClassEditScheduleMenu,
                                                                                1,
                                                                                $$v
                                                                              )
                                                                            },
                                                                            expression:
                                                                              "localClassEditScheduleMenu[1]"
                                                                          }
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "v-card",
                                                                            [
                                                                              _c(
                                                                                "v-toolbar",
                                                                                {
                                                                                  attrs: {
                                                                                    elevation:
                                                                                      "0"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-toolbar-title",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          _vm.$t(
                                                                                            "editScheduleLabel"
                                                                                          )
                                                                                        )
                                                                                      )
                                                                                    ]
                                                                                  ),
                                                                                  _c(
                                                                                    "v-spacer"
                                                                                  ),
                                                                                  _c(
                                                                                    "pb-btn",
                                                                                    {
                                                                                      staticClass:
                                                                                        "mr-1",
                                                                                      attrs: {
                                                                                        small:
                                                                                          "",
                                                                                        icon:
                                                                                          "",
                                                                                        depressed:
                                                                                          "",
                                                                                        label: _vm.$t(
                                                                                          "closeLabel"
                                                                                        )
                                                                                      },
                                                                                      on: {
                                                                                        click: function(
                                                                                          $event
                                                                                        ) {
                                                                                          $event.stopPropagation()
                                                                                          $event.preventDefault()
                                                                                          return function() {
                                                                                            _vm.localClassEditScheduleMenu = [
                                                                                              false,
                                                                                              false
                                                                                            ]
                                                                                          }.apply(
                                                                                            null,
                                                                                            arguments
                                                                                          )
                                                                                        }
                                                                                      }
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "v-icon",
                                                                                        [
                                                                                          _vm._v(
                                                                                            "fal fa-xmark"
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              ),
                                                                              _c(
                                                                                "v-divider"
                                                                              ),
                                                                              _c(
                                                                                "v-form",
                                                                                {
                                                                                  ref:
                                                                                    "editScheduleMenuForm1",
                                                                                  attrs: {
                                                                                    "lazy-validation":
                                                                                      ""
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-list",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item",
                                                                                        [
                                                                                          _c(
                                                                                            "v-radio-group",
                                                                                            {
                                                                                              attrs: {
                                                                                                column:
                                                                                                  "",
                                                                                                mandatory:
                                                                                                  ""
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "label",
                                                                                                    fn: function() {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "div",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "mb-8"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                _vm.$t(
                                                                                                                  "whenScheduleStartLabel"
                                                                                                                )
                                                                                                              )
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    },
                                                                                                    proxy: true
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                false,
                                                                                                2191655040
                                                                                              ),
                                                                                              model: {
                                                                                                value:
                                                                                                  _vm.localEditScheduleOption,
                                                                                                callback: function(
                                                                                                  $$v
                                                                                                ) {
                                                                                                  _vm.localEditScheduleOption = $$v
                                                                                                },
                                                                                                expression:
                                                                                                  "localEditScheduleOption"
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "v-radio",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    label: _vm.$t(
                                                                                                      "todayLabel"
                                                                                                    ),
                                                                                                    value:
                                                                                                      "T"
                                                                                                  }
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                "v-radio",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "pt-4 pb-1",
                                                                                                  attrs: {
                                                                                                    label:
                                                                                                      _vm.$t(
                                                                                                        "classStartLabel"
                                                                                                      ) +
                                                                                                      "*",
                                                                                                    value:
                                                                                                      "CS"
                                                                                                  }
                                                                                                }
                                                                                              ),
                                                                                              _c(
                                                                                                "v-radio",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    id:
                                                                                                      "otherDateRadio",
                                                                                                    value:
                                                                                                      "O"
                                                                                                  },
                                                                                                  scopedSlots: _vm._u(
                                                                                                    [
                                                                                                      {
                                                                                                        key:
                                                                                                          "label",
                                                                                                        fn: function() {
                                                                                                          return [
                                                                                                            _c(
                                                                                                              "pb-date-field",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  hideDetails:
                                                                                                                    "auto",
                                                                                                                  label:
                                                                                                                    _vm.$t(
                                                                                                                      "otherDateLabel"
                                                                                                                    ) +
                                                                                                                    "*",
                                                                                                                  value:
                                                                                                                    _vm.localClassScheduleNewStart,
                                                                                                                  errorMessage:
                                                                                                                    _vm.localClassScheduleNewStartErrorMessage,
                                                                                                                  rules:
                                                                                                                    _vm.localEditScheduleOption ===
                                                                                                                    "O"
                                                                                                                      ? _vm.localRules
                                                                                                                      : []
                                                                                                                },
                                                                                                                on: {
                                                                                                                  "update:value": function(
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    _vm.localClassScheduleNewStart = $event
                                                                                                                  },
                                                                                                                  change: function(
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    _vm.localEditScheduleOption =
                                                                                                                      "O"
                                                                                                                  }
                                                                                                                }
                                                                                                              }
                                                                                                            )
                                                                                                          ]
                                                                                                        },
                                                                                                        proxy: true
                                                                                                      }
                                                                                                    ],
                                                                                                    null,
                                                                                                    false,
                                                                                                    1499848001
                                                                                                  )
                                                                                                }
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .xs
                                                                                        ? _c(
                                                                                            "v-list-item",
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  ref:
                                                                                                    "continueButton",
                                                                                                  attrs: {
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "info"
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      $event.stopPropagation()
                                                                                                      $event.preventDefault()
                                                                                                      return _vm.editSchedule(
                                                                                                        1,
                                                                                                        true
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "copyCurrentScheduleLabel"
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .xs
                                                                                        ? _c(
                                                                                            "v-list-item",
                                                                                            [
                                                                                              _c(
                                                                                                "v-btn",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    small:
                                                                                                      "",
                                                                                                    color:
                                                                                                      "error"
                                                                                                  },
                                                                                                  on: {
                                                                                                    click: function(
                                                                                                      $event
                                                                                                    ) {
                                                                                                      $event.stopPropagation()
                                                                                                      $event.preventDefault()
                                                                                                      return _vm.editSchedule(
                                                                                                        1,
                                                                                                        false
                                                                                                      )
                                                                                                    }
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm.$t(
                                                                                                        "fromScratchLabel"
                                                                                                      )
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      !_vm
                                                                                        .$vuetify
                                                                                        .breakpoint
                                                                                        .xs
                                                                                        ? _c(
                                                                                            "v-list-item",
                                                                                            [
                                                                                              _c(
                                                                                                "v-list-item-icon",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "ml-12 mr-0"
                                                                                                },
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      ref:
                                                                                                        "continueButton",
                                                                                                      attrs: {
                                                                                                        small:
                                                                                                          "",
                                                                                                        color:
                                                                                                          "info"
                                                                                                      },
                                                                                                      on: {
                                                                                                        click: function(
                                                                                                          $event
                                                                                                        ) {
                                                                                                          $event.stopPropagation()
                                                                                                          $event.preventDefault()
                                                                                                          return _vm.editSchedule(
                                                                                                            1,
                                                                                                            true
                                                                                                          )
                                                                                                        }
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "copyCurrentScheduleLabel"
                                                                                                          )
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              ),
                                                                                              _c(
                                                                                                "v-list-item-action",
                                                                                                [
                                                                                                  _c(
                                                                                                    "v-btn",
                                                                                                    {
                                                                                                      attrs: {
                                                                                                        small:
                                                                                                          "",
                                                                                                        color:
                                                                                                          "error"
                                                                                                      },
                                                                                                      on: {
                                                                                                        click: function(
                                                                                                          $event
                                                                                                        ) {
                                                                                                          $event.stopPropagation()
                                                                                                          $event.preventDefault()
                                                                                                          return _vm.editSchedule(
                                                                                                            1,
                                                                                                            false
                                                                                                          )
                                                                                                        }
                                                                                                      }
                                                                                                    },
                                                                                                    [
                                                                                                      _vm._v(
                                                                                                        _vm._s(
                                                                                                          _vm.$t(
                                                                                                            "fromScratchLabel"
                                                                                                          )
                                                                                                        )
                                                                                                      )
                                                                                                    ]
                                                                                                  )
                                                                                                ],
                                                                                                1
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        : _vm._e(),
                                                                                      _c(
                                                                                        "v-list-item",
                                                                                        [
                                                                                          _c(
                                                                                            "v-list-item-content",
                                                                                            [
                                                                                              _c(
                                                                                                "v-list-item-subtitle",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "list-item-subtitle-wrap-text"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    "*" +
                                                                                                      _vm._s(
                                                                                                        _vm.$t(
                                                                                                          "startNewScheduleOnPastDateMsg"
                                                                                                        )
                                                                                                      )
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e()
                                                                ],
                                                                1
                                                              ),
                                                              _vm._l(
                                                                _vm.localSelectedClassScheduleArrayClone,
                                                                function(
                                                                  schedItem,
                                                                  i
                                                                ) {
                                                                  return _c(
                                                                    "v-item",
                                                                    {
                                                                      key:
                                                                        "scheduleSelector-" +
                                                                        schedItem.scheduleId +
                                                                        _vm.localRefreshKey +
                                                                        i,
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "default",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var active =
                                                                                ref.active
                                                                              var toggle =
                                                                                ref.toggle
                                                                              return [
                                                                                _c(
                                                                                  "v-col",
                                                                                  [
                                                                                    _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs: {
                                                                                          "input-value": active,
                                                                                          text:
                                                                                            "",
                                                                                          small:
                                                                                            "",
                                                                                          rounded:
                                                                                            ""
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            $event.stopPropagation()
                                                                                            $event.preventDefault()
                                                                                            return function() {
                                                                                              toggle()
                                                                                              _vm.localClassScheduleFrequencySelected =
                                                                                                schedItem.frequency
                                                                                            }.apply(
                                                                                              null,
                                                                                              arguments
                                                                                            )
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.getScheduleStartDisplayDateNoYear(
                                                                                                  schedItem.scheduleStart
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        ),
                                                                                        _c(
                                                                                          "v-btn",
                                                                                          {
                                                                                            directives: [
                                                                                              {
                                                                                                name:
                                                                                                  "show",
                                                                                                rawName:
                                                                                                  "v-show",
                                                                                                value:
                                                                                                  !_vm.isNewClass &&
                                                                                                  _vm.localIsEditingSchedule &&
                                                                                                  _vm.canDeleteSchedule(
                                                                                                    schedItem
                                                                                                  ) &&
                                                                                                  _vm.localClassScheduleArraySelected ===
                                                                                                    i,
                                                                                                expression:
                                                                                                  "!isNewClass && localIsEditingSchedule && canDeleteSchedule(schedItem) && localClassScheduleArraySelected === i"
                                                                                              }
                                                                                            ],
                                                                                            staticClass:
                                                                                              "ml-5 border-padding",
                                                                                            attrs: {
                                                                                              icon:
                                                                                                "",
                                                                                              "x-small":
                                                                                                "",
                                                                                              "aria-label": _vm.$t(
                                                                                                "deleteScheduleLabel"
                                                                                              )
                                                                                            },
                                                                                            on: {
                                                                                              click: function(
                                                                                                $event
                                                                                              ) {
                                                                                                $event.stopPropagation()
                                                                                                return _vm.deleteSchedule(
                                                                                                  i
                                                                                                )
                                                                                              }
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-icon",
                                                                                              [
                                                                                                _vm._v(
                                                                                                  "fal fa-trash-alt"
                                                                                                )
                                                                                              ]
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        true
                                                                      )
                                                                    }
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            2
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "v-card-text",
                                            {
                                              class: _vm.$vuetify.breakpoint.xs
                                                ? "pa-0"
                                                : ""
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "title-on-border"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("scheduleLabel")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c(
                                                "v-row",
                                                {
                                                  staticClass: "mt-1",
                                                  attrs: { align: "center" }
                                                },
                                                [
                                                  (_vm
                                                    .localSelectedClassScheduleArrayClone
                                                    .length > 1 ||
                                                    (!_vm.isNewClass &&
                                                      _vm.localIsEditingSchedule)) &&
                                                  !_vm.$vuetify.breakpoint.xs
                                                    ? _c(
                                                        "v-item-group",
                                                        {
                                                          staticClass:
                                                            "shrink mr-6 text-left",
                                                          attrs: {
                                                            mandatory: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.localClassScheduleArraySelected,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.localClassScheduleArraySelected = $$v
                                                            },
                                                            expression:
                                                              "localClassScheduleArraySelected"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            [
                                                              _vm.localIsEditingSchedule
                                                                ? _c(
                                                                    "v-menu",
                                                                    {
                                                                      attrs: {
                                                                        "close-on-content-click": false,
                                                                        transition:
                                                                          "scale-transition",
                                                                        "min-width":
                                                                          "auto",
                                                                        "max-width":
                                                                          "450",
                                                                        origin:
                                                                          "auto",
                                                                        "offset-overflow":
                                                                          "",
                                                                        "offset-y":
                                                                          "",
                                                                        left: ""
                                                                      },
                                                                      scopedSlots: _vm._u(
                                                                        [
                                                                          {
                                                                            key:
                                                                              "activator",
                                                                            fn: function(
                                                                              ref
                                                                            ) {
                                                                              var on =
                                                                                ref.on
                                                                              var attrs =
                                                                                ref.attrs
                                                                              return [
                                                                                _c(
                                                                                  "pb-btn",
                                                                                  _vm._g(
                                                                                    _vm._b(
                                                                                      {
                                                                                        staticClass:
                                                                                          "ml-4",
                                                                                        attrs: {
                                                                                          icon:
                                                                                            "",
                                                                                          small:
                                                                                            "",
                                                                                          label: _vm.$t(
                                                                                            "createNewScheduleLabel"
                                                                                          )
                                                                                        }
                                                                                      },
                                                                                      "pb-btn",
                                                                                      attrs,
                                                                                      false
                                                                                    ),
                                                                                    on
                                                                                  ),
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "fal fa-circle-plus"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ]
                                                                            }
                                                                          }
                                                                        ],
                                                                        null,
                                                                        false,
                                                                        2428705287
                                                                      ),
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .localClassEditScheduleMenu[1],
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.localClassEditScheduleMenu,
                                                                            1,
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "localClassEditScheduleMenu[1]"
                                                                      }
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-card",
                                                                        [
                                                                          _c(
                                                                            "v-toolbar",
                                                                            {
                                                                              attrs: {
                                                                                elevation:
                                                                                  "0"
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-toolbar-title",
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm.$t(
                                                                                        "editScheduleLabel"
                                                                                      )
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _c(
                                                                                "v-spacer"
                                                                              ),
                                                                              _c(
                                                                                "pb-btn",
                                                                                {
                                                                                  staticClass:
                                                                                    "mr-1",
                                                                                  attrs: {
                                                                                    small:
                                                                                      "",
                                                                                    icon:
                                                                                      "",
                                                                                    depressed:
                                                                                      "",
                                                                                    label: _vm.$t(
                                                                                      "closeLabel"
                                                                                    )
                                                                                  },
                                                                                  on: {
                                                                                    click: function(
                                                                                      $event
                                                                                    ) {
                                                                                      $event.stopPropagation()
                                                                                      $event.preventDefault()
                                                                                      return function() {
                                                                                        _vm.localClassEditScheduleMenu = [
                                                                                          false,
                                                                                          false
                                                                                        ]
                                                                                      }.apply(
                                                                                        null,
                                                                                        arguments
                                                                                      )
                                                                                    }
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "v-icon",
                                                                                    [
                                                                                      _vm._v(
                                                                                        "fal fa-xmark"
                                                                                      )
                                                                                    ]
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          ),
                                                                          _c(
                                                                            "v-divider"
                                                                          ),
                                                                          _c(
                                                                            "v-form",
                                                                            {
                                                                              ref:
                                                                                "editScheduleMenuForm1",
                                                                              attrs: {
                                                                                "lazy-validation":
                                                                                  ""
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "v-list",
                                                                                [
                                                                                  _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-radio-group",
                                                                                        {
                                                                                          attrs: {
                                                                                            column:
                                                                                              "",
                                                                                            mandatory:
                                                                                              ""
                                                                                          },
                                                                                          scopedSlots: _vm._u(
                                                                                            [
                                                                                              {
                                                                                                key:
                                                                                                  "label",
                                                                                                fn: function() {
                                                                                                  return [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "mb-8"
                                                                                                      },
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            _vm.$t(
                                                                                                              "whenScheduleStartLabel"
                                                                                                            )
                                                                                                          )
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ]
                                                                                                },
                                                                                                proxy: true
                                                                                              }
                                                                                            ],
                                                                                            null,
                                                                                            false,
                                                                                            2191655040
                                                                                          ),
                                                                                          model: {
                                                                                            value:
                                                                                              _vm.localEditScheduleOption,
                                                                                            callback: function(
                                                                                              $$v
                                                                                            ) {
                                                                                              _vm.localEditScheduleOption = $$v
                                                                                            },
                                                                                            expression:
                                                                                              "localEditScheduleOption"
                                                                                          }
                                                                                        },
                                                                                        [
                                                                                          !_vm.mCurrentDate.isBefore(
                                                                                            _vm.mCurrentYearFirstDay
                                                                                          )
                                                                                            ? _c(
                                                                                                "v-radio",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    label: _vm.$t(
                                                                                                      "todayLabel"
                                                                                                    ),
                                                                                                    value:
                                                                                                      "T"
                                                                                                  }
                                                                                                }
                                                                                              )
                                                                                            : _vm._e(),
                                                                                          _c(
                                                                                            "v-radio",
                                                                                            {
                                                                                              staticClass:
                                                                                                "pt-4 pb-1",
                                                                                              attrs: {
                                                                                                label:
                                                                                                  _vm.$t(
                                                                                                    "classStartLabel"
                                                                                                  ) +
                                                                                                  "*",
                                                                                                value:
                                                                                                  "CS"
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                          _c(
                                                                                            "v-radio",
                                                                                            {
                                                                                              attrs: {
                                                                                                id:
                                                                                                  "otherDateRadio",
                                                                                                value:
                                                                                                  "O"
                                                                                              },
                                                                                              scopedSlots: _vm._u(
                                                                                                [
                                                                                                  {
                                                                                                    key:
                                                                                                      "label",
                                                                                                    fn: function() {
                                                                                                      return [
                                                                                                        _c(
                                                                                                          "pb-date-field",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              hideDetails:
                                                                                                                "auto",
                                                                                                              label:
                                                                                                                _vm.$t(
                                                                                                                  "otherDateLabel"
                                                                                                                ) +
                                                                                                                "*",
                                                                                                              value:
                                                                                                                _vm.localClassScheduleNewStart,
                                                                                                              errorMessage:
                                                                                                                _vm.localClassScheduleNewStartErrorMessage,
                                                                                                              rules:
                                                                                                                _vm.localEditScheduleOption ===
                                                                                                                "O"
                                                                                                                  ? _vm.localRules
                                                                                                                  : []
                                                                                                            },
                                                                                                            on: {
                                                                                                              "update:value": function(
                                                                                                                $event
                                                                                                              ) {
                                                                                                                _vm.localClassScheduleNewStart = $event
                                                                                                              },
                                                                                                              change: function(
                                                                                                                $event
                                                                                                              ) {
                                                                                                                _vm.localEditScheduleOption =
                                                                                                                  "O"
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        )
                                                                                                      ]
                                                                                                    },
                                                                                                    proxy: true
                                                                                                  }
                                                                                                ],
                                                                                                null,
                                                                                                false,
                                                                                                1499848001
                                                                                              )
                                                                                            }
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-icon",
                                                                                        {
                                                                                          staticClass:
                                                                                            "ml-12 mr-0"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              attrs: {
                                                                                                small:
                                                                                                  "",
                                                                                                color:
                                                                                                  "info"
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.stopPropagation()
                                                                                                  $event.preventDefault()
                                                                                                  return _vm.editSchedule(
                                                                                                    1,
                                                                                                    true
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  !_vm.mCurrentDate.isBefore(
                                                                                                    _vm.mCurrentYearFirstDay
                                                                                                  )
                                                                                                    ? _vm.$t(
                                                                                                        "copyCurrentScheduleLabel"
                                                                                                      )
                                                                                                    : _vm.$t(
                                                                                                        "copyInitialScheduleLabel"
                                                                                                      )
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      ),
                                                                                      _c(
                                                                                        "v-list-item-action",
                                                                                        [
                                                                                          _c(
                                                                                            "v-btn",
                                                                                            {
                                                                                              attrs: {
                                                                                                small:
                                                                                                  "",
                                                                                                color:
                                                                                                  "error"
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  $event.stopPropagation()
                                                                                                  $event.preventDefault()
                                                                                                  return _vm.editSchedule(
                                                                                                    1,
                                                                                                    false
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                _vm._s(
                                                                                                  _vm.$t(
                                                                                                    "fromScratchLabel"
                                                                                                  )
                                                                                                )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  ),
                                                                                  _c(
                                                                                    "v-list-item",
                                                                                    [
                                                                                      _c(
                                                                                        "v-list-item-content",
                                                                                        [
                                                                                          _c(
                                                                                            "v-list-item-subtitle",
                                                                                            {
                                                                                              staticClass:
                                                                                                "list-item-subtitle-wrap-text"
                                                                                            },
                                                                                            [
                                                                                              _vm._v(
                                                                                                "*" +
                                                                                                  _vm._s(
                                                                                                    _vm.$t(
                                                                                                      "startNewScheduleOnPastDateMsg"
                                                                                                    )
                                                                                                  )
                                                                                              )
                                                                                            ]
                                                                                          )
                                                                                        ],
                                                                                        1
                                                                                      )
                                                                                    ],
                                                                                    1
                                                                                  )
                                                                                ],
                                                                                1
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ],
                                                                        1
                                                                      )
                                                                    ],
                                                                    1
                                                                  )
                                                                : _vm._e()
                                                            ],
                                                            1
                                                          ),
                                                          _vm._l(
                                                            _vm.localSelectedClassScheduleArrayClone,
                                                            function(
                                                              schedItem,
                                                              i
                                                            ) {
                                                              return _c(
                                                                "v-item",
                                                                {
                                                                  key:
                                                                    "scheduleSelector-" +
                                                                    schedItem.scheduleId +
                                                                    _vm.localRefreshKey +
                                                                    i,
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var active =
                                                                            ref.active
                                                                          var toggle =
                                                                            ref.toggle
                                                                          return [
                                                                            _c(
                                                                              "div",
                                                                              [
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    attrs: {
                                                                                      "input-value": active,
                                                                                      text:
                                                                                        "",
                                                                                      small:
                                                                                        "",
                                                                                      rounded:
                                                                                        ""
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                        return function() {
                                                                                          toggle()
                                                                                          _vm.localClassScheduleFrequencySelected =
                                                                                            schedItem.frequency
                                                                                        }.apply(
                                                                                          null,
                                                                                          arguments
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "span",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            _vm.getScheduleStartDisplayDateNoYear(
                                                                                              schedItem.scheduleStart
                                                                                            )
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "v-btn",
                                                                                  {
                                                                                    directives: [
                                                                                      {
                                                                                        name:
                                                                                          "show",
                                                                                        rawName:
                                                                                          "v-show",
                                                                                        value:
                                                                                          !_vm.isNewClass &&
                                                                                          _vm.localIsEditingSchedule &&
                                                                                          _vm.canDeleteSchedule(
                                                                                            schedItem
                                                                                          ),
                                                                                        expression:
                                                                                          "!isNewClass && localIsEditingSchedule && canDeleteSchedule(schedItem)"
                                                                                      }
                                                                                    ],
                                                                                    staticClass:
                                                                                      "border-padding",
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "",
                                                                                      "x-small":
                                                                                        "",
                                                                                      "aria-label": _vm.$t(
                                                                                        "deleteScheduleLabel"
                                                                                      )
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        $event.stopPropagation()
                                                                                        return _vm.deleteSchedule(
                                                                                          i
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-icon",
                                                                                      [
                                                                                        _vm._v(
                                                                                          "fal fa-trash-alt"
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              ],
                                                                              1
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    true
                                                                  )
                                                                }
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    : _vm._e(),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _c(
                                                        "v-window",
                                                        {
                                                          attrs: {
                                                            vertical: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.localClassScheduleArraySelected,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.localClassScheduleArraySelected = $$v
                                                            },
                                                            expression:
                                                              "localClassScheduleArraySelected"
                                                          }
                                                        },
                                                        _vm._l(
                                                          _vm.localSelectedClassScheduleArrayClone,
                                                          function(
                                                            scheduleItem,
                                                            i
                                                          ) {
                                                            return _c(
                                                              "v-window-item",
                                                              {
                                                                key:
                                                                  "scheduleWindow-" +
                                                                  scheduleItem.scheduleId +
                                                                  _vm.localRefreshKey +
                                                                  i
                                                              },
                                                              [
                                                                _c(
                                                                  "v-card",
                                                                  {
                                                                    attrs: {
                                                                      flat: ""
                                                                    }
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "v-card-title",
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            attrs: {
                                                                              align:
                                                                                "center"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "strong",
                                                                              {
                                                                                staticClass:
                                                                                  "text-h6"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    _vm.$t(
                                                                                      "scheduleAsOfLabel"
                                                                                    )
                                                                                  ) +
                                                                                    " " +
                                                                                    _vm._s(
                                                                                      _vm.isNewClass
                                                                                        ? _vm.getDisplayDate(
                                                                                            _vm
                                                                                              .localSelectedClassClone
                                                                                              .classStartDate
                                                                                          )
                                                                                        : _vm.getDisplayDate(
                                                                                            scheduleItem.scheduleStart
                                                                                          )
                                                                                    )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    ),
                                                                    _c(
                                                                      "v-card-text",
                                                                      {
                                                                        class: _vm
                                                                          .$vuetify
                                                                          .breakpoint
                                                                          .xs
                                                                          ? "px-2"
                                                                          : ""
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "v-row",
                                                                          {
                                                                            staticClass:
                                                                              "mb-3",
                                                                            attrs: {
                                                                              justify: _vm
                                                                                .$vuetify
                                                                                .breakpoint
                                                                                .xs
                                                                                ? "start"
                                                                                : "end"
                                                                            }
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-col",
                                                                              {
                                                                                attrs: {
                                                                                  cols: _vm
                                                                                    .$vuetify
                                                                                    .breakpoint
                                                                                    .smAndDown
                                                                                    ? 4
                                                                                    : 3
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "div",
                                                                                  [
                                                                                    !_vm.isNewClass &&
                                                                                    !_vm.localIsEditingSchedule
                                                                                      ? _c(
                                                                                          "v-menu",
                                                                                          {
                                                                                            attrs: {
                                                                                              "close-on-content-click": false,
                                                                                              transition:
                                                                                                "scale-transition",
                                                                                              "min-width":
                                                                                                "auto",
                                                                                              "max-width":
                                                                                                "450",
                                                                                              origin:
                                                                                                "auto",
                                                                                              "z-index":
                                                                                                "999",
                                                                                              "offset-overflow":
                                                                                                "",
                                                                                              "offset-y":
                                                                                                "",
                                                                                              left:
                                                                                                ""
                                                                                            },
                                                                                            scopedSlots: _vm._u(
                                                                                              [
                                                                                                {
                                                                                                  key:
                                                                                                    "activator",
                                                                                                  fn: function(
                                                                                                    ref
                                                                                                  ) {
                                                                                                    var onMenu =
                                                                                                      ref.on
                                                                                                    return [
                                                                                                      _c(
                                                                                                        "pb-btn",
                                                                                                        _vm._g(
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              color:
                                                                                                                "primary",
                                                                                                              text:
                                                                                                                ""
                                                                                                            }
                                                                                                          },
                                                                                                          Object.assign(
                                                                                                            {},
                                                                                                            onMenu
                                                                                                          )
                                                                                                        ),
                                                                                                        [
                                                                                                          _c(
                                                                                                            "span",
                                                                                                            {
                                                                                                              staticClass:
                                                                                                                "ml-1"
                                                                                                            },
                                                                                                            [
                                                                                                              _vm._v(
                                                                                                                _vm._s(
                                                                                                                  _vm.$t(
                                                                                                                    "editScheduleLabel"
                                                                                                                  )
                                                                                                                )
                                                                                                              )
                                                                                                            ]
                                                                                                          )
                                                                                                        ]
                                                                                                      )
                                                                                                    ]
                                                                                                  }
                                                                                                }
                                                                                              ],
                                                                                              null,
                                                                                              true
                                                                                            ),
                                                                                            model: {
                                                                                              value:
                                                                                                _vm
                                                                                                  .localClassEditScheduleMenu[
                                                                                                  i
                                                                                                ],
                                                                                              callback: function(
                                                                                                $$v
                                                                                              ) {
                                                                                                _vm.$set(
                                                                                                  _vm.localClassEditScheduleMenu,
                                                                                                  i,
                                                                                                  $$v
                                                                                                )
                                                                                              },
                                                                                              expression:
                                                                                                "localClassEditScheduleMenu[i]"
                                                                                            }
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "v-card",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-toolbar",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      elevation:
                                                                                                        "0"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-toolbar-title",
                                                                                                      [
                                                                                                        _vm._v(
                                                                                                          _vm._s(
                                                                                                            _vm.$t(
                                                                                                              "editScheduleLabel"
                                                                                                            )
                                                                                                          )
                                                                                                        )
                                                                                                      ]
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "v-spacer"
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "pb-btn",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "mr-1",
                                                                                                        attrs: {
                                                                                                          small:
                                                                                                            "",
                                                                                                          icon:
                                                                                                            "",
                                                                                                          depressed:
                                                                                                            "",
                                                                                                          label: _vm.$t(
                                                                                                            "closeLabel"
                                                                                                          )
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.stopPropagation()
                                                                                                            $event.preventDefault()
                                                                                                            return _vm.undoEditSchedule.apply(
                                                                                                              null,
                                                                                                              arguments
                                                                                                            )
                                                                                                          }
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "fal fa-xmark"
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-divider"
                                                                                                ),
                                                                                                _c(
                                                                                                  "v-form",
                                                                                                  {
                                                                                                    ref:
                                                                                                      "editScheduleMenuForm0",
                                                                                                    refInFor: true,
                                                                                                    attrs: {
                                                                                                      "lazy-validation":
                                                                                                        ""
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-list",
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-list-item",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-radio-group",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  column:
                                                                                                                    "",
                                                                                                                  mandatory:
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "label",
                                                                                                                      fn: function() {
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "div",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "mb-8"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  _vm.$t(
                                                                                                                                    "whenScheduleStartLabel"
                                                                                                                                  )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      },
                                                                                                                      proxy: true
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                ),
                                                                                                                model: {
                                                                                                                  value:
                                                                                                                    _vm.localEditScheduleOption,
                                                                                                                  callback: function(
                                                                                                                    $$v
                                                                                                                  ) {
                                                                                                                    _vm.localEditScheduleOption = $$v
                                                                                                                  },
                                                                                                                  expression:
                                                                                                                    "localEditScheduleOption"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                !_vm.mCurrentDate.isBefore(
                                                                                                                  _vm.mCurrentYearFirstDay
                                                                                                                )
                                                                                                                  ? _c(
                                                                                                                      "v-radio",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          label: _vm.$t(
                                                                                                                            "todayLabel"
                                                                                                                          ),
                                                                                                                          value:
                                                                                                                            "T"
                                                                                                                        }
                                                                                                                      }
                                                                                                                    )
                                                                                                                  : _vm._e(),
                                                                                                                _c(
                                                                                                                  "v-radio",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pt-4 pb-1",
                                                                                                                    attrs: {
                                                                                                                      label:
                                                                                                                        _vm.$t(
                                                                                                                          "classStartLabel"
                                                                                                                        ) +
                                                                                                                        "*",
                                                                                                                      value:
                                                                                                                        "CS"
                                                                                                                    }
                                                                                                                  }
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-radio",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      id:
                                                                                                                        "otherDateRadio",
                                                                                                                      value:
                                                                                                                        "O"
                                                                                                                    },
                                                                                                                    scopedSlots: _vm._u(
                                                                                                                      [
                                                                                                                        {
                                                                                                                          key:
                                                                                                                            "label",
                                                                                                                          fn: function() {
                                                                                                                            return [
                                                                                                                              _c(
                                                                                                                                "pb-date-field",
                                                                                                                                {
                                                                                                                                  attrs: {
                                                                                                                                    hideDetails:
                                                                                                                                      "auto",
                                                                                                                                    label:
                                                                                                                                      _vm.$t(
                                                                                                                                        "otherDateLabel"
                                                                                                                                      ) +
                                                                                                                                      "*",
                                                                                                                                    value:
                                                                                                                                      _vm.localClassScheduleNewStart,
                                                                                                                                    errorMessage:
                                                                                                                                      _vm.localClassScheduleNewStartErrorMessage,
                                                                                                                                    rules:
                                                                                                                                      _vm.localEditScheduleOption ===
                                                                                                                                      "O"
                                                                                                                                        ? _vm.localRules
                                                                                                                                        : []
                                                                                                                                  },
                                                                                                                                  on: {
                                                                                                                                    "update:value": function(
                                                                                                                                      $event
                                                                                                                                    ) {
                                                                                                                                      _vm.localClassScheduleNewStart = $event
                                                                                                                                    },
                                                                                                                                    change: function(
                                                                                                                                      $event
                                                                                                                                    ) {
                                                                                                                                      _vm.localEditScheduleOption =
                                                                                                                                        "O"
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          },
                                                                                                                          proxy: true
                                                                                                                        }
                                                                                                                      ],
                                                                                                                      null,
                                                                                                                      true
                                                                                                                    )
                                                                                                                  }
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _vm
                                                                                                          .$vuetify
                                                                                                          .breakpoint
                                                                                                          .xs
                                                                                                          ? _c(
                                                                                                              "v-list-item",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-btn",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      small:
                                                                                                                        "",
                                                                                                                      color:
                                                                                                                        "info"
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                        $event.preventDefault()
                                                                                                                        return _vm.editSchedule(
                                                                                                                          0,
                                                                                                                          true
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        !_vm.mCurrentDate.isBefore(
                                                                                                                          _vm.mCurrentYearFirstDay
                                                                                                                        )
                                                                                                                          ? _vm.$t(
                                                                                                                              "copyCurrentScheduleLabel"
                                                                                                                            )
                                                                                                                          : _vm.$t(
                                                                                                                              "copyInitialScheduleLabel"
                                                                                                                            )
                                                                                                                      )
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        _vm
                                                                                                          .$vuetify
                                                                                                          .breakpoint
                                                                                                          .xs
                                                                                                          ? _c(
                                                                                                              "v-list-item",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-btn",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      small:
                                                                                                                        "",
                                                                                                                      color:
                                                                                                                        "error"
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                        $event.preventDefault()
                                                                                                                        return _vm.editSchedule(
                                                                                                                          0,
                                                                                                                          false
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "fromScratchLabel"
                                                                                                                        )
                                                                                                                      )
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        _vm
                                                                                                          .$vuetify
                                                                                                          .breakpoint
                                                                                                          .xs
                                                                                                          ? _c(
                                                                                                              "v-list-item",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "pb-btn",
                                                                                                                  {
                                                                                                                    ref:
                                                                                                                      "continueButton",
                                                                                                                    refInFor: true,
                                                                                                                    attrs: {
                                                                                                                      small:
                                                                                                                        "",
                                                                                                                      text:
                                                                                                                        "",
                                                                                                                      color:
                                                                                                                        "primary",
                                                                                                                      label: _vm.$t(
                                                                                                                        "editCurrentScheduleLabel"
                                                                                                                      )
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                        $event.preventDefault()
                                                                                                                        return function() {
                                                                                                                          _vm.localClassScheduleNewStart =
                                                                                                                            ""
                                                                                                                          _vm.localClassScheduleNewStartErrorMessage =
                                                                                                                            ""
                                                                                                                          _vm.localIsEditingSchedule = true
                                                                                                                          _vm.localClassEditScheduleMenu[0] = true
                                                                                                                        }.apply(
                                                                                                                          null,
                                                                                                                          arguments
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "skipLabel"
                                                                                                                        )
                                                                                                                      )
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        !_vm
                                                                                                          .$vuetify
                                                                                                          .breakpoint
                                                                                                          .xs
                                                                                                          ? _c(
                                                                                                              "v-list-item",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-list-item-icon",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "ml-8 mr-4 mr-0"
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-btn",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          small:
                                                                                                                            "",
                                                                                                                          color:
                                                                                                                            "info"
                                                                                                                        },
                                                                                                                        on: {
                                                                                                                          click: function(
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            $event.preventDefault()
                                                                                                                            return _vm.editSchedule(
                                                                                                                              0,
                                                                                                                              true
                                                                                                                            )
                                                                                                                          }
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            !_vm.mCurrentDate.isBefore(
                                                                                                                              _vm.mCurrentYearFirstDay
                                                                                                                            )
                                                                                                                              ? _vm.$t(
                                                                                                                                  "copyCurrentScheduleLabel"
                                                                                                                                )
                                                                                                                              : _vm.$t(
                                                                                                                                  "copyInitialScheduleLabel"
                                                                                                                                )
                                                                                                                          )
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-btn",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      small:
                                                                                                                        "",
                                                                                                                      color:
                                                                                                                        "error"
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                        $event.preventDefault()
                                                                                                                        return _vm.editSchedule(
                                                                                                                          0,
                                                                                                                          false
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "fromScratchLabel"
                                                                                                                        )
                                                                                                                      )
                                                                                                                    )
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-list-item-action",
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "pb-btn",
                                                                                                                      {
                                                                                                                        ref:
                                                                                                                          "continueButton",
                                                                                                                        refInFor: true,
                                                                                                                        attrs: {
                                                                                                                          small:
                                                                                                                            "",
                                                                                                                          text:
                                                                                                                            "",
                                                                                                                          color:
                                                                                                                            "primary",
                                                                                                                          label: _vm.$t(
                                                                                                                            "editCurrentScheduleLabel"
                                                                                                                          )
                                                                                                                        },
                                                                                                                        on: {
                                                                                                                          click: function(
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            $event.preventDefault()
                                                                                                                            return function() {
                                                                                                                              _vm.localClassScheduleNewStart =
                                                                                                                                ""
                                                                                                                              _vm.localClassScheduleNewStartErrorMessage =
                                                                                                                                ""
                                                                                                                              _vm.localIsEditingSchedule = true
                                                                                                                              _vm.localClassEditScheduleMenu[0] = true
                                                                                                                            }.apply(
                                                                                                                              null,
                                                                                                                              arguments
                                                                                                                            )
                                                                                                                          }
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            _vm.$t(
                                                                                                                              "skipLabel"
                                                                                                                            )
                                                                                                                          )
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        _c(
                                                                                                          "v-list-item",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-list-item-content",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-list-item-subtitle",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "list-item-subtitle-wrap-text"
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "*" +
                                                                                                                        _vm._s(
                                                                                                                          _vm.$t(
                                                                                                                            "startNewScheduleOnPastDateMsg"
                                                                                                                          )
                                                                                                                        )
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ],
                                                                                  1
                                                                                ),
                                                                                !_vm.isNewClass &&
                                                                                _vm.localIsEditingSchedule
                                                                                  ? _c(
                                                                                      "v-btn",
                                                                                      {
                                                                                        attrs: {
                                                                                          color:
                                                                                            "primary",
                                                                                          text:
                                                                                            ""
                                                                                        },
                                                                                        on: {
                                                                                          click: function(
                                                                                            $event
                                                                                          ) {
                                                                                            return _vm.undoEditSchedule()
                                                                                          }
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "span",
                                                                                          {
                                                                                            staticClass:
                                                                                              "mr-1"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                _vm.$t(
                                                                                                  "undoEditSchedule"
                                                                                                )
                                                                                              )
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  : _vm._e()
                                                                              ],
                                                                              1
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm.isNewClass ||
                                                                        scheduleItem.isNewSchedule
                                                                          ? _c(
                                                                              "v-select",
                                                                              {
                                                                                attrs: {
                                                                                  items:
                                                                                    _vm.localClassScheduleFrequency,
                                                                                  label: _vm.$t(
                                                                                    "frequencyLabel"
                                                                                  ),
                                                                                  "hide-details":
                                                                                    "auto",
                                                                                  dense:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  rules:
                                                                                    _vm.localRules
                                                                                },
                                                                                on: {
                                                                                  change: function(
                                                                                    $event
                                                                                  ) {
                                                                                    scheduleItem.frequency =
                                                                                      _vm.localClassScheduleFrequencySelected
                                                                                  }
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm.localClassScheduleFrequencySelected,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.localClassScheduleFrequencySelected = $$v
                                                                                  },
                                                                                  expression:
                                                                                    "localClassScheduleFrequencySelected"
                                                                                }
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            directives: [
                                                                              {
                                                                                name:
                                                                                  "show",
                                                                                rawName:
                                                                                  "v-show",
                                                                                value:
                                                                                  (_vm.isNewClass ||
                                                                                    scheduleItem.isNewSchedule) &&
                                                                                  _vm.localClassScheduleFrequencySelected ===
                                                                                    "DS",
                                                                                expression:
                                                                                  "(isNewClass || scheduleItem.isNewSchedule) && localClassScheduleFrequencySelected === 'DS'"
                                                                              }
                                                                            ],
                                                                            staticClass:
                                                                              "mt-3"
                                                                          },
                                                                          [
                                                                            _vm
                                                                              .$vuetify
                                                                              .breakpoint
                                                                              .xs
                                                                              ? _c(
                                                                                  "v-row",
                                                                                  {
                                                                                    attrs: {
                                                                                      justify:
                                                                                        "center"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "pa-0 my-2"
                                                                                          },
                                                                                          [
                                                                                            _c(
                                                                                              "pb-date-time-range-field",
                                                                                              {
                                                                                                attrs: {
                                                                                                  type:
                                                                                                    "time",
                                                                                                  start:
                                                                                                    _vm.localDailyStartTime,
                                                                                                  end:
                                                                                                    _vm.localDailyEndTime,
                                                                                                  min:
                                                                                                    _vm.startTime24HrFmt,
                                                                                                  max:
                                                                                                    _vm.endTime24HrFmt,
                                                                                                  required:
                                                                                                    _vm.localClassScheduleFrequencySelected ===
                                                                                                    "DS",
                                                                                                  "hide-details":
                                                                                                    "auto",
                                                                                                  offsetTop: true
                                                                                                },
                                                                                                on: {
                                                                                                  "update:start": function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    _vm.localDailyStartTime = $event
                                                                                                  },
                                                                                                  "update:end": function(
                                                                                                    $event
                                                                                                  ) {
                                                                                                    _vm.localDailyEndTime = $event
                                                                                                  }
                                                                                                }
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      ]
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                              : _c(
                                                                                  "v-row",
                                                                                  {
                                                                                    attrs: {
                                                                                      justify:
                                                                                        "center"
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "v-col",
                                                                                      {
                                                                                        attrs: {
                                                                                          cols:
                                                                                            "12"
                                                                                        }
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "pb-date-time-range-field",
                                                                                          {
                                                                                            attrs: {
                                                                                              type:
                                                                                                "time",
                                                                                              start:
                                                                                                _vm.localDailyStartTime,
                                                                                              end:
                                                                                                _vm.localDailyEndTime,
                                                                                              min:
                                                                                                _vm.startTime24HrFmt,
                                                                                              max:
                                                                                                _vm.endTime24HrFmt,
                                                                                              required:
                                                                                                _vm.localClassScheduleFrequencySelected ===
                                                                                                "DS",
                                                                                              "hide-details":
                                                                                                "auto",
                                                                                              offsetTop: true
                                                                                            },
                                                                                            on: {
                                                                                              "update:start": function(
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.localDailyStartTime = $event
                                                                                              },
                                                                                              "update:end": function(
                                                                                                $event
                                                                                              ) {
                                                                                                _vm.localDailyEndTime = $event
                                                                                              }
                                                                                            }
                                                                                          }
                                                                                        )
                                                                                      ],
                                                                                      1
                                                                                    )
                                                                                  ],
                                                                                  1
                                                                                )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            directives: [
                                                                              {
                                                                                name:
                                                                                  "show",
                                                                                rawName:
                                                                                  "v-show",
                                                                                value:
                                                                                  (!_vm.isNewClass &&
                                                                                    !scheduleItem.isNewSchedule) ||
                                                                                  _vm.localClassScheduleFrequencySelected ===
                                                                                    "C",
                                                                                expression:
                                                                                  "(!isNewClass && !scheduleItem.isNewSchedule) || localClassScheduleFrequencySelected === 'C'"
                                                                              }
                                                                            ],
                                                                            staticClass:
                                                                              "mt-3"
                                                                          },
                                                                          [
                                                                            _vm
                                                                              .$vuetify
                                                                              .breakpoint
                                                                              .xs
                                                                              ? _c(
                                                                                  "div",
                                                                                  [
                                                                                    _vm._l(
                                                                                      _vm.cycleDays,
                                                                                      function(
                                                                                        cycleDay,
                                                                                        p
                                                                                      ) {
                                                                                        return _c(
                                                                                          "div",
                                                                                          {
                                                                                            key:
                                                                                              "cycledays-" +
                                                                                              p +
                                                                                              _vm.localRefreshKey
                                                                                          },
                                                                                          [
                                                                                            _vm
                                                                                              .getSchoolYearSettings
                                                                                              .classCycle ===
                                                                                              "twoWeeks" &&
                                                                                            (p ==
                                                                                              0 ||
                                                                                              p ==
                                                                                                7)
                                                                                              ? _c(
                                                                                                  "v-row",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      justify:
                                                                                                        "center",
                                                                                                      align:
                                                                                                        "center"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-col",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "py-5",
                                                                                                        attrs: {
                                                                                                          cols:
                                                                                                            "auto"
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "font-weight-bold text-h6"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                p ==
                                                                                                                  0
                                                                                                                  ? _vm.$t(
                                                                                                                      "week1Label"
                                                                                                                    )
                                                                                                                  : _vm.$t(
                                                                                                                      "week2Label"
                                                                                                                    )
                                                                                                              )
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              : _vm._e(),
                                                                                            _c(
                                                                                              "v-row",
                                                                                              {
                                                                                                staticClass:
                                                                                                  "my-2",
                                                                                                attrs: {
                                                                                                  justify:
                                                                                                    "center",
                                                                                                  align:
                                                                                                    "center"
                                                                                                }
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "pa-0 my-2"
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "pb-chip",
                                                                                                      {
                                                                                                        staticStyle: {
                                                                                                          opacity:
                                                                                                            "1"
                                                                                                        },
                                                                                                        attrs: {
                                                                                                          "input-value":
                                                                                                            scheduleItem[
                                                                                                              "day" +
                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                "Teach"
                                                                                                            ],
                                                                                                          "active-class":
                                                                                                            "accent3--text font-weight-bold",
                                                                                                          disabled: !_vm.localIsEditingSchedule,
                                                                                                          filter:
                                                                                                            "",
                                                                                                          outlined:
                                                                                                            ""
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.stopPropagation()
                                                                                                            return _vm.changeScheduleItemCycleDays(
                                                                                                              scheduleItem,
                                                                                                              cycleDay
                                                                                                            )
                                                                                                          },
                                                                                                          keyup: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            if (
                                                                                                              !$event.type.indexOf(
                                                                                                                "key"
                                                                                                              ) &&
                                                                                                              _vm._k(
                                                                                                                $event.keyCode,
                                                                                                                "enter",
                                                                                                                13,
                                                                                                                $event.key,
                                                                                                                "Enter"
                                                                                                              )
                                                                                                            ) {
                                                                                                              return null
                                                                                                            }
                                                                                                            $event.stopPropagation()
                                                                                                            return _vm.changeScheduleItemCycleDays(
                                                                                                              scheduleItem,
                                                                                                              cycleDay
                                                                                                            )
                                                                                                          }
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                cycleDay.title
                                                                                                              )
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ]
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                ),
                                                                                                _c(
                                                                                                  "div",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "pa-0 my-2"
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "pb-date-time-range-field",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          type:
                                                                                                            "time",
                                                                                                          start:
                                                                                                            scheduleItem[
                                                                                                              "day" +
                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                "StartTime"
                                                                                                            ],
                                                                                                          end:
                                                                                                            scheduleItem[
                                                                                                              "day" +
                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                "EndTime"
                                                                                                            ],
                                                                                                          disabled:
                                                                                                            !scheduleItem[
                                                                                                              "day" +
                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                "Teach"
                                                                                                            ] ||
                                                                                                            !_vm.localIsEditingSchedule,
                                                                                                          min:
                                                                                                            _vm.startTime24HrFmt,
                                                                                                          max:
                                                                                                            _vm.endTime24HrFmt,
                                                                                                          "hide-details":
                                                                                                            "auto",
                                                                                                          offsetTop:
                                                                                                            _vm.isEmpty(
                                                                                                              scheduleItem.additionalClassDays
                                                                                                            ) &&
                                                                                                            p ===
                                                                                                              _vm
                                                                                                                .cycleDays
                                                                                                                .length -
                                                                                                                1
                                                                                                        },
                                                                                                        on: {
                                                                                                          "update:start": function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.$set(
                                                                                                              scheduleItem,
                                                                                                              "day" +
                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                "StartTime",
                                                                                                              $event
                                                                                                            )
                                                                                                          },
                                                                                                          "update:end": function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            return _vm.$set(
                                                                                                              scheduleItem,
                                                                                                              "day" +
                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                "EndTime",
                                                                                                              $event
                                                                                                            )
                                                                                                          }
                                                                                                        }
                                                                                                      }
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ]
                                                                                            ),
                                                                                            _vm
                                                                                              .getSchoolYearSettings
                                                                                              .classCycle ===
                                                                                              "twoWeeks" &&
                                                                                            (p ==
                                                                                              6 ||
                                                                                              p ==
                                                                                                13)
                                                                                              ? _c(
                                                                                                  "div",
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-row",
                                                                                                      {
                                                                                                        directives: [
                                                                                                          {
                                                                                                            name:
                                                                                                              "show",
                                                                                                            rawName:
                                                                                                              "v-show",
                                                                                                            value:
                                                                                                              _vm.localIsEditingSchedule,
                                                                                                            expression:
                                                                                                              "localIsEditingSchedule"
                                                                                                          }
                                                                                                        ]
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-col",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "text-center",
                                                                                                            attrs: {
                                                                                                              cols:
                                                                                                                "12"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-btn",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  color:
                                                                                                                    "primary",
                                                                                                                  text:
                                                                                                                    ""
                                                                                                                },
                                                                                                                on: {
                                                                                                                  click: function(
                                                                                                                    $event
                                                                                                                  ) {
                                                                                                                    $event.stopPropagation()
                                                                                                                    return _vm.addScheduleDay(
                                                                                                                      scheduleItem.scheduleId,
                                                                                                                      p ==
                                                                                                                        6
                                                                                                                        ? "week1"
                                                                                                                        : "week2"
                                                                                                                    )
                                                                                                                  }
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-icon",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      small:
                                                                                                                        ""
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      "fal fa-plus"
                                                                                                                    )
                                                                                                                  ]
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "ml-1"
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "addScheduleDayLabel"
                                                                                                                        )
                                                                                                                      )
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _vm._l(
                                                                                                      scheduleItem.additionalClassDays.filter(
                                                                                                        function(
                                                                                                          c
                                                                                                        ) {
                                                                                                          return p ==
                                                                                                            6
                                                                                                            ? _vm.week1DayCodes.includes(
                                                                                                                c.dayCode
                                                                                                              )
                                                                                                            : _vm.week2DayCodes.includes(
                                                                                                                c.dayCode
                                                                                                              )
                                                                                                        }
                                                                                                      ),
                                                                                                      function(
                                                                                                        acd,
                                                                                                        j
                                                                                                      ) {
                                                                                                        return _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            key:
                                                                                                              "additionalclassdays-" +
                                                                                                              j +
                                                                                                              _vm.localRefreshKey,
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "pa-0 my-2"
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-row",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      justify:
                                                                                                                        "center",
                                                                                                                      align:
                                                                                                                        "center"
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-col",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          cols:
                                                                                                                            "8"
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-select",
                                                                                                                          {
                                                                                                                            attrs: {
                                                                                                                              items:
                                                                                                                                p ==
                                                                                                                                6
                                                                                                                                  ? _vm.localDaysOfWeek.slice(
                                                                                                                                      0,
                                                                                                                                      7
                                                                                                                                    )
                                                                                                                                  : _vm.localDaysOfWeek.slice(
                                                                                                                                      7,
                                                                                                                                      14
                                                                                                                                    ),
                                                                                                                              "hide-details":
                                                                                                                                "true",
                                                                                                                              disabled: !_vm.localIsEditingSchedule,
                                                                                                                              dense:
                                                                                                                                "",
                                                                                                                              outlined:
                                                                                                                                "",
                                                                                                                              height:
                                                                                                                                "55"
                                                                                                                            },
                                                                                                                            model: {
                                                                                                                              value:
                                                                                                                                acd.dayCode,
                                                                                                                              callback: function(
                                                                                                                                $$v
                                                                                                                              ) {
                                                                                                                                _vm.$set(
                                                                                                                                  acd,
                                                                                                                                  "dayCode",
                                                                                                                                  $$v
                                                                                                                                )
                                                                                                                              },
                                                                                                                              expression:
                                                                                                                                "acd.dayCode"
                                                                                                                            }
                                                                                                                          }
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    ),
                                                                                                                    _c(
                                                                                                                      "v-col",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          cols:
                                                                                                                            "1"
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-btn",
                                                                                                                          {
                                                                                                                            directives: [
                                                                                                                              {
                                                                                                                                name:
                                                                                                                                  "show",
                                                                                                                                rawName:
                                                                                                                                  "v-show",
                                                                                                                                value:
                                                                                                                                  _vm.localIsEditingSchedule,
                                                                                                                                expression:
                                                                                                                                  "localIsEditingSchedule"
                                                                                                                              }
                                                                                                                            ],
                                                                                                                            attrs: {
                                                                                                                              elevation:
                                                                                                                                "0",
                                                                                                                              fab:
                                                                                                                                "",
                                                                                                                              "x-small":
                                                                                                                                ""
                                                                                                                            },
                                                                                                                            on: {
                                                                                                                              click: function(
                                                                                                                                $event
                                                                                                                              ) {
                                                                                                                                $event.stopPropagation()
                                                                                                                                _vm.deleteScheduleDay(
                                                                                                                                  scheduleItem.additionalClassDays.findIndex(
                                                                                                                                    function(
                                                                                                                                      c
                                                                                                                                    ) {
                                                                                                                                      return (
                                                                                                                                        c.extraDay ==
                                                                                                                                        acd.extraDay
                                                                                                                                      )
                                                                                                                                    }
                                                                                                                                  )
                                                                                                                                )
                                                                                                                              }
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "v-icon",
                                                                                                                              [
                                                                                                                                _vm._v(
                                                                                                                                  "fal fa-trash-alt"
                                                                                                                                )
                                                                                                                              ]
                                                                                                                            )
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "div",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "pa-0 my-2"
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "pb-date-time-range-field",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      type:
                                                                                                                        "time",
                                                                                                                      start:
                                                                                                                        acd.startTime,
                                                                                                                      end:
                                                                                                                        acd.endTime,
                                                                                                                      min:
                                                                                                                        _vm.startTime24HrFmt,
                                                                                                                      max:
                                                                                                                        _vm.endTime24HrFmt,
                                                                                                                      disabled: !_vm.localIsEditingSchedule,
                                                                                                                      "hide-details":
                                                                                                                        "auto",
                                                                                                                      offsetTop:
                                                                                                                        j ===
                                                                                                                        scheduleItem.additionalClassDays.filter(
                                                                                                                          function(
                                                                                                                            c
                                                                                                                          ) {
                                                                                                                            return p ==
                                                                                                                              6
                                                                                                                              ? _vm.week1DayCodes.includes(
                                                                                                                                  c.dayCode
                                                                                                                                )
                                                                                                                              : _vm.week2DayCodes.includes(
                                                                                                                                  c.dayCode
                                                                                                                                )
                                                                                                                          }
                                                                                                                        )
                                                                                                                          .length -
                                                                                                                          1
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      "update:start": function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        return _vm.$set(
                                                                                                                          acd,
                                                                                                                          "startTime",
                                                                                                                          $event
                                                                                                                        )
                                                                                                                      },
                                                                                                                      "update:end": function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        return _vm.$set(
                                                                                                                          acd,
                                                                                                                          "endTime",
                                                                                                                          $event
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      }
                                                                                                    )
                                                                                                  ],
                                                                                                  2
                                                                                                )
                                                                                              : _vm._e()
                                                                                          ],
                                                                                          1
                                                                                        )
                                                                                      }
                                                                                    ),
                                                                                    _vm
                                                                                      .getSchoolYearSettings
                                                                                      .classCycle !==
                                                                                    "twoWeeks"
                                                                                      ? _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              "v-row",
                                                                                              {
                                                                                                directives: [
                                                                                                  {
                                                                                                    name:
                                                                                                      "show",
                                                                                                    rawName:
                                                                                                      "v-show",
                                                                                                    value:
                                                                                                      _vm.localIsEditingSchedule,
                                                                                                    expression:
                                                                                                      "localIsEditingSchedule"
                                                                                                  }
                                                                                                ]
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-col",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "text-center",
                                                                                                    attrs: {
                                                                                                      cols:
                                                                                                        "12"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "primary",
                                                                                                          text:
                                                                                                            ""
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.stopPropagation()
                                                                                                            return _vm.addScheduleDay(
                                                                                                              scheduleItem.scheduleId
                                                                                                            )
                                                                                                          }
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              small:
                                                                                                                ""
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "fal fa-plus"
                                                                                                            )
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "ml-1"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                _vm.$t(
                                                                                                                  "addScheduleDayLabel"
                                                                                                                )
                                                                                                              )
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _vm._l(
                                                                                              scheduleItem.additionalClassDays,
                                                                                              function(
                                                                                                acd,
                                                                                                j
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "v-row",
                                                                                                  {
                                                                                                    key:
                                                                                                      "additionalclassdays-" +
                                                                                                      j +
                                                                                                      _vm.localRefreshKey,
                                                                                                    staticClass:
                                                                                                      "my-2",
                                                                                                    attrs: {
                                                                                                      justify:
                                                                                                        "center",
                                                                                                      align:
                                                                                                        "center"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "pa-0 my-2"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-col",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  cols:
                                                                                                                    "8"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-select",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      items:
                                                                                                                        _vm.localDaysOfWeek,
                                                                                                                      "hide-details":
                                                                                                                        "true",
                                                                                                                      disabled: !_vm.localIsEditingSchedule,
                                                                                                                      dense:
                                                                                                                        "",
                                                                                                                      outlined:
                                                                                                                        ""
                                                                                                                    },
                                                                                                                    model: {
                                                                                                                      value:
                                                                                                                        acd.dayCode,
                                                                                                                      callback: function(
                                                                                                                        $$v
                                                                                                                      ) {
                                                                                                                        _vm.$set(
                                                                                                                          acd,
                                                                                                                          "dayCode",
                                                                                                                          $$v
                                                                                                                        )
                                                                                                                      },
                                                                                                                      expression:
                                                                                                                        "acd.dayCode"
                                                                                                                    }
                                                                                                                  }
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-col",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  cols:
                                                                                                                    "1"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-btn",
                                                                                                                  {
                                                                                                                    directives: [
                                                                                                                      {
                                                                                                                        name:
                                                                                                                          "show",
                                                                                                                        rawName:
                                                                                                                          "v-show",
                                                                                                                        value:
                                                                                                                          _vm.localIsEditingSchedule,
                                                                                                                        expression:
                                                                                                                          "localIsEditingSchedule"
                                                                                                                      }
                                                                                                                    ],
                                                                                                                    attrs: {
                                                                                                                      elevation:
                                                                                                                        "0",
                                                                                                                      fab:
                                                                                                                        "",
                                                                                                                      "x-small":
                                                                                                                        "",
                                                                                                                      "aria-label": _vm.$t(
                                                                                                                        "removeDayLabel"
                                                                                                                      )
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                        return _vm.deleteScheduleDay(
                                                                                                                          j
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-icon",
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "fal fa-trash-alt"
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "div",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "pa-0 my-2"
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "pb-date-time-range-field",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              type:
                                                                                                                "time",
                                                                                                              start:
                                                                                                                acd.startTime,
                                                                                                              end:
                                                                                                                acd.endTime,
                                                                                                              min:
                                                                                                                _vm.startTime24HrFmt,
                                                                                                              max:
                                                                                                                _vm.endTime24HrFmt,
                                                                                                              disabled: !_vm.localIsEditingSchedule,
                                                                                                              "hide-details":
                                                                                                                "auto",
                                                                                                              offsetTop:
                                                                                                                j ===
                                                                                                                scheduleItem
                                                                                                                  .additionalClassDays
                                                                                                                  .length -
                                                                                                                  1
                                                                                                            },
                                                                                                            on: {
                                                                                                              "update:start": function(
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.$set(
                                                                                                                  acd,
                                                                                                                  "startTime",
                                                                                                                  $event
                                                                                                                )
                                                                                                              },
                                                                                                              "update:end": function(
                                                                                                                $event
                                                                                                              ) {
                                                                                                                return _vm.$set(
                                                                                                                  acd,
                                                                                                                  "endTime",
                                                                                                                  $event
                                                                                                                )
                                                                                                              }
                                                                                                            }
                                                                                                          }
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  ]
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          2
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ],
                                                                                  2
                                                                                )
                                                                              : _c(
                                                                                  "div",
                                                                                  [
                                                                                    _c(
                                                                                      "div",
                                                                                      [
                                                                                        _vm
                                                                                          .getSchoolYearSettings
                                                                                          .classCycle ===
                                                                                          "twoWeeks" &&
                                                                                        _vm.browserWidth >=
                                                                                          1680
                                                                                          ? _c(
                                                                                              "div",
                                                                                              [
                                                                                                _c(
                                                                                                  "v-row",
                                                                                                  {
                                                                                                    attrs: {
                                                                                                      justify:
                                                                                                        "center",
                                                                                                      align:
                                                                                                        "start"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-col",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          cols:
                                                                                                            "6"
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-col",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "py-5",
                                                                                                                attrs: {
                                                                                                                  cols:
                                                                                                                    "12"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-h6"
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "week1Label"
                                                                                                                        )
                                                                                                                      )
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value: !_vm.localIsEditingSchedule,
                                                                                                                expression:
                                                                                                                  "!localIsEditingSchedule"
                                                                                                              }
                                                                                                            ],
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-data-table",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  headers:
                                                                                                                    _vm.scheduleTableHeaders,
                                                                                                                  items: _vm.cycleDays
                                                                                                                    .slice(
                                                                                                                      0,
                                                                                                                      7
                                                                                                                    )
                                                                                                                    .filter(
                                                                                                                      function(
                                                                                                                        i
                                                                                                                      ) {
                                                                                                                        return scheduleItem[
                                                                                                                          "day" +
                                                                                                                            i.classScheduleDayIndex +
                                                                                                                            "Teach"
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  "items-per-page": -1,
                                                                                                                  "hide-default-footer":
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "title",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.title
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.start",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  scheduleItem[
                                                                                                                                    "day" +
                                                                                                                                      item.classScheduleDayIndex +
                                                                                                                                      "StartTime"
                                                                                                                                  ] ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.end",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  scheduleItem[
                                                                                                                                    "day" +
                                                                                                                                      item.classScheduleDayIndex +
                                                                                                                                      "EndTime"
                                                                                                                                  ] ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _vm._l(
                                                                                                          _vm.cycleDays.slice(
                                                                                                            0,
                                                                                                            7
                                                                                                          ),
                                                                                                          function(
                                                                                                            cycleDay,
                                                                                                            p
                                                                                                          ) {
                                                                                                            return _c(
                                                                                                              "v-row",
                                                                                                              {
                                                                                                                directives: [
                                                                                                                  {
                                                                                                                    name:
                                                                                                                      "show",
                                                                                                                    rawName:
                                                                                                                      "v-show",
                                                                                                                    value:
                                                                                                                      _vm.localIsEditingSchedule,
                                                                                                                    expression:
                                                                                                                      "localIsEditingSchedule"
                                                                                                                  }
                                                                                                                ],
                                                                                                                key:
                                                                                                                  "cycledays-" +
                                                                                                                  p +
                                                                                                                  _vm.localRefreshKey,
                                                                                                                attrs: {
                                                                                                                  justify:
                                                                                                                    "center",
                                                                                                                  align:
                                                                                                                    "center"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pl-0 pr-0 pt-0 pb-5",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "auto"
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "pb-chip",
                                                                                                                      {
                                                                                                                        staticStyle: {
                                                                                                                          opacity:
                                                                                                                            "1"
                                                                                                                        },
                                                                                                                        attrs: {
                                                                                                                          "input-value":
                                                                                                                            scheduleItem[
                                                                                                                              "day" +
                                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                                "Teach"
                                                                                                                            ],
                                                                                                                          "active-class":
                                                                                                                            "accent3--text font-weight-bold",
                                                                                                                          disabled: !_vm.localIsEditingSchedule,
                                                                                                                          filter:
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            ""
                                                                                                                        },
                                                                                                                        on: {
                                                                                                                          click: function(
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.changeScheduleItemCycleDays(
                                                                                                                              scheduleItem,
                                                                                                                              cycleDay
                                                                                                                            )
                                                                                                                          },
                                                                                                                          keyup: function(
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            if (
                                                                                                                              !$event.type.indexOf(
                                                                                                                                "key"
                                                                                                                              ) &&
                                                                                                                              _vm._k(
                                                                                                                                $event.keyCode,
                                                                                                                                "enter",
                                                                                                                                13,
                                                                                                                                $event.key,
                                                                                                                                "Enter"
                                                                                                                              )
                                                                                                                            ) {
                                                                                                                              return null
                                                                                                                            }
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.changeScheduleItemCycleDays(
                                                                                                                              scheduleItem,
                                                                                                                              cycleDay
                                                                                                                            )
                                                                                                                          }
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "span",
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              _vm._s(
                                                                                                                                cycleDay.title
                                                                                                                              )
                                                                                                                            )
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-row",
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-col",
                                                                                                                          {
                                                                                                                            attrs: {
                                                                                                                              cols:
                                                                                                                                "10"
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "pb-date-time-range-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  type:
                                                                                                                                    "time",
                                                                                                                                  start:
                                                                                                                                    scheduleItem[
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "StartTime"
                                                                                                                                    ],
                                                                                                                                  end:
                                                                                                                                    scheduleItem[
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "EndTime"
                                                                                                                                    ],
                                                                                                                                  disabled:
                                                                                                                                    !scheduleItem[
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "Teach"
                                                                                                                                    ] ||
                                                                                                                                    !_vm.localIsEditingSchedule,
                                                                                                                                  min:
                                                                                                                                    _vm.startTime24HrFmt,
                                                                                                                                  max:
                                                                                                                                    _vm.endTime24HrFmt,
                                                                                                                                  "hide-details":
                                                                                                                                    "auto",
                                                                                                                                  offsetTop:
                                                                                                                                    _vm.isEmpty(
                                                                                                                                      scheduleItem.additionalClassDays
                                                                                                                                    ) &&
                                                                                                                                    p ===
                                                                                                                                      6
                                                                                                                                },
                                                                                                                                on: {
                                                                                                                                  "update:start": function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.$set(
                                                                                                                                      scheduleItem,
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "StartTime",
                                                                                                                                      $event
                                                                                                                                    )
                                                                                                                                  },
                                                                                                                                  "update:end": function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.$set(
                                                                                                                                      scheduleItem,
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "EndTime",
                                                                                                                                      $event
                                                                                                                                    )
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            )
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        ),
                                                                                                                        _c(
                                                                                                                          "v-col",
                                                                                                                          {
                                                                                                                            staticClass:
                                                                                                                              "pl-0 pr-0 pt-5 pb-0",
                                                                                                                            attrs: {
                                                                                                                              cols:
                                                                                                                                "1"
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "pb-btn",
                                                                                                                              _vm._g(
                                                                                                                                _vm._b(
                                                                                                                                  {
                                                                                                                                    directives: [
                                                                                                                                      {
                                                                                                                                        name:
                                                                                                                                          "show",
                                                                                                                                        rawName:
                                                                                                                                          "v-show",
                                                                                                                                        value:
                                                                                                                                          scheduleItem[
                                                                                                                                            "day" +
                                                                                                                                              cycleDay.classScheduleDayIndex +
                                                                                                                                              "Teach"
                                                                                                                                          ] &&
                                                                                                                                          _vm.localIsEditingSchedule,
                                                                                                                                        expression:
                                                                                                                                          "scheduleItem['day' + cycleDay.classScheduleDayIndex + 'Teach'] && localIsEditingSchedule"
                                                                                                                                      }
                                                                                                                                    ],
                                                                                                                                    attrs: {
                                                                                                                                      label: _vm.$t(
                                                                                                                                        "copyTimesLabel"
                                                                                                                                      ),
                                                                                                                                      elevation:
                                                                                                                                        "0",
                                                                                                                                      fab:
                                                                                                                                        "",
                                                                                                                                      "x-small":
                                                                                                                                        ""
                                                                                                                                    },
                                                                                                                                    on: {
                                                                                                                                      click: function(
                                                                                                                                        $event
                                                                                                                                      ) {
                                                                                                                                        $event.stopPropagation()
                                                                                                                                        return _vm.copyTimes(
                                                                                                                                          scheduleItem[
                                                                                                                                            "day" +
                                                                                                                                              cycleDay.classScheduleDayIndex +
                                                                                                                                              "StartTime"
                                                                                                                                          ],
                                                                                                                                          scheduleItem[
                                                                                                                                            "day" +
                                                                                                                                              cycleDay.classScheduleDayIndex +
                                                                                                                                              "EndTime"
                                                                                                                                          ]
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  },
                                                                                                                                  "pb-btn",
                                                                                                                                  _vm.attrs,
                                                                                                                                  false
                                                                                                                                ),
                                                                                                                                _vm.on
                                                                                                                              ),
                                                                                                                              [
                                                                                                                                _c(
                                                                                                                                  "v-icon",
                                                                                                                                  [
                                                                                                                                    _vm._v(
                                                                                                                                      "fal fa-copy"
                                                                                                                                    )
                                                                                                                                  ]
                                                                                                                                )
                                                                                                                              ],
                                                                                                                              1
                                                                                                                            )
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          }
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  _vm.localIsEditingSchedule,
                                                                                                                expression:
                                                                                                                  "localIsEditingSchedule"
                                                                                                              }
                                                                                                            ]
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-col",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "text-center",
                                                                                                                attrs: {
                                                                                                                  cols:
                                                                                                                    "12"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-btn",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      color:
                                                                                                                        "primary",
                                                                                                                      text:
                                                                                                                        ""
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                        return _vm.addScheduleDay(
                                                                                                                          scheduleItem.scheduleId,
                                                                                                                          "week1"
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-icon",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          small:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "fal fa-plus"
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                    _c(
                                                                                                                      "span",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "ml-1"
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            _vm.$t(
                                                                                                                              "addScheduleDayLabel"
                                                                                                                            )
                                                                                                                          )
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  !_vm.localIsEditingSchedule &&
                                                                                                                  scheduleItem.additionalClassDays.filter(
                                                                                                                    function(
                                                                                                                      c
                                                                                                                    ) {
                                                                                                                      return _vm.week1DayCodes.includes(
                                                                                                                        c.dayCode
                                                                                                                      )
                                                                                                                    }
                                                                                                                  )
                                                                                                                    .length >
                                                                                                                    0,
                                                                                                                expression:
                                                                                                                  "!localIsEditingSchedule && scheduleItem.additionalClassDays.filter(c => week1DayCodes.includes(c.dayCode)).length > 0"
                                                                                                              }
                                                                                                            ],
                                                                                                            staticClass:
                                                                                                              "mt-3",
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-data-table",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  headers:
                                                                                                                    _vm.additionalScheduleTableHeaders,
                                                                                                                  items: scheduleItem.additionalClassDays.filter(
                                                                                                                    function(
                                                                                                                      c
                                                                                                                    ) {
                                                                                                                      return _vm.week1DayCodes.includes(
                                                                                                                        c.dayCode
                                                                                                                      )
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  "items-per-page": -1,
                                                                                                                  "no-data-text":
                                                                                                                    "",
                                                                                                                  "hide-default-footer":
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.title",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  _vm.localDaysOfWeek
                                                                                                                                    .slice(
                                                                                                                                      0,
                                                                                                                                      7
                                                                                                                                    )
                                                                                                                                    .filter(
                                                                                                                                      function(
                                                                                                                                        wd
                                                                                                                                      ) {
                                                                                                                                        return (
                                                                                                                                          wd.value ===
                                                                                                                                          item.dayCode
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    )[0]
                                                                                                                                    .text
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.start",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.startTime ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.end",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.endTime ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _vm._l(
                                                                                                          scheduleItem.additionalClassDays.filter(
                                                                                                            function(
                                                                                                              c
                                                                                                            ) {
                                                                                                              return _vm.week1DayCodes.includes(
                                                                                                                c.dayCode
                                                                                                              )
                                                                                                            }
                                                                                                          ),
                                                                                                          function(
                                                                                                            acd,
                                                                                                            j
                                                                                                          ) {
                                                                                                            return _c(
                                                                                                              "v-row",
                                                                                                              {
                                                                                                                directives: [
                                                                                                                  {
                                                                                                                    name:
                                                                                                                      "show",
                                                                                                                    rawName:
                                                                                                                      "v-show",
                                                                                                                    value:
                                                                                                                      _vm.localIsEditingSchedule,
                                                                                                                    expression:
                                                                                                                      "localIsEditingSchedule"
                                                                                                                  }
                                                                                                                ],
                                                                                                                key:
                                                                                                                  "additionalclassdays-" +
                                                                                                                  j +
                                                                                                                  _vm.localRefreshKey,
                                                                                                                attrs: {
                                                                                                                  justify:
                                                                                                                    "center",
                                                                                                                  align:
                                                                                                                    "center"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pl-0 pr-0 pt-0 pb-0",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "3"
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-select",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          items: _vm.localDaysOfWeek.slice(
                                                                                                                            0,
                                                                                                                            7
                                                                                                                          ),
                                                                                                                          "hide-details":
                                                                                                                            "true",
                                                                                                                          disabled: !_vm.localIsEditingSchedule,
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          height:
                                                                                                                            "55"
                                                                                                                        },
                                                                                                                        model: {
                                                                                                                          value:
                                                                                                                            acd.dayCode,
                                                                                                                          callback: function(
                                                                                                                            $$v
                                                                                                                          ) {
                                                                                                                            _vm.$set(
                                                                                                                              acd,
                                                                                                                              "dayCode",
                                                                                                                              $$v
                                                                                                                            )
                                                                                                                          },
                                                                                                                          expression:
                                                                                                                            "acd.dayCode"
                                                                                                                        }
                                                                                                                      }
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-row",
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-col",
                                                                                                                          {
                                                                                                                            attrs: {
                                                                                                                              cols:
                                                                                                                                "10"
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "pb-date-time-range-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  type:
                                                                                                                                    "time",
                                                                                                                                  start:
                                                                                                                                    acd.startTime,
                                                                                                                                  end:
                                                                                                                                    acd.endTime,
                                                                                                                                  min:
                                                                                                                                    _vm.startTime24HrFmt,
                                                                                                                                  max:
                                                                                                                                    _vm.endTime24HrFmt,
                                                                                                                                  disabled: !_vm.localIsEditingSchedule,
                                                                                                                                  "hide-details":
                                                                                                                                    "auto",
                                                                                                                                  offsetTop:
                                                                                                                                    j ===
                                                                                                                                    scheduleItem.additionalClassDays.filter(
                                                                                                                                      function(
                                                                                                                                        c
                                                                                                                                      ) {
                                                                                                                                        return _vm.week1DayCodes.includes(
                                                                                                                                          c.dayCode
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                      .length -
                                                                                                                                      1
                                                                                                                                },
                                                                                                                                on: {
                                                                                                                                  "update:start": function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.$set(
                                                                                                                                      acd,
                                                                                                                                      "startTime",
                                                                                                                                      $event
                                                                                                                                    )
                                                                                                                                  },
                                                                                                                                  "update:end": function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.$set(
                                                                                                                                      acd,
                                                                                                                                      "endTime",
                                                                                                                                      $event
                                                                                                                                    )
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            )
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        ),
                                                                                                                        _c(
                                                                                                                          "v-col",
                                                                                                                          {
                                                                                                                            staticClass:
                                                                                                                              "pl-0 pr-0 pt-5 pb-0",
                                                                                                                            attrs: {
                                                                                                                              cols:
                                                                                                                                "1"
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "v-btn",
                                                                                                                              {
                                                                                                                                directives: [
                                                                                                                                  {
                                                                                                                                    name:
                                                                                                                                      "show",
                                                                                                                                    rawName:
                                                                                                                                      "v-show",
                                                                                                                                    value:
                                                                                                                                      _vm.localIsEditingSchedule,
                                                                                                                                    expression:
                                                                                                                                      "localIsEditingSchedule"
                                                                                                                                  }
                                                                                                                                ],
                                                                                                                                attrs: {
                                                                                                                                  elevation:
                                                                                                                                    "0",
                                                                                                                                  fab:
                                                                                                                                    "",
                                                                                                                                  "x-small":
                                                                                                                                    "",
                                                                                                                                  "aria-label": _vm.$t(
                                                                                                                                    "removeDayLabel"
                                                                                                                                  )
                                                                                                                                },
                                                                                                                                on: {
                                                                                                                                  click: function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    $event.stopPropagation()
                                                                                                                                    _vm.deleteScheduleDay(
                                                                                                                                      scheduleItem.additionalClassDays.findIndex(
                                                                                                                                        function(
                                                                                                                                          c
                                                                                                                                        ) {
                                                                                                                                          return (
                                                                                                                                            c.extraDay ==
                                                                                                                                            acd.extraDay
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                    )
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              },
                                                                                                                              [
                                                                                                                                _c(
                                                                                                                                  "v-icon",
                                                                                                                                  [
                                                                                                                                    _vm._v(
                                                                                                                                      "fal fa-trash-alt"
                                                                                                                                    )
                                                                                                                                  ]
                                                                                                                                )
                                                                                                                              ],
                                                                                                                              1
                                                                                                                            )
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          }
                                                                                                        )
                                                                                                      ],
                                                                                                      2
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "v-col",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          cols:
                                                                                                            "6"
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-col",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "py-5",
                                                                                                                attrs: {
                                                                                                                  cols:
                                                                                                                    "12"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-h6"
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "week2Label"
                                                                                                                        )
                                                                                                                      )
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value: !_vm.localIsEditingSchedule,
                                                                                                                expression:
                                                                                                                  "!localIsEditingSchedule"
                                                                                                              }
                                                                                                            ],
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-data-table",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  headers:
                                                                                                                    _vm.scheduleTableHeaders,
                                                                                                                  items: _vm.cycleDays
                                                                                                                    .slice(
                                                                                                                      7,
                                                                                                                      14
                                                                                                                    )
                                                                                                                    .filter(
                                                                                                                      function(
                                                                                                                        i
                                                                                                                      ) {
                                                                                                                        return scheduleItem[
                                                                                                                          "day" +
                                                                                                                            i.classScheduleDayIndex +
                                                                                                                            "Teach"
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  "items-per-page": -1,
                                                                                                                  "hide-default-footer":
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "title",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.title
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.start",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  scheduleItem[
                                                                                                                                    "day" +
                                                                                                                                      item.classScheduleDayIndex +
                                                                                                                                      "StartTime"
                                                                                                                                  ] ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.end",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  scheduleItem[
                                                                                                                                    "day" +
                                                                                                                                      item.classScheduleDayIndex +
                                                                                                                                      "EndTime"
                                                                                                                                  ] ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _vm._l(
                                                                                                          _vm.cycleDays.slice(
                                                                                                            7,
                                                                                                            14
                                                                                                          ),
                                                                                                          function(
                                                                                                            cycleDay,
                                                                                                            p
                                                                                                          ) {
                                                                                                            return _c(
                                                                                                              "v-row",
                                                                                                              {
                                                                                                                directives: [
                                                                                                                  {
                                                                                                                    name:
                                                                                                                      "show",
                                                                                                                    rawName:
                                                                                                                      "v-show",
                                                                                                                    value:
                                                                                                                      _vm.localIsEditingSchedule,
                                                                                                                    expression:
                                                                                                                      "localIsEditingSchedule"
                                                                                                                  }
                                                                                                                ],
                                                                                                                key:
                                                                                                                  "cycledays-" +
                                                                                                                  p +
                                                                                                                  _vm.localRefreshKey,
                                                                                                                attrs: {
                                                                                                                  justify:
                                                                                                                    "center",
                                                                                                                  align:
                                                                                                                    "center"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pl-0 pr-0 pt-0 pb-5",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "auto"
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "pb-chip",
                                                                                                                      {
                                                                                                                        staticStyle: {
                                                                                                                          opacity:
                                                                                                                            "1"
                                                                                                                        },
                                                                                                                        attrs: {
                                                                                                                          "input-value":
                                                                                                                            scheduleItem[
                                                                                                                              "day" +
                                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                                "Teach"
                                                                                                                            ],
                                                                                                                          "active-class":
                                                                                                                            "accent3--text font-weight-bold",
                                                                                                                          disabled: !_vm.localIsEditingSchedule,
                                                                                                                          filter:
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            ""
                                                                                                                        },
                                                                                                                        on: {
                                                                                                                          click: function(
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.changeScheduleItemCycleDays(
                                                                                                                              scheduleItem,
                                                                                                                              cycleDay
                                                                                                                            )
                                                                                                                          },
                                                                                                                          keyup: function(
                                                                                                                            $event
                                                                                                                          ) {
                                                                                                                            if (
                                                                                                                              !$event.type.indexOf(
                                                                                                                                "key"
                                                                                                                              ) &&
                                                                                                                              _vm._k(
                                                                                                                                $event.keyCode,
                                                                                                                                "enter",
                                                                                                                                13,
                                                                                                                                $event.key,
                                                                                                                                "Enter"
                                                                                                                              )
                                                                                                                            ) {
                                                                                                                              return null
                                                                                                                            }
                                                                                                                            $event.stopPropagation()
                                                                                                                            return _vm.changeScheduleItemCycleDays(
                                                                                                                              scheduleItem,
                                                                                                                              cycleDay
                                                                                                                            )
                                                                                                                          }
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "span",
                                                                                                                          [
                                                                                                                            _vm._v(
                                                                                                                              _vm._s(
                                                                                                                                cycleDay.title
                                                                                                                              )
                                                                                                                            )
                                                                                                                          ]
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-row",
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-col",
                                                                                                                          {
                                                                                                                            attrs: {
                                                                                                                              cols:
                                                                                                                                "10"
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "pb-date-time-range-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  type:
                                                                                                                                    "time",
                                                                                                                                  start:
                                                                                                                                    scheduleItem[
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "StartTime"
                                                                                                                                    ],
                                                                                                                                  end:
                                                                                                                                    scheduleItem[
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "EndTime"
                                                                                                                                    ],
                                                                                                                                  disabled:
                                                                                                                                    !scheduleItem[
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "Teach"
                                                                                                                                    ] ||
                                                                                                                                    !_vm.localIsEditingSchedule,
                                                                                                                                  min:
                                                                                                                                    _vm.startTime24HrFmt,
                                                                                                                                  max:
                                                                                                                                    _vm.endTime24HrFmt,
                                                                                                                                  "hide-details":
                                                                                                                                    "auto",
                                                                                                                                  offsetTop:
                                                                                                                                    _vm.isEmpty(
                                                                                                                                      scheduleItem.additionalClassDays
                                                                                                                                    ) &&
                                                                                                                                    p ===
                                                                                                                                      6
                                                                                                                                },
                                                                                                                                on: {
                                                                                                                                  "update:start": function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.$set(
                                                                                                                                      scheduleItem,
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "StartTime",
                                                                                                                                      $event
                                                                                                                                    )
                                                                                                                                  },
                                                                                                                                  "update:end": function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.$set(
                                                                                                                                      scheduleItem,
                                                                                                                                      "day" +
                                                                                                                                        cycleDay.classScheduleDayIndex +
                                                                                                                                        "EndTime",
                                                                                                                                      $event
                                                                                                                                    )
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            )
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        ),
                                                                                                                        _c(
                                                                                                                          "v-col",
                                                                                                                          {
                                                                                                                            staticClass:
                                                                                                                              "pl-0 pr-0 pt-5 pb-0",
                                                                                                                            attrs: {
                                                                                                                              cols:
                                                                                                                                "1"
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "pb-btn",
                                                                                                                              _vm._g(
                                                                                                                                _vm._b(
                                                                                                                                  {
                                                                                                                                    directives: [
                                                                                                                                      {
                                                                                                                                        name:
                                                                                                                                          "show",
                                                                                                                                        rawName:
                                                                                                                                          "v-show",
                                                                                                                                        value:
                                                                                                                                          scheduleItem[
                                                                                                                                            "day" +
                                                                                                                                              cycleDay.classScheduleDayIndex +
                                                                                                                                              "Teach"
                                                                                                                                          ] &&
                                                                                                                                          _vm.localIsEditingSchedule,
                                                                                                                                        expression:
                                                                                                                                          "scheduleItem['day' + cycleDay.classScheduleDayIndex + 'Teach'] && localIsEditingSchedule"
                                                                                                                                      }
                                                                                                                                    ],
                                                                                                                                    attrs: {
                                                                                                                                      label: _vm.$t(
                                                                                                                                        "copyTimesLabel"
                                                                                                                                      ),
                                                                                                                                      elevation:
                                                                                                                                        "0",
                                                                                                                                      fab:
                                                                                                                                        "",
                                                                                                                                      "x-small":
                                                                                                                                        ""
                                                                                                                                    },
                                                                                                                                    on: {
                                                                                                                                      click: function(
                                                                                                                                        $event
                                                                                                                                      ) {
                                                                                                                                        $event.stopPropagation()
                                                                                                                                        return _vm.copyTimes(
                                                                                                                                          scheduleItem[
                                                                                                                                            "day" +
                                                                                                                                              cycleDay.classScheduleDayIndex +
                                                                                                                                              "StartTime"
                                                                                                                                          ],
                                                                                                                                          scheduleItem[
                                                                                                                                            "day" +
                                                                                                                                              cycleDay.classScheduleDayIndex +
                                                                                                                                              "EndTime"
                                                                                                                                          ]
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  },
                                                                                                                                  "pb-btn",
                                                                                                                                  _vm.attrs,
                                                                                                                                  false
                                                                                                                                ),
                                                                                                                                _vm.on
                                                                                                                              ),
                                                                                                                              [
                                                                                                                                _c(
                                                                                                                                  "v-icon",
                                                                                                                                  [
                                                                                                                                    _vm._v(
                                                                                                                                      "fal fa-copy"
                                                                                                                                    )
                                                                                                                                  ]
                                                                                                                                )
                                                                                                                              ],
                                                                                                                              1
                                                                                                                            )
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          }
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  _vm.localIsEditingSchedule,
                                                                                                                expression:
                                                                                                                  "localIsEditingSchedule"
                                                                                                              }
                                                                                                            ]
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-col",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "text-center",
                                                                                                                attrs: {
                                                                                                                  cols:
                                                                                                                    "12"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-btn",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      color:
                                                                                                                        "primary",
                                                                                                                      text:
                                                                                                                        ""
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                        return _vm.addScheduleDay(
                                                                                                                          scheduleItem.scheduleId,
                                                                                                                          "week2"
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-icon",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          small:
                                                                                                                            ""
                                                                                                                        }
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "fal fa-plus"
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    ),
                                                                                                                    _c(
                                                                                                                      "span",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "ml-1"
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            _vm.$t(
                                                                                                                              "addScheduleDayLabel"
                                                                                                                            )
                                                                                                                          )
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  !_vm.localIsEditingSchedule &&
                                                                                                                  scheduleItem.additionalClassDays.filter(
                                                                                                                    function(
                                                                                                                      c
                                                                                                                    ) {
                                                                                                                      return _vm.week2DayCodes.includes(
                                                                                                                        c.dayCode
                                                                                                                      )
                                                                                                                    }
                                                                                                                  )
                                                                                                                    .legnth >
                                                                                                                    0,
                                                                                                                expression:
                                                                                                                  "!localIsEditingSchedule && scheduleItem.additionalClassDays.filter(c => week2DayCodes.includes(c.dayCode)).legnth > 0"
                                                                                                              }
                                                                                                            ],
                                                                                                            staticClass:
                                                                                                              "mt-3",
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-data-table",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  headers:
                                                                                                                    _vm.additionalScheduleTableHeaders,
                                                                                                                  items: scheduleItem.additionalClassDays.filter(
                                                                                                                    function(
                                                                                                                      c
                                                                                                                    ) {
                                                                                                                      return _vm.week2DayCodes.includes(
                                                                                                                        c.dayCode
                                                                                                                      )
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  "items-per-page": -1,
                                                                                                                  "no-data-text":
                                                                                                                    "",
                                                                                                                  "hide-default-footer":
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.title",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  _vm.localDaysOfWeek
                                                                                                                                    .slice(
                                                                                                                                      7,
                                                                                                                                      14
                                                                                                                                    )
                                                                                                                                    .filter(
                                                                                                                                      function(
                                                                                                                                        wd
                                                                                                                                      ) {
                                                                                                                                        return (
                                                                                                                                          wd.value ===
                                                                                                                                          item.dayCode
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    )[0]
                                                                                                                                    .text
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.start",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.startTime ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.end",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.endTime ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _vm._l(
                                                                                                          scheduleItem.additionalClassDays.filter(
                                                                                                            function(
                                                                                                              c
                                                                                                            ) {
                                                                                                              return _vm.week2DayCodes.includes(
                                                                                                                c.dayCode
                                                                                                              )
                                                                                                            }
                                                                                                          ),
                                                                                                          function(
                                                                                                            acd,
                                                                                                            j
                                                                                                          ) {
                                                                                                            return _c(
                                                                                                              "v-row",
                                                                                                              {
                                                                                                                directives: [
                                                                                                                  {
                                                                                                                    name:
                                                                                                                      "show",
                                                                                                                    rawName:
                                                                                                                      "v-show",
                                                                                                                    value:
                                                                                                                      _vm.localIsEditingSchedule,
                                                                                                                    expression:
                                                                                                                      "localIsEditingSchedule"
                                                                                                                  }
                                                                                                                ],
                                                                                                                key:
                                                                                                                  "additionalclassdays-" +
                                                                                                                  j +
                                                                                                                  _vm.localRefreshKey,
                                                                                                                attrs: {
                                                                                                                  justify:
                                                                                                                    "center",
                                                                                                                  align:
                                                                                                                    "center"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "pl-0 pr-0 pt-0 pb-0",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "3"
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-select",
                                                                                                                      {
                                                                                                                        attrs: {
                                                                                                                          items: _vm.localDaysOfWeek.slice(
                                                                                                                            7,
                                                                                                                            14
                                                                                                                          ),
                                                                                                                          "hide-details":
                                                                                                                            "true",
                                                                                                                          disabled: !_vm.localIsEditingSchedule,
                                                                                                                          dense:
                                                                                                                            "",
                                                                                                                          outlined:
                                                                                                                            "",
                                                                                                                          height:
                                                                                                                            "55"
                                                                                                                        },
                                                                                                                        model: {
                                                                                                                          value:
                                                                                                                            acd.dayCode,
                                                                                                                          callback: function(
                                                                                                                            $$v
                                                                                                                          ) {
                                                                                                                            _vm.$set(
                                                                                                                              acd,
                                                                                                                              "dayCode",
                                                                                                                              $$v
                                                                                                                            )
                                                                                                                          },
                                                                                                                          expression:
                                                                                                                            "acd.dayCode"
                                                                                                                        }
                                                                                                                      }
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                ),
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-row",
                                                                                                                      [
                                                                                                                        _c(
                                                                                                                          "v-col",
                                                                                                                          {
                                                                                                                            attrs: {
                                                                                                                              cols:
                                                                                                                                "10"
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "pb-date-time-range-field",
                                                                                                                              {
                                                                                                                                attrs: {
                                                                                                                                  type:
                                                                                                                                    "time",
                                                                                                                                  start:
                                                                                                                                    acd.startTime,
                                                                                                                                  end:
                                                                                                                                    acd.endTime,
                                                                                                                                  min:
                                                                                                                                    _vm.startTime24HrFmt,
                                                                                                                                  max:
                                                                                                                                    _vm.endTime24HrFmt,
                                                                                                                                  disabled: !_vm.localIsEditingSchedule,
                                                                                                                                  "hide-details":
                                                                                                                                    "auto",
                                                                                                                                  offsetTop:
                                                                                                                                    j ===
                                                                                                                                    scheduleItem.additionalClassDays.filter(
                                                                                                                                      function(
                                                                                                                                        c
                                                                                                                                      ) {
                                                                                                                                        return _vm.week2DayCodes.includes(
                                                                                                                                          c.dayCode
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    )
                                                                                                                                      .length -
                                                                                                                                      1
                                                                                                                                },
                                                                                                                                on: {
                                                                                                                                  "update:start": function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.$set(
                                                                                                                                      acd,
                                                                                                                                      "startTime",
                                                                                                                                      $event
                                                                                                                                    )
                                                                                                                                  },
                                                                                                                                  "update:end": function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    return _vm.$set(
                                                                                                                                      acd,
                                                                                                                                      "endTime",
                                                                                                                                      $event
                                                                                                                                    )
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              }
                                                                                                                            )
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        ),
                                                                                                                        _c(
                                                                                                                          "v-col",
                                                                                                                          {
                                                                                                                            staticClass:
                                                                                                                              "pl-0 pr-0 pt-5 pb-0",
                                                                                                                            attrs: {
                                                                                                                              cols:
                                                                                                                                "1"
                                                                                                                            }
                                                                                                                          },
                                                                                                                          [
                                                                                                                            _c(
                                                                                                                              "v-btn",
                                                                                                                              {
                                                                                                                                directives: [
                                                                                                                                  {
                                                                                                                                    name:
                                                                                                                                      "show",
                                                                                                                                    rawName:
                                                                                                                                      "v-show",
                                                                                                                                    value:
                                                                                                                                      _vm.localIsEditingSchedule,
                                                                                                                                    expression:
                                                                                                                                      "localIsEditingSchedule"
                                                                                                                                  }
                                                                                                                                ],
                                                                                                                                attrs: {
                                                                                                                                  elevation:
                                                                                                                                    "0",
                                                                                                                                  fab:
                                                                                                                                    "",
                                                                                                                                  "x-small":
                                                                                                                                    "",
                                                                                                                                  "aria-label": _vm.$t(
                                                                                                                                    "removeDayLabel"
                                                                                                                                  )
                                                                                                                                },
                                                                                                                                on: {
                                                                                                                                  click: function(
                                                                                                                                    $event
                                                                                                                                  ) {
                                                                                                                                    $event.stopPropagation()
                                                                                                                                    _vm.deleteScheduleDay(
                                                                                                                                      scheduleItem.additionalClassDays.findIndex(
                                                                                                                                        function(
                                                                                                                                          c
                                                                                                                                        ) {
                                                                                                                                          return (
                                                                                                                                            c.extraDay ==
                                                                                                                                            acd.extraDay
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      )
                                                                                                                                    )
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              },
                                                                                                                              [
                                                                                                                                _c(
                                                                                                                                  "v-icon",
                                                                                                                                  [
                                                                                                                                    _vm._v(
                                                                                                                                      "fal fa-trash-alt"
                                                                                                                                    )
                                                                                                                                  ]
                                                                                                                                )
                                                                                                                              ],
                                                                                                                              1
                                                                                                                            )
                                                                                                                          ],
                                                                                                                          1
                                                                                                                        )
                                                                                                                      ],
                                                                                                                      1
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          }
                                                                                                        )
                                                                                                      ],
                                                                                                      2
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            )
                                                                                          : _c(
                                                                                              "div",
                                                                                              [
                                                                                                !_vm.localIsEditingSchedule
                                                                                                  ? _c(
                                                                                                      "div",
                                                                                                      [
                                                                                                        _vm
                                                                                                          .getSchoolYearSettings
                                                                                                          .classCycle ===
                                                                                                        "twoWeeks"
                                                                                                          ? _c(
                                                                                                              "v-row",
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-col",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "py-5",
                                                                                                                    attrs: {
                                                                                                                      cols:
                                                                                                                        "12"
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "span",
                                                                                                                      {
                                                                                                                        staticClass:
                                                                                                                          "text-h6"
                                                                                                                      },
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          _vm._s(
                                                                                                                            _vm.$t(
                                                                                                                              "week1Label"
                                                                                                                            )
                                                                                                                          )
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          : _vm._e(),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value: !_vm.localIsEditingSchedule,
                                                                                                                expression:
                                                                                                                  "!localIsEditingSchedule"
                                                                                                              }
                                                                                                            ],
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-data-table",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  headers:
                                                                                                                    _vm.scheduleTableHeaders,
                                                                                                                  items:
                                                                                                                    _vm
                                                                                                                      .getSchoolYearSettings
                                                                                                                      .classCycle ===
                                                                                                                    "twoWeeks"
                                                                                                                      ? _vm.cycleDays
                                                                                                                          .slice(
                                                                                                                            0,
                                                                                                                            7
                                                                                                                          )
                                                                                                                          .filter(
                                                                                                                            function(
                                                                                                                              i
                                                                                                                            ) {
                                                                                                                              return scheduleItem[
                                                                                                                                "day" +
                                                                                                                                  i.classScheduleDayIndex +
                                                                                                                                  "Teach"
                                                                                                                              ]
                                                                                                                            }
                                                                                                                          )
                                                                                                                      : _vm.cycleDays.filter(
                                                                                                                          function(
                                                                                                                            i
                                                                                                                          ) {
                                                                                                                            return scheduleItem[
                                                                                                                              "day" +
                                                                                                                                i.classScheduleDayIndex +
                                                                                                                                "Teach"
                                                                                                                            ]
                                                                                                                          }
                                                                                                                        ),
                                                                                                                  "items-per-page": -1,
                                                                                                                  "hide-default-footer":
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "title",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.title
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.start",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  scheduleItem[
                                                                                                                                    "day" +
                                                                                                                                      item.classScheduleDayIndex +
                                                                                                                                      "StartTime"
                                                                                                                                  ] ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.end",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  scheduleItem[
                                                                                                                                    "day" +
                                                                                                                                      item.classScheduleDayIndex +
                                                                                                                                      "EndTime"
                                                                                                                                  ] ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  (_vm
                                                                                                                    .getSchoolYearSettings
                                                                                                                    .classCycle ===
                                                                                                                    "twoWeeks" &&
                                                                                                                    scheduleItem.additionalClassDays.filter(
                                                                                                                      function(
                                                                                                                        c
                                                                                                                      ) {
                                                                                                                        return _vm.week1DayCodes.includes(
                                                                                                                          c.dayCode
                                                                                                                        )
                                                                                                                      }
                                                                                                                    )
                                                                                                                      .length >
                                                                                                                      0) ||
                                                                                                                  scheduleItem
                                                                                                                    .additionalClassDays
                                                                                                                    .length >
                                                                                                                    0,
                                                                                                                expression:
                                                                                                                  "(getSchoolYearSettings.classCycle === 'twoWeeks' && scheduleItem.additionalClassDays.filter(c => week1DayCodes.includes(c.dayCode)).length > 0) || scheduleItem.additionalClassDays.length > 0"
                                                                                                              }
                                                                                                            ],
                                                                                                            staticClass:
                                                                                                              "mt-3",
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-data-table",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  headers:
                                                                                                                    _vm.additionalScheduleTableHeaders,
                                                                                                                  items:
                                                                                                                    _vm
                                                                                                                      .getSchoolYearSettings
                                                                                                                      .classCycle ===
                                                                                                                    "twoWeeks"
                                                                                                                      ? scheduleItem.additionalClassDays.filter(
                                                                                                                          function(
                                                                                                                            c
                                                                                                                          ) {
                                                                                                                            return _vm.week1DayCodes.includes(
                                                                                                                              c.dayCode
                                                                                                                            )
                                                                                                                          }
                                                                                                                        )
                                                                                                                      : scheduleItem.additionalClassDays,
                                                                                                                  "items-per-page": -1,
                                                                                                                  "no-data-text":
                                                                                                                    "",
                                                                                                                  "hide-default-footer":
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.title",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  _vm
                                                                                                                                    .getSchoolYearSettings
                                                                                                                                    .classCycle ===
                                                                                                                                    "twoWeeks"
                                                                                                                                    ? _vm.localDaysOfWeek
                                                                                                                                        .slice(
                                                                                                                                          0,
                                                                                                                                          7
                                                                                                                                        )
                                                                                                                                        .filter(
                                                                                                                                          function(
                                                                                                                                            wd
                                                                                                                                          ) {
                                                                                                                                            return (
                                                                                                                                              wd.value ===
                                                                                                                                              item.dayCode
                                                                                                                                            )
                                                                                                                                          }
                                                                                                                                        )[0]
                                                                                                                                        .text
                                                                                                                                    : _vm.localDaysOfWeek.filter(
                                                                                                                                        function(
                                                                                                                                          wd
                                                                                                                                        ) {
                                                                                                                                          return (
                                                                                                                                            wd.value ===
                                                                                                                                            item.dayCode
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      )[0]
                                                                                                                                        .text
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.start",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.startTime ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.end",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.endTime ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  _vm
                                                                                                                    .getSchoolYearSettings
                                                                                                                    .classCycle ===
                                                                                                                  "twoWeeks",
                                                                                                                expression:
                                                                                                                  "getSchoolYearSettings.classCycle === 'twoWeeks'"
                                                                                                              }
                                                                                                            ]
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-col",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "py-5",
                                                                                                                attrs: {
                                                                                                                  cols:
                                                                                                                    "12"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "span",
                                                                                                                  {
                                                                                                                    staticClass:
                                                                                                                      "text-h6"
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _vm._v(
                                                                                                                      _vm._s(
                                                                                                                        _vm.$t(
                                                                                                                          "week2Label"
                                                                                                                        )
                                                                                                                      )
                                                                                                                    )
                                                                                                                  ]
                                                                                                                )
                                                                                                              ]
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  _vm
                                                                                                                    .getSchoolYearSettings
                                                                                                                    .classCycle ===
                                                                                                                  "twoWeeks",
                                                                                                                expression:
                                                                                                                  "getSchoolYearSettings.classCycle === 'twoWeeks'"
                                                                                                              }
                                                                                                            ],
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-data-table",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  headers:
                                                                                                                    _vm.scheduleTableHeaders,
                                                                                                                  items: _vm.cycleDays
                                                                                                                    .slice(
                                                                                                                      7,
                                                                                                                      14
                                                                                                                    )
                                                                                                                    .filter(
                                                                                                                      function(
                                                                                                                        i
                                                                                                                      ) {
                                                                                                                        return scheduleItem[
                                                                                                                          "day" +
                                                                                                                            i.classScheduleDayIndex +
                                                                                                                            "Teach"
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    ),
                                                                                                                  "items-per-page": -1,
                                                                                                                  "hide-default-footer":
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "title",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.title
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.start",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  scheduleItem[
                                                                                                                                    "day" +
                                                                                                                                      item.classScheduleDayIndex +
                                                                                                                                      "StartTime"
                                                                                                                                  ] ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.end",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  scheduleItem[
                                                                                                                                    "day" +
                                                                                                                                      item.classScheduleDayIndex +
                                                                                                                                      "EndTime"
                                                                                                                                  ] ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          {
                                                                                                            directives: [
                                                                                                              {
                                                                                                                name:
                                                                                                                  "show",
                                                                                                                rawName:
                                                                                                                  "v-show",
                                                                                                                value:
                                                                                                                  _vm
                                                                                                                    .getSchoolYearSettings
                                                                                                                    .classCycle ===
                                                                                                                    "twoWeeks" &&
                                                                                                                  scheduleItem.additionalClassDays.filter(
                                                                                                                    function(
                                                                                                                      c
                                                                                                                    ) {
                                                                                                                      return _vm.week2DayCodes.includes(
                                                                                                                        c.dayCode
                                                                                                                      )
                                                                                                                    }
                                                                                                                  )
                                                                                                                    .legnth >
                                                                                                                    0,
                                                                                                                expression:
                                                                                                                  "getSchoolYearSettings.classCycle === 'twoWeeks' && scheduleItem.additionalClassDays.filter(c => week2DayCodes.includes(c.dayCode)).legnth > 0"
                                                                                                              }
                                                                                                            ],
                                                                                                            staticClass:
                                                                                                              "mt-3",
                                                                                                            attrs: {
                                                                                                              justify:
                                                                                                                "center",
                                                                                                              align:
                                                                                                                "center"
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-data-table",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  headers:
                                                                                                                    _vm.additionalScheduleTableHeaders,
                                                                                                                  items: scheduleItem.additionalClassDays.filter(
                                                                                                                    function(
                                                                                                                      c
                                                                                                                    ) {
                                                                                                                      return _vm.week2DayCodes.includes(
                                                                                                                        c.dayCode
                                                                                                                      )
                                                                                                                    }
                                                                                                                  ),
                                                                                                                  "items-per-page": -1,
                                                                                                                  "no-data-text":
                                                                                                                    "",
                                                                                                                  "hide-default-footer":
                                                                                                                    ""
                                                                                                                },
                                                                                                                scopedSlots: _vm._u(
                                                                                                                  [
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.title",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  _vm.localDaysOfWeek
                                                                                                                                    .slice(
                                                                                                                                      7,
                                                                                                                                      14
                                                                                                                                    )
                                                                                                                                    .filter(
                                                                                                                                      function(
                                                                                                                                        wd
                                                                                                                                      ) {
                                                                                                                                        return (
                                                                                                                                          wd.value ===
                                                                                                                                          item.dayCode
                                                                                                                                        )
                                                                                                                                      }
                                                                                                                                    )[0]
                                                                                                                                    .text
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.start",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.startTime ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    },
                                                                                                                    {
                                                                                                                      key:
                                                                                                                        "item.end",
                                                                                                                      fn: function(
                                                                                                                        ref
                                                                                                                      ) {
                                                                                                                        var item =
                                                                                                                          ref.item
                                                                                                                        return [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  item.endTime ||
                                                                                                                                    _vm.$t(
                                                                                                                                      "notSetLabel"
                                                                                                                                    )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      }
                                                                                                                    }
                                                                                                                  ],
                                                                                                                  null,
                                                                                                                  true
                                                                                                                )
                                                                                                              }
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  : _c(
                                                                                                      "div",
                                                                                                      _vm._l(
                                                                                                        _vm.cycleDays,
                                                                                                        function(
                                                                                                          cycleDay,
                                                                                                          p
                                                                                                        ) {
                                                                                                          return _c(
                                                                                                            "div",
                                                                                                            {
                                                                                                              key:
                                                                                                                "cycledays-" +
                                                                                                                p +
                                                                                                                _vm.localRefreshKey
                                                                                                            },
                                                                                                            [
                                                                                                              _vm
                                                                                                                .getSchoolYearSettings
                                                                                                                .classCycle ===
                                                                                                                "twoWeeks" &&
                                                                                                              (p ==
                                                                                                                0 ||
                                                                                                                p ==
                                                                                                                  7)
                                                                                                                ? _c(
                                                                                                                    "v-row",
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "v-col",
                                                                                                                        {
                                                                                                                          staticClass:
                                                                                                                            "py-5",
                                                                                                                          attrs: {
                                                                                                                            cols:
                                                                                                                              "3"
                                                                                                                          }
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "text-h6"
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  p ==
                                                                                                                                    0
                                                                                                                                    ? _vm.$t(
                                                                                                                                        "week1Label"
                                                                                                                                      )
                                                                                                                                    : _vm.$t(
                                                                                                                                        "week2Label"
                                                                                                                                      )
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                : _vm._e(),
                                                                                                              _c(
                                                                                                                "v-row",
                                                                                                                {
                                                                                                                  attrs: {
                                                                                                                    justify:
                                                                                                                      "center",
                                                                                                                    align:
                                                                                                                      "center"
                                                                                                                  }
                                                                                                                },
                                                                                                                [
                                                                                                                  _c(
                                                                                                                    "v-col",
                                                                                                                    {
                                                                                                                      staticClass:
                                                                                                                        "pl-0 pr-0 pt-0 pb-5 text-center",
                                                                                                                      attrs: {
                                                                                                                        cols:
                                                                                                                          "auto"
                                                                                                                      }
                                                                                                                    },
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "pb-chip",
                                                                                                                        {
                                                                                                                          staticStyle: {
                                                                                                                            opacity:
                                                                                                                              "1"
                                                                                                                          },
                                                                                                                          attrs: {
                                                                                                                            "input-value":
                                                                                                                              scheduleItem[
                                                                                                                                "day" +
                                                                                                                                  cycleDay.classScheduleDayIndex +
                                                                                                                                  "Teach"
                                                                                                                              ],
                                                                                                                            "active-class":
                                                                                                                              "accent3--text font-weight-bold",
                                                                                                                            disabled: !_vm.localIsEditingSchedule,
                                                                                                                            filter:
                                                                                                                              "",
                                                                                                                            outlined:
                                                                                                                              ""
                                                                                                                          },
                                                                                                                          on: {
                                                                                                                            click: function(
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              $event.stopPropagation()
                                                                                                                              return _vm.changeScheduleItemCycleDays(
                                                                                                                                scheduleItem,
                                                                                                                                cycleDay
                                                                                                                              )
                                                                                                                            },
                                                                                                                            keyup: function(
                                                                                                                              $event
                                                                                                                            ) {
                                                                                                                              if (
                                                                                                                                !$event.type.indexOf(
                                                                                                                                  "key"
                                                                                                                                ) &&
                                                                                                                                _vm._k(
                                                                                                                                  $event.keyCode,
                                                                                                                                  "enter",
                                                                                                                                  13,
                                                                                                                                  $event.key,
                                                                                                                                  "Enter"
                                                                                                                                )
                                                                                                                              ) {
                                                                                                                                return null
                                                                                                                              }
                                                                                                                              $event.stopPropagation()
                                                                                                                              return _vm.changeScheduleItemCycleDays(
                                                                                                                                scheduleItem,
                                                                                                                                cycleDay
                                                                                                                              )
                                                                                                                            }
                                                                                                                          }
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "span",
                                                                                                                            [
                                                                                                                              _vm._v(
                                                                                                                                _vm._s(
                                                                                                                                  cycleDay.title
                                                                                                                                )
                                                                                                                              )
                                                                                                                            ]
                                                                                                                          )
                                                                                                                        ]
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  ),
                                                                                                                  _c(
                                                                                                                    "v-col",
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "v-row",
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "v-col",
                                                                                                                            {
                                                                                                                              attrs: {
                                                                                                                                cols:
                                                                                                                                  "11"
                                                                                                                              }
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "pb-date-time-range-field",
                                                                                                                                {
                                                                                                                                  attrs: {
                                                                                                                                    type:
                                                                                                                                      "time",
                                                                                                                                    start:
                                                                                                                                      scheduleItem[
                                                                                                                                        "day" +
                                                                                                                                          cycleDay.classScheduleDayIndex +
                                                                                                                                          "StartTime"
                                                                                                                                      ],
                                                                                                                                    end:
                                                                                                                                      scheduleItem[
                                                                                                                                        "day" +
                                                                                                                                          cycleDay.classScheduleDayIndex +
                                                                                                                                          "EndTime"
                                                                                                                                      ],
                                                                                                                                    disabled:
                                                                                                                                      !scheduleItem[
                                                                                                                                        "day" +
                                                                                                                                          cycleDay.classScheduleDayIndex +
                                                                                                                                          "Teach"
                                                                                                                                      ] ||
                                                                                                                                      !_vm.localIsEditingSchedule,
                                                                                                                                    min:
                                                                                                                                      _vm.startTime24HrFmt,
                                                                                                                                    max:
                                                                                                                                      _vm.endTime24HrFmt,
                                                                                                                                    "hide-details":
                                                                                                                                      "auto",
                                                                                                                                    offsetTop:
                                                                                                                                      _vm.isEmpty(
                                                                                                                                        scheduleItem.additionalClassDays
                                                                                                                                      ) &&
                                                                                                                                      p ===
                                                                                                                                        _vm
                                                                                                                                          .cycleDays
                                                                                                                                          .length -
                                                                                                                                          1
                                                                                                                                  },
                                                                                                                                  on: {
                                                                                                                                    "update:start": function(
                                                                                                                                      $event
                                                                                                                                    ) {
                                                                                                                                      return _vm.$set(
                                                                                                                                        scheduleItem,
                                                                                                                                        "day" +
                                                                                                                                          cycleDay.classScheduleDayIndex +
                                                                                                                                          "StartTime",
                                                                                                                                        $event
                                                                                                                                      )
                                                                                                                                    },
                                                                                                                                    "update:end": function(
                                                                                                                                      $event
                                                                                                                                    ) {
                                                                                                                                      return _vm.$set(
                                                                                                                                        scheduleItem,
                                                                                                                                        "day" +
                                                                                                                                          cycleDay.classScheduleDayIndex +
                                                                                                                                          "EndTime",
                                                                                                                                        $event
                                                                                                                                      )
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                }
                                                                                                                              )
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          ),
                                                                                                                          _c(
                                                                                                                            "v-col",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "pl-0 pr-0 pt-5 pb-0",
                                                                                                                              attrs: {
                                                                                                                                cols:
                                                                                                                                  "1"
                                                                                                                              }
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "pb-btn",
                                                                                                                                _vm._g(
                                                                                                                                  _vm._b(
                                                                                                                                    {
                                                                                                                                      directives: [
                                                                                                                                        {
                                                                                                                                          name:
                                                                                                                                            "show",
                                                                                                                                          rawName:
                                                                                                                                            "v-show",
                                                                                                                                          value:
                                                                                                                                            scheduleItem[
                                                                                                                                              "day" +
                                                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                                                "Teach"
                                                                                                                                            ] &&
                                                                                                                                            _vm.localIsEditingSchedule,
                                                                                                                                          expression:
                                                                                                                                            "scheduleItem['day' + cycleDay.classScheduleDayIndex + 'Teach'] && localIsEditingSchedule"
                                                                                                                                        }
                                                                                                                                      ],
                                                                                                                                      attrs: {
                                                                                                                                        label: _vm.$t(
                                                                                                                                          "copyTimesLabel"
                                                                                                                                        ),
                                                                                                                                        elevation:
                                                                                                                                          "0",
                                                                                                                                        fab:
                                                                                                                                          "",
                                                                                                                                        "x-small":
                                                                                                                                          ""
                                                                                                                                      },
                                                                                                                                      on: {
                                                                                                                                        click: function(
                                                                                                                                          $event
                                                                                                                                        ) {
                                                                                                                                          $event.stopPropagation()
                                                                                                                                          return _vm.copyTimes(
                                                                                                                                            scheduleItem[
                                                                                                                                              "day" +
                                                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                                                "StartTime"
                                                                                                                                            ],
                                                                                                                                            scheduleItem[
                                                                                                                                              "day" +
                                                                                                                                                cycleDay.classScheduleDayIndex +
                                                                                                                                                "EndTime"
                                                                                                                                            ]
                                                                                                                                          )
                                                                                                                                        }
                                                                                                                                      }
                                                                                                                                    },
                                                                                                                                    "pb-btn",
                                                                                                                                    _vm.attrs,
                                                                                                                                    false
                                                                                                                                  ),
                                                                                                                                  _vm.on
                                                                                                                                ),
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    "v-icon",
                                                                                                                                    [
                                                                                                                                      _vm._v(
                                                                                                                                        "fal fa-copy"
                                                                                                                                      )
                                                                                                                                    ]
                                                                                                                                  )
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              )
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          )
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    1
                                                                                                                  )
                                                                                                                ],
                                                                                                                1
                                                                                                              ),
                                                                                                              _vm
                                                                                                                .getSchoolYearSettings
                                                                                                                .classCycle ===
                                                                                                                "twoWeeks" &&
                                                                                                              (p ==
                                                                                                                6 ||
                                                                                                                p ==
                                                                                                                  13)
                                                                                                                ? _c(
                                                                                                                    "div",
                                                                                                                    [
                                                                                                                      _c(
                                                                                                                        "v-row",
                                                                                                                        {
                                                                                                                          directives: [
                                                                                                                            {
                                                                                                                              name:
                                                                                                                                "show",
                                                                                                                              rawName:
                                                                                                                                "v-show",
                                                                                                                              value:
                                                                                                                                _vm.localIsEditingSchedule,
                                                                                                                              expression:
                                                                                                                                "localIsEditingSchedule"
                                                                                                                            }
                                                                                                                          ]
                                                                                                                        },
                                                                                                                        [
                                                                                                                          _c(
                                                                                                                            "v-col",
                                                                                                                            {
                                                                                                                              staticClass:
                                                                                                                                "text-center",
                                                                                                                              attrs: {
                                                                                                                                cols:
                                                                                                                                  "12"
                                                                                                                              }
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "v-btn",
                                                                                                                                {
                                                                                                                                  attrs: {
                                                                                                                                    color:
                                                                                                                                      "primary",
                                                                                                                                    text:
                                                                                                                                      ""
                                                                                                                                  },
                                                                                                                                  on: {
                                                                                                                                    click: function(
                                                                                                                                      $event
                                                                                                                                    ) {
                                                                                                                                      $event.stopPropagation()
                                                                                                                                      return _vm.addScheduleDay(
                                                                                                                                        scheduleItem.scheduleId,
                                                                                                                                        p ==
                                                                                                                                          6
                                                                                                                                          ? "week1"
                                                                                                                                          : "week2"
                                                                                                                                      )
                                                                                                                                    }
                                                                                                                                  }
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    "v-icon",
                                                                                                                                    {
                                                                                                                                      attrs: {
                                                                                                                                        small:
                                                                                                                                          ""
                                                                                                                                      }
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _vm._v(
                                                                                                                                        "fal fa-plus"
                                                                                                                                      )
                                                                                                                                    ]
                                                                                                                                  ),
                                                                                                                                  _c(
                                                                                                                                    "span",
                                                                                                                                    {
                                                                                                                                      staticClass:
                                                                                                                                        "ml-1"
                                                                                                                                    },
                                                                                                                                    [
                                                                                                                                      _vm._v(
                                                                                                                                        _vm._s(
                                                                                                                                          _vm.$t(
                                                                                                                                            "addScheduleDayLabel"
                                                                                                                                          )
                                                                                                                                        )
                                                                                                                                      )
                                                                                                                                    ]
                                                                                                                                  )
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              )
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          )
                                                                                                                        ],
                                                                                                                        1
                                                                                                                      ),
                                                                                                                      _vm._l(
                                                                                                                        scheduleItem.additionalClassDays.filter(
                                                                                                                          function(
                                                                                                                            c
                                                                                                                          ) {
                                                                                                                            return p ==
                                                                                                                              6
                                                                                                                              ? _vm.week1DayCodes.includes(
                                                                                                                                  c.dayCode
                                                                                                                                )
                                                                                                                              : _vm.week2DayCodes.includes(
                                                                                                                                  c.dayCode
                                                                                                                                )
                                                                                                                          }
                                                                                                                        ),
                                                                                                                        function(
                                                                                                                          acd,
                                                                                                                          j
                                                                                                                        ) {
                                                                                                                          return _c(
                                                                                                                            "v-row",
                                                                                                                            {
                                                                                                                              key:
                                                                                                                                "additionalclassdays-" +
                                                                                                                                j +
                                                                                                                                _vm.localRefreshKey,
                                                                                                                              attrs: {
                                                                                                                                justify:
                                                                                                                                  "center",
                                                                                                                                align:
                                                                                                                                  "center"
                                                                                                                              }
                                                                                                                            },
                                                                                                                            [
                                                                                                                              _c(
                                                                                                                                "v-col",
                                                                                                                                {
                                                                                                                                  staticClass:
                                                                                                                                    "pl-0 pr-0 pt-0 pb-0",
                                                                                                                                  attrs: {
                                                                                                                                    cols:
                                                                                                                                      "3"
                                                                                                                                  }
                                                                                                                                },
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    "v-select",
                                                                                                                                    {
                                                                                                                                      attrs: {
                                                                                                                                        items:
                                                                                                                                          p ==
                                                                                                                                          6
                                                                                                                                            ? _vm.localDaysOfWeek.slice(
                                                                                                                                                0,
                                                                                                                                                7
                                                                                                                                              )
                                                                                                                                            : _vm.localDaysOfWeek.slice(
                                                                                                                                                7,
                                                                                                                                                14
                                                                                                                                              ),
                                                                                                                                        "hide-details":
                                                                                                                                          "true",
                                                                                                                                        disabled: !_vm.localIsEditingSchedule,
                                                                                                                                        dense:
                                                                                                                                          "",
                                                                                                                                        outlined:
                                                                                                                                          "",
                                                                                                                                        height:
                                                                                                                                          "55"
                                                                                                                                      },
                                                                                                                                      model: {
                                                                                                                                        value:
                                                                                                                                          acd.dayCode,
                                                                                                                                        callback: function(
                                                                                                                                          $$v
                                                                                                                                        ) {
                                                                                                                                          _vm.$set(
                                                                                                                                            acd,
                                                                                                                                            "dayCode",
                                                                                                                                            $$v
                                                                                                                                          )
                                                                                                                                        },
                                                                                                                                        expression:
                                                                                                                                          "acd.dayCode"
                                                                                                                                      }
                                                                                                                                    }
                                                                                                                                  )
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              ),
                                                                                                                              _c(
                                                                                                                                "v-col",
                                                                                                                                [
                                                                                                                                  _c(
                                                                                                                                    "v-row",
                                                                                                                                    [
                                                                                                                                      _c(
                                                                                                                                        "v-col",
                                                                                                                                        {
                                                                                                                                          attrs: {
                                                                                                                                            cols:
                                                                                                                                              "11"
                                                                                                                                          }
                                                                                                                                        },
                                                                                                                                        [
                                                                                                                                          _c(
                                                                                                                                            "pb-date-time-range-field",
                                                                                                                                            {
                                                                                                                                              attrs: {
                                                                                                                                                type:
                                                                                                                                                  "time",
                                                                                                                                                start:
                                                                                                                                                  acd.startTime,
                                                                                                                                                end:
                                                                                                                                                  acd.endTime,
                                                                                                                                                min:
                                                                                                                                                  _vm.startTime24HrFmt,
                                                                                                                                                max:
                                                                                                                                                  _vm.endTime24HrFmt,
                                                                                                                                                disabled: !_vm.localIsEditingSchedule,
                                                                                                                                                "hide-details":
                                                                                                                                                  "auto",
                                                                                                                                                offsetTop:
                                                                                                                                                  j ===
                                                                                                                                                  scheduleItem.additionalClassDays.filter(
                                                                                                                                                    function(
                                                                                                                                                      c
                                                                                                                                                    ) {
                                                                                                                                                      return p ==
                                                                                                                                                        6
                                                                                                                                                        ? _vm.week1DayCodes.includes(
                                                                                                                                                            c.dayCode
                                                                                                                                                          )
                                                                                                                                                        : _vm.week2DayCodes.includes(
                                                                                                                                                            c.dayCode
                                                                                                                                                          )
                                                                                                                                                    }
                                                                                                                                                  )
                                                                                                                                                    .length -
                                                                                                                                                    1
                                                                                                                                              },
                                                                                                                                              on: {
                                                                                                                                                "update:start": function(
                                                                                                                                                  $event
                                                                                                                                                ) {
                                                                                                                                                  return _vm.$set(
                                                                                                                                                    acd,
                                                                                                                                                    "startTime",
                                                                                                                                                    $event
                                                                                                                                                  )
                                                                                                                                                },
                                                                                                                                                "update:end": function(
                                                                                                                                                  $event
                                                                                                                                                ) {
                                                                                                                                                  return _vm.$set(
                                                                                                                                                    acd,
                                                                                                                                                    "endTime",
                                                                                                                                                    $event
                                                                                                                                                  )
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            }
                                                                                                                                          )
                                                                                                                                        ],
                                                                                                                                        1
                                                                                                                                      ),
                                                                                                                                      _c(
                                                                                                                                        "v-col",
                                                                                                                                        {
                                                                                                                                          staticClass:
                                                                                                                                            "pl-0 pr-0 pt-5 pb-0",
                                                                                                                                          attrs: {
                                                                                                                                            cols:
                                                                                                                                              "1"
                                                                                                                                          }
                                                                                                                                        },
                                                                                                                                        [
                                                                                                                                          _c(
                                                                                                                                            "v-btn",
                                                                                                                                            {
                                                                                                                                              directives: [
                                                                                                                                                {
                                                                                                                                                  name:
                                                                                                                                                    "show",
                                                                                                                                                  rawName:
                                                                                                                                                    "v-show",
                                                                                                                                                  value:
                                                                                                                                                    _vm.localIsEditingSchedule,
                                                                                                                                                  expression:
                                                                                                                                                    "localIsEditingSchedule"
                                                                                                                                                }
                                                                                                                                              ],
                                                                                                                                              attrs: {
                                                                                                                                                elevation:
                                                                                                                                                  "0",
                                                                                                                                                fab:
                                                                                                                                                  "",
                                                                                                                                                "x-small":
                                                                                                                                                  "",
                                                                                                                                                "aria-label":
                                                                                                                                                  "$t('removeDayLabel')"
                                                                                                                                              },
                                                                                                                                              on: {
                                                                                                                                                click: function(
                                                                                                                                                  $event
                                                                                                                                                ) {
                                                                                                                                                  $event.stopPropagation()
                                                                                                                                                  _vm.deleteScheduleDay(
                                                                                                                                                    scheduleItem.additionalClassDays.findIndex(
                                                                                                                                                      function(
                                                                                                                                                        c
                                                                                                                                                      ) {
                                                                                                                                                        return (
                                                                                                                                                          c.extraDay ==
                                                                                                                                                          acd.extraDay
                                                                                                                                                        )
                                                                                                                                                      }
                                                                                                                                                    )
                                                                                                                                                  )
                                                                                                                                                }
                                                                                                                                              }
                                                                                                                                            },
                                                                                                                                            [
                                                                                                                                              _c(
                                                                                                                                                "v-icon",
                                                                                                                                                [
                                                                                                                                                  _vm._v(
                                                                                                                                                    "fal fa-trash-alt"
                                                                                                                                                  )
                                                                                                                                                ]
                                                                                                                                              )
                                                                                                                                            ],
                                                                                                                                            1
                                                                                                                                          )
                                                                                                                                        ],
                                                                                                                                        1
                                                                                                                                      )
                                                                                                                                    ],
                                                                                                                                    1
                                                                                                                                  )
                                                                                                                                ],
                                                                                                                                1
                                                                                                                              )
                                                                                                                            ],
                                                                                                                            1
                                                                                                                          )
                                                                                                                        }
                                                                                                                      )
                                                                                                                    ],
                                                                                                                    2
                                                                                                                  )
                                                                                                                : _vm._e()
                                                                                                            ],
                                                                                                            1
                                                                                                          )
                                                                                                        }
                                                                                                      ),
                                                                                                      0
                                                                                                    )
                                                                                              ]
                                                                                            )
                                                                                      ]
                                                                                    ),
                                                                                    _vm
                                                                                      .getSchoolYearSettings
                                                                                      .classCycle !==
                                                                                    "twoWeeks"
                                                                                      ? _c(
                                                                                          "div",
                                                                                          [
                                                                                            _c(
                                                                                              "v-row",
                                                                                              {
                                                                                                directives: [
                                                                                                  {
                                                                                                    name:
                                                                                                      "show",
                                                                                                    rawName:
                                                                                                      "v-show",
                                                                                                    value:
                                                                                                      _vm.localIsEditingSchedule,
                                                                                                    expression:
                                                                                                      "localIsEditingSchedule"
                                                                                                  }
                                                                                                ]
                                                                                              },
                                                                                              [
                                                                                                _c(
                                                                                                  "v-col",
                                                                                                  {
                                                                                                    staticClass:
                                                                                                      "text-center",
                                                                                                    attrs: {
                                                                                                      cols:
                                                                                                        "12"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-btn",
                                                                                                      {
                                                                                                        attrs: {
                                                                                                          color:
                                                                                                            "primary",
                                                                                                          text:
                                                                                                            ""
                                                                                                        },
                                                                                                        on: {
                                                                                                          click: function(
                                                                                                            $event
                                                                                                          ) {
                                                                                                            $event.stopPropagation()
                                                                                                            return _vm.addScheduleDay(
                                                                                                              scheduleItem.scheduleId
                                                                                                            )
                                                                                                          }
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-icon",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              small:
                                                                                                                ""
                                                                                                            }
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              "fal fa-plus"
                                                                                                            )
                                                                                                          ]
                                                                                                        ),
                                                                                                        _c(
                                                                                                          "span",
                                                                                                          {
                                                                                                            staticClass:
                                                                                                              "ml-1"
                                                                                                          },
                                                                                                          [
                                                                                                            _vm._v(
                                                                                                              _vm._s(
                                                                                                                _vm.$t(
                                                                                                                  "addScheduleDayLabel"
                                                                                                                )
                                                                                                              )
                                                                                                            )
                                                                                                          ]
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              ],
                                                                                              1
                                                                                            ),
                                                                                            _vm._l(
                                                                                              scheduleItem.additionalClassDays,
                                                                                              function(
                                                                                                acd,
                                                                                                j
                                                                                              ) {
                                                                                                return _c(
                                                                                                  "v-row",
                                                                                                  {
                                                                                                    directives: [
                                                                                                      {
                                                                                                        name:
                                                                                                          "show",
                                                                                                        rawName:
                                                                                                          "v-show",
                                                                                                        value:
                                                                                                          _vm.localIsEditingSchedule,
                                                                                                        expression:
                                                                                                          "localIsEditingSchedule"
                                                                                                      }
                                                                                                    ],
                                                                                                    key:
                                                                                                      "additionalclassdays-" +
                                                                                                      j +
                                                                                                      _vm.localRefreshKey,
                                                                                                    attrs: {
                                                                                                      justify:
                                                                                                        "center",
                                                                                                      align:
                                                                                                        "center"
                                                                                                    }
                                                                                                  },
                                                                                                  [
                                                                                                    _c(
                                                                                                      "v-col",
                                                                                                      {
                                                                                                        staticClass:
                                                                                                          "pl-0 pr-0 pt-0 pb-0",
                                                                                                        attrs: {
                                                                                                          cols:
                                                                                                            "3"
                                                                                                        }
                                                                                                      },
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-select",
                                                                                                          {
                                                                                                            attrs: {
                                                                                                              items:
                                                                                                                _vm.localDaysOfWeek,
                                                                                                              "hide-details":
                                                                                                                "true",
                                                                                                              disabled: !_vm.localIsEditingSchedule,
                                                                                                              dense:
                                                                                                                "",
                                                                                                              outlined:
                                                                                                                "",
                                                                                                              height:
                                                                                                                "55"
                                                                                                            },
                                                                                                            model: {
                                                                                                              value:
                                                                                                                acd.dayCode,
                                                                                                              callback: function(
                                                                                                                $$v
                                                                                                              ) {
                                                                                                                _vm.$set(
                                                                                                                  acd,
                                                                                                                  "dayCode",
                                                                                                                  $$v
                                                                                                                )
                                                                                                              },
                                                                                                              expression:
                                                                                                                "acd.dayCode"
                                                                                                            }
                                                                                                          }
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    ),
                                                                                                    _c(
                                                                                                      "v-col",
                                                                                                      [
                                                                                                        _c(
                                                                                                          "v-row",
                                                                                                          [
                                                                                                            _c(
                                                                                                              "v-col",
                                                                                                              {
                                                                                                                attrs: {
                                                                                                                  cols:
                                                                                                                    "11"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "pb-date-time-range-field",
                                                                                                                  {
                                                                                                                    attrs: {
                                                                                                                      type:
                                                                                                                        "time",
                                                                                                                      start:
                                                                                                                        acd.startTime,
                                                                                                                      end:
                                                                                                                        acd.endTime,
                                                                                                                      min:
                                                                                                                        _vm.startTime24HrFmt,
                                                                                                                      max:
                                                                                                                        _vm.endTime24HrFmt,
                                                                                                                      disabled: !_vm.localIsEditingSchedule,
                                                                                                                      "hide-details":
                                                                                                                        "auto",
                                                                                                                      offsetTop:
                                                                                                                        j ===
                                                                                                                        scheduleItem
                                                                                                                          .additionalClassDays
                                                                                                                          .length -
                                                                                                                          1
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      "update:start": function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        return _vm.$set(
                                                                                                                          acd,
                                                                                                                          "startTime",
                                                                                                                          $event
                                                                                                                        )
                                                                                                                      },
                                                                                                                      "update:end": function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        return _vm.$set(
                                                                                                                          acd,
                                                                                                                          "endTime",
                                                                                                                          $event
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  }
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            ),
                                                                                                            _c(
                                                                                                              "v-col",
                                                                                                              {
                                                                                                                staticClass:
                                                                                                                  "pl-0 pr-0 pt-5 pb-0",
                                                                                                                attrs: {
                                                                                                                  cols:
                                                                                                                    "1"
                                                                                                                }
                                                                                                              },
                                                                                                              [
                                                                                                                _c(
                                                                                                                  "v-btn",
                                                                                                                  {
                                                                                                                    directives: [
                                                                                                                      {
                                                                                                                        name:
                                                                                                                          "show",
                                                                                                                        rawName:
                                                                                                                          "v-show",
                                                                                                                        value:
                                                                                                                          _vm.localIsEditingSchedule,
                                                                                                                        expression:
                                                                                                                          "localIsEditingSchedule"
                                                                                                                      }
                                                                                                                    ],
                                                                                                                    attrs: {
                                                                                                                      elevation:
                                                                                                                        "0",
                                                                                                                      fab:
                                                                                                                        "",
                                                                                                                      "x-small":
                                                                                                                        "",
                                                                                                                      "aria-label": _vm.$t(
                                                                                                                        "removeDayLabel"
                                                                                                                      )
                                                                                                                    },
                                                                                                                    on: {
                                                                                                                      click: function(
                                                                                                                        $event
                                                                                                                      ) {
                                                                                                                        $event.stopPropagation()
                                                                                                                        return _vm.deleteScheduleDay(
                                                                                                                          j
                                                                                                                        )
                                                                                                                      }
                                                                                                                    }
                                                                                                                  },
                                                                                                                  [
                                                                                                                    _c(
                                                                                                                      "v-icon",
                                                                                                                      [
                                                                                                                        _vm._v(
                                                                                                                          "fal fa-trash-alt"
                                                                                                                        )
                                                                                                                      ]
                                                                                                                    )
                                                                                                                  ],
                                                                                                                  1
                                                                                                                )
                                                                                                              ],
                                                                                                              1
                                                                                                            )
                                                                                                          ],
                                                                                                          1
                                                                                                        )
                                                                                                      ],
                                                                                                      1
                                                                                                    )
                                                                                                  ],
                                                                                                  1
                                                                                                )
                                                                                              }
                                                                                            )
                                                                                          ],
                                                                                          2
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                )
                                                                          ]
                                                                        )
                                                                      ],
                                                                      1
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.selectedClassExtraLessonsClone.length > 0
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "mt-8",
                                      attrs: { justify: "center" }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-1 pr-0 pb-0 pl-0",
                                          attrs: { col: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            {
                                              attrs: {
                                                height: "100%",
                                                outlined: ""
                                              }
                                            },
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "title-on-border"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t("addDaysLabel")
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _vm._l(
                                                    _vm.selectedClassExtraLessonsClone,
                                                    function(
                                                      extraLessonItem,
                                                      l
                                                    ) {
                                                      return _c(
                                                        "v-chip",
                                                        {
                                                          key:
                                                            "adddays-" +
                                                            l +
                                                            _vm.localRefreshKey,
                                                          attrs: {
                                                            "active-class":
                                                              "ma-2 accent1--text",
                                                            "input-value":
                                                              "true",
                                                            outlined: "",
                                                            close: "",
                                                            "close-icon":
                                                              "fal fa-trash-alt"
                                                          },
                                                          on: {
                                                            "click:close": function(
                                                              $event
                                                            ) {
                                                              return _vm.removeExtraLesson(
                                                                l
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.getDisplayDate(
                                                                  _vm.getExtraLessonOrNoClassDate(
                                                                    extraLessonItem.customDate
                                                                  )
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-8",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.localSelectedClassClone.noClass
                                              .length > 0 ||
                                            _vm.localShowRemoveDays,
                                          expression:
                                            "localSelectedClassClone.noClass.length > 0 || localShowRemoveDays"
                                        }
                                      ],
                                      staticClass: "pt-1 pr-0 pb-0 pl-0",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { outlined: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "title-on-border"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("removeDaysLabel")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._l(
                                                _vm.localSelectedClassClone
                                                  .noClass,
                                                function(noClassItem, e) {
                                                  return _c(
                                                    "v-chip",
                                                    {
                                                      key:
                                                        "noclass-" +
                                                        e +
                                                        _vm.localRefreshKey,
                                                      attrs: {
                                                        "active-class":
                                                          "ma-2 accent2--text",
                                                        "input-value": "true",
                                                        outlined: "",
                                                        close: "",
                                                        "close-icon":
                                                          "fal fa-trash-alt"
                                                      },
                                                      on: {
                                                        "click:close": function(
                                                          $event
                                                        ) {
                                                          return _vm.deleteNoClassDay(
                                                            e
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("span", [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.getDisplayDate(
                                                              _vm.getExtraLessonOrNoClassDate(
                                                                noClassItem.customDate
                                                              )
                                                            )
                                                          )
                                                        )
                                                      ])
                                                    ]
                                                  )
                                                }
                                              )
                                            ],
                                            2
                                          ),
                                          _c(
                                            "v-card-actions",
                                            [
                                              _vm.$vuetify.breakpoint.xs
                                                ? _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        justify: "center"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        [
                                                          _c("pb-date-field", {
                                                            key:
                                                              "noclass-selectdate-" +
                                                              _vm.localRefreshKey,
                                                            attrs: {
                                                              label: _vm.$t(
                                                                "selectDateLabel"
                                                              ),
                                                              hideDetails:
                                                                "auto",
                                                              value:
                                                                _vm.localClassRemoveDate,
                                                              errorMessage:
                                                                _vm.localClassRemoveDateErrorMessage,
                                                              dense: ""
                                                            },
                                                            on: {
                                                              "update:value": function(
                                                                $event
                                                              ) {
                                                                _vm.localClassRemoveDate = $event
                                                              },
                                                              change:
                                                                _vm.addNoClassDay
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                : _c(
                                                    "v-row",
                                                    [
                                                      _c("v-col", {
                                                        attrs: { cols: "3" }
                                                      }),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "6" }
                                                        },
                                                        [
                                                          _c(
                                                            "v-row",
                                                            {
                                                              staticClass:
                                                                "class-remove-date mx-10"
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "11"
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "pb-date-field",
                                                                    {
                                                                      key:
                                                                        "noclass-selectdate-" +
                                                                        _vm.localRefreshKey,
                                                                      attrs: {
                                                                        label: _vm.$t(
                                                                          "selectDateLabel"
                                                                        ),
                                                                        hideDetails:
                                                                          "auto",
                                                                        value:
                                                                          _vm.localClassRemoveDate,
                                                                        errorMessage:
                                                                          _vm.localClassRemoveDateErrorMessage,
                                                                        dense:
                                                                          ""
                                                                      },
                                                                      on: {
                                                                        "update:value": function(
                                                                          $event
                                                                        ) {
                                                                          _vm.localClassRemoveDate = $event
                                                                        },
                                                                        change:
                                                                          _vm.addNoClassDay
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      ),
                                                      _c("v-col", {
                                                        attrs: { cols: "3" }
                                                      })
                                                    ],
                                                    1
                                                  )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.localSelectedClassClone.noClass
                                              .length === 0 &&
                                            !_vm.localShowRemoveDays,
                                          expression:
                                            "localSelectedClassClone.noClass.length === 0 && !localShowRemoveDays"
                                        }
                                      ],
                                      staticClass:
                                        "pt-1 pr-0 pb-0 pl-0 ma-0 text-left",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary", text: "" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              _vm.localShowRemoveDays = true
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("fal fa-plus")]
                                          ),
                                          _c("span", { staticClass: "ml-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("removeDaysLabel"))
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _vm.showConnectToGoogle &&
                              _vm.localIsGoogleConnected
                                ? _c(
                                    "v-row",
                                    {
                                      staticClass: "mt-8",
                                      attrs: {
                                        align: "center",
                                        justify: "center"
                                      }
                                    },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pt-1 pr-0 pb-0 pl-0",
                                          attrs: { col: "12" }
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            { attrs: { outlined: "" } },
                                            [
                                              _c(
                                                "v-card-text",
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "title-on-border"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "googleClassLabel"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      staticClass: "mt-2",
                                                      attrs: {
                                                        justify: "center"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pt-0 pr-0 pb-0 pl-0 ma-0 text-left",
                                                          attrs: { col: "12" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-h6 ml-1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.localGoogleConnectedName
                                                                  ) +
                                                                  " "
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    [
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "pt-0 pr-0 pb-0 pl-0 ma-0 text-left",
                                                          attrs: { col: "12" }
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "syncLabel"
                                                                  )
                                                                ) +
                                                                " : "
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        justify: "center"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "v-chip-group",
                                                        {
                                                          staticStyle: {
                                                            "align-content":
                                                              "center"
                                                          },
                                                          attrs: {
                                                            "active-class":
                                                              "accent3--text font-weight-bold",
                                                            column: "",
                                                            multiple: ""
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.localGoogleChipOptions,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.localGoogleChipOptions = $$v
                                                            },
                                                            expression:
                                                              "localGoogleChipOptions"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "pb-chip",
                                                            {
                                                              attrs: {
                                                                filter: "",
                                                                outlined: ""
                                                              },
                                                              on: {
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  $event.stopPropagation()
                                                                  return _vm.arrayAddOrRemove(
                                                                    _vm.localGoogleChipOptions,
                                                                    0
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "courseworkLabel"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "pb-chip",
                                                            {
                                                              attrs: {
                                                                filter: "",
                                                                outlined: ""
                                                              },
                                                              on: {
                                                                keyup: function(
                                                                  $event
                                                                ) {
                                                                  if (
                                                                    !$event.type.indexOf(
                                                                      "key"
                                                                    ) &&
                                                                    _vm._k(
                                                                      $event.keyCode,
                                                                      "enter",
                                                                      13,
                                                                      $event.key,
                                                                      "Enter"
                                                                    )
                                                                  ) {
                                                                    return null
                                                                  }
                                                                  $event.stopPropagation()
                                                                  return _vm.arrayAddOrRemove(
                                                                    _vm.localGoogleChipOptions,
                                                                    1
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "studentsLabel"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: { justify: "end" }
                                                    },
                                                    [
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            text: ""
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.stopPropagation()
                                                              return _vm.disconnectGoogleClass.apply(
                                                                null,
                                                                arguments
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "v-icon",
                                                            {
                                                              attrs: {
                                                                small: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "fal fa-ban"
                                                              )
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-1"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "disconnectLabel"
                                                                  )
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-row",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.showConnectToGoogle &&
                                        !_vm.localIsGoogleConnected,
                                      expression:
                                        "showConnectToGoogle && !localIsGoogleConnected"
                                    }
                                  ],
                                  staticClass: "mt-8",
                                  attrs: { justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "pt-1 pr-0 pb-0 pl-0 ma-0 text-left",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            top: "",
                                            "offset-y": "",
                                            "max-height": 500
                                          },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function(ref) {
                                                var on = ref.on
                                                var attrs = ref.attrs
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                            text: ""
                                                          }
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _c(
                                                        "v-icon",
                                                        {
                                                          attrs: { small: "" }
                                                        },
                                                        [_vm._v("fal fa-plus")]
                                                      ),
                                                      _c(
                                                        "span",
                                                        { staticClass: "ml-1" },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$t(
                                                                "connectGoogleClassLabel"
                                                              )
                                                            )
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ])
                                        },
                                        [
                                          _c(
                                            "v-list",
                                            _vm._l(
                                              _vm.googleClassesList,
                                              function(item, e) {
                                                return _c(
                                                  "v-list-item",
                                                  {
                                                    key:
                                                      "googleclass-" +
                                                      e +
                                                      _vm.localRefreshKey,
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.connectGoogleClass(
                                                          item
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("v-list-item-title", [
                                                      _vm._v(_vm._s(item.name))
                                                    ])
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-8",
                                  attrs: { align: "center", justify: "center" }
                                },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            (_vm.localSelectedClassClone
                                              .classDesc &&
                                              _vm.localSelectedClassClone
                                                .classDesc !== "") ||
                                            _vm.localShowDescription,
                                          expression:
                                            "(localSelectedClassClone.classDesc && localSelectedClassClone.classDesc !== '') || localShowDescription"
                                        }
                                      ],
                                      staticClass: "pt-1 pr-0 pb-0 pl-0 ma-0",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-card",
                                        { attrs: { outlined: "" } },
                                        [
                                          _c(
                                            "v-card-text",
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "title-on-border"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$t("descriptionLabel")
                                                    )
                                                  )
                                                ]
                                              ),
                                              _c("pb-text-editor", {
                                                model: {
                                                  value:
                                                    _vm.localSelectedClassClone
                                                      .classDesc,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.localSelectedClassClone,
                                                      "classDesc",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "localSelectedClassClone.classDesc"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            (!_vm.localSelectedClassClone
                                              .classDesc ||
                                              _vm.localSelectedClassClone
                                                .classDesc === "") &&
                                            !_vm.localShowDescription,
                                          expression:
                                            "(!localSelectedClassClone.classDesc || localSelectedClassClone.classDesc === '') && !localShowDescription"
                                        }
                                      ],
                                      staticClass:
                                        "pt-1 pr-0 pb-0 pl-0 ma-0 text-left",
                                      attrs: { col: "12" }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { color: "primary", text: "" },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              _vm.localShowDescription = true
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            { attrs: { small: "" } },
                                            [_vm._v("fal fa-plus")]
                                          ),
                                          _c("span", { staticClass: "ml-1" }, [
                                            _vm._v(
                                              _vm._s(_vm.$t("descriptionLabel"))
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._t("default")
                    ],
                    2
                  )
                ],
                1
              )
            ]
          ),
          !_vm.$vuetify.breakpoint.smAndDown
            ? _c("v-col", { attrs: { cols: "1" } })
            : _vm._e()
        ],
        1
      ),
      _c("pb-confirm", { ref: "classEditorConfirm" }),
      _c("manage-lessons", {
        attrs: {
          classId: _vm.localSelectedClassClone.classId,
          startDate: _vm.localSelectedClassClone.classStartDate,
          endDate: _vm.localSelectedClassClone.classEndDate
        },
        model: {
          value: _vm.showManageLessons,
          callback: function($$v) {
            _vm.showManageLessons = $$v
          },
          expression: "showManageLessons"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }